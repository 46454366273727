<template>
    <div class="NoticiaModal">

        <div class="d-flex justify-content-between mt-2">
            <span class="txt-h-6 txt-size-10 txt-grey-2">
                {{formatDate(noticia.dtPublicacao)}}
            </span>

            <div class="funcoes-noticia d-flex align-items-center">
                <div class="txt-h-6 txt-size-10 txt-grey-7 divisor-rigth px-3 text-decoration-underline d-flex align-items-center c-pointer"
                     v-if="noticia.idNoticiaIngles != null"
                     @click="trocaLinguagem">
                    <i class="fa fa-language fa-2x mr-2"></i>

                    Traduzir texto
                </div>

                <div class="divisor-rigth px-3">
                    <span class="txt-h-6 txt-size-16 txt-grey-7">
                        Tamanho da fonte:
                    </span>
                    <button class="btn p-0 mx-2 txt-h-6 txt-size-16 txt-grey-7" @click="trocaFonte(1)"> <i class="fas fa-minus"></i> </button>
                    <button class="btn p-0 txt-h-6 txt-size-16 txt-grey-7" @click="trocaFonte(2)"> <i class="fas fa-plus"></i> </button>
                </div>
                <div class="ps-3 txt-size-25 ">
                    <a class="txt-grey-7" :href="getLinkShare(1)" target="_blank"><i class="fab fa-facebook-square c-pointer me-2"></i></a>
                    <a class="txt-grey-7" :href="getLinkShare(2)" target="_blank"><i class="fab fa-twitter-square c-pointer me-2"></i></a>
                    <a class="txt-grey-7" :href="getLinkShare(3)" target="_blank"><i class="fab fa-linkedin c-pointer me-2"></i></a>
                    <a class="txt-grey-7" :href="getLinkShare(4)" target="_blank"><i class="fab fa-whatsapp-square c-pointer"></i></a>
                </div>
            </div>
        </div>


        <p class="txt-h-1 txt-blue-2 mt-3 mb-0">
            {{dadosExibicao.dsTitulo}}
        </p>

        <p class="txt-p txt-size-20 txt-w-5 txt-grey-2 mt-3">
            {{dadosExibicao.dsSubTitulo}}
        </p>


        <figure class="mt-3">
            <picture>
                <!--<source :srcset="noticia.urlImagemInterna" media="(min-width: 600px)">-->
                <img class="w-100 imagem-noticia" :src="noticia.urlImagemInterna" onerror="this.onerror=null; this.src='/image-not-found.jpg'" :alt="noticia.dsLegendaImagemInterna" />
            </picture>
            <figcaption class="mt-2" v-if="noticia.dsLegendaImagemInterna">{{noticia.dsLegendaImagemInterna}}</figcaption>

        </figure>


        <!--    CONTEUDO DA NOTICIA    -->
        <div class="conteudo-noticia mt-3 recuo txt-p txt-grey-2" v-html="dadosExibicao.htmNoticia" :style="'font-size:'+tamanhoFonte+'rem'" oncopy="return false" oncut="return false" onpaste="return false"></div>

        <!--    COMPLEMENTOS    -->
        <template v-if="user || noticia.flPublico == 'Sim' ">
            <div class="w-100 bg-grey-1 p-4 mt-3" v-if="lstComplementos != null">
                <div class="row mb-3" v-for="item in lstComplementos" :key="item.idNoticiaComplemento">
                    <div class="col-12">

                        <template v-if="item.tpComplemento == 'Video'">
                            <button class="btn btn-blue btn-complemento reduz-texto-1" type="button" @click="showVideo(item)">
                                <span class="reduz-texto-1"><i class="fas fa-file-video"></i> {{item.dsComplemento}}</span>
                            </button>
                        </template>
                        <template v-else-if="item.tpComplemento == 'Audio'">
                            <button class="btn btn-blue btn-complemento reduz-texto-1" type="button" @click="showAudio(item)">
                                <span class="reduz-texto-1"><i class="fas fa-file-audio"></i> {{item.dsComplemento}}</span>
                            </button>
                        </template>
                        <template v-else-if="item.tpComplemento == 'Pdf'">
                            <a class="txt-p txt-blue-2 reduz-texto-1" :src="getLinkComplemento(item)" @click="geraLogComplementoItem(item.idNoticiaComplemento)" target="_blank">
                                <i class="fas fa-link"></i> {{item.dsComplemento}}
                            </a>
                        </template>
                        <template v-else-if="item.tpComplemento == 'Link'">
                            <a class="txt-p txt-blue-2 reduz-texto-1" :href="getLinkComplemento(item)" @click="geraLogComplementoItem(item.idNoticiaComplemento)" target="_blank">
                                <i class="fas fa-link"></i> {{item.dsComplemento}}
                            </a>
                        </template>

                    </div>
                </div>
            </div>
        </template>


        <!--    AUTOR    -->
        <div class="box-autor mt-4" v-if="noticia.autor">
            <div class="d-flex">
                <div class="img-autor me-5">
                    <img class="rounded-circle" width="150" height="150" :src="noticia.autor.urlImagem" />
                </div>
                <div class="dados-autor d-flex flex-column">
                    <span class="txt-h-6 txt-size-12 txt-grey-2">Escrito por</span>
                    <p class="txt-h-3 txt-size-25 txt-blue-2 mb-2">{{noticia.autor.nmUsuario}}</p>
                    <p class="txt-h-5 txt-size-18 txt-grey-2 txt-w-n">
                        {{noticia.autor.dsUsuario}}
                    </p>
                </div>
            </div>
        </div>

        <Tags class="mt-3" :titulo="'TAGS'" :lstTags="lstTags" :link="'PesquisaNoticia'"></Tags>

        <Comentarios class="mt-4" :id="id" :tipo="'Noticia'"></Comentarios>

        <ModalAudio :showModal="showModalAudio" :dados="audioModal"></ModalAudio>
        <ModalVideo :showModal="showModalVideo" :dados="videoModal"></ModalVideo>
    </div>
</template>

<script>
    import { urlApi, avisoErro, urlLeitorPDF } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'

    import Tags from '@/components/Biblioteca/Tags'
    import Comentarios from '@/components/Biblioteca/Comentarios'
    import ModalAudio from '@/components/Biblioteca/ModalAudio'
    import ModalVideo from '@/components/Biblioteca/ModalVideo'

    export default {
        name: 'NoticialModal',
        props: {
            idConteudo: Number,
        },
        components: {
            Tags, Comentarios, ModalAudio, ModalVideo
        },
        data: function () {
            return {
                noticia: null,
                lstTags: [],
                lstNoticiasRelacionadas: [],
                lstComplementos: null,

                linguagem: 'pt',

                dadosExibicao: null,

                tamanhoFonte: 1,

                showModalAudio: false,
                showModalVideo: false,

                audioModal: null,
                videoModal: null,

                idLogNoticiaComplemento: 0,
                qtdCaracteresCopia: 700,
            }
        },
        methods: {
            load() {
                this.carregaNoticia();
            },

            carregaNoticia() {
                let $this = this;
                axios.get(`${urlApi}/Noticia/Get/${$this.idConteudo}`)
                    .then(res => {
                        if (res.data.success) {
                            var noticia = res.data.data;
                            noticia.urlImagem = $this.getUrlS3(noticia.urlImagem);
                            noticia.urlImagemInterna = $this.getUrlS3(noticia.urlImagemInterna);

                            $this.noticia = noticia;
                            // SET TITLE PARA ARTIGO
                            if ($this.noticia.nmSubCategoria == "Artigo") {
                                $this.title = "Artigo";
                            }
                            $this.dadosExibicao = {
                                dsTitulo: $this.noticia.dsTitulo,
                                dsSubTitulo: $this.noticia.dsSubTitulo,
                                htmNoticia: $this.noticia.htmNoticia,
                            };

                            $this.montaTags($this.noticia.dsTags);

                            if ($this.user || $this.noticia.flPublico == 'Sim') {
                                $this.carregaComplementos($this.noticia.idNoticia);
                            }

                        } else {
                            avisoErro("Não foi possível carregar a notícia");
                            $this.noticiaDestaque = null;
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar a notícia");
                        $this.noticiaDestaque = null;
                    });
            },

            carregaComplementos(idNoticia) {
                let $this = this;
                $this.geraLogComplemento();
                axios.get(`${urlApi}/Noticia/ComplementosNoticia/${idNoticia}`)
                    .then(res => {
                        if (res.data.success) {
                            $this.lstComplementos = [];
                            if (res.data.data.rows != null) {
                                for (let i = 0; i < res.data.data.rows.length; i++) {
                                    let item = res.data.data.rows[i];

                                    $this.lstComplementos.push(item);
                                }
                            }
                        } else {
                            avisoErro("Não foi possível carregar os complementos");
                            $this.lstComplementos = null;
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os complementos");
                        $this.lstComplementos = null;
                    });
            },

            //  AÇÕES COMPLEMENTOS
            showVideo(complemento) {
                this.geraLogComplementoItem(complemento.idNoticiaComplemento);
                complemento.p_idVideo = complemento.idNoticiaComplemento;
                complemento.p_tipo = "ComplementoNoticia";
                complemento.p_tpExibicao = "Local";
                this.videoModal = complemento;
                this.showModalVideo = !this.showModalVideo;
            },

            showAudio(complemento) {
                this.geraLogComplementoItem(complemento.idNoticiaComplemento);
                complemento.p_idAudio = complemento.idNoticiaComplemento;
                complemento.p_tipo = "ComplementoNoticia";
                complemento.p_tpExibicao = "Local";
                this.audioModal = complemento;
                this.showModalAudio = !this.showModalAudio;
            },

            getLinkComplemento(complemento) {
                if (complemento.tpComplemento == 'Pdf') {
                    if (complemento.urlArquivo.substr(0, 1) != '/') {
                        complemento.urlArquivo = '/' + complemento.urlArquivo
                    }
                    return urlLeitorPDF + complemento.urlArquivo;
                } else {
                    return complemento.urlArquivo
                }
            },

            geraLogComplemento() {
                let $this = this;
                axios.post(`${urlApi}/Log/LogNoticiaComplemento`, { p_idLogNoticiaComplemento: 0, p_idNoticia: $this.noticia.idNoticia })
                    .then(res => {
                        if (res.data.success) {
                            $this.idLogNoticiaComplemento = res.data.msg;
                        } else {
                            console.log("Erro Log");
                        }
                    })
                    .catch(function () {
                        console.log("Erro Log");
                    });
            },

            geraLogComplementoItem(idNoticiaComplemento) {
                let $this = this;
                axios.post(`${urlApi}/Log/LogNoticiaComplementoItem`, { p_idLogNoticiaComplemento: parseInt($this.idLogNoticiaComplemento), p_idNoticiaComplemento: idNoticiaComplemento })
                    .then(res => {
                        if (res.data.success) {
                            console.log("Gerou Log");
                        } else {
                            console.log("Erro Log");
                        }
                    })
                    .catch(function () {
                        console.log("Erro Log");
                    });
            },

            montaTags(tags) {
                let $this = this;
                if (tags != null && tags != undefined) {
                    tags = tags.split(';');
                    if (tags != undefined && tags != null && tags != '') {
                        for (let i = 0; i < tags.length; i++) {
                            let tag = tags[i].trim();
                            $this.lstTags.push(tag);
                        }
                    }
                }
            },

            formatDate(date) {
                return moment(date).format('DD/MM/YYYY [às] HH:mm');
            },

            formatLink(titulo) {
                if (titulo) {
                    return titulo.toLowerCase().replace(/\s/g, '-');
                } else {
                    return "";
                }

            },

            trocaLinguagem() {
                let $this = this;
                if ($this.linguagem == 'pt') {
                    $this.linguagem = 'en';
                    $this.dadosExibicao = {
                        dsTitulo: $this.noticia.noticiaIngles.dsTitulo,
                        dsSubTitulo: $this.noticia.noticiaIngles.dsSubTitulo,
                        htmNoticia: $this.noticia.noticiaIngles.htmNoticia,
                    };
                } else {
                    $this.linguagem = 'pt';
                    $this.dadosExibicao = {
                        dsTitulo: $this.noticia.dsTitulo,
                        dsSubTitulo: $this.noticia.dsSubTitulo,
                        htmNoticia: $this.noticia.htmNoticia,
                    };
                }
            },

            trocaFonte(tipo) {
                let $this = this;
                if (tipo == 2) {
                    $this.tamanhoFonte = parseFloat($this.tamanhoFonte) + parseFloat(0.1);
                } else {
                    $this.tamanhoFonte = parseFloat($this.tamanhoFonte) - parseFloat(0.1);
                }
            },

            getLinkShare(tipo) {
                var url = window.location.href;
                var uri = '';
                switch (tipo) {
                    case 1:
                        // facebook
                        return 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(url);
                    case 2:
                        // twitter
                        uri = encodeURI(this.noticia.dsTitulo + ' ' + url)
                        return 'https://twitter.com/intent/tweet?text=' + uri
                    case 3:
                        // linkedin
                        return 'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURI(url);
                    case 4:
                        // whats
                        uri = encodeURI(this.noticia.dsTitulo + ' ' + url)
                        return 'whatsapp://send?' + uri
                }
            },
                        
            getUrlS3(url) {
                return `${urlApi}/Utilidade/GetUrlS3?url=${url}`;
            },
            
        },
        mounted: function () {
            this.load();
        },
        watch: {
            
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">

</style>

