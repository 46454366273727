<template>
    <div class="video-player">
        <video ref="videoPlayer" class="video-js"></video>
    </div>
</template>

<script>
    import videojs from 'video.js';
    import 'video.js/dist/video-js.css'

    //videoOptions: {
    //    autoplay: true,
    //        controls: true,
    //            sources: [
    //                {
    //                    src:
    //                        "/path/to/video.mp4",
    //                    type: "video/mp4"
    //                }
    //            ]
    //}

    export default {
        name: 'VideoPlayer',
        props: {
            options: {
                type: Object,
                default() {
                    return {};
                }
            }
        },
        data: function () {
            return {
                player: null
            }
        },
        mounted() {
            this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {

            })
        },
        beforeDestroy() {
            if (this.player) {
                this.player.dispose()
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .video-player, .video-js {
        max-width: 100%;
        max-height: 500px;
    }

    @media (max-width: 575.98px) {
        .video-player, .video-js {
            max-width: 100%;
            max-height: 300px;
        }
    }
</style>

