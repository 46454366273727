<template>
    <section class="detalheprocesso">
        <Header></Header>

        <div class="container mt-3">
            <div class="row">
                <div class="col-12">
                    <span class="txt-h-4 txt-grey-6 c-pointer" @click="votar"><i class="fas fa-arrow-left"></i> Voltar</span>
                </div>
            </div>

        </div>

        <div class="container">
            <div class="row mb-4">
                <div class="col-7">
                    <div class="w-100 h-100 d-flex justify-content-between align-items-center" v-if="contagemPrazo">
                        <span class="txt-p txt-w-7 txt-size-18 txt-grey-6">
                            {{contagemPrazo.dsEdital}}
                        </span>
                        <button class="btn btn-contador_prazos-outline px-4 d-none d-sm-block" @click="showModalRelatorioProcesso = !showModalRelatorioProcesso">
                            <i class="fas fa-print"></i> Imprimir lista de ocorrências
                        </button>
                    </div>
                </div>
                <div class="col-5">
                    <button class="btn btn-contador_prazos-outline px-4 d-block d-sm-none" @click="showModalRelatorioProcesso = !showModalRelatorioProcesso">
                        <i class="fas fa-print"></i> Imprimir lista de ocorrências
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <div class="row mb-2 pb-2" style=" border-bottom: 2px solid #DADADA;">
                        <div class="col-2">
                            <span class="txt-p txt-w-5 txt-size-14 txt-grey-3">
                                Concluído
                            </span>
                        </div>
                        <div class="col-10 text-center">
                            <span class="txt-p txt-w-5 txt-size-14 txt-grey-3">
                                Ocorrência
                            </span>
                        </div>
                    </div>
                    <div class="row item-ocorrencia" v-for="(item,index) in lstGridPrazo" :key="index">
                        <template v-if="item.flTipo == 'Data da Sessão'">
                            <!-- DATA DA SESSAO -->
                            <div class="col-2 d-flex align-items-center justify-content-center">

                            </div>
                            <div class="col-10 detalhes-ocorrencia detalhes-vermelho">
                                <p class="txt-p txt-w-7 txt-size-18 data-ocorrencia">
                                    <i class="fas fa-calendar"></i> {{formatDateTime(contagemPrazo.dtSessao)}}
                                </p>
                                <p class="txt-p txt-w-7 txt-size-14 txt-grey-3">
                                    Data da sessão
                                </p>
                            </div>
                        </template>
                        <template v-else-if="item.flTipo == 'Evento'">
                            <div class="col-2 d-flex align-items-center justify-content-center">
                                <div class="form-check" v-if="item.flConcluido != 'Sim'">
                                    <input type="checkbox" class="form-check-input" @click="marcarConcluido(item.idPrazo, index)" />
                                </div>
                            </div>
                            <div class="col-10 detalhes-ocorrencia detalhes-azul c-pointer" @click="editarOcorrencia(item.idPrazo)">
                                <p class="txt-p txt-w-7 txt-size-18 data-ocorrencia">
                                    <i class="fas fa-calendar"></i> {{formatDateTime(item.dtPrazo)}}
                                </p>
                                <p class="txt-p txt-w-7 txt-size-14 txt-grey-3">
                                    {{item.dsPrazo}}
                                </p>
                                <p class="txt-p txt-w-4 txt-size-14 txt-grey-3" v-if="item.flConcluido == 'Sim'">
                                    Concluído em <span class="txt-w-bold">{{formatDateTime(item.dtConcluido)}}</span> por <span class="txt-w-bold">{{item.dsLoginConcluido}}</span>
                                </p>
                            </div>
                        </template>

                        <template v-else>

                            <div class="col-2 d-flex align-items-center justify-content-center">
                                <div class="form-check" v-if="item.flConcluido != 'Sim'">
                                    <input type="checkbox" class="form-check-input" @click="marcarConcluido(item.idPrazo, index)" />
                                </div>
                            </div>
                            <div class="col-10 detalhes-ocorrencia detalhes-verde c-pointer" @click="editarOcorrencia(item.idPrazo)">
                                <p class="txt-p txt-w-7 txt-size-18 data-ocorrencia">
                                    <i class="fas fa-calendar"></i> {{formatDateTime(item.dtPrazo)}}
                                </p>
                                <p class="txt-p txt-w-7 txt-size-14 txt-grey-3">
                                    {{item.dsPrazo}}
                                </p>
                                <p class="txt-p txt-w-4 txt-size-14 txt-grey-3" v-if="item.flConcluido == 'Sim'">
                                    Concluído em <span class="txt-w-bold">{{formatDateTime(item.dtConcluido)}}</span> por <span class="txt-w-bold">{{item.dsLoginConcluido}}</span>
                                </p>
                            </div>

                        </template>

                    </div>

                    <div class="row my-5">
                        <div class="col-12">
                            <button class="btn btn-contador_prazos w-100" @click="novaOcorrencia">
                                <i class="fas fa-plus"></i> Adicionar ocorrência
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <div class="box-anotacoes h-100 mb-2">
                        <div class="row">
                            <div class="col-12 col-md-6 d-flex align-items-center" >
                                <span class="txt-p txt-w-7 txt-size-16 txt-grey-6">
                                    Anotações
                                </span>
                            </div>
                            <div class="col-12 col-md-6 d-flex align-items-start align-items-md-end">
                                <button class="btn btn-contador_prazos px-4 px-sm-2" @click="novaAnotacao">
                                    <i class="fas fa-plus d-block d-md-none"></i> <span class="d-none d-md-block"><i class="fas fa-plus"></i> Adicionar anotação</span> 
                                </button>
                            </div>
                        </div>
                        <div class="item-anotacoes mt-3 ps-1 ps-md-3" v-for="(item,index) in lstAnotacoes" :key="index">
                            <div class="divisor w-100 d-flex justify-content-between align-items-center position-relative">
                                <span class="txt-p txt-w-7 txt-size-16 txt-grey-2">
                                    {{formatDateTime(item.dtAnotacao)}}
                                </span>
                                <button class="btn btn-excluir px-1 px-md-4 d-none d-sm-block" @click="excluirAnotacao(item.idContagemPrazoAnotacao,item.dsTitulo )">
                                    <span>
                                        <i class="fas fa-trash-alt"></i>
                                    </span>
                                </button>
                                <div class="d-block d-sm-none ">
                                    <button class="btn btn-excluir btn-excluir-sm px-1 px-md-4 " @click="excluirAnotacao(item.idContagemPrazoAnotacao,item.dsTitulo )">
                                        <span>
                                    <i class="fas fa-trash-alt"></i>
                                        </span>
                                    </button>
                                </div>
                                
                            </div>
                            <div class="mt-2">
                                <p class="txt-p txt-w-7 txt-size-16 txt-grey-6">
                                    {{item.dsTitulo}}
                                </p>
                                <p class="txt-p txt-w-4 txt-size-14 txt-grey-6">
                                    {{item.dsAnotacao}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalOcorrencia v-if="contagemPrazo"
                         :showModal="showModalOcorrencia"
                         :idContagemPrazoData="idContagemPrazoData"
                         :idModalidade="contagemPrazo.idModalidade"
                         :idContagemPrazo="contagemPrazo.idContagemPrazo"
                         :atualizar="carregaProcesso"></ModalOcorrencia>

        <ModalAnotacao v-if="contagemPrazo"
                       :showModal="showModalAnotacao"
                       :idContagemPrazoAnotacao="idContagemPrazoAnotacao"
                       :idContagemPrazo="contagemPrazo.idContagemPrazo"
                       :atualizar="carregaAnotacoes"></ModalAnotacao>

        <ModalRelatorioProcesso v-if="id"
                       :idProcesso="parseInt(id)"
                       :showModal="showModalRelatorioProcesso"
                       ></ModalRelatorioProcesso>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';
    import ModalOcorrencia from '@/components/ContadorPrazos/ModalOcorrencia';
    import ModalAnotacao from '@/components/ContadorPrazos/ModalAnotacao';
    import ModalRelatorioProcesso from '@/components/ContadorPrazos/ModalRelatorioProcesso';

    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'
    import Swal from 'sweetalert2'

    export default {
        name: 'DetalheProcesso',
        components: { Header, Footer, ModalOcorrencia, ModalAnotacao, ModalRelatorioProcesso },
        props: ['id'],
        computed: {
            user() {
                return this.$store.state.user
            },
        },
        data() {
            return {
                lstAnotacoes: [],

                contagemPrazo: null,
                lstGridPrazo: [],

                showModalOcorrencia: false,
                idContagemPrazoData: null,

                showModalAnotacao: false,
                idContagemPrazoAnotacao: null,

                showModalRelatorioProcesso: false,
            }
        },
        methods: {
            votar() {
                this.$router.go(-1);
            },

            load() {
                this.carregaProcesso();
                this.carregaAnotacoes();
            },

            carregaProcesso() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/DetalhesProcesso/${$this.id}`)
                    .then(res => {
                        if (res.data.success) {
                            $this.contagemPrazo = null;
                            $this.lstGridPrazo = [];
                            if (res.data.data != null) {
                                $this.contagemPrazo = res.data.data.contagemPrazo;
                                let lstGridPrazo = res.data.data.lstGridPrazo;

                                for (let i = 0; i < lstGridPrazo.length; i++) {
                                    var item = lstGridPrazo[i];
                                    item.totalDias = moment(item.dtPrazo, 'DD/MM/YYYY HH:mm:ss').diff(moment(), 'days');
                                    $this.lstGridPrazo.push(item);
                                }
                            }
                        } else {
                            avisoErro("Não foi possível carregar os processos");
                            $this.contagemPrazo = null;
                            $this.lstGridPrazo = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os processos");
                        $this.contagemPrazo = null;
                        $this.lstGridPrazo = [];
                    });
            },

            carregaAnotacoes() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/GetAnotacoes/${$this.id}`)
                    .then(res => {
                        if (res.data.success) {
                            $this.lstAnotacoes = [];
                            if (res.data.data != null) {
                                for (let i = 0; i < res.data.data.rows.length; i++) {
                                    var item = res.data.data.rows[i];
                                    item.dtAnotacao = item.dtAnotacao.split('T')[0] + 'T' + item.hrAnotacao;
                                    $this.lstAnotacoes.push(item);
                                }
                            }
                        } else {
                            avisoErro("Não foi possível carregar as anotações");
                            $this.lstAnotacoes = [];
                        }
                    })
                    .catch(function (){
                        avisoErro("Não foi possível carregar as anotações");
                        $this.lstAnotacoes = [];
                    });
            },

            marcarConcluido(idContagemPrazoData, index) {
                let $this = this;
                Swal.fire({
                    title: 'Deseja marcar como concluído?',
                    text: 'Esta operação não poderá ser desfeita.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    confirmButtonColor: '#83A431',
                }).then((result) => {
                    if (result.isConfirmed) {
                        $this.setConcluido(idContagemPrazoData, index);
                    }
                });
            },

            setConcluido(idContagemPrazoData, index) {
                let $this = this;
                axios.post(`${urlApi}/ContadorPrazo/MarcarDataConcluida`, { idContagemPrazoData: idContagemPrazoData })
                    .then(res => {
                        if (res.data.success) {
                            var result = res.data.data;
                            
                            $this.lstGridPrazo[index].dtConcluido = result.dtConcluido;
                            $this.lstGridPrazo[index].dsLoginConcluido = result.dsLoginConcluido;
                            $this.lstGridPrazo[index].flConcluido = result.flConcluido;

                            avisoSucesso("Ocorrência marcada como concluída");
                        } else {
                            avisoErro("Não foi possível marcar como concluído");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível marcar como concluído");
                    });
            },

            novaOcorrencia() {
                this.showModalOcorrencia = !this.showModalOcorrencia;
                this.idContagemPrazoData = null;
            },

            editarOcorrencia(idPrazo) {
                this.showModalOcorrencia = !this.showModalOcorrencia;
                this.idContagemPrazoData = idPrazo;
            },

            novaAnotacao() {
                this.showModalAnotacao = !this.showModalAnotacao;
                this.idContagemPrazoAnotacao = null;
            },

            formatDateTime(dt) {
                return moment(dt).format("DD/MM/YYYY [às] HH:mm");
            },

            excluirAnotacao(idContagemPrazoAnotacao, nmAnotacao) {
                let $this = this;
                Swal.fire({
                    title: 'Tem certeza que deseja excluir "' + nmAnotacao + '"?',
                    text: 'Esta operação não poderá ser desfeita.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    confirmButtonColor: '#83A431',
                }).then((result) => {
                    if (result.isConfirmed) {
                        $this.removeAnotacao(idContagemPrazoAnotacao);
                    }
                })
            },

            removeAnotacao(idContagemPrazoAnotacao) {
                let $this = this;
                axios.delete(`${urlApi}/ContadorPrazo/DeleteAnotacao`, { params: { idContagemPrazoAnotacao: idContagemPrazoAnotacao } })
                    .then(res => {
                        if (res.data.success) {
                            $this.carregaAnotacoes();
                            avisoSucesso("Anotação excluida com sucesso");
                        } else {
                            avisoErro("Não foi possível excluir a anotação");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível excluir a anotação");
                    });
            },

        },
        mounted: function () {
            this.load();
        },
        watch: {
            
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .item-ocorrencia {
        border-bottom: 2px solid #DADADA;
        padding: 20px 0;

        .detalhes-ocorrencia {
            border-left: 10px solid #9BB854;
        }

        .detalhes-vermelho {
            border-left: 10px solid #D88E8F;

            .data-ocorrencia {
                color: #D88E8F !important;
            }
        }

        .detalhes-azul {
            border-left: 10px solid #7BBDD9;

            .data-ocorrencia {
                color: #7BBDD9 !important;
            }
        }

        .detalhes-verde {
            border-left: 10px solid #9BB854;

            .data-ocorrencia {
                color: #9BB854 !important;
            }
        }
    }

    .box-anotacoes {
        background: #FFFFFF;
        border: 3px solid #7C7C7C;
        border-radius: 20px;
        padding: 20px;

        .item-anotacoes {
            background: #FFFFFF;
            border: 1px solid #A6A6A6;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            border-radius: 5px;

            .divisor {
                border-bottom: 1px solid #F2F2F2;
            }
        }

        .btn-excluir {
            border-radius: 0 2px 0 0;
            background: #D78E8E;
            padding: 5px;
            border: 3px solid;
            border-color: #D78E8E;
            color: #F2F2F2;
            font-size: 18px;
        }

        .btn-excluir-sm {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .box-anotacoes:hover {
        .btn-excluir-sm {
            display: block;
        }
    }

    
</style>

