<template>
    <section class="homelogado">
        <Header></Header>

        <div class="container mt-4">
            <Modulos class="mt-5"></Modulos>
        </div>

        <Footer class="mt-5"></Footer>

    </section>
</template>

<script>
    import Header from '@/components/Header'
    import Footer from '@/components/Footer'
    import Modulos from '@/components/Modulos'

    //import { urlApi, avisoErro } from '@/config/global'
    //import axios from 'axios'

    export default {
        name: 'MeusModulos',
        components: { Header, Footer, Modulos },
        data() {
            return {
                
            }
        },
        methods: {
           
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .inputs-pesquisa {
        top: -18px;
        position: absolute;
    }

    
</style>

