<template>
    <div class="ModalProcesso">
        <b-modal ref="modal-processo" id="ModalProcesso" size="lg" :hide-footer="true" :hide-header="true">
            <div class="modal-titulo txt-p txt-w-7 txt-size-20 txt-grey-2 m-2">
                <p class="txt-w-20 mb-0 txt-contador_prazos">{{titulo}}</p>
                <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <div class="modal-conteudo m-2 px-3">
                <form class="mt-2">
                    <div class="mb-3">
                        <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                            1. Nome do processo:
                        </label>
                        <div class="w-100">
                            <input class="form-control" type="text" id="dsEdital" aria-describedby="dsEdital" placeholder="Nome do processo" maxlength="100" v-model="processo.dsEdital">
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="NumroEdital" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                            2. Modalidade, número do edital e ano:
                        </label>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <select class="form-select" aria-label="Selecione uma modalidade" v-model="processo.idModalidade">
                                    <option class="d-none" value="null" selected disabled>Selecione uma modalidade</option>
                                    <option :value="item.idModalidade" v-for="item in lstModalidades" :key="item.idModalidade">{{item.nmModalidade}}</option>
                                </select>
                            </div>

                            <div class="col-6">
                                <input class="form-control" type="number" id="nrEdital" aria-describedby="nrEdital" placeholder="Número do edital" maxlength="50" v-model="processo.nrEdital">
                            </div>
                            <div class="col-6">
                                <date-picker class="w-100" v-model="processo.nrAno" type="year" placeholder="Ano"></date-picker>
                            </div>

                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="DataHora" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                            3. Data e Hora:
                        </label>
                        <div class="row">
                            <div class="col-6">
                                <date-picker class="w-100" v-model="processo.dtSessao" format="DD/MM/YYYY" value-type="format" placeholder="Data"></date-picker>
                            </div>
                            <div class="col-6">
                                <date-picker class="w-100" v-model="processo.hrSessao" type="time" format="HH:mm" placeholder="Hora"></date-picker>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer mt-3">
                <button class="btn btn-contador_prazos w-100 px-4 py-2" type="button" @click="salvar" :disabled="validaSalvar">
                    <span v-if="idProcesso">Editar </span>
                    <span v-else >Adicionar </span>
                    processo
                </button>
            </div>
        </b-modal>
    </div>
</template> 

<script>
    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import axios from 'axios'
import * as moment from 'moment';
    //import moment from 'moment'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/pt-br';

    export default {
        name: 'ModalProcesso',
        props: {
            idProcesso: Number,
            showModal: Boolean,
            atualizar: Function,
            lstModalidades: Array,
        },
        components: { DatePicker },
        data: function () {
            return {
                titulo: 'Adicionar novo processo',

                processo: {
                    idContagemPrazo: 0,
                    idModalidade: null,
                    dsEdital: null,
                    nrEdital: null,
                    nrAno: null,
                    dtSessao: null,
                    hrSessao: null,
                },
            }
        },
        computed: {
            validaSalvar() {
                let $this = this;
                if ($this.processo.idModalidade == null) {
                    return true;
                }

                if ($this.processo.dsEdital == null || $this.processo.dsEdital == '') {
                    return true;
                }

                if ($this.processo.nrEdital == null || $this.processo.nrEdital == 0) {
                    return true;
                }

                if ($this.processo.nrAno == null || $this.processo.nrAno == '') {
                    return true;
                }

                if ($this.processo.dtSessao == null || $this.processo.dtSessao == '') {
                    return true;
                }

                if ($this.processo.hrSessao == null || $this.processo.hrSessao == '') {
                    return true;
                }

                return false;
            },
        },
        methods: {
            load() {
                this.processo = {
                    idContagemPrazo: 0,
                    idModalidade: null,
                    dsEdital: null,
                    nrEdital: null,
                    nrAno: null,
                    dtSessao: null,
                    hrSessao: null,
                };

                if (this.idProcesso) {
                    this.carregaProcesso();
                }
                this.$refs['modal-processo'].show();
            },

            carregaProcesso() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/Get/${$this.idProcesso}`)
                    .then(res => {
                        if (res.data.success) {
                            let processo = res.data.data;
                            let nrAno = new Date().setFullYear(processo.nrAno);
                            $this.processo = {
                                idContagemPrazo: processo.idContagemPrazo,
                                idModalidade: processo.idModalidade,
                                dsEdital: processo.dsEdital,
                                nrEdital: processo.nrEdital,
                                nrAno: new Date(nrAno),
                                dtSessao: moment(processo.dtSessao).format('DD/MM/YYYY'),
                                hrSessao: new Date(processo.dtSessao),
                            };
                        } else {
                            avisoErro("Não foi possível carregar o processo");
                        }
                    })
                    .catch(res => {
                        avisoErro(res.response.data.errors[0]);
                    });
            },

            
            hideModalFn() {
                this.$refs['modal-processo'].hide();
            },

            salvar() {
                let $this = this;
                var processo = Object.assign({}, $this.processo);
                processo.nrAno = moment(processo.nrAno).format('YYYY');
                processo.nrEdital = parseInt(processo.nrEdital);
                processo.hrSessao = moment(processo.hrSessao).format('YYYY-MM-DD HH:mm:ss');
                processo.dtSessao = moment(processo.dtSessao,'DD/MM/YYYY').format('YYYY-MM-DD') + ' 00:00:00';
                axios.post(`${urlApi}/ContadorPrazo/Post`, processo, { showLoading: true })
                    .then(res => {
                        if (res.data.success) {
                            $this.atualizar();
                            avisoSucesso("Processo salvo com sucesso.");
                            $this.hideModalFn();
                        } else {
                            avisoErro("Não foi possível salvar o processo");
                        }
                    })
                    .catch(res => {
                        
                        if (res) {
                            avisoErro(res.response.data.errors[0]);
                        } else {
                            avisoErro("Não foi possível salvar o processo");
                        }
                        
                    });
            },
        },
        mounted: function () {
        },
        watch: {
            showModal: function () {
                this.load();
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #DADADA;
        display: flex;
        justify-content: space-between;
    }

    .modal-conteudo {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 70vh;
    }

</style>

