<template>
    <div class="viewACORD" v-if="dados">
        <div class="modal-conteudo m-2">
            <div id="divImprimir">
                <div class="row mb-2">
                    <div class="col-md-4">
                        <h4 class="txtApp">Número do Acórdão:</h4>
                        <span class="regular"> {{dados.nrAcordao}}/{{dados.nrAnoAcordao}} - {{dados.nmColegiado}}</span>
                    </div>
                    <div class="col-md-4">
                        <h4 class="txtApp">Relator:</h4>
                        <span class="regular" v-html="dados.nmRelator"></span>
                    </div>
                    <div class="col-md-4">
                        <h4 class="txtApp">Processo:</h4>
                        <span class="regular" v-html="dados.nmProcesso"></span>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-4">
                        <h4 class="txtApp">Tipo de processo:</h4>
                        <span class="regular" v-html="dados.nmTipoProcesso"></span>
                    </div>
                    <div class="col-md-4">
                        <h4 class="txtApp">Data da sessão:</h4>
                        <template v-if="dados.dtSessao == null || dados.dtSessao == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dtSessao"></span>
                        </template>
                    </div>
                    <div class="col-md-4">
                        <h4 class="txtApp">Número da ata:</h4>
                        <span class="regular" v-html="dados.nrAta"></span>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Interessado / Responsável / Recorrente:</h4>
                        <template v-if="dados.dsInteressados == null || dados.dsInteressados == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dsInteressados"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Entidade:</h4>
                        <template v-if="dados.nmEntidade == null || dados.nmEntidade == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.nmEntidade"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Representante do Ministério Público:</h4>
                        <template v-if="dados.nmRepresentanteEmpresa == null || dados.nmRepresentanteEmpresa == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.nmRepresentanteEmpresa"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Unidade Técnica:</h4>
                        <template v-if="dados.nmUnidadeTecnica == null || dados.nmUnidadeTecnica == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.nmUnidadeTecnica"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Representante Legal:</h4>
                        <template v-if="dados.nmAdvogado == null || dados.nmAdvogado == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.nmAdvogado"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Assunto:</h4>
                        <template v-if="dados.dsAssunto == null || dados.dsAssunto == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dsAssunto"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Sumário:</h4>
                        <template v-if="dados.dsSumario == null || dados.dsSumario == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dsSumario"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Acórdão:</h4>
                        <template v-if="dados.dsAcordao == null || dados.dsAcordao == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dsAcordao"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Quórum:</h4>
                        <template v-if="dados.dsQuorum == null || dados.dsQuorum == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dsQuorum"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Relatório:</h4>
                        <template v-if="dados.nmRelatorio == null || dados.nmRelatorio == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.nmRelatorio"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Voto:</h4>
                        <template v-if="dados.dsVoto == null || dados.dsVoto == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dsVoto"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Fragmentos do Inteiro Teor:</h4>
                        <template v-if="dados.dsFragmentosInteiroTeor == null || dados.dsFragmentosInteiroTeor == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dsFragmentosInteiroTeor"></span>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer pb-0 mt-4 d-flex justify-content-end">
            <AdicionarBiblioteca v-if="exibeBiblioteca" :id="dados.idAcordao.toString()" :tipo="tpConteudo" :cdConteudo="cdConteudo"></AdicionarBiblioteca>

            <button class="btn btn-blue txt-size-14" @click="imprimir">
                <i class="fas fa-print"></i> Imprimir
            </button>
        </div>
    </div>
</template>

<script>
    import AdicionarBiblioteca from '../AdicionarBiblioteca'
    import { urlApi } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'viewACORD',
        components: { AdicionarBiblioteca },
        data: function () {
            return {
                exibeBiblioteca: true,
                tpConteudo: 'Pesquisa',
                cdConteudo: 'ACORD',
            }
        },
        props: {
            dados: Object,
        },
        methods: {
            verificaExisteRepositorio(idConteudo, tpConteudo, cdConteudo) {
                let $this = this;
                axios.get(`${urlApi}/Repositorio`, { params: { idConteudo: idConteudo, tpConteudo: tpConteudo, cdConteudo: cdConteudo } })
                    .then(res => {
                        if (res.data.success) {
                            $this.exibeBiblioteca = res.data.data;
                        } else {
                            console.log('Não foi possível verificar o repositório')
                        }
                    })
                    .catch(function () {
                        console.log('Não foi possível verificar o repositório')
                    });
            },

            imprimir() {
                var conteudo = document.getElementById('divImprimir').innerHTML,
                    tela_impressao = window.open('about:blank');

                tela_impressao.document.write(conteudo);
                tela_impressao.window.print();
                tela_impressao.window.close();
            },
        },
        mounted: function () {
            this.verificaExisteRepositorio(this.dados.idAcordao.toString(), this.tpConteudo, this.cdConteudo);
        },
        watch: {
            dados: {
                handler() {
                    this.verificaExisteRepositorio(this.dados.idAcordao.toString(), this.tpConteudo, this.cdConteudo);
                },
                deep: true
            },
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    
   
</style>

