<template>
    <section class="Biblioteca">
        <Header></Header>
        <div class="container">
            <div class="row my-3">
                <div class="col-12 text-center">
                    <p class="txt-p txt-size-20 txt-biblioteca mb-0">
                        Sollicita <span class="txt-w-7">Biblioteca</span>
                    </p>
                </div>
                <div class="col-12 text-end">
                    <ManualFerramenta :name="'Manual Biblioteca'" :btnClass="'btn-biblioteca'" :nameBtn="'Manual'" :urlPdf="'/manuais/FERRAMENTA BIBLIOTECA PDF.pdf'"></ManualFerramenta>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="box-branco">
                        <div class="row mb-3">
                            <div class="input-group">
                                <span class="input-group-text bg-white border-biblioteca" id="dsTermo"><i class="fas fa-search"></i></span>
                                <input type="text" class="form-control border-biblioteca" ref="dsTermo" style="border-left: unset;" placeholder="Pequise por produto ou serviço" aria-label="dsTermo" aria-describedby="dsTermo" v-model="pesquisa.dsTermo" v-on:keyup.enter="buscar">
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12">
                                <button class="btn btn-biblioteca w-100" @click="buscar">
                                    Buscar
                                </button>
                            </div>
                        </div>

                        <hr />

                        <div class="row">
                            <ul class="list-group pesquisa-formulario__lista-filtros d-flex flex-wrap flex-row">
                                <li id="menu_todos" :class="'pesquisar-fomulario__filtro-item '+(idMenuConteudo == 'menu_todos'?'active':'')" @click="openResultado('','','menu_todos')">
                                    <a href="#" class="regular txtApp">
                                        <i class="fas fa-globe-africa"></i> Todos Resultados
                                    </a>
                                </li>
                                <li :class="'pesquisar-fomulario__filtro-item '+(item.idMenuConteudo == idMenuConteudo?'active':'')" @click="openResultado(item.tpConteudo, item.cdConteudo, item.idMenuConteudo)" v-for="(item,index) in lstConteudos" :key="index">
                                    <a :id="item.idMenuConteudo" href="#" class="txt-p">
                                        {{item.nmConteudo}}
                                    </a>
                                    <!--<span class="badge badge-primary badge-pill d-none"> </span>-->
                                </li>
                            </ul>
                        </div>


                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <p class="txt-p txt-size-20 txt-grey-6 txt-w-7">
                        Resultados da pesquisa: <span class="txt-italic txt-w-4 txt-grey-3">“{{(dataAjax && dataAjax.dsTermo?dataAjax.dsTermo:'-')}}”</span>
                    </p>
                </div>
            </div>
            
            <template v-if="!flResultados">
                <div class="row">
                    <div class="col-12">
                        <div class="bg-white rounded p-3 text-center">
                            <p class="txt-p txt-size-16 txt-w-7 txt-grey-3 mb-0">
                                Use o menu acima para pesquisar por produtos, serviços e fornecedores. <span class="txt-w-4">Os resultados aparecerão aqui.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </template>
            <template>
                <div class="row" v-if="rows.length == 0 && flResultados">
                    <div class="col-12" >
                        <div class="alert alert-danger mt-3" role="alert">
                            Nenhum Registro Encontrado...
                        </div>
                    </div>
                </div>
                <template v-else>
                    <div class="row">
                        <div class="col-12">
                            <div class="item-biblioteca p-4 mb-3" v-for="(item,index) in getListResult" :key="index" id="itemList">
                                <div class="row">
                                    <div class="col-8 col-sm-10 d-flex justify-content-center flex-column flex-wrap">
                                        <p class="txt-p txt-size-16 txt-w-7 txt-biblioteca mb-1">
                                            {{item.nmConteudo}}
                                        </p>
                                        <p class="txt-p txt-size-12 txt-w-4 mb-0">
                                            {{item.dsAnotacao}}
                                        </p>
                                    </div>
                                    <div class="col-4 col-sm-2">
                                        <button class="btn btn-biblioteca w-100 p-1 mb-1" @click="detalheRepositorio(item)">
                                            <i class="fas fa-eye"></i> Visualizar
                                        </button>
                                        <button class="btn btn-excluir-outline w-100 p-1" @click="remover(item.idRepositorio,item.nmConteudo)">
                                            <i class="fas fa-trash"></i> Remover
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row ">
                        <div class="col-12 d-flex justify-content-center" v-if="rows > 0">
                            <b-pagination v-model="currentPage"
                                          :total-rows="rows"
                                          :per-page="perPage"
                                          ellipsis-class="d-none"
                                          aria-controls="itemList"></b-pagination>
                        </div>
                    </div>
                    
                </template>

            </template>
        </div>

        <ModalRepositorio :showModal="showModal" :idRepositorio="idRepositorio"></ModalRepositorio>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';
    import ModalRepositorio from '@/components/Biblioteca/ModalRepositorio';
    import ManualFerramenta from '@/components/ManualFerramenta';

    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import axios from 'axios'
    import Swal from 'sweetalert2'
    //import moment from 'moment'
    export default {
        name: 'Biblioteca',
        components: { Header, Footer, ModalRepositorio, ManualFerramenta },
        computed: {
            user() {
                return this.$store.state.user
            },
            rows() {
                return this.lstResultados.length
            },
            getListResult() {
                return this.lstResultados.slice(
                    (this.currentPage - 1) * this.perPage,
                    this.currentPage * this.perPage,
                );
            },
        },
        data() {
            return {

                pesquisa: {
                    dsTermo: '',
                },
                idMenuConteudo: 'menu_todos',

                tpConteudo: null,
                cdConteudo: null,

                lstResultados: [],
                lstConteudos: [],
                flResultados: false,

                perPage: 10,
                currentPage: 1,

                dataAjax: null,
                showModal: false,
                idRepositorio: 0,
            }
        },
        methods: {
            load() {
                //let $this = this;
                
                this.getConteudos();
            },

            getConteudos() {
                let $this = this;
                axios.get(`${urlApi}/Repositorio/GetConteudos`, {
                    params: {
                    }
                })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data) {
                                $this.lstConteudos = res.data.data;
                                $this.getResultados();
                            }
                        } else {
                            console.log("Não foi possível carregas os conteudos");
                        }
                    })
                    .catch(function () {
                        console.log("Não foi possível carregas os conteudos");
                    });
            },

            getResultados() {
                let $this = this;
                axios.get(`${urlApi}/Repositorio/GetResultados`, {
                    params: {
                        p_tpConteudo: $this.tpConteudo,
                        p_cdConteudo: $this.cdConteudo,
                        p_dsTermo: $this.pesquisa.dsTermo,
                    }
                })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data) {
                                $this.flResultados = true;
                                $this.lstResultados = res.data.data;
                            }
                        } else {
                            console.log("Não foi possível carregas os conteudos");
                        }
                    })
                    .catch(function () {
                        console.log("Não foi possível carregas os conteudos");
                    });
            },

            openResultado(tpConteudo,cdConteudo,p_menu) {
                this.idMenuConteudo = p_menu;
                this.tpConteudo = tpConteudo;
                this.cdConteudo = cdConteudo;
                this.getResultados();
            },

            buscar() {
                let $this = this;
                if ($this.validarBuscar()) {
                    $this.dataAjax = Object.assign({}, $this.pesquisa);
                    $this.getResultados();
                }
            },

            validarBuscar() {
                let $this = this;
                
                if ($this.pesquisa.dsTermo != "" && $this.pesquisa.dsTermo.length < 3) {
                    avisoErro('O campo "Busca" deve conter ao menos 3 caracteres');
                    //this.$refs.dsTermo.$el.focus()
                    return false;
                }

                return true;
            },

            remover(idRepositorio, nmConteudo) {
                let $this = this;
                Swal.fire({
                    title: 'Tem certeza que deseja excluir "' + nmConteudo + '"?',
                    text: 'Esta operação não poderá ser desfeita.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    confirmButtonColor: '#83A431',
                }).then((result) => {
                    if (result.isConfirmed) {
                        $this.removeRepositorio(idRepositorio);
                    }
                })
            },

            removeRepositorio(idRepositorio) {
                let $this = this;
                axios.delete(`${urlApi}/Repositorio/Delete`, { params: { idRepositorio: idRepositorio } })
                    .then(res => {
                        if (res.data.success) {
                            $this.getConteudos();
                            avisoSucesso("Conteúdo removido com sucesso");
                        } else {
                            avisoErro("Não foi possível remover o conteúdo");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível remover o conteúdo");
                    });
            },

            detalheRepositorio(repositorio) {
                this.idRepositorio = repositorio.idRepositorio;
                this.showModal = !this.showModal;
            },
                        
        },
        mounted: function () {
            this.load();
        },
        watch: {

        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    .box-branco {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }

    .border-biblioteca {
        border-color: #7BC0CA;
    }

    .pesquisa-formulario__lista-filtros {
        display: flex;
        flex-flow: row wrap;
        list-style: none;
        padding: 0 1rem;

        .pesquisar-fomulario__filtro-item {
            padding: 0;
            margin: 0;
            border-bottom: 3px solid #efecec;
            cursor: pointer;

            a {
                color: #767676;
                padding: 1rem 1rem;
                display: block;
                font-weight: 400;
                font-size: 12px;
            }
        }

        .pesquisar-fomulario__filtro-item.active {
            a {
                color: #7BC0CA;
                font-weight: 700;
            }

            border-bottom: 3px solid #7BC0CA;
        }
    }

    .item-biblioteca {
        box-shadow: 0 2px 5px rgb(0 0 0 / 8%);
        background-color: #fff !important;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.125);
    }

    .paginate .paginate-buttons {
        display: flex;
        justify-content: space-between;
    }

    .grid-app {
        .btn-navegacao {
            background-color: #f5f5f5;
            border: 1px solid #ccc;
            border-radius: 3px;
            font-weight: 600;
            padding: 7px 10px;
            color: #00b6a6 !important;
        }
    }

</style>

