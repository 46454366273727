<template>
    <div class="AutenticacaoAutomatica">

    </div>
</template>

<script>
    import { urlApi } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'AutenticacaoAutomatica',
        components: { },
        data: function () {
            return {
                
            }
        },
        methods: {
            
            load() {
               
            },

            autenticar(token, refreshTokenWeb) {
                let $this = this;
                axios.post(`${urlApi}/Auth/ValidateTokenWeb`, { accessToken: token, refreshTokenWeb: refreshTokenWeb })
                    .then(res => {
                        if (res.data.token.authenticated) {
                            res.data.token.usuario = res.data.usuario;
                            res.data.token.usuario.idContrato = res.data.idContrato;
                            res.data.token.usuario.tpPessoa = res.data.tpPessoa;
                            res.data.token.usuario.flCortesia = res.data.flCortesia;
                            $this.$store.commit('setUser', res.data.token)
                            if ($this.$route.query.route != undefined) {
                                if($this.$route.query.route == 'LeiComentada'){
                                    $this.$router.push({ name: 'Pesquisa', query: { leiComentada: 'true' } });
                                }else{
                                    $this.$router.push({ name: $this.$route.query.route });
                                }
                                
                            } else {
                                $this.$router.push({ name: 'HomeLogado' });
                            }
                            
                        } else {
                            /* eslint-disable */
                            console.log("Erro ao realizar login.");
                            window.location.href = '/redirect';
                        }
                    })
                    .catch(function () {
                        /* eslint-disable */
                        console.log("Erro ao realizar login.");
                        window.location.href = '/redirect';
                    })
            },
        },
        //created: function () {
        //    this.load();
        //},
        mounted: function () {
            if (this.$route.query.token != undefined && this.$route.query.refreshTokenWeb != undefined) {
                this.autenticar(this.$route.query.token, this.$route.query.refreshTokenWeb);
            } else {
                window.location.href = '/redirect';
            }

        },
        watch: {
            $route(to) {
                if (to.query.token != undefined && to.query.refreshTokenWeb != undefined) {
                    this.autenticar(to.query.token, to.query.refreshTokenWeb);
                } else {
                    window.location.href = '/redirect';
                }
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    
</style>

