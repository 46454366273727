<template>
    <div class="viewLGFED">
        <div class="modal-conteudo m-2">
            <span class="modal-subtitulo d-none">Legislação Federal</span>

            <div id="divImprimir">
                <div class="row">
                    <div class="col-md-12" v-if="dados.legislacao">
                        <h3 class="bold">{{dados.legislacao.nmTitulo}}</h3>
                        <p class="regular col-12 subtituloLei" v-if="dados.legislacao.dsLegislacao" v-html="dados.legislacao.dsLegislacao.replace('\r\n','<br />')"></p>
                        <div class="regular" id="htmlLegislacao" @click="handleClick"></div>
                    </div>
                </div>
            </div>
            <div class="d-none" id="conteudoImprimir"></div>
        </div>

        <div class="modal-footer pb-0 mt-4 d-flex justify-content-end">
            <AdicionarBiblioteca v-if="exibeBiblioteca" :id="dados.legislacao.idLegislacao.toString()" :tipo="'Pesquisa'" :cdConteudo="'LGFED'"></AdicionarBiblioteca>

            <button class="btn btn-blue txt-size-14" @click="imprimir">
                <i class="fas fa-print"></i> Imprimir
            </button>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'
    import AdicionarBiblioteca from '../AdicionarBiblioteca'
    import { urlApi } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'viewLGFED',
        components: { AdicionarBiblioteca },
        data: function () {
            return {
                exibeBiblioteca: true,
            }
        },
        props: {
            dados: Object,
        },
        methods: {
            verificaExisteRepositorio(idConteudo, tpConteudo, cdConteudo) {
                let $this = this;
                axios.get(`${urlApi}/Repositorio`, { params: { idConteudo: idConteudo, tpConteudo: tpConteudo, cdConteudo: cdConteudo } })
                    .then(res => {
                        if (res.data.success) {
                            $this.exibeBiblioteca = res.data.data;
                        } else {
                            console.log('Não foi possível verificar o repositório')
                        }
                    })
                    .catch(function () {
                        console.log('Não foi possível verificar o repositório')
                    });
            },

            montaAnotacoes() {
                let $this = this;
                var html = this.dados.legislacao.htmlLegislacao;
                var htmlFormat = html.replace(new RegExp("{{idLegislacaoAnotacao-(.*?)-(.*?)}}", "g"), function (match, p1) {
                    if ($this.dados.flExibeLeiComentada) {
                        let obj = $this.dados.legislacaoAnotacao.find(x => x.idLegislacaoAnotacao == p1);
                        if (obj) {
                            var classRegulamentacao = (obj.flRegulamentacao == "Sim" ? "legislacao_anotacao_regulamentacao" : "");
                            var txtBtn = (obj.flRegulamentacao == "Sim" ? "Ver regulamentação" : "Ver anotação");
                            var div = '';

                            div = `<a class="ver_anotacao ` + classRegulamentacao + `" href="javascript:void(0)" data-id="` + obj.idLegislacaoAnotacao + `" >` + txtBtn + `</a>
                <div class="legislacao_anotacao `+ classRegulamentacao + `" id="LegislacaoAnotacao-` + obj.idLegislacaoAnotacao + `" idLegislacaoAnotacao="` + obj.idLegislacaoAnotacao + `">
                    
                </div>`;

                            return div;
                        } else {
                            return "";
                        }
                    }
                    else {
                        return ""
                    }
                });

                $this.$nextTick(function () {
                    $("#htmlLegislacao").html(htmlFormat);
                    $this.bindAnotacoes();
                });
            },

            bindAnotacoes() {
                let $this = this;
                $(".ver_anotacao").unbind("click");
                $(".ver_anotacao").on("click", function () {
                    var id = $(this).attr("data-id");
                    let obj = $this.dados.legislacaoAnotacao.find(x => x.idLegislacaoAnotacao == id);
                    if (obj) {
                        id = "LegislacaoAnotacao-" + id;
                        var div = $this.montaHtmlAnotacao(obj);
                        $("#" + id).html(div);

                        document.getElementById(id).className.includes('active') ? document.getElementById(id).classList.remove('active') : document.getElementById(id).classList.add('active');
                    }
                });
            },

            handleClick(e) {
                let clickedElId = e.target.id;
                if (clickedElId == 'downloadAnotacao' || clickedElId == 'downloadAnotacaoText') {
                    let value = e.target.attributes.value.value;
                    let $this = this;
                    axios({
                        url: `${urlApi}/Utilidade/BaixarArquivo?urlArquivo=${value}`, //your url
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', $this.getNomeArquivo(value)); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    });
                }
            },

            getNomeArquivo(url) {
                var lst = url.split("/");
                return lst[lst.length - 1];
            },

            montaHtmlAnotacao(obj) {
                var div = '';
                var lstTipoAnotacao = this.dados.lstTipoAnotacao;
                switch (lstTipoAnotacao.find(x => x.idTipoAnotacao == obj.idTipoAnotacao).cdTipoAnotacao) {
                    case 1:
                        div = `
                      <p class="titulo">`+ obj.dsTitulo + `</p>
                      <p class="descricao">`+ obj.dsDescricao + `</p>
                `;
                        break;

                    case 2:
                        div = `
                      <a class="link" href="`+ obj.urlLink + `" target="_blank"><p class="">` + obj.dsTitulo + `</p></a>
                  `;
                        break;

                    case 3:
                        div = `
                      <a class="link anotacao_oculta_imprimir link-download" id="downloadAnotacao" href="javascript:void(0)" value="`+ obj.urlUpload + `  data-url="` + obj.urlUpload + `"><p class="" id="downloadAnotacaoText"  value="` + obj.urlUpload + `">` + obj.dsTitulo + `</p></a>

                      `;
                        break;

                    case 4:
                        if (obj.tpExibicaoVideo == "Local") {
                            div = `
                      <p class="titulo">`+ obj.dsTitulo + `</p>
                      <video id="id" width="720" height="480" class="anotacao_oculta_imprimir" allowfullscreen controls="controls" poster="/NP/SollicitaAdmin/" onkeydown="return false" oncontextmenu="return false" >
                          <source src="`+ obj.urlVideo + `" type="video/mp4">
                          <source src="`+ obj.urlVideo + `" type="video/webm">
                      </video>
                  `;
                        } else {
                            if (obj.tpExibicaoVideo == "YouTube") {
                                let src = obj.urlVideo.replace("https://www.youtube.com/watch?v=", "");
                                src = src.replace("https://youtu.be/", "");
                                if (src.indexOf("&") > 0) {
                                    src = src.substring(0, src.indexOf("&"));
                                }
                                div = `
                          <p class="titulo">`+ obj.dsTitulo + `</p>
                          <iframe class="anotacao_oculta_imprimir" id="iframeVimeo" width="720" height="480" src="https://www.youtube.com/embed/` + src + `?controls=1&disablekb=1&fs=0&mute=1&modestbranding=1&iv_load_policy=3&showinfo=0'" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          <span class="txt-link" style="display:none;">` + obj.urlVideo + `</span>
                      `;
                            } else {
                                let src = obj.urlVideo.replace("https://vimeo.com/", "");
                                div = `
                          <p class="titulo">`+ obj.dsTitulo + `</p>
                          <iframe class="anotacao_oculta_imprimir" id="iframeVimeo" width="720" height="480" src="https://player.vimeo.com/video/` + src + `" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                          <span class="txt-link" style="display:none;">` + obj.urlVideo + `</span>
                     `;
                            }

                        }
                        break;

                    default:
                        break;
                }
                return div;
            },

            imprimir() {
                $("#conteudoImprimir").html($("#divImprimir").html());
                for (let i = 0; i < this.dados.legislacaoAnotacao.length; i++) {
                    let obj = this.dados.legislacaoAnotacao[i];
                    if (obj) {
                        var div = this.montaHtmlAnotacao(obj);
                        $("#conteudoImprimir #LegislacaoAnotacao-" + obj.idLegislacaoAnotacao).html(div);
                    }
                }
                /*eslint-disable */
                $("#conteudoImprimir .ver_anotacao").unbind("click"); $(".link-download").unbind("click");

                $("#conteudoImprimir .legislacao_anotacao").show();
                $("#conteudoImprimir .ver_anotacao").hide();
                $("#conteudoImprimir .anotacao_oculta_imprimir").hide();
                $("#conteudoImprimir .txt-link").show();
                var css = $("#cssView").html();
                var html = document.getElementById('conteudoImprimir').innerHTML + css
                var conteudo = html,
                    tela_impressao = window.open('about:blank');

                tela_impressao.document.write(conteudo);
                tela_impressao.window.print();
                tela_impressao.window.close();
                $("#conteudoImprimir").html("");

                /*eslint-enable */
            },
        },
        created: function () {
            this.montaAnotacoes();
        },
        mounted: function () {
            this.verificaExisteRepositorio(this.dados.legislacao.idLegislacao.toString(), 'Pesquisa', 'LGFED');
        },
        watch: {
            dados: {
                handler() {
                    this.montaAnotacoes();
                    this.verificaExisteRepositorio(this.dados.legislacao.idLegislacao.toString(), 'Pesquisa', 'LGMUN');
                },
                deep: true
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
    .ver_anotacao {
        cursor: pointer;
        color: #00528e;
        font-size: 1em;
        font-weight: bold;
    }

    .ver_anotacao.legislacao_anotacao_regulamentacao {
        color: #feb811;
    }

    .legislacao_anotacao {
        padding: 10px;
        border: 1px solid #dedede;
        display: none;
    }
    
    .legislacao_anotacao.legislacao_anotacao_regulamentacao {
        border: 1px solid #02568c !important;
    }

    .legislacao_anotacao.active {
        display: block;
    }

    .legislacao_anotacao .titulo {
        font-weight: bold;
        color: #000;
    }

    .legislacao_anotacao .descricao {
        color: #000;
        margin-bottom: 0;
    }

    .legislacao_anotacao .link p {
        font-weight: bold;
        color: #000;
        margin-bottom: 0;
    }
   
</style>

