<template>
    <div class="NoticiaAudioModal">
        <div v-if="audio != null">

            <div class="box-audio mt-4">
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-6">
                        <div class="video-responsivo audio">
                            <audio ref="videoPlayer" class="video-js"></audio>
                        </div>
                    </div>
                    <div class="col-3"></div>
                </div>
            </div>

            <div class="mt-2">
                <div class="row">
                    <div class="col-12">
                        <p class="txt-h-2 txt-size-30 txt-blue-2 ">
                            {{audio.nmTitulo}}
                        </p>
                    </div>
                </div>
                <div class="container mt-3">
                    <div class="row">
                        <div class="col-12">
                            <span class="txt-h-6 txt-size-12 txt-grey-2">{{formatDate(audio.dtAudio)}}</span>

                            <div class="mt-2 txt-p txt-grey-2 txt-size-16 recuo" v-html="audio.dsDescricao"></div>

                            <Tags class="mt-3" :titulo="'TAGS'" :lstTags="lstTags" :link="'PesquisaAudio'"></Tags>

                            <!--<Comentarios class="mt-4" :id="audio.idVideo.toString()" :tipo="'Video'"></Comentarios>-->


                        </div>

                    </div>
                </div>
            </div>

        </div>
        <div v-else>
            <div class="mt-5">
                <div class="container" style="height: 44vh;">
                    <div class="row">
                        <div class="col-12 col-md-12">
                            <span class="txt-h-5 txt-blue-2">Áudio não encontrado</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'
    import Tags from '@/components/Biblioteca/Tags'

    import videojs from 'video.js';
    import 'video.js/dist/video-js.css'

    export default {
        name: 'NoticiaAudioModal',
        props: {
            idConteudo: Number,
        },
        components: {
            Tags
        },
        data: function () {
            return {
                audio: null,
                lstTags: [],

                player: null,

                options: {
                    autoplay: false,
                    controls: true,
                    sources: [
                        {
                            src: "",
                            type: "video/mp4"
                        }
                    ]
                },
            }
        },
        methods: {
            load() {
                this.carregaVideo();
            },

            carregaVideo() {
                let $this = this;
                axios.get(`${urlApi}/Audio/Get/${$this.idConteudo}`)
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data && res.data.data.idAudio) {
                                $this.audio = res.data.data;

                                $this.montaTags($this.audio.dsTags);

                                $this.options.sources[0].src = $this.audio.urlAudio;

                                $this.$nextTick(function () {
                                    $this.player = videojs($this.$refs.videoPlayer, $this.options, function onPlayerReady() {
                                        console.log('onPlayerReady');
                                    });
                                });
                            }

                        } else {
                            avisoErro("Não foi possível carregar o áudio");
                            $this.video = null;
                        }
                    })
                    .catch(function(){
                        avisoErro("Não foi possível carregar o áudio");
                        $this.video = null;
                    });
            },

            montaTags(tags) {
                let $this = this;
                if (tags != null && tags != undefined) {
                    tags = tags.split(';');
                    if (tags != undefined && tags != null && tags != '') {
                        for (let i = 0; i < tags.length; i++) {
                            let tag = tags[i].trim();
                            $this.lstTags.push(tag);
                        }
                    }
                }
            },

            formatDate(date) {
                return moment(date).format('DD/MM/YYYY [às] HH:mm');
            },

            
        },
        mounted: function () {
            this.load();
        },
        watch: {
            
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">

</style>

