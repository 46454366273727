<template>
    <div class="ContratarModulo" >
        <b-modal ref="modal-contratar-modulo" id="modalContratarModulo" size="lg" :hide-footer="true" :hide-header="true">
            <div class="w-100 text-end txt-size-20 txt-blue-2">
                <span @click="close"><i class="fas fa-times c-pointer"></i></span>
            </div>

            <div class="px-3" v-if="dadosModulo != null">
                <p class="txt-h-2 txt-size-25 txt-blue-2 txt-letter-n-2 txt-w-7">
                    Você ainda não possuí acesso <template v-if="isModulo">ao módulo</template><template v-else>à ferramenta</template> <span :class="'txt-'+dadosModulo.classe">{{dadosModulo.nome}}</span>
                </p>

                <p class="txt-h-3 txt-size-20 txt-blue-2 txt-letter-n-2 txt-w-4" v-html="dadosModulo.descricao">

                </p>

                <div class="d-flex justify-content-center my-3">
                    <div :class="'p-3 rounded bg-'+dadosModulo.classe">
                        <img class="" style="width: 100px;" :src="dadosModulo.urlImagem" />
                    </div>
                   
                </div>
                

                <p class="txt-h-3 txt-size-20 txt-blue-2 txt-letter-n-2">
                    Teve interesse? Clique no botão abaixo e confira os planos!
                </p>

                <a :class="'btn w-100 txt-size-20 btn-'+dadosModulo.classe" :href="linkPlanos" target="_blank">
                    Quero ver os planos!
                </a>
            </div>
            

        </b-modal>
    </div>
</template>

<script>
    import { linkPlanos } from '@/config/global'

    export default {
        name: 'ContratarModulo',
        data: function () {
            return {
                linkPlanos: linkPlanos,
            }
        },
        props: {
            showModal: Boolean,
            
            dadosModulo: Object,
            isModulo: {
                type: Boolean,
                default: true
            },
        },
        methods: {
            
            load() {
                
            },

            close() {
                this.$refs['modal-contratar-modulo'].hide();
            }

        },
        created: function () {
            this.load();
        },
        watch: {
            showModal: function () {
                this.$refs['modal-contratar-modulo'].show();
            },
            
        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* Mudar Senha */
   .form-control {
        border: 1px solid #474747;
    }
</style>

