import { render, staticRenderFns } from "./LeitorPdf.vue?vue&type=template&id=c45f7456"
import script from "./LeitorPdf.vue?vue&type=script&lang=js"
export * from "./LeitorPdf.vue?vue&type=script&lang=js"
import style0 from "./LeitorPdf.vue?vue&type=style&index=0&id=c45f7456&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports