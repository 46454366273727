<template>
    <div class="ExperimenteGratis" >
        <b-modal ref="modal-experimente-gratis" id="modalExperimenteGratis" size="lg" :hide-footer="true" :hide-header="true">
            <div class="w-100 text-end txt-size-20 txt-blue-2">
                <span @click="close"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <form class="px-3 pb-3" id="formExperimenteGratis">

                <div class="row">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-size-25 txt-w-bold mb-2">
                            Experimente Grátis!
                        </span>
                        <p class="txt-p txt-size-16 txt-w-5 txt-grey-2">
                            Preencha os campos abaixo, e você receberá por e-mail um login e senha de acesso para experimentar todas as ferramentas do Sollicita.
                        </p>
                    </div>

                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-20">
                            Nome
                        </span>
                        <div class="">
                            <input type="text" class="form-control" id="dados_nmUsuario" placeholder="Nome Completo" name="dados.nmUsuario" v-model="dados.nmUsuario" maxlength="100" required>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-20">
                            Empresa/Instituição
                        </span>
                        <div class="">
                            <input type="text" class="form-control" id="dados_nmEmpresaInstituicao" placeholder="Empresa/Instituição" name="dados.nmEmpresaInstituicao" v-model="dados.nmEmpresaInstituicao" maxlength="100" required>
                        </div>
                    </div>

                </div>

                <div class="row mb-4">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-20">
                            CNPJ
                        </span>
                        <div class="">
                            <!--<input type="text" class="form-control" id="dados_nrCnpj" placeholder="CNPJ" name="dados.nrCnpj" v-model="dados.nrCnpj" maxlength="100" required>-->
                            <vue-mask class="form-control"
                                      id="dados_nrCnpj"
                                      v-model="dados.nrCnpj"
                                      mask="00.000.000/0000-00"
                                      :raw="false"
                                      :options="options" required>
                            </vue-mask>
                        </div>
                    </div>

                </div>

                <div class="row mb-4">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-20">
                            Celular
                        </span>
                        <div class="">
                            <!--<input type="text" class="form-control" id="dados_nrCelular " placeholder="Telefone" name="dados.nrCelular " v-model="dados.nrCelular " maxlength="100" required>-->
                            <vue-mask class="form-control"
                                      id="dados_nrCelular "
                                      v-model="dados.nrCelular "
                                      mask="(00) 00000-0000"
                                      :raw="false"
                                      :options="optionsTelefone" required>
                            </vue-mask>
                        </div>
                    </div>

                </div>

                <div class="row mb-4">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-20">
                            Email
                        </span>
                        <div class="">
                            <input type="email" class="form-control" id="dados_dsEmail" placeholder="Email" name="dados.dsEmail" v-model="dados.dsEmail" maxlength="100" required>
                        </div>
                    </div>

                </div>

                <div class="row mb-4">
                    <div class="col-12 col-md-6">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-20">
                            UF
                        </span>
                        <div class="">
                            <select class="form-control form-select" id="dados_nmUf" name="dados.nmUf" v-model="dados.nmUf" required >
                                <option value="0" selected disabled> -- Selecione --</option>
                                <option value="AC">Acre</option>
                                <option value="AL">Alagoas</option>
                                <option value="AM">Amazonas</option>
                                <option value="AP">Amapá</option>
                                <option value="BA">Bahia</option>
                                <option value="CE">Ceará</option>
                                <option value="DF">Distrito Federal</option>
                                <option value="ES">Espírito Santo</option>
                                <option value="GO">Goiás</option>
                                <option value="MA">Maranhão</option>
                                <option value="MT">Mato Grosso</option>
                                <option value="MS">Mato Grosso do Sul</option>
                                <option value="MG">Minas Gerais</option>
                                <option value="PA">Pará</option>
                                <option value="PB">Paraíba</option>
                                <option value="PR">Paraná</option>
                                <option value="PE">Pernambuco</option>
                                <option value="PI">Piauí</option>
                                <option value="RJ">Rio de Janeiro</option>
                                <option value="RN">Rio Grande do Norte</option>
                                <option value="RO">Rondônia</option>
                                <option value="RS">Rio Grande do Sul</option>
                                <option value="RR">Roraima</option>
                                <option value="SC">Santa Catarina</option>
                                <option value="SE">Sergipe</option>
                                <option value="SP">São Paulo</option>
                                <option value="TO">Tocantins</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 d-none">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-20">
                            Como Soube
                        </span>
                        <div class="">
                            <select class="form-control" id="dados_dsComoSoube" name="dados.dsComoSoube" v-model="dados.dsComoSoube" required>
                                <option value="0" selected disabled> -- Selecione --</option>
                                <option value="Google">Google</option>
                                <option value="E-mail Marketing">E-mail Marketing</option>
                                <option value="Evento Negócios Públicos">Evento Negócios Públicos</option>
                                <option value="Redes Sociais">Redes Sociais</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="w-100">
                        <button class="btn btn-yellow w-100" type="button" :disabled="!liberaSalvar" @click="enviar">Enviar</button>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
    import vueMask from 'vue-jquery-mask'
    import { urlApi, avisoSucesso, avisoErro } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'ExperimenteGratis',
        components: {
            vueMask,
        },
        data: function () {
            return {

                dados: {
                    nmUsuario: null,
                    nmEmpresaInstituicao: null,
                    nrCnpj: '',
                    nrCelular : '',
                    dsEmail: null,
                    nmUf: 0,
                    dsComoSoube: 0,
                },

                options: {
                    placeholder: 'CNPJ',
                },
                optionsTelefone: {
                    placeholder: 'Telefone',
                },
            }
        },
        props: {
            showModal: Boolean,
        },
        computed: {
            liberaSalvar() {
                if (this.dados.nmUsuario == '' || this.dados.nmUsuario == null) {
                    return false;
                }

                if (this.dados.nmEmpresaInstituicao == '' || this.dados.nmEmpresaInstituicao == null) {
                    return false;
                }

                if (this.dados.nrCnpj == '' || this.dados.nrCnpj == null) {
                    return false;
                }

                if (this.dados.nrCelular  == '' || this.dados.nrCelular  == null) {
                    return false;
                }

                if (this.dados.dsEmail == '' || this.dados.dsEmail == null) {
                    return false;
                }

                if (this.dados.nmUf == '' || this.dados.nmUf == null) {
                    return false;
                }

                return true;
            },

        },        
        methods: {
            enviar() {
                if (this.checkForm()) {
                    let $this = this;
                    axios.post(`${urlApi}/Utilidade/SolicitacaoContato`, $this.dados)
                        .then(res => {
                            if (res.data.success) {
                                // aviso ok
                                avisoSucesso("Sua solicitação de acesso foi realizada com sucesso. Aguarde nosso contato!");
                                $this.$refs['modal-experimente-gratis'].hide();
                            } else {
                                avisoErro("Não foi possível enviar sua solicitação.");
                            }
                        })
                        .catch(function(){
                            avisoErro("Não foi possível enviar sua solicitação.");
                        });
                }
                return false;
            },

            checkForm() {
                var form = document.getElementById('formExperimenteGratis')
                if (!form.checkValidity()) {
                    var tmpSubmit = document.createElement('button')
                    form.appendChild(tmpSubmit)
                    tmpSubmit.click()
                    form.removeChild(tmpSubmit)

                    return false;
                }

                return true;
            },

            load() {
                             
            },

            close() {
                this.$refs['modal-experimente-gratis'].hide();
            }

        },
        created: function () {
            this.load();
        },
        watch: {
            showModal: function () {
                this.$refs['modal-experimente-gratis'].show();
                this.load();
            },
            
        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* Experimente Gratis */
   .form-control {
        border: 1px solid #474747;
    }
</style>

