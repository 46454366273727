<template>
    <div class="InputPesquisa">
        <div class="container mt-4">

            <div class="row">
                <span class="txt-h-3 txt-size-22 txt-blue-4 txt-w-8">
                    Sollicita Pesquisa
                </span>
                <div class="d-flex justify-content-end">
                    <ManualFerramenta class="mr-2" :name="'Manual Pesquisa'" :btnClass="'btn-blue'" :nameBtn="'Manual'" :urlPdf="'/manuais/FERRAMENTA PESQUISA PDF.pdf'"></ManualFerramenta>
                </div>
                <div class="col-12 mt-2">
                    <div class="d-flex bd-highlight position-relative ">
                        <img class="position-absolute" style="z-index: 2;" src="../assets/img/icone_pesquisa_maior-min.png" />
                        <div class="d-flex align-items-center" style="width:80px; min-height: 133px;"></div>
                        <div class="d-flex justify-content-center flex-column bg-grey-1 text-center p-3 rounded-end flex-grow-1 ">
                            <p class="txt-h-3 txt-size-20 txt-blue-5 txt-w-6 mb-0 box-grey">
                                Pesquise por termos, temas ou perguntas e nós te mostramos o que temos em nosso acervo de módulos e ferramentas!
                            </p>
                            <div class="position-relative">
                                <div class="inputs-pesquisa w-100 px-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="input-group mb-3">
                                                <input type="text" class="form-control pesquisa" placeholder="Pesquise o que você procura" aria-label="Recipient's username" aria-describedby="basic-addon2" v-model="pesquisa" v-on:keyup.enter="acaoBtnPesquisa">
                                                <span class="input-group-text c-pointer" id="basic-addon2" @click="acaoBtnPesquisa"><i class="fas fa-search"></i></span>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <button class="btn btn-blue w-100" type="button" @click="acaoBtnPesquisa">
                                                Pesquisar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ManualFerramenta from '@/components/ManualFerramenta'
    
    export default {
        name: 'InputPesquisa',
        components: { ManualFerramenta },
        data: function () {
            return {
                pesquisa: null,
            }
        },
        props: {
            pesquisar: {
                type: Function,
                required: true,
            },
        },
        methods: {
            acaoBtnPesquisa() {
                this.pesquisar(this.pesquisa);
            },
        },
        mounted: function () {
           
           
        },
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .inputs-pesquisa {
        position: absolute;
        padding-left: 0px !important;
    }

    .box-grey {
        padding-left: 150px !important;
    }

    .pesquisa {
        border-color: #FBBC0D;
        border-width: 2px;
        box-shadow: none;
        border-right: none;
        height: 40px;
    }

    .pesquisa:focus {
        border-color: #FBBC0D;
        border-width: 2px;
        box-shadow: none;
        border-right: none;
    }

    .input-group-text {
        background: #FFF;
        border-color: #FBBC0D;
        border-width: 2px;
        border-left: none;
        color: #D4D4D4;
    }

    // `sm` applies to x-small devices (portrait phones, less than 576px)
    @media (min-width: 576px) {
        .inputs-pesquisa {
            top: 0;
            padding-left: 0px !important;
        }

        .box-grey {
            padding-left: 150px !important;
        }
    }

    // `md` applies to small devices (landscape phones, less than 768px)
    @media (min-width: 768px) {
        .inputs-pesquisa {
            top: 0;
            padding-left: 150px !important;
        }

        .box-grey {
            padding-left: 150px !important;
        }
    }

    // `lg` applies to medium devices (tablets, less than 992px)
    @media (min-width: 992px) {
        .inputs-pesquisa {
            top: 10px;
            padding-left: 100px !important;
        }

        .box-grey {
            padding-left: 100px !important;
        }
    }

    // `xl` applies to large devices (desktops, less than 1200px)
    @media (min-width: 1200px) {
        .inputs-pesquisa {
            top: 20px;
            padding-left: 100px !important;
        }

        .box-grey {
            padding-left: 100px !important;
        }
    }

    // `xxl` applies to x-large devices (large desktops, less than 1400px)
    @media (min-width: 1400px) {
        .inputs-pesquisa {
            top: 30px;
            padding-left: 80px !important;
        }

        .box-grey {
            padding-left: 80px !important;
        }
    }

</style>

