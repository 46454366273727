<template>
    <section class="DetalheFornecedor">
        <Header></Header>

        <div class="container my-3">
            <div class="row ">
                <div class="col-2">
                    <span class="txt-h-4 txt-grey-6 c-pointer" @click="votar"><i class="fas fa-arrow-left"></i> Voltar</span>
                </div>
                <div class="col-8 text-center">
                    <p class="txt-p txt-size-20 txt-fornecedores mb-0">
                        Sollicita <span class="txt-w-7">Fornecedores</span>
                    </p>
                </div>
                <div class="col-2">

                </div>
            </div>            
        </div>

        <div class="container" v-if="fornecedor">
            <div class="row">
                <div class="col-12">
                    <div class="dados-fornecedor">
                        <div class="icone-fornecedor d-flex flex-wrap align-content-center justify-content-center">
                            <template v-if="fornecedor.IdCatMat != 0">
                                <span class="txt-white">
                                    <i class="fas fa-box fa-3x"></i>
                                </span>
                                <p class="txt-p txt-size-16 txt-w-7 txt-white mb-0">
                                    Produto
                                </p>
                            </template>
                            <template v-else>
                                <span class="txt-white">
                                    <i class="fas fa-wrench fa-3x"></i>
                                </span>
                                <p class="txt-p txt-size-16 txt-w-7 txt-white mb-0">
                                    Serviço
                                </p>
                            </template>

                        </div>
                        <div class="detalhes-fornecedor w-90 d-flex flex-wrap align-content-center">
                            <div class="w-100 mb-2">
                                <p class="txt-p txt-size-12 txt-w-4 txt-grey-5 mb-0">
                                    Produto
                                </p>
                                <p class="txt-p txt-size-16 txt-w-7 txt-fornecedores mb-0">
                                    {{fornecedor.descricao}}
                                </p>
                            </div>
                            <div class="w-100">
                                <p class="txt-p txt-size-12 txt-w-4 txt-grey-5 mb-0">
                                    Fornecedores
                                </p>
                                <p class="txt-p txt-size-16 txt-w-7 txt-fornecedores mb-0">
                                    {{fornecedor.countItens}} fornecedores encontrados
                                </p>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <div class="box-branco">
                        <div class="row">
                            <p class="txt-p txt-size-16 txt-w-7 txt-grey-3">
                                Filtros:
                            </p>
                        </div>
                        <div class="row">
                            <div class="col-9 col-md-9 col-lg-10 col-xl-11">
                                <div class="row">
                                    <div class="col-6 col-md-4">
                                        <label for="termoPesquisa" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-3 mb-1 ps-2">Pesquise nos fornecedores</label>
                                        <input class="form-control" ref="termoPesquisa" type="text" aria-describedby="termoPesquisa" placeholder="Pesquisar" maxlength="50" v-model="pesquisaDetalhes.termoPesquisa">
                                    </div>
                                    <div class="col-6 col-md-4">
                                        <label for="uf" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-3 mb-1 ps-2">Selecione a UF:</label>
                                        <select class="form-select" aria-label="nmEstado" v-model="pesquisaDetalhes.nmEstado">
                                            <option value="">Selecione</option>
                                            <option :value="item" v-for="(item,index) in lstEstados" :key="index">{{item}}</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-md-4">
                                        <label for="nmCidade" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-3 mb-1 ps-2">Cidade</label>
                                        <input class="form-control" ref="nmCidade" type="text" aria-describedby="nmCidade" placeholder="Cidade" maxlength="50" v-model="pesquisaDetalhes.nmCidade">
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 col-md-3 col-lg-2 col-xl-1 d-flex flex-wrap align-content-end">
                                <button class="btn btn-fornecedores w-100" @click="search">
                                    Buscar
                                </button>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <Grid url_ajax="/Fornecedor/Detalhes" :is_table="false" :reload="reload" :ajax_set_result="setFornecedores" :data_ajax="getDataAjax()" :ajax_set_count="setCount" style="overflow-x: auto;">
                                <template v-slot:rows="{ rows }">
                                    <table class="table table-bordered table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    Razão Social/CNPJ
                                                </th>
                                                <th scope="col">
                                                    Cidade/UF
                                                </th>
                                                <th scope="col">
                                                    E-mail/Telefone
                                                </th>
                                                <th scope="col">
                                                    Penalidades
                                                </th>
                                            </tr>
                                        </thead>


                                        <tbody v-if="rows.length == 0 && flResultados">
                                            <tr>
                                                <td class="text-center mt-2 mb-2" colspan="4">
                                                    <span>Nenhum Registro Encontrado...</span>
                                                </td>
                                            </tr>

                                        </tbody>
                                        <tbody v-else>
                                            <tr v-for="(row,index) in rows" :key="index">
                                                <td class="largura40">
                                                    <div style="text-align: left; " class="p-2">
                                                        {{row.razaoSocial}} - {{row.cnpj}}
                                                    </div>

                                                </td>
                                                <td class="largura20">
                                                    <div class="p-2">{{row.cidade}} / {{row.estado}}</div>

                                                </td>
                                                <td class="largura25">
                                                    <div class="p-2"><a :href="'mailto:'+row.Email" style="word-break: break-all;">{{row.email}}</a><br>{{row.telefone}}</div>

                                                </td>
                                                <td class="largura15">

                                                    <span v-if="!row.temPenalidade" class="btn btn-success disabled bold btn-block btn-modal btn-resultPenal text-white">
                                                        <i class="fa fa-thumbs-up"></i> Sem penalidade
                                                    </span>
                                                    <span v-else class="btn btn-danger disabled bold btn-block btn-modal btn-resultPenal text-white">
                                                        <i class='fa fa-thumbs-down'></i> Com penalidade
                                                    </span>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>


                                </template>
                            </Grid>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';
    import Grid from '@/components/Grid';

    //import { avisoErro } from '@/config/global'
    //import moment from 'moment'
    export default {
        name: 'DetalheFornecedor',
        components: { Header, Footer, Grid },
        props: {
            fornecedor: Object,
            pesquisa: Object,
            idPesquisaFornecedor: Number,
        },
        computed: {
            user() {
                return this.$store.state.user
            },

        },
        data() {
            return {
                pesquisaDetalhes: {
                    nmCidade: '',
                    termoPesquisa: '',
                    nmEstado: '',
                },

                lstEstados: [
                    "AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO",
                ],
                reload: 0,

                lstResultados: [],
                flResultados: false,
            }
        },
        methods: {
            votar() {
                this.$router.go(-1);
            },

            load() {
                //let $this = this;
                this.pesquisaDetalhes = {
                    nmCidade: '',
                    termoPesquisa: '',
                    nmEstado: this.pesquisa.nmEstado,
                };
                this.reload = this.reload + 1;
            },

            search() {
                this.reload = this.reload + 1;
            },

            setFornecedores(rows) {

                let lista = rows.fornecedores;
                this.lstResultados = lista;
                this.flResultados = true;

                return this.lstResultados;
            },


            getDataAjax() {
                var data = {
                    idCatalogo: (this.fornecedor.idCatMat != 0 ? this.fornecedor.idCatMat : this.fornecedor.idCatSer),
                    dsPesquisa: this.pesquisaDetalhes.termoPesquisa,
                    nmCidade: this.pesquisaDetalhes.nmCidade,
                    nmEstado: this.pesquisaDetalhes.nmEstado,
                    chkProduto: this.pesquisa.chkProduto,
                    chkServico: this.pesquisa.chkServico,
                    idPesquisaFornecedor: this.idPesquisaFornecedor,
                    idCatMat: this.fornecedor.idCatMat,
                    idCatSer: this.fornecedor.idCatSer,
                };
                return data;
            },

            setCount(res) {
                return res.data.itensCount;
            },
                        
        },
        mounted: function () {            
            this.load();
        },
        watch: {
            fornecedor() {
                this.load();
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    .box-branco {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        background-color: #FFF;
        border-radius: 10px;
        padding: 20px;
    }

    .dados-fornecedor {
        display: flex;
        background: #FFFFFF;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        border-radius: 10px;

        .icone-fornecedor {
            background: #00B6A6;
            /*box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);*/
            border-radius: 10px;
            width: 10%;
            padding: 30px;
        }

        .detalhes-fornecedor {
            padding: 20px;
            /*box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);*/
            background: #FFFFFF;
            border-radius: 0 10px 10px 0;
        }
    }

    .grid-app {
        .btn-navegacao {
            background-color: #00B6A6;
            border: 1px solid #ccc;
            border-radius: 3px;
            font-weight: 600;
            padding: 7px 10px;
            color: #00B6A6 !important;
        }
    }
</style>

