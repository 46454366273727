<template>
    <div class="viewJUSTF" v-if="dados">
        <div class="modal-conteudo m-2">
            <div id="divImprimir">
                <div class="row mb-2">
                    <div class="col-md-12">
                        <p class="txt-p txt-w-7 txt-size-16 mb-0"> {{dados.dsTitulo}}</p>
                        <p class="txt-p txt-w-4 txt-size-14"> {{dados.dsSubTitulo}}</p>
                    </div>
                    <div class="col-md-12">
                        <h4 class="txtApp">Relator(a):</h4>
                        <p class="regular" v-html="dados.dsRelator"></p>
                    </div>
                    <div class="col-md-12" v-if="dados.dsRelatorDecisao">
                        <h4 class="txtApp">Relator(a) da Decisão:</h4>
                        <p class="regular" v-html="dados.dsRelatorDecisao"></p>
                    </div>
                    <div class="col-md-12">
                        <h4 class="txtApp">Julgamento:</h4>
                        <p class="regular" v-html="dados.dtJulgamento"></p>
                    </div>
                    <div class="col-md-12">
                        <h4 class="txtApp">Publicação:</h4>
                        <p class="regular" v-html="dados.dtPublicacao"></p>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12" v-if="dados.dsPublicacao">
                        <h4 class="txtApp">Publicação:</h4>
                        <p class="regular" v-html="dados.dsPublicacao"></p>
                    </div>
                    <div class="col-md-12" v-if="dados.dsPartes">
                        <h4 class="txtApp">Partes:</h4>
                        <p class="regular" v-html="dados.dsPartes"></p>
                    </div>
                    <div class="col-md-12" v-if="dados.dsDecisao">
                        <h4 class="txtApp">Decisão:</h4>
                        <p class="regular" v-html="dados.dsDecisao"></p>
                    </div>
                    <div class="col-md-12" v-if="dados.dsLegislacao">
                        <h4 class="txtApp">Legislação:</h4>
                        <p class="regular" v-html="dados.dsLegislacao"></p>
                    </div>
                    <div class="col-md-12" v-if="dados.dsObservacao">
                        <h4 class="txtApp">Observação:</h4>
                        <p class="regular" v-html="dados.dsObservacao"></p>
                    </div>
                </div>                     
            </div>
        </div>
        

        <div class="modal-footer pb-0 mt-4 d-flex justify-content-end">
            <AdicionarBiblioteca v-if="exibeBiblioteca" :id="dados.idJurisprudenciaStf.toString()" :tipo="tpConteudo" :cdConteudo="cdConteudo"></AdicionarBiblioteca>

            <a :href="dados.urlLink" class="btn btn-blue txt-size-14" target="_blank" v-if="dados.urlLink">
                <i class="fas fa-external-link-alt"></i> Acessar Jurisprudência
            </a>
            <a :href="dados.urlPdf" class="btn btn-blue txt-size-14" target="_blank" v-if="dados.urlPdf">
                <i class="fas fa-file-pdf"></i> Inteiro teor
            </a>

            <button class="btn btn-blue txt-size-14" @click="imprimir">
                <i class="fas fa-print"></i> Imprimir
            </button>
        </div>
    </div>
</template>

<script>
    import AdicionarBiblioteca from '../AdicionarBiblioteca'
    import { urlApi } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'viewJUSTF',
        components: { AdicionarBiblioteca },
        data: function () {
            return {
                exibeBiblioteca: true,
                tpConteudo: 'Pesquisa',
                cdConteudo: 'JUSTF',
            }
        },
        props: {
            dados: Object,
        },
        methods: {
            verificaExisteRepositorio(idConteudo, tpConteudo, cdConteudo) {
                let $this = this;
                axios.get(`${urlApi}/Repositorio`, { params: { idConteudo: idConteudo, tpConteudo: tpConteudo, cdConteudo: cdConteudo } })
                    .then(res => {
                        if (res.data.success) {
                            $this.exibeBiblioteca = res.data.data;
                        } else {
                            console.log('Não foi possível verificar o repositório')
                        }
                    })
                    .catch(function () {
                        console.log('Não foi possível verificar o repositório')
                    });
            },

            imprimir() {
                var conteudo = document.getElementById('divImprimir').innerHTML,
                    tela_impressao = window.open('about:blank');

                tela_impressao.document.write(conteudo);
                tela_impressao.window.print();
                tela_impressao.window.close();
            },
        },
        mounted: function () {
            this.verificaExisteRepositorio(this.dados.idJurisprudenciaStf.toString(), this.tpConteudo, this.cdConteudo);
        },
        watch: {
            dados: {
                handler() {
                    this.verificaExisteRepositorio(this.dados.idJurisprudenciaStf.toString(), this.tpConteudo, this.cdConteudo);
                },
                deep: true
            },
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    
   
</style>

