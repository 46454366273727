<template>
    <div class="viewPERIO">
        <div class="modal-conteudo m-2">
            <div class="row">
                <div class="col-md-4">
                    <img :src="dados.urlImgPeriodico" alt="" class="img-responsive img-thumbnail" />
                </div>
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-4">
                            <label class="bold" style="font-weight:bold;">Edição:</label>
                            <p class="regular"> {{getMesExtenso(dados.nrPeriodico)}} / {{dados.nrAno}}</p>
                        </div>
                        <div class="col-md-8">
                            <label class="bold" style="font-weight:bold;">Revista:</label>
                            <p class="regular">{{dados.dsTitulo}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="bold" style="font-weight:bold;">Descrição:</label>
                            <template v-if="dados.dsPeriodico == null || dados.dsPeriodico == ''">
                                <p class="regular">...</p>
                            </template>
                            <template v-else>
                                <p class="regular" v-html="dados.dsPeriodico"></p>
                            </template>

                            <span class="conteudoEncontrado" v-if="busca.dsReferencia != '' && busca.dsReferencia != null">{{busca.dsReferencia}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer pb-0 mt-4 d-flex justify-content-end" >
            <AdicionarBiblioteca v-if="exibeBiblioteca" :id="dados.idPeriodico.toString()" :tipo="tpConteudo" :cdConteudo="cdConteudo"></AdicionarBiblioteca>

            <button class="btn btn-blue txt-size-14" @click="ver">
                <i class="fas fa-eye "></i> Visualizar
            </button>
        </div>
    </div>
</template>

<script>
    import AdicionarBiblioteca from '../AdicionarBiblioteca'
    import { urlApi, linkPeriodicos } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'

    export default {
        name: 'viewPERIO',
        components: { AdicionarBiblioteca },
        data: function () {
            return {
                exibeBiblioteca: true,
                tpConteudo: 'Pesquisa',
                cdConteudo: 'PERIO',
                linkPeriodicos: linkPeriodicos,
            }
        },
        computed: {
            user() {
                return this.$store.state.user
            },
        },
        props: {
            dados: Object,
            busca: Object,
        },
        methods: {
            verificaExisteRepositorio(idConteudo, tpConteudo, cdConteudo) {
                let $this = this;
                axios.get(`${urlApi}/Repositorio`, { params: { idConteudo: idConteudo, tpConteudo: tpConteudo, cdConteudo: cdConteudo } })
                    .then(res => {
                        if (res.data.success) {
                            $this.exibeBiblioteca = res.data.data;
                        } else {
                            console.log('Não foi possível verificar o repositório')
                        }
                    })
                    .catch(function () {
                        console.log('Não foi possível verificar o repositório')
                    });
            },

            getMesExtenso(dt) {
                if (dt < 10) {
                    dt = "0" + dt;
                }
                return moment(dt).lang("pt-br").format('MMMM');
            },

            ver() {
                var url = this.linkPeriodicos + 'AutenticacaoAutomatica?token=' + this.user.accessToken + '&refreshTokenWeb=' + this.user.refreshTokenWeb + '&route=' + 'Revista/' + this.dados.idPeriodico + '/?materia=' + (parseInt(this.busca.dsReferencia.replace("Conteúdo encontrado na página ","")) -2);
                window.location.href = encodeURI(url);
            },

        },
        mounted: function () {
            this.verificaExisteRepositorio(this.dados.idPeriodico.toString(), this.tpConteudo, this.cdConteudo);
        },
        watch: {
            dados: {
                handler() {
                    this.verificaExisteRepositorio(this.dados.idPeriodico.toString(), this.tpConteudo, this.cdConteudo);
                },
                deep: true
            },
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .conteudoEncontrado {
        font-size: 1.3rem !important;
        color: #163142;
        font-weight: 500 !important;
        border-left: 3px solid #163142;
        padding-left: 6px;
    }
</style>

