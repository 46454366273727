<template>
    <div class="viewSUMUL">
        <div class="modal-conteudo m-2">
            <div id="divImprimir">
                <div class="row mb-2">
                    <div class="col-md-4">
                        <h4 class="txtApp">Acórdão:</h4>
                        <span class="regular">{{dados.nrNumero}}/{{dados.nrAnoAprovacao}} - {{dados.nmColegiado}}</span>
                    </div>
                    <div class="col-md-4">
                        <h4 class="txtApp">Data da sessão:</h4>
                        <template v-if="dados.dtSessaoFormatada == null || dados.dtSessaoFormatada == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dtSessaoFormatada"></span>
                        </template>
                    </div>
                    <div class="col-md-4">
                        <h4 class="txtApp">Relator:</h4>
                        <span class="regular" v-html="dados.nmAutores"></span>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-4">
                        <h4 class="txtApp">Área:</h4>
                        <span class="regular" v-html="dados.nmArea"></span>
                    </div>
                    <div class="col-md-4">
                        <h4 class="txtApp">Tema:</h4>
                        <span class="regular" v-html="dados.nmTema"></span>
                    </div>
                    <div class="col-md-4">
                        <h4 class="txtApp">Subtema:</h4>
                        <span class="regular" v-html="dados.nmSubTema"></span>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Tipo do processo:</h4>
                        <template v-if="dados.nmTipoProcesso == null || dados.nmTipoProcesso == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.nmTipoProcesso"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Enunciado:</h4>
                        <template v-if="dados.dsEnunciado == null || dados.dsEnunciado == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dsEnunciado"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Excerto:</h4>
                        <template v-if="dados.dsExcerto == null || dados.dsExcerto == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dsExcerto"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Publicado:</h4>
                        <template v-if="dados.dsPublicacaoApresentacao == null || dados.dsPublicacaoApresentacao == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dsPublicacaoApresentacao"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Enunciados relacionados:</h4>
                        <template v-if="dados.dsEnunciado == null || dados.dsEnunciado == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dsEnunciado"></span>
                        </template>
                    </div>
                </div>

            </div>
        </div>

        <div class="modal-footer pb-0 mt-4 d-flex justify-content-end">
            <AdicionarBiblioteca v-if="exibeBiblioteca" :id="dados.idSumula.toString()" :tipo="tpConteudo" :cdConteudo="cdConteudo"></AdicionarBiblioteca>

            <button class="btn btn-blue txt-size-14" @click="imprimir">
                <i class="fas fa-print"></i> Imprimir
            </button>
        </div>

    </div>
</template>

<script>
    import AdicionarBiblioteca from '../AdicionarBiblioteca'
    import { urlApi } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'viewSUMUL',
        components: { AdicionarBiblioteca },
        data: function () {
            return {
                exibeBiblioteca: true,
                tpConteudo: 'Pesquisa',
                cdConteudo: 'SUMUL',
            }
        },
        props: {
            dados: Object,
        },
        methods: {
            verificaExisteRepositorio(idConteudo, tpConteudo, cdConteudo) {
                let $this = this;
                axios.get(`${urlApi}/Repositorio`, { params: { idConteudo: idConteudo, tpConteudo: tpConteudo, cdConteudo: cdConteudo } })
                    .then(res => {
                        if (res.data.success) {
                            $this.exibeBiblioteca = res.data.data;
                        } else {
                            console.log('Não foi possível verificar o repositório')
                        }
                    })
                    .catch(function () {
                        console.log('Não foi possível verificar o repositório')
                    });
            },

            imprimir() {
                var conteudo = document.getElementById('divImprimir').innerHTML,
                    tela_impressao = window.open('about:blank');

                tela_impressao.document.write(conteudo);
                tela_impressao.window.print();
                tela_impressao.window.close();
            },
        },
        mounted: function () {
            this.verificaExisteRepositorio(this.dados.idSumula.toString(), this.tpConteudo, this.cdConteudo);
        },
        watch: {
            dados: {
                handler() {
                    this.verificaExisteRepositorio(this.dados.idSumula.toString(), this.tpConteudo, this.cdConteudo);
                },
                deep: true
            },
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    
   
</style>

