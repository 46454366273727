<template>
    <div class="ModalAudio" >
        <b-modal ref="modal-audio" id="modalAudio" size="md" :hide-footer="true" :hide-header="true">
            <div class="w-100 text-end txt-size-20 txt-blue-2">
                <span @click="close"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <div class="row">
                <div class="col-12">
                    <div id="divLocal" class="audioWrapper" v-show="audio != null">
                        <audio id="Audio" class="video-js vjs-default-skin" controls="controls" poster="~/Content/img/imgPlayVideo.png" allowfullscreen @*autoplay*@ data-setup="{}">
                            <source src="audio.src" type="audio/mp3">
                        </audio>

                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'ModalAudio',
        data: function () {
            return {
                audio: null,
                idAudioLog: 0,
            }
        },
        props: {
            showModal: Boolean,
            dados: Object,
        },
        methods: {

            logAudio(hrTempoVisualizacao) {
                hrTempoVisualizacao = this.segundo_2_hora(hrTempoVisualizacao);
                var quebra_tempo = hrTempoVisualizacao.split(".");
                hrTempoVisualizacao = quebra_tempo[0];

                //let $this = this;
                //axios.post(`${urlApi}/MediaPlayer/LogAudio`, { idAudioLog: $this.idAudioLog, idAudio: $this.dados.p_idAudio, hrTempoVisualizacao: hrTempoVisualizacao })
                //    .then(res => {
                //        if (res.data.success) {
                //            $this.idAudioLog = res.data.msg;
                //        } else {
                //            $this.audio = null;
                //            console.log("Erro Log");
                //        }
                //    })
                //    .catch(function () {
                //        console.log("Erro Log");
                //        $this.audio = null;
                //    });
            },

            segundo_2_hora(s) {
                let hora = (Math.round(s / 3600));
                let minuto = this.duas_casas(Math.floor((s % 3600) / 60));
                let segundo = this.duas_casas((s % 3600) % 60);

                return hora + ":" + minuto + ":" + segundo;
            },


            duas_casas(numero) {
                if (numero <= 9) {
                    numero = "0" + numero;
                }
                return numero;
            },

            load() {
                this.caregaAudio();
            },

            caregaAudio() {
                let $this = this;
                axios.get(`${urlApi}/MediaPlayer/GetAudio/`, { params: { p_tipo: $this.dados.p_tipo, p_idAudio: $this.dados.p_idAudio, } })
                    .then(res => {
                        if (res.data.success) {
                            $this.audio = res.data.data;
                            $this.$nextTick(() => {
                                $this.montaListener();
                            });
                            
                        } else {
                            avisoErro("Não foi possível carregar o áudio");
                            $this.audio = null;
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar o áudio");
                        $this.audio = null;
                    });
            },

            montaListener() {
                let $this = this;
                var AudioElement = document.getElementById('Audio');
                
                AudioElement.addEventListener("play", function () {
                    $this.logAudio(AudioElement.currentTime);
                }, true);

                AudioElement.addEventListener("pause", function () {
                    $this.logAudio(AudioElement.currentTime);
                }, true);

                AudioElement.addEventListener("ended", function () {
                    $this.logAudio(AudioElement.currentTime);
                }, true);
            },

            close() {
                this.$refs['modal-audio'].hide();
            }

        },
        created: function () {
            

        },
        watch: {
            showModal: function () {
                this.$refs['modal-audio'].show();
                this.load();
            },
            
        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    /* ModalAudio*/
    .audioWrapper {
        display: flex;
        justify-content: center;
    }
</style>

