<template>
    <div class="viewSUSTF">
        <div class="modal-conteudo m-2">
            <div id="divImprimir">
                <div class="row mb-2">
                    <div class="col-md-12">
                        <p class="txt-p txt-w-7 txt-size-16"> {{dados.dsCodigo}}</p>
                    </div>
                    <div class="col-md-12">
                        <h4 class="txtApp">Enunciado:</h4>
                        <p class="regular" v-html="dados.dsEnunciado"></p>
                    </div>
                    <div class="col-md-12">
                        <h4 class="txtApp">Data de Aprovação:</h4>
                        <p class="regular" v-html="dados.dtAprovacao"></p>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Fonte de publicação:</h4>
                        <p class="regular" v-html="dados.dsFonte"></p>
                    </div>
                    <div class="col-md-12">
                        <h4 class="txtApp">Referência Legislativa:</h4>
                        <p class="regular" v-html="dados.dsReferencia"></p>
                    </div>
                    <div class="col-md-12">
                        <h4 class="txtApp">Precedentes:</h4>
                        <p class="regular" v-html="dados.dsPrecedentes"></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer pb-0 mt-4 d-flex justify-content-end">
            <AdicionarBiblioteca v-if="exibeBiblioteca" :id="dados.idSumulaStf.toString()" :tipo="tpConteudo" :cdConteudo="cdConteudo"></AdicionarBiblioteca>

            <a :href="dados.urlLink" class="btn btn-blue txt-size-14" target="_blank" v-if="dados.urlLink">
                <i class="fas fa-external-link-alt"></i> Acessar Súmula
            </a>

            <button class="btn btn-blue txt-size-14" @click="imprimir">
                <i class="fas fa-print"></i> Imprimir
            </button>
        </div>

    </div>
</template>

<script>
    import AdicionarBiblioteca from '../AdicionarBiblioteca'
    import { urlApi } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'viewSUSTF',
        components: { AdicionarBiblioteca },
        data: function () {
            return {
                exibeBiblioteca: true,
                tpConteudo: 'Pesquisa',
                cdConteudo: 'SUSTF',
            }
        },
        props: {
            dados: Object,
        },
        methods: {
            verificaExisteRepositorio(idConteudo, tpConteudo, cdConteudo) {
                let $this = this;
                axios.get(`${urlApi}/Repositorio`, { params: { idConteudo: idConteudo, tpConteudo: tpConteudo, cdConteudo: cdConteudo } })
                    .then(res => {
                        if (res.data.success) {
                            $this.exibeBiblioteca = res.data.data;
                        } else {
                            console.log('Não foi possível verificar o repositório')
                        }
                    })
                    .catch(function () {
                        console.log('Não foi possível verificar o repositório')
                    });
            },

            imprimir() {
                var conteudo = document.getElementById('divImprimir').innerHTML,
                    tela_impressao = window.open('about:blank');

                tela_impressao.document.write(conteudo);
                tela_impressao.window.print();
                tela_impressao.window.close();
            },
        },
        mounted: function () {
            this.verificaExisteRepositorio(this.dados.idSumulaStf.toString(), this.tpConteudo, this.cdConteudo);
        },
        watch: {
            dados: {
                handler() {
                    this.verificaExisteRepositorio(this.dados.idSumulaStf.toString(), this.tpConteudo, this.cdConteudo);
                },
                deep: true
            },
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    
   
</style>

