<template>
    <div class="Modulos">
        <div class="row">
            <div class="col-12 mb-4">
                <span class="txt-h-2 txt-size-24 txt-blue-2">
                    Minhas ferramentas
                </span>
            </div>
        </div>
        <div class="row row-cols-2 row-cols-lg-3 row-cols-xl-5">
            <div class="col mb-5" v-for="item in lstFerramentas" :key="item.idFerramenta">
                <div class="box-modulo c-pointer h-100">
                    <div :class="'box-modulo-header position-relative bg-'+item.classe">
                        <img class="img-fluid" :src="item.urlImagem" />
                        <p class="mb-0 txt-h-2 txt-size-14 txt-white mt-3 txt-letter-n-2">
                            {{item.nmFerramenta}}
                        </p>
                        <ManualFerramenta :interna="false" :name="'Manual '+item.nmFerramenta" :btnClass="'btn-'+item.classe" :nameBtn="'Manual'" :urlPdf="item.urlManual"></ManualFerramenta>
                    </div>
                    <div class="box-modulo-body" @click="acessarModulo(item)">
                        <p class="txt-h-5 txt-size-12 txt-blue-2 txt-w-6 mb-0 txt-letter-n-2">
                            {{item.dsFerramenta}}
                        </p>
                    </div>
                    <div :class="'box-modulo-footer bg-'+item.classe" @click="acessarModulo(item)">
                        <p class="txt-h-5 txt-size-14 txt-white txt-w-7 txt-letter-n-2 mb-0">Ir para {{item.nmFerramenta}}</p>

                    </div>
                </div>
            </div>
        </div>

        <ContratarModulo :showModal="showModalContratar" :dadosModulo="dadosModuloContratar" :isModulo="false"></ContratarModulo>
    </div>
</template>

<script>
    import imgContador from '@/assets/img/contador-min.png';
    import imgAgenda from '@/assets/img/agenda-min.png';
    import imgBiblioteca from '@/assets/img/biblioteca-min.png';
    import imgFornecedores from '@/assets/img/fornecedores-min.png';
    import imgBanco from '@/assets/img/banco-min.png';
    import imgAnalisador from '@/assets/img/balancos-min.png';
    import imgEditais from '@/assets/img/editais-min.png';
    import imgModelos from '@/assets/img/modelos-min.png';
    import imgLei from '@/assets/img/lei-min.png';
    import imgEbook from '@/assets/img/ebook-min.png';
    //import imgPesquisa from '@/assets/img/pesquisa-min.png';

    import ContratarModulo from '@/components/ContratarModulo'
    import ManualFerramenta from '@/components/ManualFerramenta'
    import { urlApi, avisoErro, linkPeriodicos  } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'Ferramentas',
        components: { ContratarModulo, ManualFerramenta },
        computed: {
            user() {
                return this.$store.state.user
            },
        },
        data: function () {
            return {
                lstFerramentas: [
                    {
                        idFerramenta: 1, cdProduto: 'CP', nmFerramenta: 'Contador de Prazos', urlImagem: imgContador, classe: 'contador_prazos', urlModulo: 'ContadorPrazos', 
                        dsFerramenta: 'Registro das datas relevantes, com a geração automática do fluxo de vencimentos dos prazos, visão geral do processo e manutenção do histórico.',
                        flAcessoExterno: false,
                        urlManual: 'manuais/FERRAMENTA CONTADOR DE PRAZOS PDF.pdf',
                    },
                    {
                        idFerramenta: 2, cdProduto: 'SA', nmFerramenta: 'Agenda', urlImagem: imgAgenda, classe: 'agenda', urlModulo: 'Agenda',
                        dsFerramenta: 'Registro de atividades e compromissos e visualização de programações recentes.',
                        flAcessoExterno: false,
                        urlManual: 'manuais/FERRAMENTA AGENDA.pdf',
                    },
                    {
                        idFerramenta: 3, cdProduto: 'RP', nmFerramenta: 'Biblioteca', urlImagem: imgBiblioteca, classe: 'biblioteca', urlModulo: 'Biblioteca',
                        dsFerramenta: 'Ambiente exclusivo e individual de acesso rápido a conteúdos salvos.',
                        flAcessoExterno: false,
                        urlManual: 'manuais/FERRAMENTA BIBLIOTECA PDF.pdf',
                    },
                    {
                        idFerramenta: 4, cdProduto: 'SF', nmFerramenta: 'Fornecedores', urlImagem: imgFornecedores, classe: 'fornecedores', urlModulo: 'Fornecedor',
                        dsFerramenta: 'Banco de dados com fornecedores que participaram de licitações registradas nos principais portais de compras públicas; buscas por produto, serviço e fornecedor.',
                        flAcessoExterno: false,
                        urlManual: 'manuais/FERRAMENTA FORNECEDORES PDF.pdf',
                    },
                    {
                        idFerramenta: 5, cdProduto: 'BP', nmFerramenta: 'Banco de Penalidades', urlImagem: imgBanco, classe: 'banco_penalidades', urlModulo: 'BancoPenalidades',
                        dsFerramenta: 'Pesquisa nas informações do Portal Transparência, do Cadastro de Empresas Inidôneas e Suspensas e em publicações em Diários Oficiais.',
                        flAcessoExterno: false,
                        urlManual: 'manuais/FERRAMENTA BANCO DE PENALIDADES PDF.pdf',
                    },
                    {
                        idFerramenta: 6, cdProduto: 'AB', nmFerramenta: 'Analisador de Balanços', urlImagem: imgAnalisador, classe: 'analisador_balancos', urlModulo: 'AnalisadorBalancos',
                        dsFerramenta: 'Cálculo automático de índices, mediante a utilização das fórmulas indicadas no edital.',
                        flAcessoExterno: false,
                        urlManual: 'manuais/FERRAMENTA ANALISADOR DE BALANÇOS PDF.pdf',
                    },
                    {
                        idFerramenta: 7, cdProduto: 'SE', nmFerramenta: 'Editais', urlImagem: imgEditais, classe: 'editais', urlModulo: 'Editais',
                        dsFerramenta: 'Acesso a editais, inclusive Sistema de Registro de Preços, de diversos órgãos e entidades, das três esferas de governo, divulgados nos principais portais de compras públicas.',
                        flAcessoExterno: false,
                        urlManual: 'manuais/FERRAMENTA EDITAIS PDF.pdf',
                    },
                    {
                        idFerramenta: 8, cdProduto: 'MD', nmFerramenta: 'Modelos', urlImagem: imgModelos, classe: 'modelos', urlModulo: 'Modelo',
                        dsFerramenta: 'Acesse a Modelos e Manuais da AGU e PGFN – Procuradoria Geral da Fazenda Nacional, manuais oficiais, checklists, planilhas, e modelos exclusivos NP elaborados especialmente para você.',
                        flAcessoExterno: false,
                        urlManual: 'manuais/FERRAMENTA MODELOS PDF.pdf',
                    },
                    {
                        idFerramenta: 9, cdProduto: 'SP', nmFerramenta: 'Lei Comentada', urlImagem: imgLei, classe: 'lei_comentada', urlModulo: 'Pesquisa',
                        dsFerramenta: 'As Leis 14.133/21, 13.303/06 e 8.666/93 estão comentadas no Sollicita PRO, com doutrinas, acórdãos, links de conteúdo, artigos, vídeos e comentários exclusivos.',
                        flAcessoExterno: false,
                        urlManual: 'manuais/FERRAMENTA LEI COMENTADA E REGULAMENTADA PDF.pdf',
                    },
                    {
                        idFerramenta: 10, cdProduto: 'PE', nmFerramenta: 'Livraria', urlImagem: imgEbook, classe: 'ebook', urlModulo: linkPeriodicos,
                        dsFerramenta: 'Conte com uma livraria particular na área de licitações e contratos. E- Books digitais para baixar, ler e consultar onde e quando você precisar.',
                        flAcessoExterno: true,
                        route: 'Ebooks',
                        urlManual: 'manuais/FERRAMENTA LIVRARIA PDF.pdf',
                    },
                    //{
                    //    idFerramenta: 11, cdProduto: 'PE', nmFerramenta: 'Pesquisa', urlImagem: imgPesquisa, classe: 'pesquisa', urlModulo: 'Pesquisa',
                    //    dsFerramenta: ''
                    //},
                ],

                showModalContratar: false,
                dadosModuloContratar: null,

                showModalManual: false,
            }
        },
        methods: {
            acessarModulo(modulo) {
                let $this = this;
                axios.get(`${urlApi}/Produto/VerificaAcessoProduto`, { params: { cdProduto: modulo.cdProduto } })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data != 'Sim') {
                                // nao tem acesso 
                                $this.dadosModuloContratar = {
                                    nome: modulo.nmFerramenta,
                                    descricao: modulo.dsFerramenta,
                                    urlImagem: modulo.urlImagem,
                                    classe: modulo.classe
                                };
                                $this.showModalContratar = !$this.showModalContratar;
                            } else {
                                // redirect
                                if (modulo.nmFerramenta == 'Lei Comentada') {
                                    $this.$router.push({ name: 'Pesquisa', query: { leiComentada: 'true' } });
                                } else {
                                    $this.redirectModulo(modulo);
                                }
                            }
                        } else {
                            avisoErro("Não foi possível verificar a ferramenta");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível verificar a ferramenta");
                    });
            },

            redirectModulo(modulo) {
                if (modulo.flAcessoExterno) {
                    var url = modulo.urlModulo + 'AutenticacaoAutomatica?token=' + this.user.accessToken + '&refreshTokenWeb=' + this.user.refreshTokenWeb + '&route=' + modulo.route;
                    window.location.href = encodeURI(url);
                } else {
                    this.$router.push({ name: modulo.urlModulo });
                }
            },


        },

    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .box-modulo {
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .box-modulo-header {
            border-radius: 5px;
            padding: 30px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            img {
                width: 75px;
                height: 75px;
            }
        }

        .box-modulo-body {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 30px 20px;
            min-height: 150px;
        }

        .box-modulo-footer {
            border-radius: 5px;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
    
    
</style>

