<template>
    <section class="CancelamentoHotmart">
        <Header></Header>

        <section class="section_cancelamentohotmart">
            <div class="container">
                <div class="row mb-2 mt-5">
                    <div class="col-md-12 bloco">
                        <div class="cabecalho_logado hidden-xs hidden-sm">
                            <span class="bold"><i class="fa fa-exclamation-triangle"></i> Instruções de Cancelamento</span>
                        </div>
                        <div class="row">
                            <article class="col-md-8">
                                <div class="article-conteudo">
                                    <p>Para realizar o cancelamento do seu serviço de assinatura, basta seguir os seguintes passos:</p>
                                    <ol>
                                        <li>
                                            Acesse a plataforma <a href="https://app-vlc.hotmart.com/login" target="_blank">Hotmart</a>.
                                        </li>
                                        <li>
                                            Insira seu login e senha. Caso não se recorde, você pode redefini-la clicando em <strong>Esqueceu sua Senha?</strong>.
                                        </li>
                                        <li>
                                            Após realizar o login, selecione a opção <strong>Minhas Compras</strong> no menu esquerdo.
                                        </li>
                                        <li>
                                            Clique na assinatura desejada. Uma aba será aberta do lado direito.
                                        </li>
                                        <li>
                                            Nessa aba, selecione a opção <strong>Configurar Pagamento</strong>
                                        </li>
                                        <li>
                                            Na página que se abrirá, selecione a opção <strong>Deseja cancelar sua assinatura</strong>.
                                        </li>
                                        <li>
                                            Confirme o cancelamento clicando em <strong>Sim</strong>.
                                        </li>
                                    </ol>
                                    <p><b>Atenção:</b> O cancelamento da assinatura não implica o reembolso do valor já investido, apenas <b>cancela cobranças futuras</b>.</p>
                                    <p>Para maiores informações, acesse o <a href="https://atendimento.hotmart.com.br/hc/pt-br/articles/360001504571-Como-cancelar-um-servi%C3%A7o-de-assinatura-" target="_blank">guia de cancelamento</a>.</p>
                                </div>
                            </article>
                            <aside class="col-md-4">
                                <div class="alert alert-danger">
                                    <div class="alert-body text-center">
                                        <i class="fa fa-exclamation-circle fa-3x"></i>
                                        <h3><strong>Atenção</strong></h3>
                                        <p class="bold">Caso encontre alguma dificuldade para executar o cancelamento, encaminhe um e-mail para <a href="mailto:contato@sollicita.com.br">contato@sollicita.com.br</a>, que nós efetuamos o seu cancelamento.</p>
                                    </div>
                                </div>
                            </aside>
                        </div>

                    </div>
                </div>
            </div>
            
        </section>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header'
    import Footer from '@/components/Footer'

    export default {
        name: 'CancelamentoHotmart',
        components: { Header, Footer},
        data() {
            return {
            }
        },
        computed: {
            
            flPesquisa() {
                return this.$store.state.flPesquisa
            },

        },
        methods: {
            pesquisar(termo) {
                if (termo != null) {
                    this.$router.push({ name: 'Pesquisa', query: { termo: termo } });
                }
            },

        },
        created: function () {
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .section_cancelamentohotmart {
        /*height: calc(100vh - 231px);*/
    }

    .cabecalho_logado {
        color: #616161;
        font-size: 24px;
        line-height: 30px;
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 15px;
        padding: 0 5px;
    }

    article .article-conteudo p, article .article-conteudo span, article .article-conteudo ul, article .article-conteudo ol {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px !important;
    }

    a:link, a:visited {
        color: #00518c;
        text-decoration: none;
    }

    .article-conteudo a {
        color: #00518c;
        font-weight: bold;
    }

    .article-conteudo a:hover {
        color: #5577aa;
        text-decoration: underline;
    }

    p, span {
        color: #7c7c7c;
    }
</style>

