<template>
    <section class="homelogado">
        <Header></Header>

        <InputPesquisa v-if="flPesquisa" :pesquisar="pesquisar"></InputPesquisa>
        <div :class="'container '+(flPesquisa?'mt-4':'')">
            <Modulos style="margin-top: 15vh;"></Modulos>

            <Ferramentas class="mt-5"></Ferramentas>
        </div>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header'
    import Footer from '@/components/Footer'
    import Modulos from '@/components/Modulos'
    import Ferramentas from '@/components/Ferramentas'
    import InputPesquisa from '@/components/InputPesquisa'

    export default {
        name: 'HomeLogado',
        components: { Header, Footer, Modulos, Ferramentas, InputPesquisa },
        data() {
            return {
            }
        },
        computed: {
            
            flPesquisa() {
                return this.$store.state.flPesquisa
            },

        },
        methods: {
            pesquisar(termo) {
                if (termo != null) {
                    this.$router.push({ name: 'Pesquisa', query: { termo: termo } });
                }
            },

        },
        created: function () {
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    
</style>

