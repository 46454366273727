<template>
    <section class="login">
        <div class="interno_login">
            <div class="container">

                <div class="d-flex align-items-center justify-content-center h-100 flex-column">
                    <div class="text-center my-5">
                        <img class="logo_login" src="../assets/img/logo_sollicita.png">

                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="formulario_login">
                            <form class="">
                                <div class="row">
                                    <input class="form-control" type="text" name="nomelogin" placeholder="E-mail" v-model="user.login" required />
                                </div>
                                <div class="row mt-2">
                                    <input class="form-control" type="password" name="senhalogin" placeholder="Senha" v-model="user.password" v-on:keyup.enter="entrar" required />
                                </div>

                                <div class="row mt-2">
                                    <button class="btn btn-yellow btn-login" type="button" @click="entrar">Entrar</button>
                                </div>

                                <div class="row mt-3 px-0">
                                    <router-link :to="{name:'EsqueciMinhaSenha'}">
                                        <label class="txt-h-4 txt-size-16 txt-w-7 txt-white c-pointer">Esqueceu sua senha?</label>
                                    </router-link>
                                </div>

                                <div class="row mt-4">
                                    <span class="txt-h-4 txt-size-16 txt-w-7 txt-white mb-2">
                                        Não possui acesso? <b>Solicite gratuitamente!</b>
                                    </span>
                                    
                                </div>
                            </form>
                        </div>

                        <div class="voltar-sollicita mt-3">
                            <p class="txt-h-4 txt-size-16 txt-white mt-3"><router-link class="txt-white" :to="{name:'Home'}"><i class="fas fa-arrow-left"></i> Voltar para o site do Sollicita</router-link></p>
                        </div>
                    </div>
                    
                    
                </div>

                
            </div>
        </div>
        
    </section>
</template>

<script>
    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'Login',
        data() {
            return {
                user: {
                    login: "",
                    password: "",
                },
            }
        },
        methods: {
            entrar(event) {
                event.preventDefault();
                if (this.checkForm()) {
                    //var loading = this.$loading.show({});
                    let $this = this;
                    axios.post(`${urlApi}/Auth/signin`, this.user)
                        .then(res => {
                            if (res.data.token.authenticated) {
                                res.data.token.usuario = res.data.usuario;
                                res.data.token.usuario.idContrato = res.data.idContrato;
                                res.data.token.usuario.tpPessoa = res.data.tpPessoa;
                                res.data.token.usuario.flCortesia = res.data.flCortesia;

                                $this.$store.commit('setUser', res.data.token)
                                $this.$router.push({ name: 'HomeLogado' });
                            } else {
                                avisoErro("Erro ao realizar login.");
                            }
                            //loading.hide();
                        })
                        .catch(function () {
                            avisoErro("Login e/ou senha inválidos.");
                            //avisoErro(res.response.statusText);
                            //loading.hide();
                        })
                }

                return false;
            },


            checkForm() {
                var form = document.querySelector('form')
                if (!form.checkValidity()) {
                    var tmpSubmit = document.createElement('button')
                    form.appendChild(tmpSubmit)
                    tmpSubmit.click()
                    form.removeChild(tmpSubmit)

                    return false;
                }

                return true;
            },


        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .login {
        min-height: 100vh;
    }

    .interno_login {
        height: 100vh;
        /*background: linear-gradient(107.56deg, #034471 0%, rgba(3, 68, 113, 0.76) 50.01%, #034471 100%);*/
        background: linear-gradient(107.56deg, #163142 0%, #2E4655 50.01%, #173243 100%);
    }

    .container{
        height:100%;
    }

    .formulario_login {
        padding: 60px;
        background: rgba(255, 255, 255, 0.1);
        border: 3px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 10px;

    }

    .btn-login {
        border-radius: 5px;
    }
   
</style>

