<template>
    <section class="DetalheEdital">
        <Header></Header>

        <div class="container my-3">
            <div class="row ">
                <div class="col-2">
                    <span class="txt-h-4 txt-grey-6 c-pointer" @click="votar"><i class="fas fa-arrow-left"></i> Voltar</span>
                </div>
                <div class="col-8 text-center">
                    <p class="txt-p txt-size-20 txt-editais mb-0">
                        Sollicita <span class="txt-w-7">Editais</span>
                    </p>
                </div>
                <div class="col-2">

                </div>
            </div>            
        </div>

        <div class="container" v-if="edital">
            <div class="row">
                <div class="col-12">
                    <div class="dados-edital">
                        <div class="row">
                            <div class="col-12">
                                <div class="titulo-edital">
                                    <p class="txt-p txt-size-18 txt-w-7 txt-editais mb-0">
                                        Órgão/Entidade: {{edital.entidade}} / UF:  {{edital.ufEntidade}}
                                    </p>
                                    <p class="txt-p txt-size-16 txt-w-4 txt-editais mb-0">
                                        {{formatDate(edital.dataLicitacao)}} - {{edital.modalidade}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-12 my-2">
                                <hr />
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-md-6 d-flex">
                                        <div class="box-detalhes w-100 d-flex flex-wrap align-content-start">
                                            <p class="txt-p txt-size-16 txt-w-7 txt-grey-6 mb-2 w-100">
                                                Dados do edital
                                            </p>
                                            <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0">
                                                {{edital.objeto}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 mt-2 mt-sm-0">
                                        <div class="box-detalhes w-100 d-flex flex-wrap">
                                            <p class="txt-p txt-size-16 txt-w-7 txt-grey-6 mb-2">
                                                Itens do edital
                                            </p>
                                            <ul class="list-group d-flex">
                                                <template v-if="edital.descricaoItem && edital.descricaoItem.length > 0">
                                                    <li class="list-group-item regular itemLista" v-for="(item,index) in edital.descricaoItem" :key="index">
                                                        <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0">{{item}}</p>
                                                    </li>
                                                </template>
                                                <template v-else>
                                                    <li class="list-group-item">
                                                        <span id="semRegistros">Nenhum Registro Encontrado...</span>
                                                    </li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 mt-3">
                                <a :href="vertificaUrl(edital.urlEdital)" class="btn btn-editais w-100" target="_blank">Link do edital</a>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
            
        </div>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';

    import { urlApi } from '@/config/global'
    import axios from 'axios'
    //import { avisoErro } from '@/config/global'
    import moment from 'moment'
    export default {
        name: 'DetalheEdital',
        components: { Header, Footer },
        props: {
            edital: {
                type: Object,
                required: true,
            },
            pesquisa: Object,
            idPesquisaEditais: Number,
            index: Number,
        },
        computed: {
            user() {
                return this.$store.state.user
            },

        },
        data() {
            return {
                lstResultados: [],
                flResultados: false,
            }
        },
        methods: {
            votar() {
                this.$router.go(-1);
            },

            load() {
                if (this.edital == null || this.edital == undefined) {
                    this.$router.push({ name: 'Editais' });
                }
                // LOG
                this.geraLogPesquisaResultado();
            },

            geraLogPesquisaResultado() {
                let $this = this;
                axios.post(`${urlApi}/Log/LogPesquisaEditalResultados`, { idPesquisaEditais: $this.idPesquisaEditais, idLicitacao: $this.index })
                    .then(res => {
                        if (res.data.success) {
                            console.log("log ok");
                        }
                    })
            },

            vertificaUrl(url) {
                if (url.indexOf('https') == -1) {
                    url = url.replaceAll('http', 'https');
                }
                return url;
            },

            formatDate(dt) {
                return moment(dt).format('DD/MM/YYYY HH:mm');
            },
                        
        },
        mounted: function () {            
            this.load();
        },
        watch: {
            edital() {
                this.load();
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    .box-branco {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        background-color: #FFF;
        border-radius: 10px;
        padding: 20px;
    }

    .dados-edital {
        display: flex;
        background: #FFFFFF;
        border: 1px solid #767676;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 20px;

        .titulo-edital {
            padding-left: 10px;
            border-left: 10px solid #DE5E00;
        }

        .box-detalhes {
            background: #FBFBFB;
            border: 1px solid #767676;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            padding: 20px;

            .list-group {
                max-height: 300px;
                overflow-y: auto;
            }
        }

    }

</style>

