<template>
    <section class="DetalhePenalidade">
        <Header></Header>

        <div class="container my-3">
            <div class="row ">
                <div class="col-2">
                    <span class="txt-h-4 txt-grey-6 c-pointer" @click="votar"><i class="fas fa-arrow-left"></i> Voltar</span>
                </div>
                <div class="col-8 text-center">
                    <p class="txt-p txt-size-20 txt-banco_penalidades mb-0">
                        Sollicita <span class="txt-w-7">Banco de Penalidades</span>
                    </p>
                </div>
                <div class="col-2">

                </div>
            </div>
        </div>

        <div class="container" v-if="penalidade">
            <div class="row">
                <div class="col-12">
                    <div class="dados-penalidade" id="imprimir">
                        <div class="row">
                            <div class="col-12">
                                <div class="titulo-penalidade">
                                    <p class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                        CNPJ: 
                                        <span class="txt-grey-6 txt-w-4" v-if="penalidade.cnpj != '' && penalidade.cnpj != null">{{penalidade.cnpj}}</span>
                                        <span class="txt-grey-6 txt-w-4" v-else>**</span>
                                    </p>
                                    <p class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                        Razão Social: 
                                        <span class="txt-grey-6 txt-w-4" v-if="penalidade.razaoSocial != '' && penalidade.razaoSocial != null">{{penalidade.razaoSocial}}</span>
                                        <span class="txt-grey-6 txt-w-4" v-else>**</span>
                                    </p>
                                    <p class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                        UF: 
                                        <span class="txt-grey-6 txt-w-4" v-if="penalidade.uf != '' && penalidade.uf != null">{{penalidade.uf}}</span>
                                        <span class="txt-grey-6 txt-w-4" v-else>**</span>
                                    </p>
                                </div>
                            </div>
                            
                            <template v-for="(item,index) in penalidade.detalhesFontesPenalidades">
                                <div class="my-2" :key="index">
                                    <div class="col-12 my-2">
                                        <hr />
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <ul class="list-group ">
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Processo: 
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4" v-if="item.processo != '' && item.processo != null">{{item.processo}}</span>
                                                        <span class="txt-grey-6 txt-w-4" v-else>**</span>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Fundamentação Legal:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4" v-if="item.tipoSancao != '' && item.tipoSancao != null">{{item.tipoSancao}}</span>
                                                        <span class="txt-grey-6 txt-w-4" v-else>**</span>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Período:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4" v-if="item.dataAcordao != '' && item.dataAcordao != null && item.dataFinal != '' && item.dataFinal != null">
                                                            {{item.dataAcordao}} até {{item.dataFinal}}
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4" v-else>Período não encontrado</span>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Penalidade:
                                                        </span>                                                        
                                                        <span class="txt-grey-6 txt-w-4" v-if="item.detalhe != '' && item.detalhe != null">{{item.detalhe}}</span>
                                                        <span class="txt-grey-6 txt-w-4" v-else>**</span>
                                                    </li>
                                                </ul>

                                                <div class="col-md-12">
                                                    <h3 class="inline-block penalidadeBtn penalidadeAtiva mt-2" v-if="statusPenalidade(item.dataFinal)">
                                                        <span class="label label-danger"><i class="fas fa-times noPrint"></i> Penalidade Ativa</span>
                                                    </h3>
                                                    <h3 class="inline-block penalidadeBtn penalidadeInativa mt-2" v-else>
                                                        <span class="label label-success"><i class="fas fa-check noPrint"></i>  Penalidade Inativa</span>
                                                    </h3>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <ul class="list-group ">
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Número Fundamentação Legal:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4" v-if="item.deliberacao != '' && item.deliberacao != null">{{item.deliberacao}}</span>
                                                        <span class="txt-grey-6 txt-w-4" v-else>**</span>

                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Data Publicação:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4" v-if="item.dataPublicacao != '' && item.dataPublicacao != null">{{item.dataPublicacao}}</span>
                                                        <span class="txt-grey-6 txt-w-4" v-else>**</span>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Descrição Publicação:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4">**</span>

                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Detalhes Meio Publicação:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4">**</span>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Data Trânsito:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4" v-if="item.dtTransEmJulgado != '' && item.dtTransEmJulgado != null">{{item.dtTransEmJulgado}}</span>
                                                        <span class="txt-grey-6 txt-w-4" v-else>**</span>

                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Abrangência Decisão Judicial:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4">**</span>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Orgão Sancionador:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4" v-if="item.entitdadeSancionadora != '' && item.entitdadeSancionadora != null">{{item.entitdadeSancionadora}}</span>
                                                        <span class="txt-grey-6 txt-w-4" v-else>**</span>
                                                    </li> 
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Complemento Orgão Sancionador:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4">**</span>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            UF Orgão Sancionador:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4" v-if="penalidade.uf != '' && item.uf != null">{{item.uf}}</span>
                                                        <span class="txt-grey-6 txt-w-4" v-else>**</span>
                                                    </li>
                                                    <li class="list-group-item">
                                                       <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Origem Informação:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4" v-if="item.fonte != '' && item.fonte != null">{{item.fonte}}</span>
                                                        <span class="txt-grey-6 txt-w-4" v-else>**</span>

                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Endereço:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4">**</span>

                                                    </li>
                                                    <li class="list-group-item">
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Contato Origem Informação:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4">**</span>

                                                    </li>
                                                    <li class="list-group-item" >
                                                        <span class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                            Data Informação:
                                                        </span>
                                                        <span class="txt-grey-6 txt-w-4" v-if="item.dataPublicacao != '' && item.dataPublicacao != null">{{item.dataPublicacao}}</span>
                                                        <span class="txt-grey-6 txt-w-4" v-else>**</span>

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                            </template>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 text-end">
                    <button class="btn btn-banco_penalidades" @click="imprimir">
                        Imprimir
                    </button>
                </div>
            </div>
        </div>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';

    import { urlApi } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'
    export default {
        name: 'DetalhePenalidade',
        components: { Header, Footer },
        props: {
            penalidade: {
                type: Object,
                required: true,
            },
            pesquisa: Object,
            idPesquisaPenalidade: Number,
        },
        computed: {
            user() {
                return this.$store.state.user
            },

        },
        data() {
            return {
            }
        },
        methods: {
            votar() {
                this.$router.go(-1);
            },

            load() {
                if (this.penalidade == null || this.penalidade == undefined) {
                    this.$router.push({ name: 'BancoPenalidades' });
                }
                // LOG
                this.geraLogPesquisaResultado();
            },

            geraLogPesquisaResultado() {
                let $this = this;
                axios.post(`${urlApi}/Log/LogBancoPenalidadesResultado`, { idPesquisaPenalidade: $this.idPesquisaPenalidade, idFornecedor: $this.penalidade.idPenalidade })
                    .then(res => {
                        if (res.data.success) {
                            console.log("log ok");
                        }
                    })
            },

            vertificaUrl(url) {
                if (url.indexOf('https') == -1) {
                    url = url.replaceAll('http', 'https');
                }
                return url;
            },

            formatDate(dt) {
                return moment(dt).format('DD/MM/YYYY HH:mm');
            },

            statusPenalidade(dtFim) {
                var result = moment(dtFim, 'DD/MM/YYYY') > moment();
                return result;
            },

            async imprimir() {
                var options = {
                    name: '_blank',
                    specs: [
                        'fullscreen=yes',
                        'titlebar=yes',
                        'scrollbars=yes'
                    ],
                    styles: [
                        'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css',
                        './css/bancopenalidades.css'
                    ],
                    timeout: 1000, // default timeout before the print window appears
                    autoClose: true, // if false, the window will not close after printing
                    windowTitle: window.document.title, // override the window title
                };
                await this.$htmlToPaper('imprimir', options);
            },

        },
        mounted: function () {
            this.load();
        },
        watch: {
            penalidade() {
                this.load();
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" id="">
    .box-branco {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        background-color: #FFF;
        border-radius: 10px;
        padding: 20px;
    }

    .dados-penalidade {
        display: flex;
        background: #FFFFFF;
        border: 1px solid #F52628;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 20px;

        .titulo-penalidade {
            padding-left: 10px;
            border-left: 10px solid #F52628;
        }

        .box-detalhes {
            background: #FBFBFB;
            border: 1px solid #F52628;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            padding: 20px;

            .list-group {
                max-height: 300px;
                overflow-y: auto;
            }
        }

        .penalidadeBtn {
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: 0.25rem;
            float: left;
            margin-right: 10px;
        }

        .penalidadeAtiva {
            border: 1px solid #de0003;
            color: #de0003;
        }

        .penalidadeInativa {
            border: 1px solid #28a745;
            color: #28a745;
        }
    }

    @media print {
        * {
            background-color: red;
        }

        .noPrint {
            display: none;
        }
    }
</style>

