<template>
    <div class="MeusDados" >
        <b-modal ref="modal-meus-dados" id="modalMeusDados" size="xl" :hide-footer="true" :hide-header="true">
            <div class="w-100 text-end txt-size-20 txt-blue-2">
                <span @click="close"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <form class="px-3 pb-3" id="formMeusDados">

                <div class="row">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-size-25 txt-w-bold mb-2">
                            Alterar Dados
                        </span>
                        <p class="txt-p txt-size-16 txt-w-5 txt-grey-2">
                            Preencha os campos abaixo, e realize a alteração dos seus dados.
                        </p>
                    </div>
                    
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-25">
                            Nome
                        </span>
                        <div class="">
                            <input type="text" class="form-control" id="Usuarios_nmUsuario" name="usuario.nmUsuario" v-model="usuario.nmUsuario" maxlength="100" required>
                        </div>
                    </div>
                    
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-25">
                            E-mail
                        </span>
                        <div class="">
                            <input type="email" class="form-control" id="Usuarios_dsEmail" name="usuario.dsEmail" v-model="usuario.dsEmail" maxlength="100" required>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-25">
                            Telefone
                        </span>
                        <div class="">
                            <!--<input type="text" class="form-control" id="Usuarios_dsTelefone" name="usuario.dsTelefone" v-model="usuario.dsTelefone" :maxlength="100" required>-->
                            <vue-mask class="form-control"
                                      id="Usuarios_dsTelefone"
                                      name="usuario.dsTelefone"
                                      v-model="usuario.dsTelefone"
                                      mask="(00) 00000-0000"
                                      :raw="false"
                                      required>
                            </vue-mask>
                        </div>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-25">
                            Celular
                        </span>
                        <div class="">
                            <!--<input type="text" class="form-control" id="Usuarios_dsCelular" name="usuario.dsCelular" v-model="usuario.dsCelular" :maxlength="100" required>-->
                            <vue-mask class="form-control"
                                      id="Usuarios_dsCelular"
                                      name="usuario.dsCelular"
                                      v-model="usuario.dsCelular"
                                      mask="(00) 00000-0000"
                                      :raw="false"
                                      required>
                            </vue-mask>
                        </div>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-25">
                            Data de Nascimento
                        </span>
                        <div class="">
                            <vue-mask class="form-control"
                                      id="dtNascimento"
                                      name="usuario.dtNascimento"
                                      v-model="usuario.dtNascimento"
                                      mask="00/00/0000"
                                      :raw="false"
                                      :options="options" required>
                            </vue-mask>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="w-100">
                        <button class="btn btn-blue w-100" type="button" :disabled="!liberaSalvar" @click="enviar">Salvar</button>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
    import vueMask from 'vue-jquery-mask'
    import { urlApi, avisoSucesso, avisoErro } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'

    export default {
        name: 'MeusDados',
        components: {
            vueMask,
        },
        data: function () {
            return {

                limiteCaracteres: 500,

                usuario: {
                    nmUsuario: null,
                    dsEmail: null,
                    dsTelefone: null,
                    dsCelular: null,
                    dtNascimento: null,
                },

                options: {
                    placeholder: '__/__/____',
                },
            }
        },
        props: {
            showModal: Boolean,
        },
        computed: {
            liberaSalvar() {
                if (this.usuario.nmUsuario == '' || this.usuario.nmUsuario == null) {
                    return false;
                }

                if (this.usuario.dsEmail == '' || this.usuario.dsEmail == null) {
                    return false;
                }

                if (this.usuario.dsTelefone == '' || this.usuario.dsTelefone == null) {
                    return false;
                }

                if (this.usuario.dsCelular == '' || this.usuario.dsCelular == null) {
                    return false;
                }

                if (this.usuario.dtNascimento == '' || this.usuario.dtNascimento == null) {
                    return false;
                }

                return true;
            },

            user() {
                return this.$store.state.user
            },

        },        
        methods: {
            enviar() {
                if (this.checkForm()) {
                    let $this = this;
                    axios.post(`${urlApi}/Auth/AlteraDados`, $this.usuario)
                        .then(res => {
                            if (res.data.success) {
                                // aviso ok
                                avisoSucesso("Dados alterados com sucesso!");
                                $this.$refs['modal-meus-dados'].hide();
                            } else {
                                avisoErro("Não foi possível alterar seus dados.");
                            }
                        })
                        .catch(res => {
                            if (res.response.data != undefined) {
                                avisoErro(res.response.data);
                            } else {
                                avisoErro(res.response.statusText);
                            }
                        });
                }
                return false;
            },

            checkForm() {
                var form = document.getElementById('formMeusDados')
                if (!form.checkValidity()) {
                    var tmpSubmit = document.createElement('button')
                    form.appendChild(tmpSubmit)
                    tmpSubmit.click()
                    form.removeChild(tmpSubmit)

                    return false;
                }

                if (this.limiteCaracteres > 0) {
                    if (this.usuario.nmUsuario.length > 100) {
                        avisoErro("O limite de caracteres do \"Nome\" foi ultrapassado")
                        return false;
                    }

                    if (this.usuario.dsEmail.length > 100) {
                        avisoErro("O limite de caracteres \"E-mail\" foi ultrapassado")
                        return false;
                    }

                    if (this.usuario.dsTelefone.length > 100) {
                        avisoErro("O limite de caracteres \"Telefone\" foi ultrapassado")
                        return false;
                    }

                    if (this.usuario.dsCelular.length > 100) {
                        avisoErro("O limite de caracteres \"Celular\" foi ultrapassado")
                        return false;
                    }
                }

                return true;
            },

            load() {
                if (this.user) {
                    var user = this.user.usuario;
                    this.usuario = {
                        nmUsuario: user.nmUsuario,
                        dsEmail: user.dsEmail,
                        dsTelefone: user.dsTelefone,
                        dsCelular: user.dsCelular,
                        dtNascimento: moment(user.dtNascimento).format('DD/MM/YYYY'),
                    };
                }                
            },

            close() {
                this.$refs['modal-meus-dados'].hide();
            }

        },
        created: function () {
            this.load();
        },
        watch: {
            showModal: function () {
                this.$refs['modal-meus-dados'].show();
                this.load();
            },
            
        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* Meus Dados */
   .form-control {
        border: 1px solid #474747;
    }
</style>

