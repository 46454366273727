<template>
    <div id="app">
        <template v-if="$route.meta.keepAlive">
            <keep-alive max="5">
                <router-view />
            </keep-alive>
        </template>
        <template v-else>
            <router-view />
        </template>
        
    </div>
</template>

<script>

    export default {
        name: 'app',
        components: {
        }
    };
</script>

<style lang="scss">
</style>

