<template>
    <div class="ModalCompromisso">
        <b-modal ref="modal-compromisso" id="ModalCompromisso" size="lg" :hide-footer="true" :hide-header="true">
            <div class="modal-titulo txt-p txt-w-7 txt-size-20 txt-grey-2 m-2">
                <p class="txt-w-20 mb-0 txt-agenda">{{titulo}}</p>
                <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <div class="modal-conteudo px-2">
                
                    <template v-if="idCompromisso != null">
                        <ul class="nav nav-tabs" id="myTab" role="tablist" v-if="compromisso != null">
                            <!-- ABA INICIO -->
                            <li class="nav-item" role="presentation">
                                <button :class="'nav-link '+(tabAtiva=='inicio'?'active':'')" @click="tabAtiva='inicio'" id="inicio-tab" data-bs-toggle="tab" data-bs-target="#inicio" type="button" role="tab" aria-controls="home" aria-selected="true">
                                    Início
                                </button>
                            </li>
                            <template v-if="compromisso.idContagemPrazoData == 0">
                                <!-- ABA AVISOS -->
                                <li class="nav-item" role="presentation">
                                    <button :class="'nav-link '+(tabAtiva=='avisos'?'active':'')" @click="tabAtiva='avisos'" id="avisos-tab" data-bs-toggle="tab" data-bs-target="#avisos" type="button" role="tab" aria-controls="avisos" aria-selected="false">
                                        Avisos
                                    </button>
                                </li>
                                <!-- ABA PARTICIPANTES -->
                                <li class="nav-item" role="presentation">
                                    <button :class="'nav-link '+(tabAtiva=='participantes'?'active':'')" @click="tabAtiva='participantes'" id="avisos-tab" data-bs-toggle="tab" data-bs-target="#participantes" type="button" role="tab" aria-controls="participantes" aria-selected="false">
                                        Participantes
                                    </button>
                                </li>
                                <template v-if="compromisso.tpCompromisso == 'Reuniao'">
                                    <!-- ABA PAUTAS -->
                                    <li class="nav-item" role="presentation">
                                        <button :class="'nav-link '+(tabAtiva=='pautas'?'active':'')" @click="tabAtiva='pautas'" id="avisos-tab" data-bs-toggle="tab" data-bs-target="#pautas" type="button" role="tab" aria-controls="pautas" aria-selected="false">
                                            Pautas
                                        </button>
                                    </li>
                                    <!-- ABA RESULTADOS -->
                                    <li class="nav-item" role="presentation">
                                        <button :class="'nav-link '+(tabAtiva=='resultados'?'active':'')" @click="tabAtiva='resultados'" id="avisos-tab" data-bs-toggle="tab" data-bs-target="#resultados" type="button" role="tab" aria-controls="resultados" aria-selected="false">
                                            Resultados
                                        </button>
                                    </li>
                                </template>
                            </template>
                            <template v-else-if="compromisso.idContagemPrazoData != 0">
                                <!-- ABA AVISOS -->
                                <li class="nav-item" role="presentation">
                                    <button :class="'nav-link '+(tabAtiva=='avisos'?'active':'')" @click="tabAtiva='avisos'" id="avisos-tab" data-bs-toggle="tab" data-bs-target="#avisos" type="button" role="tab" aria-controls="avisos" aria-selected="false">
                                        Avisos
                                    </button>
                                </li>
                            </template>

                        </ul>
                    </template>
                    
                    <div class="tab-content mt-2" id="myTabContent">
                        <!-- ABA INICIO -->
                        <div :class="'tab-pane fade '+(tabAtiva=='inicio'?'show active':'')" id="inicio" role="tabpanel" aria-labelledby="inicio-tab" v-if="compromisso">
                            <form class="">
                                <div class="mb-3">
                                    <label for="NomeAnotacao" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-6 mb-1">
                                        1. Tipo de compromisso:
                                    </label>
                                    <div class="w-100 check-radio">
                                        <template v-if="idCompromisso == null">
                                            <div :class="'form-check mb-1 c-pointer ' + (compromisso.tpCompromisso == 'Reuniao'?'active':'')" @click="compromisso.tpCompromisso = 'Reuniao'">
                                                <input class="form-check-input" type="radio" name="tpCompromisso" id="tpCompromissoGeral" value="Reuniao" v-model="compromisso.tpCompromisso">
                                                <label class="form-check-label" for="tpCompromissoGeral">
                                                    Compromisso geral
                                                </label>
                                            </div>
                                            <div :class="'form-check mb-1 c-pointer ' + (compromisso.tpCompromisso == 'Compromisso'?'active':'')" @click="compromisso.tpCompromisso = 'Compromisso'">
                                                <input class="form-check-input" type="radio" name="tpCompromisso" id="tpCompromissoReuniao" value="Compromisso" v-model="compromisso.tpCompromisso">
                                                <label class="form-check-label" for="tpCompromissoReuniao">
                                                    Reunião
                                                </label>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div :class="'form-check mb-1 c-pointer ' + (compromisso.tpCompromisso == 'Compromisso'?'active':'')">
                                                <input class="form-check-input" type="radio" name="tpCompromisso" id="tpCompromissoGeral" disabled="disabled" value="Compromisso" v-model="compromisso.tpCompromisso">
                                                <label class="form-check-label" for="tpCompromissoGeral">
                                                    Compromisso geral
                                                </label>
                                            </div>
                                            <div :class="'form-check mb-1 c-pointer ' + (compromisso.tpCompromisso == 'Reuniao'?'active':'')">
                                                <input class="form-check-input" type="radio" name="tpCompromisso" id="tpCompromissoReuniao" disabled="disabled" value="Reuniao" v-model="compromisso.tpCompromisso">
                                                <label class="form-check-label" for="tpCompromissoReuniao">
                                                    Reunião
                                                </label>
                                            </div>
                                            <div :class="'form-check mb-1 c-pointer ' + (compromisso.tpCompromisso == 'Contador de Prazo'?'active':'')">
                                                <input class="form-check-input" type="radio" name="tpCompromisso" id="tpCompromissoReuniao" disabled="disabled" value="Contador de Prazo" v-model="compromisso.tpCompromisso">
                                                <label class="form-check-label" for="tpCompromissoReuniao">
                                                    Contador de Prazos
                                                </label>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                        2. Adicione um título, resuma de forma informativa e rápida o intúito desse compromisso:
                                    </label>
                                    <div class="row">
                                        <div class="col-12">
                                            <input class="form-control" type="text" id="nmTitulo" aria-describedby="nmTitulo" placeholder="Escreva o título" maxlength="100" v-model="compromisso.nmTitulo">
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                        3. Anotação:
                                    </label>
                                    <div class="row">
                                        <div class="col-12">
                                            <textarea class="form-control" rows="5" maxlength="500" v-model="compromisso.dsCompromisso" placeholder="Escreva sua anotação"></textarea>
                                        </div>

                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                        4. Data e Hora:
                                    </label>
                                    <div class="row">
                                        <div class="col-12 mb-1">
                                            <label for="dtEtapa" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-5 mb-1 ps-2">Data</label>
                                            <date-picker class="w-100" v-model="compromisso.dtCompromisso" format="DD/MM/YYYY" placeholder="Data"></date-picker>
                                        </div>
                                        <div class="col-6">
                                            <label for="hrEtapa" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-5 mb-1 ps-2">Hora de <span class="txt-w-7">INÍCIO</span></label>
                                            <date-picker class="w-100" v-model="compromisso.hrCompromisso" format="HH:mm" placeholder="Hora de INÍCIO" type="time"></date-picker>
                                        </div>
                                        <div class="col-6">
                                            <label for="hrEtapa" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-5 mb-1 ps-2">Hora de <span class="txt-w-7">TÉRMINO</span></label>
                                            <date-picker class="w-100" v-model="compromisso.hrDuracao" format="HH:mm" placeholder="Hora de TÉRMINO" type="time"></date-picker>
                                        </div>
                                    </div>
                                </div>
                                <div class="">
                                    <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                        5. Local:
                                    </label>
                                    <div class="row">
                                        <div class="col-12">
                                            <input class="form-control" type="text" id="dsLocal" aria-describedby="dsLocal" placeholder="Qual o local do evento ?" maxlength="100" v-model="compromisso.dsLocal">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- ABA AVISOS -->
                        <div :class="'tab-pane fade '+(tabAtiva=='avisos'?'show active':'')" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div class="">
                                <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                    Crie um novo aviso prenchendo os campos abaixo:
                                </label>
                                <div class="row">
                                    <div class="col-6 col-lg-5">
                                        <label for="dtEtapa" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-5 mb-1 ps-2">Data</label>
                                        <date-picker class="w-100" v-model="aviso.dtAviso" format="DD/MM/YYYY" placeholder="Data"></date-picker>
                                    </div>
                                    <div class="col-6 col-lg-5">
                                        <label for="hrEtapa" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-5 mb-1 ps-2">Hora</label>
                                        <date-picker class="w-100" v-model="aviso.hrAviso" format="HH:mm" placeholder="Hora" type="time"></date-picker>
                                    </div>
                                    <div class="col-12 col-lg-2 d-flex mt-2 mt-lg-0">
                                        <button class="btn btn-agenda w-100" type="button" @click="salvarAviso" :disabled="validaAviso">
                                            Adicionar
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div class="my-3" v-if="lstAvisos.length > 0">
                                <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                    Veja todos seus avisos:
                                </label>
                                <div class="lstAvisos mt-2">
                                    <div class="row">
                                        <div class="col-12 col-md-4 mt-2" v-for="(item,index) in lstAvisos" :key="index">
                                            <div class="item-aviso box-branco-2">
                                                <div class="row">
                                                    <div class="col-8">
                                                        <p class="txt-p txt-size-14 txt-grey-2 txt-w-7 mb-2">
                                                            Data: <span class="txt-w-4">{{getDataAviso(item.dtAviso)}}</span>
                                                        </p>
                                                        <p class="txt-p txt-size-14 txt-grey-2 txt-w-7 mb-0">
                                                            Hora: <span class="txt-w-4">{{getHoraAviso(item.dtAviso)}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-4 text-end">
                                                        <button class="btn btn-red" @click="excluiAviso(item.idCompromissoAviso)">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                        <!--<button class="btn btn-blue" @click="editAviso(item.idCompromissoAviso)">
                                        <i class="fa fa-pencil"></i>
                                    </button>-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ABA PARTICIPANTES -->
                        <div :class="'tab-pane fade '+(tabAtiva=='participantes'?'show active':'')" id="participantes" role="tabpanel" aria-labelledby="participantes-tab">
                            <div class="">
                                <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                    Adicione participantes a esse compromisso inserindo o nome e e-mail a pessoa nos campos abaixo. A pessoa receberá um aviso informando participação por e-mail.
                                </label>
                                <div class="row">
                                    <div class="col-6 col-lg-5">
                                        <label for="nmPessoa" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-5 mb-1 ps-2">Nome</label>
                                        <input class="form-control" type="text" id="nmTitulo" aria-describedby="nmPessoa" placeholder="Escreva o nome" maxlength="50" v-model="participante.nmPessoa">
                                    </div>
                                    <div class="col-6 col-lg-5">
                                        <label for="hrEtapa" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-5 mb-1 ps-2">E-mail</label>
                                        <input class="form-control" type="email" id="dsEmail" aria-describedby="dsEmail" placeholder="Escreva o e-mail" maxlength="100" v-model="participante.dsEmail">
                                    </div>
                                    <div class="col-12 col-lg-2 d-flex mt-2 mt-lg-0">
                                        <button class="btn btn-agenda w-100" type="button" @click="salvarParticipante" :disabled="validaParticipante">
                                            Adicionar
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div class="my-3" v-if="lstParticipantes.length > 0">
                                <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                    Veja todos seus participantes:
                                </label>
                                <div class="lstAvisos mt-2">
                                    <div class="row">
                                        <div class="col-12 mt-2" v-for="(item,index) in lstParticipantes" :key="index">
                                            <div class="item-aviso box-branco-2" v-if="item.agendaPessoa">
                                                <div class="row">
                                                    <div class="col-8">
                                                        <p class="txt-p txt-size-14 txt-grey-2 txt-w-7 mb-2">
                                                            Nome: <span class="txt-w-4">{{item.agendaPessoa.nmPessoa}}</span>
                                                        </p>
                                                        <p class="txt-p txt-size-14 txt-grey-2 txt-w-7 mb-0">
                                                            E-mail: <span class="txt-w-4">{{item.agendaPessoa.dsEmail}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-4 text-end">
                                                        <button class="btn btn-red" @click="excluiParticipante(item.agendaCompromissoParticipante.idCompromisso,item.agendaCompromissoParticipante.idPessoa)">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                        <!--<button class="btn btn-blue" @click="editParticipante(item.idPessoa)">
                                                            <i class="fa fa-pencil"></i>
                                                        </button>-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ABA PAUTAS -->
                        <div :class="'tab-pane fade '+(tabAtiva=='pautas'?'show active':'')" id="pautas" role="tabpanel" aria-labelledby="pautas-tab">
                            <div class="">
                                <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                    Crie uma nova pauta utilizando o campo abaixo.
                                </label>
                                <div class="row">
                                    <div class="col-8 col-md-10">
                                        <label for="nmPessoa" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-5 mb-1 ps-2">Nome</label>
                                        <textarea class="form-control form-compromisso" v-model="pauta.dsPauta" cols="20" data-val="true" data-val-required="Campo obrigatório" id="dsPauta" name="dsPauta" rows="5"></textarea>
                                    </div>                                    
                                    <div class="col-4 col-md-2 d-flex">
                                        <button class="btn btn-agenda w-100" type="button" @click="salvarPauta" :disabled="validaPauta">
                                            Adicionar
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div class="my-3" v-if="lstPautas.length > 0">
                                <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                    Veja todos as pautas cadastradas:
                                </label>
                                <div class="lstAvisos mt-2">
                                    <div class="row">
                                        <div class="col-12 mt-2" v-for="(item,index) in lstPautas" :key="index">
                                            <div class="item-aviso box-branco-2">
                                                <div class="row">
                                                    <div class="col-8">
                                                        <p class="txt-p txt-size-14 txt-grey-2 txt-w-7 mb-2" style="word-break:break-all">
                                                            <span class="txt-w-4">{{item.dsPauta}}</span>
                                                        </p>
                                                    </div>
                                                    <div class="col-4 text-end">
                                                        <button class="btn btn-red" @click="excluiPauta(item.idCompromissoPauta)">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                        <!--<button class="btn btn-blue" @click="editParticipante(item.idPessoa)">
                                    <i class="fa fa-pencil"></i>
                                </button>-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- ABA RESULTADOS -->
                        <div :class="'tab-pane fade '+(tabAtiva=='resultados'?'show active':'')" id="resultados" role="tabpanel" aria-labelledby="resultados-tab">
                            <div class="">
                                <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                    Marque os participantes presentes.
                                </label>
                                <div class="row" v-if="lstParticipantes.length > 0">
                                    <div class="col-12 mb-2" v-for="(item,index) in lstParticipantes" :key="index">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="item.agendaCompromissoParticipante.flPresenteCheck">
                                            <label class="form-check-label" for="flexCheckChecked">
                                                {{item.agendaPessoa.nmPessoa}} ({{item.agendaPessoa.dsEmail}})
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="my-3" v-if="lstPautas.length > 0">
                                <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                    Pautas
                                </label>
                                <div class="lstAvisos">
                                    <div class="row">
                                        <div class="col-12 mt-2" v-for="(item,index) in lstPautas" :key="index">
                                            <div class="item-aviso box-branco-2">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <p class="txt-p txt-size-14 txt-grey-2 txt-w-7 mb-2">
                                                            Tema: <span class="txt-w-4">{{item.dsPauta}}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <label for="dsResultado" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-5 mb-1 ps-2">Resultado</label>
                                                        <textarea class="form-control" rows="3" maxlength="500" v-model="item.dsResultado" placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="row">
                                    <div class="col-12 text-end">
                                        <button class="btn btn-contador_prazos mr-2" type="button" @click="imprimir">
                                            Imprimir
                                        </button>
                                        <button class="btn btn-agenda " type="button" @click="registrar">
                                            Registrar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
            </div>
            <div class="modal-footer mt-3" v-if="compromisso">
                <template v-if="compromisso.idContagemPrazoData == 0">
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-cancelar  me-3" type="button" @click="hideModalFn">
                            Cancelar
                        </button>
                        <button class="btn btn-excluir  me-3" type="button" @click="excluirCompromisso" v-if="compromisso.idCompromisso && compromisso.idCompromisso != 0" >
                            Excluir
                        </button>
                        <button class="btn btn-agenda px-4 py-2" type="button" @click="salvar" :disabled="validaSalvar">
                            Salvar compromisso
                        </button>
                    </div>
                </template>
            </div>
            <ModalImprimirCompromisso :idCompromisso="idCompromisso" :showModal="showModalImprimir"></ModalImprimirCompromisso>
        </b-modal>
    </div>
</template>

<script>
    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/pt-br';
    import Swal from 'sweetalert2'

    import ModalImprimirCompromisso from './ModalImprimirCompromisso'

    export default {
        name: 'ModalCompromisso',
        props: {
            idCompromisso: Number,
            showModal: Boolean,
            atualizar: Function,
        },
        components: { DatePicker, ModalImprimirCompromisso },
        data: function () {
            return {
                titulo: 'Adicionar compromisso',
                tabAtiva: 'inicio',

                compromisso: {
                    tpCompromisso: '',
                    nmTitulo: '',
                    dsCompromisso: '',
                    dtCompromisso: '',
                    hrCompromisso: '',
                    hrDuracao: '',
                    dsLocal: '',
                    idContagemPrazoData: 0,
                },

                aviso: {
                    dtAviso: '',
                    hrAviso: ''
                },

                participante: {
                    nmPessoa: '',
                    dsEmail: '',
                },

                pauta: {
                    dsPauta: '',
                },

                lstAvisos: [],
                lstParticipantes: [],
                lstPautas: [],

                showModalImprimir: false,
            }
        },
        computed: {
            validaSalvar() {
                let $this = this;

                if ($this.compromisso.tpCompromisso == '' || $this.compromisso.tpCompromisso == null) {
                    return true;
                }

                if ($this.compromisso.nmTitulo == '' || $this.compromisso.nmTitulo == null) {
                    return true;
                }

                if ($this.compromisso.dsCompromisso == '' || $this.compromisso.dsCompromisso == null) {
                    return true;
                }

                if ($this.compromisso.dtCompromisso == '' || $this.compromisso.dtCompromisso == null) {
                    return true;
                }

                if ($this.compromisso.hrCompromisso == '' || $this.compromisso.hrCompromisso == null) {
                    return true;
                }

                return false;
            },

            validaAviso() {
                let $this = this;

                if ($this.aviso.dtAviso == '' || $this.aviso.dtAviso == null) {
                    return true;
                }

                if ($this.aviso.hrAviso == '' || $this.aviso.hrAviso == null) {
                    return true;
                }

                return false;
            },

            validaParticipante() {
                let $this = this;

                if ($this.participante.nmPessoa == '' || $this.participante.nmPessoa == null) {
                    return true;
                }

                if ($this.participante.dsEmail == '' || $this.participante.dsEmail == null) {
                    return true;
                }

                return false;
            },

            validaPauta() {
                let $this = this;

                if ($this.pauta.dsPauta == '' || $this.pauta.dsPauta == null) {
                    return true;
                }

                return false;
            }
        },
        methods: {
            load() {
                this.compromisso = {
                    tpCompromisso: '',
                    nmTitulo: '',
                    dsCompromisso: '',
                    dtCompromisso: '',
                    hrCompromisso: '',
                    hrDuracao: '',
                    dsLocal: '',
                    idContagemPrazoData: 0,
                };

                this.tabAtiva = 'inicio';

                if (this.idCompromisso) {
                    this.titulo = 'Detalhes do compromisso';
                    this.carregaCompromisso();
                    this.carregaAvisos();
                    this.carregaParticipantes();
                    this.carregaPautas();
                }
                this.$refs['modal-compromisso'].show();
            },

            carregaCompromisso() {
                let $this = this;
                axios.get(`${urlApi}/Agenda/Get/${$this.idCompromisso}`)
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data != null) {
                                var compromisso = res.data.data;
                                var hrCompromisso = moment(compromisso.hrCompromisso, 'HH:mm:ss');
                                var hrDuracao = "";
                                if (compromisso.hrDuracao != null) {
                                    var duracao = compromisso.hrDuracao.split(':');

                                    hrDuracao = moment(hrCompromisso).add(duracao[0], 'h').add(duracao[1], 'm').add(duracao[2], 's');
                                    hrDuracao = new Date(hrDuracao.toISOString());
                                }
                                
                                $this.compromisso = {
                                    idCompromisso: compromisso.idCompromisso,
                                    tpCompromisso: compromisso.tpCompromisso,
                                    nmTitulo: compromisso.nmTitulo,
                                    dsCompromisso: compromisso.dsCompromisso,
                                    dtCompromisso: new Date(moment(compromisso.dtCompromisso).toISOString()),
                                    hrCompromisso: new Date(hrCompromisso.toISOString()),
                                    hrDuracao: hrDuracao,
                                    dsLocal: compromisso.dsLocal,
                                    idContagemPrazoData: compromisso.idContagemPrazoData,
                                };
                            }
                        } else {
                            avisoErro("Não foi possível carregar o compromisso");
                            $this.compromisso = null;
                            $this.hideModalFn();
                        }
                    })
                    .catch(ex => {
                        console.log(ex);
                        avisoErro("Não foi possível carregar o compromisso");
                        $this.compromisso = null;
                        $this.hideModalFn();
                    });
            },

            carregaAvisos() {
                let $this = this;
                axios.get(`${urlApi}/Agenda/GetAvisos/${$this.idCompromisso}`)
                    .then(res => {
                        $this.lstAvisos = [];
                        if (res.data.success) {
                            if (res.data.data != null) {
                                $this.lstAvisos = res.data.data;
                            }
                        } else {
                            avisoErro("Não foi possível carregar os avisos");
                            $this.lstAvisos = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os avisos");
                        $this.lstAvisos = [];
                    });
            },

            carregaParticipantes() {
                let $this = this;
                axios.get(`${urlApi}/Agenda/GetParticipantes/${$this.idCompromisso}`)
                    .then(res => {
                        $this.lstParticipantes = [];
                        if (res.data.success) {
                            if (res.data.data != null) {
                                for (let i = 0; i < res.data.data.length; i++) {
                                    var item = res.data.data[i];
                                    item.agendaCompromissoParticipante.flPresenteCheck = (item.agendaCompromissoParticipante.flPresente == 'Sim' ? true : false);
                                    $this.lstParticipantes.push(item);
                                }
                            }
                        } else {
                            avisoErro("Não foi possível carregar os participantes");
                            $this.lstParticipantes = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os participantes");
                        $this.lstParticipantes = [];
                    });
            },

            carregaPautas() {
                let $this = this;
                axios.get(`${urlApi}/Agenda/GetPauta/${$this.idCompromisso}`)
                    .then(res => {
                        $this.lstAvisos = [];
                        if (res.data.success) {
                            if (res.data.data != null) {
                                $this.lstPautas = res.data.data;
                            }
                        } else {
                            avisoErro("Não foi possível carregar as pautas");
                            $this.lstAvisos = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar as pautas");
                        $this.lstAvisos = [];
                    });
            },
            
            hideModalFn() {
                this.$refs['modal-compromisso'].hide();
            },

            salvar() {
                if (this.validaHoraFim()) {
                    let $this = this;
                    let compromisso = $this.compromisso;
                    compromisso.dtCompromisso = moment(compromisso.dtCompromisso).format('YYYY-MM-DD');
                   
                    compromisso.hrDuracao = moment.utc(moment(compromisso.hrDuracao).diff(compromisso.hrCompromisso)).format("HH:mm:ss");

                    compromisso.hrCompromisso = moment(compromisso.hrCompromisso).format('HH:mm:ss');

                    axios.post(`${urlApi}/Agenda/Post`, compromisso, { showLoading: true })
                        .then(res => {
                            if (res.data.success) {
                                $this.atualizar();
                                $this.hideModalFn();
                                avisoSucesso("Compromisso salvo com sucesso.");
                            } else {
                                avisoErro("Não foi possível salvar o compromisso");
                            }
                        })
                        .catch(res => {
                            avisoErro(res.response.data.errors[0]);
                        });
                }
            },

            salvarAviso() {
                let $this = this;
                let aviso = $this.aviso;
                aviso.idCompromisso = $this.idCompromisso;
                aviso.dtAviso = moment(aviso.dtAviso).format('YYYY-MM-DD') + ' ' + moment(aviso.hrAviso).format('HH:mm:ss');
                
                axios.post(`${urlApi}/Agenda/PostAviso`, aviso, { showLoading: true })
                    .then(res => {
                        if (res.data.success) {
                            $this.aviso = {
                                dtAviso: '',
                                hrAviso: ''
                            };
                            $this.carregaAvisos();
                            avisoSucesso("Aviso salvo com sucesso.");
                        } else {
                            avisoErro("Não foi possível salvar o aviso");
                        }
                    })
                    .catch(res => {
                        avisoErro(res.response.data.errors[0]);
                    });
            },

            salvarParticipante() {
                let $this = this;
                let participante = $this.participante;
                participante.idCompromisso = $this.idCompromisso;
                axios.post(`${urlApi}/Agenda/PostParticipante`, participante, { showLoading: true })
                    .then(res => {
                        if (res.data.success) {
                            $this.participante = {
                                nmPessoa: '',
                                dsEmail: '',
                            };
                            $this.carregaParticipantes();
                            avisoSucesso("Participante salvo com sucesso.");
                        } else {
                            avisoErro("Não foi possível salvar o participante");
                        }
                    })
                    .catch(res => {
                        avisoErro(res.response.data.errors[0]);
                    });
            },
            
            salvarPauta() {
                let $this = this;
                let pauta = $this.pauta;
                pauta.idCompromisso = $this.idCompromisso;
                axios.post(`${urlApi}/Agenda/PostPauta`, pauta, { showLoading: true })
                    .then(res => {
                        if (res.data.success) {
                            $this.pauta = {
                                dsPauta: '',
                            };
                            $this.carregaPautas();
                            avisoSucesso("Pauta salva com sucesso.");
                        } else {
                            avisoErro("Não foi possível salvar a pauta");
                        }
                    })
                    .catch(res => {
                        avisoErro(res.response.data.errors[0]);
                    });
            },

            excluirCompromisso() {
                let $this = this;
                Swal.fire({
                    title: 'Tem certeza que deseja excluir este compromisso"?',
                    text: 'Esta operação não poderá ser desfeita.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    confirmButtonColor: '#83A431',
                }).then((result) => {
                    if (result.isConfirmed) {
                        $this.confirmaExcluirCompromisso();
                    }
                })
            },

            confirmaExcluirCompromisso() {
                let $this = this;
                axios.delete(`${urlApi}/Agenda/Delete`, { params: { idCompromisso: $this.idCompromisso } })
                    .then(res => {
                        if (res.data.success) {
                            $this.atualizar();
                            $this.hideModalFn();
                            avisoSucesso("Compromisso excluido com sucesso");

                        } else {
                            avisoErro("Não foi possível excluir o compromisso");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível excluir o compromisso");
                    });
            },

            excluiAviso(idCompromissoAviso) {
                let $this = this;
                Swal.fire({
                    title: 'Tem certeza que deseja excluir este aviso"?',
                    text: 'Esta operação não poderá ser desfeita.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    confirmButtonColor: '#83A431',
                }).then((result) => {
                    if (result.isConfirmed) {
                        $this.confirmaExcluirAviso(idCompromissoAviso);
                    }
                })
            },

            confirmaExcluirAviso(idCompromissoAviso) {
                let $this = this;
                axios.delete(`${urlApi}/Agenda/DeleteAviso`, { params: { idCompromissoAviso: idCompromissoAviso } })
                    .then(res => {
                        if (res.data.success) {
                            $this.carregaAvisos();
                            avisoSucesso("Aviso excluido com sucesso");
                        } else {
                            avisoErro("Não foi possível excluir o aviso");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível excluir o aviso");
                    });
            },

            excluiParticipante(idCompromisso, idPessoa) {
                let $this = this;
                Swal.fire({
                    title: 'Tem certeza que deseja excluir este participante"?',
                    text: 'Esta operação não poderá ser desfeita.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    confirmButtonColor: '#83A431',
                }).then((result) => {
                    if (result.isConfirmed) {
                        $this.confirmaExcluiParticipante(idCompromisso, idPessoa);
                    }
                })
            },

            confirmaExcluiParticipante(idCompromisso, idPessoa) {
                let $this = this;
                axios.delete(`${urlApi}/Agenda/DeleteParticipante`, { params: { idCompromisso: idCompromisso, idPessoa: idPessoa } })
                    .then(res => {
                        if (res.data.success) {
                            $this.carregaParticipantes();
                            avisoSucesso("Participante excluido com sucesso");
                        } else {
                            avisoErro("Não foi possível excluir o participante");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível excluir o participante");
                    });
            },

            excluiPauta(idCompromissoPauta) {
                let $this = this;
                Swal.fire({
                    title: 'Tem certeza que deseja excluir esta pauta"?',
                    text: 'Esta operação não poderá ser desfeita.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    confirmButtonColor: '#83A431',
                }).then((result) => {
                    if (result.isConfirmed) {
                        $this.confirmaExcluirPauta(idCompromissoPauta);
                    }
                })
            },

            confirmaExcluirPauta(idCompromissoPauta) {
                let $this = this;
                axios.delete(`${urlApi}/Agenda/DeletePauta`, { params: { idCompromissoPauta: idCompromissoPauta } })
                    .then(res => {
                        if (res.data.success) {
                            $this.carregaPautas();
                            avisoSucesso("Pauta excluida com sucesso");
                        } else {
                            avisoErro("Não foi possível excluir a pauta");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível excluir a pauta");
                    });
            },

            imprimir() {
                this.showModalImprimir = !this.showModalImprimir;
            },

            registrar() {
                let $this = this;
                let lstRegistrar = {
                    lstParticipantes: [],
                    lstPautas: [],
                };

                for (let i = 0; i < $this.lstParticipantes.length; i++) {
                    let item = $this.lstParticipantes[i];
                    lstRegistrar.lstParticipantes.push({
                        idCompromisso: item.agendaCompromissoParticipante.idCompromisso,
                        idPessoa: item.agendaCompromissoParticipante.idPessoa,
                        flPresente: (item.agendaCompromissoParticipante.flPresenteCheck?'Sim':'Não'),
                    });
                }

                for (let i = 0; i < $this.lstPautas.length; i++) {
                    let item = $this.lstPautas[i];
                    lstRegistrar.lstPautas.push({
                        idCompromissoPauta: item.idCompromissoPauta,
                        dsResultado: item.dsResultado,
                    });
                }

                axios.post(`${urlApi}/Agenda/PostResultado`, lstRegistrar, { showLoading: true })
                    .then(res => {
                        if (res.data.success) {
                            $this.carregaParticipantes();
                            $this.carregaPautas();
                            avisoSucesso("Dados registrados com sucesso.");
                        } else {
                            avisoErro("Não foi possível salvar os dados");
                        }
                    })
                    .catch(res => {
                        avisoErro(res.response.data.errors[0]);
                    });
            },

            validaHoraFim() {
                if (this.compromisso.hrDuracao != '' || this.compromisso.hrDuracao != null) {
                    var hrCompromisso = moment(this.compromisso.hrCompromisso);
                    var hrDuracao = moment(this.compromisso.hrDuracao);

                    if (hrCompromisso > hrDuracao) {
                        avisoErro('A \'Hora de INÍCIO\' não pode ser maior que a \'Hora de TÉRMINO\'');
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            },

            getDataAviso(dt) {
                if (dt != null) {
                    return moment(dt).format('DD/MM/YYYY');
                } else {
                    return "";
                }
                
            },

            getHoraAviso(dt) {
                if (dt != null) {
                    return moment(dt).format('HH:mm');
                } else {
                    return "";
                }
            },
        },
        mounted: function () {
        },
        watch: {
            showModal: function () {
                this.load();
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #DADADA;
        display: flex;
        justify-content: space-between;
    }

    .modal-conteudo {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 71vh;
    }

    .check-radio {
        .form-check {
            background: #FFFFFF;
            border: 1px solid #A6A6A6;
            border-radius: 5px;
            padding: 10px 35px;
        }

        .form-check.active {
            border: 1px solid #009FE6;
        }
    }

    .btn-red {
        background: #D78E8E;
        border-radius: 3px;
        color: #fff;
        padding: 5px 10px !important;
        cursor: pointer;
    }
    .btn-red:hover {
        background: #D78E8E;
        border-radius: 3px;
        color: #fff;
    }
</style>

