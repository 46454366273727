<template>
    <div class="viewPUBLI">
        <div class="modal-conteudo m-2">
            <div class="row" v-if="dados">
                <div class="col-md-4">

                    <img v-if="dados.perfil.urlImagem" :src="dados.perfil.urlImagem" alt="" class="img-responsive img-thumbnail" />

                    <p class="txt-p txt-w-7 txt-size-18 txt-blue-2 c-pointer mb-0 mt-2" >{{dados.perfil.nmExibicao}}</p>
                    <p class="txt-p txt-w-4 txt-size-14 txt-blue-2 c-pointer mb-0" >{{dados.perfil.dsCurriculo}}</p>
                </div>
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="bold" style="font-weight:bold;">Descrição:</label>
                            <p v-html="dados.dsPublicacao"></p>
                            <template v-if="dados.idTipoPublicacao == 1">
                                <p class="txt-p txt-grey-1">
                                    {{dados.dsTextoGeral}}
                                </p>
                            </template>
                            <template v-else-if="dados.idTipoPublicacao == 2">
                                <div class="mt-3">
                                    <label class="bold" style="font-weight:bold;">Artigo:</label>
                                </div>

                                <div class="conteudo-artigo mt-2" v-html="dados.dsArtigo"></div>
                            </template>
                            <template v-else-if="dados.idTipoPublicacao == 3">
                                <div class="d-flex justify-content-center">
                                    <img class="img-publicacao" :src="dados.urlImagemS3">
                                </div>
                            </template>
                            <template v-else-if="dados.idTipoPublicacao == 4">
                                <template v-if="dados.tpExibicaoVideo == 'Local'">
                                    <div class="d-flex justify-content-center">
                                        <video class="video-publicacao" width="100%" height="auto" allowfullscreen controls="" data-setup="{}" controlsList="nodownload">
                                            <source :src="dados.urlVideoS3" type="video/mp4">
                                            <source :src="dados.urlVideoS3" type="video/webm">
                                        </video>
                                    </div>
                                </template>
                                <template v-else>
                                    <iframe width="100%" height="500px" :src="urlVideoExterno()" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                </template>

                            </template>
                            <template v-else-if="dados.idTipoPublicacao == 5">
                                <iframe width="100%" height="500px" :src="getUrl()" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                            </template>
                            <template v-else-if="dados.idTipoPublicacao == 6">
                                <a class="" :href="dados.urlLink" target="_blank">{{dados.urlLink}}</a>
                                <a class="visualizacao-link" :href="dados.urlLink" v-if="link != null" target="_blank">
                                    <div class="visualizacao-link-img mt-2" v-if="link.img != undefined ">
                                        <img class="img-link" :src="link.img" />
                                    </div>
                                    <div class="visualizacao-link-descritivo" v-if="link.title != undefined">
                                        <span class="txt-p txt-grey-1">
                                            {{link.title}}
                                        </span>
                                    </div>
                                </a>
                            </template>




                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer pb-0 mt-4 d-flex justify-content-end">
            <AdicionarBiblioteca v-if="exibeBiblioteca" :id="dados.idPublicacao.toString()" :tipo="tpConteudo" :cdConteudo="cdConteudo"></AdicionarBiblioteca>

        </div>

        
    </div>
</template>

<script>
    import AdicionarBiblioteca from '../AdicionarBiblioteca'
    import { urlApi, urlLeitorPDF } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'viewPUBLI',
        components: { AdicionarBiblioteca },
        data: function () {
            return {
                exibeBiblioteca: true,
                tpConteudo: 'Pesquisa',
                cdConteudo: 'PUBLI',

                link: null,
            }
        },
        props: {
            dados: Object,
        },
        methods: {
            verificaExisteRepositorio(idConteudo, tpConteudo, cdConteudo) {
                let $this = this;
                axios.get(`${urlApi}/Repositorio`, { params: { idConteudo: idConteudo, tpConteudo: tpConteudo, cdConteudo: cdConteudo } })
                    .then(res => {
                        if (res.data.success) {
                            $this.exibeBiblioteca = res.data.data;
                        } else {
                            console.log('Não foi possível verificar o repositório')
                        }
                    })
                    .catch(function () {
                        console.log('Não foi possível verificar o repositório')
                    });
            },

            verificaLink() {
                if (this.dados.idTipoPublicacao == 6) {
                    if (this.dados.lstTags != null && this.dados.lstTags.length > 0) {
                        var img = this.dados.lstTags.find(x => x.nome == "image");
                        var title = this.dados.lstTags.find(x => x.nome == "title");

                        this.link = {};
                        if (img != undefined) {
                            this.link.img = img.valor;
                        }

                        if (title != undefined) {
                            this.link.title = title.valor;
                        }
                    }
                }
            },

            visualizarDocumento() {
                window.open(urlLeitorPDF + '/' + encodeURIComponent(this.dados.urlArquivoPdf), '_blank');
            },

            getUrl() {
                return urlLeitorPDF + '/' + encodeURIComponent(this.dados.urlArquivoPdf);
            },

            urlVideoExterno() {
                var url = '';
                var video = this.dados.urlVideo;
                if (video.includes('vimeo.com')) {
                    let src = video.replace("https://vimeo.com/", "");
                    url = 'https://player.vimeo.com/video/' + src
                } else if (video.includes('youtube')) {
                    let src = video.replace("https://www.youtube.com/watch?v=", "");
                    if (src.indexOf("&") > 0) {
                        src = src.substring(0, src.indexOf("&"));
                    }
                    url = 'https://www.youtube.com/embed/' + src
                } else if (video.includes('youtu.be')) {
                    let src = video.replace("https://youtu.be/", "");
                    if (src.indexOf("?") > 0) {
                        src = src.substring(0, src.indexOf("?"));
                    }
                    url = 'https://www.youtube.com/embed/' + src
                } else {
                    url = video;
                }

                return url;
            },
        },
        mounted: function () {
            this.verificaExisteRepositorio(this.dados.idPublicacao.toString(), this.tpConteudo, this.cdConteudo);
            this.verificaLink();
        },
        watch: {
            dados: {
                handler() {
                    this.verificaExisteRepositorio(this.dados.idPublicacao.toString(), this.tpConteudo, this.cdConteudo);
                },
                deep: true
            },
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    
   
</style>

