<template>
    <div class="GerenciarUsuarios">
        <Header></Header>

        <div class="conteudo-gerenciar-usuarios txt-p txt-blue-2">
            <div class="container pt-4 mb-5">
                <div class="row">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-w-bold txt-size-20 c-pointer" @click="voltar"><i class="fas fa-arrow-left"></i> Voltar</span>
                    </div>
                </div>

                <div class="row ">
                    <div class="col-6 mt-4">
                        <span class="txt-p txt-size-25 txt-w-bold txt-blue-2">
                            Gerenciar Usuários
                        </span>

                    </div>
                </div>
                <div class="row  mb-4" v-if="usuarios.length == 0">
                    <div class="col-12 text-center my-5">
                        <p class="txt-p txt-blue-2 txt-w-5 txt-size-18">Nenhum usuário encontrado!</p>
                    </div>
                </div>
                <div class="row mb-4 px-4" v-else>
                    <!-- TOTAIS -->
                    <div class="col-12 box-cinza my-3 p-2">
                        <div class="row align-items-center ">
                            <div class="col-12 col-md-9">
                                <div class="row align-items-start d-flex p-2">
                                    <div class="col-6">
                                        <template v-for="(item,index) in lstProdutos.slice(0,6)">
                                            <template v-for="(item2,index2) in dados.lstTotaisProdutos" >
                                                    <p v-if="item.idProduto == item2.idProduto" :key="index+'-'+index2">{{item.nmProduto}}: <span class="txt-w-bold txt-blue-2">{{item2.msg}}</span></p>
                                                    <!--div class="col text-center mb-1 p-1" v-if="item.idProduto == item2.idProduto" :key="index+'-'+index2">
                                                        <div class="border p-2">
                                                            <b>{{item.nmProduto}}:</b> <label :id="'lbl_resultado_'+item2.idProduto" class="bold"> {{item2.msg}}</label>
                                                        </div>
                                                    </div>-->
                                            </template>
                                        </template>
                                    </div>
                                    <div class="col-6">
                                        <template v-for="(item,index) in lstProdutos.slice(6,lstProdutos.length)">
                                            <template v-for="(item2,index2) in dados.lstTotaisProdutos">
                                                <p class="" v-if="item.idProduto == item2.idProduto" :key="index+'-'+index2">{{item.nmProduto}}: <span class="txt-w-bold txt-blue-2">{{item2.msg}}</span></p>
                                                <!--div class="col text-center mb-1 p-1" v-if="item.idProduto == item2.idProduto" :key="index+'-'+index2">
                                                    <div class="border p-2">
                                                        <b>{{item.nmProduto}}:</b> <label :id="'lbl_resultado_'+item2.idProduto" class="bold"> {{item2.msg}}</label>
                                                    </div>
                                                </div>-->
                                            </template>

                                        </template>
                                    </div>
                                   
                                </div>

                            </div>
                            <div class="col-12 col-md-3 text-center" style="font-size:1rem;">
                                <span>Total de Usuários</span>

                                <h3 class="bold" style="font-weight:bold;">{{dados.totalUsuarios}}</h3>
                                <button class="btn btn-blue w-100  txt-w-5" @click="addUsuarioCliente(1,null,contrato.idContrato,contrato.idCliente)" v-if="contrato!=null"><i class="fas fa-plus"></i> Novo Usuário</button>
                            </div>
                        </div>

                    </div>

                    <!-- GESTOR -->
                    <div class="col-12 box-cinza my-3 p-2" v-if="dados.dadosSupervisor && dados.dadosSupervisor.usuario">
                        <div class="d-flex">
                            <div class="col-8 align-items-center my-3 px-2">
                                <p class="bold mb-0" style="font-size:1rem; font-weight:bold;">{{dados.dadosSupervisor.usuario.nmUsuario}} <span class="label-gestor txt-p txt-size-14 txt-w-bold bg-blue-3 ">Gestor de Contrato</span></p>
                            </div>
                            <div class="col-4 d-flex justify-content-end">
                                <button type="button" class="btn btn-transparent" @click="addUsuarioCliente(2,dados.dadosSupervisor.usuario.dsLogin,contrato.idContrato,contrato.idCliente)"><i class="fas fa-pencil-alt"></i></button>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="col-12 text-center">
                                <p class="txt-p txt-size-12 txt-blue-2 mb-0">Este usuário possui todas as permissões</p>
                            </div>
                        </div>
                    </div>

                    <!-- USUARIOS -->
                    <div class="col-12 my-3 p-0">
                        <div class="row">
                            <div class="col-4 " v-for="(item, index) in usuarios" :key="index">
                                <div class="box-cinza p-3 mt-1 mb-4">
                                    <div class="row">
                                        <div class="col-9 text-left">
                                            <p class="bold mb-0" style="font-size:1rem; font-weight:bold;">{{item.nmUsuario}}</p>
                                        </div>
                                        <div class="col-3 justify-content-end">
                                            <button type="button" class="btn btn-transparent p-0 px-1 mb-2 me-2" style="display:inline-block; font-size:.8rem;" @click="addUsuarioCliente(2,item.dsLogin,item.idContrato,item.idCliente)"><i class="fas fa-pencil-alt"></i></button>
                                            <button type="button" class="btn btn-transparent p-0 px-1 mb-2" style="display:inline-block; font-size:.8rem;" @click="excluirUsuarioContrato(item)"><i class="fas fa-times-circle text-danger"></i></button>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row p-2">

                                        <div class="row">
                                            <div class="col-6 mb-1" v-for="(itemProduto,index2) in item.lstProdutoClienteUsuarioProduto" :key="index2">
                                                <div class="text-center" >
                                                    <div class="d-flex justify-content-start" style="">

                                                        <input  v-model="itemProduto.flPossuiProduto" type="checkbox" class="largerCheckbox" :id="index+'_'+ item.idCliente + '_' + item.idContrato + '_' + itemProduto.produto.idProduto" @click="changePermission(item,itemProduto)" />


                                                        <span class="bold text-left py-1 px-2" :style="'font-size:.7rem; font-weight:bold; color: #'+itemProduto.produto.nrCor+'; border-radius:10px;'">
                                                            {{itemProduto.produto.nmProduto.replace(" de ", " ").replace(" ", "&nbsp;")}}
                                                        </span>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <CadatroUsuarioCliente :showModal="showModalUsuario" :dados="usuarioCrud" v-on:reload="load"></CadatroUsuarioCliente>

        <Footer class="mt-5"></Footer>
    </div>
</template>

<script>
    import Header from '@/components/Header'
    import Footer from '@/components/Footer'
    import CadatroUsuarioCliente from '@/components/CadatroUsuarioCliente'

    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'
    import Swal from 'sweetalert2'
    import { avisoToast } from '../config/global'

    export default {
        name: 'GerenciarUsuarios',
        components: { Header, Footer, CadatroUsuarioCliente },
        data: function () {
            return {
                usuarios: [],

                contrato: null,
                lstProdutos: [],
                dados: null,

                showModalUsuario: false,
                usuarioCrud: { dsLogin: null },

            }
        },

        methods: {
            voltar() {
                this.$router.go(-1)
            },

            load() {
                let $this = this;
                axios.get(`${urlApi}/ClienteContratoProduto/GetDadosGerenciamento/`, { loading: false, params: { }})
                    .then(res => {
                        if (res.data.success) {
                            var result = res.data.data;
                            $this.contrato = result.contrato;
                            $this.lstProdutos = result.lstProdutos;
                            $this.dados = result.dados;
                                                        
                            $this.usuarios = result.lstUsuarioClienteLista;
                        } else {
                            avisoErro("Não foi possível carregar os usuários.");
                        }

                    })
                    .catch(res => {
                        if (res.response.status == 403) {
                            avisoErro("Você não tem acesso a esse produto.");
                            setTimeout(function () {
                                window.location.href = "/HomeLogado";
                            }, 2000);                            
                        } else {
                            if (res.data != undefined) {
                                avisoErro(res.response.data);
                            } else {
                                avisoErro(res.response.statusText);
                            }
                        }
                        
                        
                    });
            },


            changePermission(usuario, produto) {
                var $this = this;
                // verifica remover ou adicionar
                var url = produto.flPossuiProduto ? 'RemoverPermissao' : 'AdicionarPermissao'
                var params = {
                    idCliente: usuario.idCliente,
                    idContrato: usuario.idContrato,
                    dsLogin: usuario.dsLogin,
                    idProduto: produto.produto.idProduto,
                }

                axios.post(`${urlApi}/ClienteContratoProduto/${url}/`, params)
                    .then(res => {
                        if (res.data.success) {
                            $this.getTotalUsuariosProduto(usuario.idCliente, produto.produto.idProduto, usuario.idContrato);
                            avisoToast("Permissão editada com sucesso!")
                        } else {
                            avisoErro("Não foi possível editar a permissão.");
                            if (url == 'removerPermissao') {
                                produto.flPossuiProduto = true;
                            } else {
                                produto.flPossuiProduto = false;
                            }
                        }

                    })
                    .catch(res => {
                        if (res.response.data != undefined) {
                            avisoErro(res.response.data.errors[0]);
                            if (url == 'removerPermissao') {
                                produto.flPossuiProduto = true;
                            } else {
                                produto.flPossuiProduto = false;
                            } 
                        } else {
                            avisoErro("Erro ao gravar permissão");
                        }
                    });
            },

            getTotalUsuariosProduto(idCliente, idProduto, idContrato) {
                let $this = this;
                var params = {
                    idCliente: idCliente,
                    idContrato: idContrato,
                    idProduto: idProduto,
                }
                axios.get(`${urlApi}/ClienteContratoProduto/GetTotalUsuariosProduto/`, { params: params })
                    .then(res => {
                        if (res.data.success) {
                            var pos = $this.dados.lstTotaisProdutos.map(function (e) { return e.idProduto }).indexOf(idProduto);
                            $this.dados.lstTotaisProdutos[pos].msg = res.data.data;                            
                        } else {
                            avisoErro("Não foi possível carregar a quantidade de utilizações.");                            
                        }

                    })
                    .catch(res => {
                        if (res.data != undefined) {
                            avisoErro(res.response.data);
                        } else {
                            avisoErro(res.response.statusText);
                        }
                    });
            },


            addUsuarioCliente(cdOperacao, dsLogin, idContrato, idCliente) {
                switch (cdOperacao) {
                    // inserir
                    case 1:
                        this.usuarioCrud = { dsLogin: null, idContrato: idContrato, idCliente: idCliente };
                        this.showModalUsuario = !this.showModalUsuario;
                        break;
                    // alterar
                    case 2:
                        this.usuarioCrud = { dsLogin: dsLogin, idContrato: idContrato, idCliente: idCliente };
                        this.showModalUsuario = !this.showModalUsuario;
                        break;
                    // excluir
                    case 3:
                        break;
                }


            },

            excluirUsuarioContrato(usuario) {
                let $this = this;
                Swal.fire({
                    title: 'Confirmar a exclusão do usuário "' + usuario.nmUsuario+'"?',
                    showDenyButton: true,
                    //showCancelButton: true,
                    confirmButtonText: 'Sim',
                    denyButtonText: 'Não',
                }).then((result) => {
                    if (result.isConfirmed) {                        
                        axios.post(`${urlApi}/ClienteContratoProduto/ExcluirUsuarioContrato/`, { idCliente: usuario.idCliente, idContrato: usuario.idContrato, dsLogin:usuario.dsLogin})
                            .then(res => {
                                if (res.data.success) {                                    
                                    avisoToast("Usuário excluido com sucesso!")
                                    $this.usuarios = $this.usuarios.filter(function (elm) { return (elm.dsLogin != usuario.dsLogin) });
                                } else {
                                    avisoErro("Não foi possível excluir o usuário.");                                    
                                }

                            })
                            .catch(res => {
                                if (res.data != undefined) {
                                    avisoErro(res.response.data);
                                } else {
                                    avisoErro(res.response.statusText);
                                }
                            });
                    }
                })
            },
            
        },
        created: function () {
            this.load();
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .conteudo-gerenciar-usuarios {
        min-height: 46vh;
    }

    .box-cinza {
        box-shadow: 0 2px 5px;
        background-color: #FFF;
        border-radius: 5px;
        border: 1px solid;
    }

    .label-gestor {
        padding: 5px;
        border-radius: 6px;
        color: #fff;
    }


    .largerCheckbox {
        min-width: 20px;
        height: 20px;
    }
</style>

