import Vue from 'vue'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(VueLoading, {
    color: '#000000',
    loader: 'spinner',
    backgroundColor: '#ffffff',
    opacity: 0.5,
    canCancel: false,
});

// exemplo de uso
/*
  let loader = this.$loading.show({
                    // Optional parameters

                });
                setTimeout(() => {
                    loader.hide()
                }, 5000)

    this.$store.commit('showLoading', {});
    this.$store.commit('hideLoading');
 */

