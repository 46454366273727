<template>
    <div class="Modulos">
        <div class="row">
            <div class="col-12 mb-4">
                <span class="txt-h-2 txt-size-24 txt-blue-2 ">
                    Meus módulos
                </span>
            </div>

            <div class="col-12 col-md-4 mb-3" v-for="item in lstModulos" :key="item.idModulo">
                <div class="box-modulo c-pointer h-100">
                    <div :class="'box-modulo-header position-relative bg-'+item.classe">
                        <img class="img-fluid" :src="item.urlImagem" />
                        <p class="mb-0 txt-h-2 txt-size-20 txt-white mt-3 txt-letter-n-2">
                            {{item.nmModulo}}
                        </p>
                        <ManualFerramenta :interna="false" :name="'Manual '+item.nmModulo" :btnClass="'btn-'+item.classe" :nameBtn="'Manual'" :urlPdf="item.urlManual"></ManualFerramenta>
                    </div>
                    <div class="box-modulo-body" @click="acessarModulo(item)">
                        <p class="txt-h-5 txt-size-18 txt-blue-2 txt-w-6 mb-0 txt-letter-n-2">
                            {{item.dsModulo}}
                        </p>
                    </div>
                    <div :class="'box-modulo-footer bg-'+item.classe" @click="acessarModulo(item)">
                        <span class="txt-h-5 txt-size-18 txt-white txt-w-7 txt-letter-n-2">Ir para {{item.nmModulo}}</span>
                    </div>
                </div>
            </div>
        </div>

        <ContratarModulo :showModal="showModalContratar" :dadosModulo="dadosModuloContratar"></ContratarModulo>
    </div>
</template>

<script>
    import imgOrientacoes from '@/assets/img/orientacao-min.png';
    import imgCapacitacao from '@/assets/img/capacitacao-min.png';
    import imgPeriodicos from '@/assets/img/periodicos-min.png';

    import ContratarModulo from '@/components/ContratarModulo'
    import ManualFerramenta from '@/components/ManualFerramenta'
    import { urlApi, avisoErro, linkOrientacoes, linkCapacitacao, linkPeriodicos  } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'Modulos',
        components: { ContratarModulo, ManualFerramenta },
        computed: {
            user() {
                return this.$store.state.user
            },
        },
        data: function () {
            return {
                lstModulos: [
                    {
                        idModulo: 1,
                        nmModulo: 'Orientações',
                        cdProduto: 'SO',
                        dsModulo: 'O Sollicita Orientações é o único suporte integral e seguro de licitações e contratos do mercado! Oferecemos Orientações técnicas com o menor tempo de respostas em quatro modalidades: orientações por escrito, telefone, whatsapp e mentoria!',
                        urlImagem: imgOrientacoes,
                        classe: 'orientacoes',
                        urlModulo: linkOrientacoes,
                        urlManual: 'manuais/FERRAMENTA ORIENTAÇÕES PDF.pdf',
                    },
                    {
                        idModulo: 2,
                        nmModulo: 'Capacitação',
                        cdProduto: 'SC',
                        dsModulo: 'Os melhores e mais conceituados eventos do Grupo Negócios Públicos gravados para assistir quando e onde quiser.Além de cursos intensivos e aulas ao vivo exclusivos do Sollicita PRO.',
                        urlImagem: imgCapacitacao,
                        classe: 'capacitacao',
                        urlModulo: linkCapacitacao,
                        urlManual: 'manuais/FERRAMENTA CAPACITAÇÃO PDF.pdf',
                    },
                    {
                        idModulo: 3,
                        nmModulo: 'Periódicos',
                        cdProduto: 'PE',
                        dsModulo: 'Os periódicos, contam ainda com parceiros experientes e notoriamente reconhecidos como altamente qualificados, em matéria de contratação pública, que atuam como articulistas e colunistas.',
                        urlImagem: imgPeriodicos,
                        classe: 'periodicos',
                        urlModulo: linkPeriodicos,
                        urlManual: 'manuais/FERRAMENTA PERIÓDICOS PDF.pdf',
                    },
                ],

                showModalContratar: false,
                dadosModuloContratar: null,
            }
        },
        methods: {
            acessarModulo(modulo) {
                let $this = this;
                axios.get(`${urlApi}/Produto/VerificaAcessoProduto`, { params: { cdProduto: modulo.cdProduto } })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data != 'Sim') {
                                // nao tem acesso 
                                this.dadosModuloContratar = {
                                    nome: modulo.nmModulo,
                                    descricao: modulo.dsModulo,
                                    urlImagem: modulo.urlImagem,
                                    classe: modulo.classe
                                };
                                this.showModalContratar = !this.showModalContratar;
                            } else {
                                // redirect
                                $this.redirectModulo(modulo);
                            }
                        } else {
                            avisoErro("Não foi possível verificar o módulo");                            
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível verificar o módulo");
                    });
            },

            redirectModulo(modulo) {
                var url = modulo.urlModulo + 'AutenticacaoAutomatica?token=' + this.user.accessToken + '&refreshTokenWeb=' + this.user.refreshTokenWeb;
                window.location.href = encodeURI(url);
                //window.open(encodeURI(url), '_blank');
            },

        },

    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .box-modulo {
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .box-modulo-header {
            border-radius: 5px;
            padding: 30px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            img {
                width: 132px;
                height: 132px;
            }
        }

        .box-modulo-body {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 180px;
            padding: 30px 20px;
        }


        .box-modulo-footer {
            border-radius: 5px;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>

