<template>
    <section class="pesquisa">
        <Header></Header>

        <div class="position-relative mt-4">
            <div :class="'busca-lateral d-none d-md-flex '+(showBuscaLateral? '':'inative')">
                <div class="btn-toggle" @click="showBuscaLateral = !showBuscaLateral">
                    <span v-show="showBuscaLateral" id="menuOn"><i class="fas fa-chevron-right"></i></span>
                    <span v-show="!showBuscaLateral" id="menuOff"><i class="fas fa-chevron-left"></i></span>
                </div>
                <span class="txt-p txt-size-16 txt-grey-3 txt-w-7 mb-2"><i class="fas fa-chart-line me-1"></i> Temas em alta</span>
                <ul class="p-0 m-0">
                    <li v-for="(item,index) in lstEmAlta" :key="index">
                        <template v-if="item.id != 0">
                            <button class="btn w-100 bg-blue-2 txt-white" type="button" @click="buscaEmAlta('','','',item.id)">{{item.nome}}</button>
                        </template>
                        <template v-else>
                            <button class="btn w-100 bg-blue-2 txt-white" type="button" @click="buscaEmAlta(item.nome,'','',item.id)">{{item.nome}}</button>
                        </template>
                    </li>
                </ul>
            </div>

            <div class="container">
                <div class="col-12">
                    <div class="busca-emAlta d-block d-md-none">
                        <span class="badge badge-primary btn-emAlta bg-blue-2 txt-white me-1"
                              @click="buscaEmAlta((item.id == 0?item.nome:''),'','',item.id)"
                              v-for="(item,index) in lstEmAlta"
                              :key="index">
                            {{item.nome}}
                        </span>
                    </div>
                </div>
            </div>
            
        </div>

        <div class="container mt-4 box-input-pesquisa">
            <div class="row justify-content-center">
                
                <div class="col-12 col-md-10">
                    <span class="txt-h-3 txt-size-22 txt-blue-4 txt-w-8">
                        Sollicita Pesquisa
                    </span>
                    <div class="d-flex justify-content-end">
                        <ManualFerramenta class="me-2" :name="'Manual Pesquisa'" :btnClass="'btn-blue'" :nameBtn="'Manual'" :urlPdf="'/manuais/FERRAMENTA PESQUISA PDF.pdf'"></ManualFerramenta>
                        <ManualFerramenta :name="'Manual Lei Comentada'" :btnClass="'btn-blue'" :nameBtn="'Manual Lei Comentada'" :urlPdf="'/manuais/FERRAMENTA LEI COMENTADA E REGULAMENTADA PDF.pdf'"></ManualFerramenta>
                    </div>
                    <div class="w-100 mt-2 bg-grey-1 mt-3 px-3 pt-4 pb-5 box-cinza position-relative">
                        <img class="position-absolute img-pesquisa" src="../assets/img/icone_pesquisa_menor-min.png" />
                        <p class="txt-h-3 txt-size-20 txt-blue-5 txt-w-6 text-center mb-4" style="padding: 0px 60px;">
                            Pesquise por termos, temas ou perguntas e nós te mostramos o que temos em nosso acervo!
                        </p>
                        <div class="input-group input-pesquisa mb-3">
                            <input type="text" class="form-control pesquisa" placeholder="Pesquise o que você procura" aria-label="Recipient's username" aria-describedby="basic-addon2" v-model="termoBusca" v-on:keyup.enter="pesquisar" v-on:keyup="preencheRecomendacoes" @focus="preencheRecomendacoes" @blur="limpaRecomendacoes">
                            <span class="input-group-text c-pointer" id="basic-addon2" @click="pesquisar"><i class="fas fa-search"></i></span>
                            <div class="pesquisa_recomendacoes" v-show="lstPesquisaRecomendacoes.length > 0">
                                <span class="pesquisa_recomendacao" @click="selectAutocomplete(item)" v-for="item in lstPesquisaRecomendacoes" :key="item">{{item}}</span>
                            </div>
                        </div>
                        <div class="position-relative">
                            <div class="filtros-pesquisa w-100 ">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="bg-white rounded" style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);">
                                            <div class="row p-3">
                                                <div class="col-12 col-lg-3">
                                                    <div class="mb-3">
                                                        <label for="dtInicial" class="form-label txt-p txt-w-4 txt-grey-3 txt-size-14 mb-0 ps-2">Data inicial</label>
                                                        <date-picker class="w-100" v-model="dtInicial" format="DD/MM/YYYY" value-type="format"></date-picker>

                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-3">
                                                    <div class="mb-3">
                                                        <label for="dtFinal" class="form-label txt-p txt-w-4 txt-grey-3 txt-size-14 mb-0 ps-2">Data final</label>
                                                        <date-picker class="w-100" v-model="dtFim" format="DD/MM/YYYY" value-type="format"></date-picker>

                                                    </div>
                                                </div>
                                                <div class="col-12 col-lg-6 d-flex align-items-center">
                                                    <div class="c-pointer txt-p txt-w-4 txt-grey-3 txt-size-14" id="tooltip-target-1">
                                                        <input class="check-pesquisa " type="radio" name="inlineRadioOptions" id="inlineRadio1" value="abrangente" v-model="tpPesquisa">
                                                        <label class="form-check-label c-pointer me-1 w-50" for="inlineRadio1">Pesquisa Abrangente</label>
                                                        <i class="fas fa-info-circle"></i>
                                                        <b-tooltip target="tooltip-target-1" triggers="hover">
                                                            Traz apenas resultados com o termo exatamente como foi digitado.
                                                        </b-tooltip>
                                                    </div>
                                                    <div class="c-pointer txt-p txt-w-4 txt-grey-3 txt-size-14" id="tooltip-target-2">
                                                        <input class="check-pesquisa" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="proxima" v-model="tpPesquisa">
                                                        <label class="form-check-label c-pointer me-1 w-50" for="inlineRadio2">Pesquisa Aproximada</label>
                                                        <i class="fas fa-info-circle"></i>
                                                        <b-tooltip target="tooltip-target-2" triggers="hover">
                                                            Traz todos os resultados que encontrem qualquer palavra presente no termo pesquisado.
                                                        </b-tooltip>
                                                    </div>
                                                    <div class="c-pointer txt-p txt-w-4 txt-grey-3 txt-size-14" id="tooltip-target-3">
                                                        <input class="check-pesquisa" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="precisa" v-model="tpPesquisa">
                                                        <label class="form-check-label c-pointer me-1 w-50" for="inlineRadio3">Pesquisa Precisa</label>
                                                        <i class="fas fa-info-circle"></i>
                                                        <b-tooltip target="tooltip-target-3" triggers="hover" placement="top">
                                                            Traz os resultados que contenham os termos da pesquisa exatamente como foram digitados.
                                                        </b-tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="divisor-filtro w-100"></div>
                                            <div class="row px-3">
                                                <ul class="list-group pesquisa-formulario__lista-filtros">
                                                    <li :class="'pesquisar-fomulario__filtro-item '+(item.idConteudo == idConteudo?'active':'')" v-for="(item,index) in lstConteudos" :key="index">
                                                        <a :id="'item_'+item.idConteudo" href="#" class="txt-p" @click="openResultadosParametrizado(termo,dtInicial,dtFim,item.idConteudo )">
                                                            <i :class="getConteudo(item.cdConteudo).icon"></i> {{item.nmConteudo}}
                                                        </a>
                                                        <!--<span class="badge badge-primary badge-pill d-none"> </span>-->
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 mt-3">
                                        <button class="btn btn-blue w-100" type="button" @click="pesquisar">
                                            Pesquisar
                                        </button>

                                    </div>
                                </div>
                                <span class="txt-h-5 txt-w-4 txt-size-14 txt-grey-2 fst-italic mt-2" v-if="rows > 0">
                                    Foram encontrados {{qtdRegistros}} resultados para sua pesquisa <span class="'txt-w-7">“{{termo}}”</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    

                </div>
            </div>
        </div>

        <div class="container px-5 mt-5 pt-5" style="min-height: 200px;">
            
            <div class="row">
                <div :class="'col-12 '+classTamanhoResultados" v-if="lstResultados.length > 0" id="itemList">

                    <div class="card-resultado px-4 py-3 mb-3 position-relative" v-for="(item,index) in getListResult" :key="index">
                        <div class="mb-1">
                            <span class="txt-p txt-w-4 txt-size-12 txt-grey-3">
                                <i :class="getConteudo(item.cdConteudo).icon"></i> {{getConteudo(item.cdConteudo).nmConteudo}}
                            </span>
                            <span class="mx-2" style="border-right: 2px solid #767676;"></span>
                            <span class="txt-p txt-w-4 txt-size-12 txt-grey-3" v-if="item.dsReferencia != null && item.dsReferencia != ''">
                                {{item.dsReferencia}}
                            </span>
                        </div>
                        <div class="mb-2">
                            <template v-if="item.tpArquivo == 'Arquivo'">
                                <a class="txt-p txt-w-7 txt-size-18 txt-blue-2 c-pointer mb-0 stretched-link" @click="carregaArquivo(item.urlArquivo, item.cdConteudo, item.idItemPesquisa)" v-html="item.nmResultado">

                                </a>
                            </template>
                            <template v-else>
                                <a class="txt-p txt-w-7 txt-size-18 txt-blue-2 c-pointer mb-0 stretched-link" @click="openPesquisaResultado(item.cdConteudo, item.idItemPesquisa, item.dsReferencia,item.urlArquivo)" v-html="item.nmResultado">

                                </a>
                            </template>
                        </div>
                        <div>
                            <p class="txt-p txt-w-4 txt-size-14 txt-grey-2 mb-0" v-html="item.dsResultado">

                            </p>
                        </div>
                    </div>
                </div>

                <!--sujestao-->
                <div :class="'col-12 '+classTamanhoRecomendacoes">
                    <div class="card-resultado-recomendado px-4 py-3 mb-3 position-relative" v-for="(item,index) in getListRecomendados" :key="'recomendado-'+index">
                        <div class="mb-1">
                            <span class="txt-p txt-w-4 txt-size-12 txt-grey-3">
                                <i :class="getConteudo(item.cdConteudo).icon"></i> {{getConteudo(item.cdConteudo).nmConteudo}}
                            </span>
                            <span class="mx-2" style="border-right: 2px solid #767676;"></span>
                            <span class="txt-p txt-w-4 txt-size-12 txt-grey-3" v-if="item.dsReferencia != null && item.dsReferencia != ''">
                                {{item.dsReferencia}}
                            </span>
                        </div>
                        <div class="mb-2">
                            <template v-if="item.tpArquivo == 'Arquivo'">
                                <a class="txt-p txt-w-7 txt-size-18 txt-blue-2 c-pointer mb-0" @click="carregaArquivo(item.urlArquivo, item.cdConteudo, item.idItemPesquisa)" v-html="item.nmResultado">

                                </a>
                            </template>
                            <template v-else>
                                <a class="txt-p txt-w-7 txt-size-18 txt-blue-2 c-pointer mb-0" @click="openPesquisaResultado(item.cdConteudo, item.idItemPesquisa, item.dsReferencia,item.urlArquivo)" v-html="item.nmResultado">

                                </a>
                            </template>
                        </div>
                        <div>
                            <p class="txt-p txt-w-4 txt-size-14 txt-grey-2 mb-0" v-html="item.dsResultado">

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-center" v-if="rows > 0">
                    <b-pagination v-model="currentPage"
                                  :total-rows="rows"
                                  :per-page="perPage"
                                  ellipsis-class="d-none"
                                  aria-controls="itemList"
                                  ></b-pagination>
                </div>

            </div>
        </div>

        <ModalDetalhes :dados="modalDetalhes" :showModal="showModalDetalhes"></ModalDetalhes>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header'
    import Footer from '@/components/Footer'
    import ModalDetalhes from '@/components/ModalDetalhes'
    import ManualFerramenta from '@/components/ManualFerramenta'

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/pt-br';

    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'

    //import moment from 'moment'
    export default {
        name: 'Pesquisa',
        components: { Header, Footer, ModalDetalhes, DatePicker, ManualFerramenta },
        data() {
            return {
                termo: null,
                termoBusca: null,
                dtInicial: "",
                dtFim: "",
                tpPesquisa: 'abrangente',

                lstConteudos: [],
                lstResultados: [],
                lstRecomendados: [],
                lstPesquisaRecomendacoes: [],
                qtdRegistros: null,

                showBuscaLateral: true,
                idConteudo: 0,

                lstConteudo: [
                    { cdConteudo: 'TODOS', icon: 'fas fa-globe-africa', nmConteudo: 'Todos os resultados' },
                    { cdConteudo: 'ACORD', icon: 'far fa-newspaper', nmConteudo: 'Acórdão TCU' },
                    { cdConteudo: 'AUDIO', icon: 'fas fa-volume-up', nmConteudo: 'Áudio' },
                    { cdConteudo: 'BOLET', icon: 'fas fa-file-alt', nmConteudo: 'Boletim TCU' },
                    { cdConteudo: 'JURIS', icon: 'fas fa-file', nmConteudo: 'Jurisprudência TCU' },
                    { cdConteudo: 'LFSIS', icon: 'far fa-newspaper', nmConteudo: 'Legislação Sistema S' },
                    { cdConteudo: 'LGEST', icon: 'far fa-newspaper', nmConteudo: 'Legislação Estadual' },
                    { cdConteudo: 'LGFED', icon: 'far fa-newspaper', nmConteudo: 'Legislação Federal' },
                    { cdConteudo: 'LGMUN', icon: 'far fa-newspaper', nmConteudo: 'Legislação Municipal' },
                    { cdConteudo: 'MODEL', icon: 'far fa-newspaper', nmConteudo: 'Modelo' },
                    { cdConteudo: 'NOTIC', icon: 'far fa-newspaper', nmConteudo: 'Notícia' },
                    { cdConteudo: 'ORIJU', icon: 'far fa-newspaper', nmConteudo: 'Orientação Técnica' },
                    { cdConteudo: 'PERIO', icon: 'far fa-newspaper', nmConteudo: 'Periódico' },
                    { cdConteudo: 'PUBLI', icon: 'far fa-newspaper', nmConteudo: 'Publicação' },
                    { cdConteudo: 'SUMUL', icon: 'far fa-newspaper', nmConteudo: 'Súmula TCU' },
                    { cdConteudo: 'VIDEO', icon: 'far fa-play-circle', nmConteudo: 'Vídeo' },
                    { cdConteudo: 'MATER', icon: 'far fa-newspaper', nmConteudo: 'Doutrina' },
                    { cdConteudo: 'LEICO', icon: 'far fa-newspaper', nmConteudo: 'lei Comentada' },
                    { cdConteudo: 'ACSTF', icon: 'far fa-newspaper', nmConteudo: 'Acórdão STF' },
                    { cdConteudo: 'JUSTF', icon: 'far fa-newspaper', nmConteudo: 'Jurisprudência STF' },
                    { cdConteudo: 'BOSTF', icon: 'far fa-newspaper', nmConteudo: 'Boletim STF' },
                    { cdConteudo: 'SUSTF', icon: 'far fa-newspaper', nmConteudo: 'Súmula STF' },
                ],

                lstEmAlta: [
                    { id: 24, nome: 'Legislação'},
                    { id: 10, nome: 'Produção própria'},
                    { id: 0, nome: 'Pregão'},
                    { id: 0, nome: 'ETP'},
                    { id: 0, nome: 'Lei 14.133/21'},
                    { id: 0, nome: 'Lei 8.666'},
                    { id: 0, nome: 'Sistema S'},
                    { id: 0, nome: 'Estatais'},
                    { id: 0, nome: 'Pregoeiros'},
                    { id: 0, nome: 'Administração Pública'},
                    { id: 0, nome: 'Governança'},
                    { id: 0, nome: 'Obras e serviços de engenharia'},
                    { id: 0, nome: 'Contratos'},
                    //{ id: 26, nome: 'Lei comentada'},
                ],

                classTamanhoResultados: '',
                classTamanhoRecomendacoes: '',

                flExibeModelo: false,
                flExibeOrientacao: false,
                flContratoAtivo: false,

                modalDetalhes: {
                    cdConteudo: null,
                    idItemPesquisa: null,
                    dsReferencia: null,
                    urlArquivo: null,
                },

                showModalDetalhes: false,

                perPage: 10,
                currentPage: 1,

                idLogPesquisa: null,
            }
        },
        computed: {
            rows() {
                return this.lstResultados.length
            },
            getListResult() {
                let $this = this;
                var newArray = this.lstResultados.slice(
                    (this.currentPage - 1) * this.perPage,
                    this.currentPage * this.perPage,
                ).map(function (element) {
                    return $this.marcaTexto(element);
                });
                return newArray;
            },

            getListRecomendados() {
                let $this = this;
                var newArray = this.lstRecomendados.map(function (element) {
                    return $this.marcaTexto(element);
                });
                return newArray;
            },

        },
        methods: {
            marcaTexto(elm) {                
                const regexPattern = new RegExp(this.termo, 'gi');

                // replace no resultado
                var text = elm.dsResultado;
                text = text.replace(/(<mark class="highlightColor">|<\/mark>)/gim, '');
                var newText = text.replace(regexPattern, '<mark class="highlightColor">$&</mark>');
                elm.dsResultado = newText;

                text = elm.nmResultado;
                text = text.replace(/(<mark class="highlightColor">|<\/mark>)/gim, '');
                newText = text.replace(regexPattern, '<mark class="highlightColor">$&</mark>');
                elm.nmResultado = newText;

                return elm;
            },


            limpaRecomendacoes() {
                let $this = this;
                // timeout para nao conflitar no click do item do autocomplete
                setTimeout(function () {
                    $this.lstPesquisaRecomendacoes = [];
                }, 200)
            },

            selectAutocomplete(item) {
                let $this = this;
                $this.termoBusca = item;
                $this.pesquisar();
            },


            openResultadosParametrizado(termo, dtInicial, dtFim, idConteudo, leiComentada = false) {
                this.idConteudo = idConteudo;

                let $this = this;
                axios.get(`${urlApi}/Pesquisa/ResultadosDetalhes`, {
                    params: {
                        p_dsTermo: termo,
                        p_dtInicial: dtInicial,
                        p_dtFinal: dtFim,
                        p_idConteudo: idConteudo,
                        p_tpPesquisa: $this.tpPesquisa,
                        p_leiComentada: leiComentada
                    }
                })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data) {
                                $this.idLogPesquisa = res.data.data.idLogPesquisa;
                                var lstResultados = res.data.data.lstResultados
                                $this.lstResultados = lstResultados.filter(x => x.flIndicacao == "Não");
                                $this.lstRecomendados = lstResultados.filter(x=> x.flIndicacao == "Sim");

                                if ($this.lstRecomendados.length == 0) {
                                    $this.classTamanhoResultados = '';
                                } else {
                                    $this.classTamanhoResultados = 'col-lg-8';
                                }

                                if ($this.lstResultados.length == 0) {
                                    $this.classTamanhoRecomendacoes = '';
                                } else {
                                    $this.classTamanhoRecomendacoes = 'col-lg-4';
                                } 

                            }
                        } else {
                            console.log("Não foi possível verificar ResultadosDetalhes");
                        }
                    })
                    .catch(function () {
                        console.log("Não foi possível verificar ResultadosDetalhes");
                    });
            },

            load() {
                let $this = this;
                $this.getExibeModelo();
                $this.clearData();

                $this.termo = this.termoBusca;

                if (($this.termo && $this.termo != "") || ($this.leiComentada && $this.leiComentada != "")) {
                    $this.getContratoAtivo(function () {
                        if ($this.flContratoAtivo) {
                            $this.getResultados();

                            //$this.getExibeOrientacao();
                        } else {
                            avisoErro("Verificar Contrato.")
                        }
                    });
                }
                
            },

            clearData() {
                this.lstConteudos = [];
                this.lstResultados = [];
                this.lstRecomendados = [];
            },

            pesquisar() {
                this.load();
            },
                       
            getConteudo(cdConteudo) {
                return this.lstConteudo.filter(x => x.cdConteudo == cdConteudo)[0];
            },

            getResultados() {
                let $this = this;
                $this.lstPesquisaRecomendacoes = [];

                if (!$this.leiComentada && $this.leiComentada == "") {
                    if ($this.termo.trim() == "") {
                        avisoErro("O campo 'Busca' deve ser informado");
                        return false;
                    }
                    if ($this.termo.length < 3) {
                        avisoErro("O campo 'Busca' deve conter ao menos 3 caracteres");
                        return false;
                    }
                }

                axios.get(`${urlApi}/Pesquisa/Resultados`, {
                    params : {
                        p_dsTermo: $this.termo,
                        p_dtInicial: $this.dtInicial,
                        p_dtFinal: $this.dtFim,
                        p_idConteudo: 0,
                        p_tpPesquisa: $this.tpPesquisa,
                        p_leiComentada: $this.leiComentada
                    }
                })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data) {
                                $this.flExibeOrientacao = res.data.data.flExibeOrientacao;
                                var lstResultados = res.data.data.lstResultados
                                $this.lstConteudos.push({ idConteudo: 0, cdConteudo: 'TODOS', nmConteudo: 'Todos Resultados', });
                                for (let i = 0; i < res.data.data.lstConteudos.length; i++) {
                                    let item = res.data.data.lstConteudos[i];
                                    var qtdRegistros = lstResultados.filter(x => x.cdConteudo == item.cdConteudo && x.flIndicacao == "Não").length;
                                    if (qtdRegistros > 0) {
                                        $this.lstConteudos.push(item);
                                    }
                                }
                                $this.openResultadosParametrizado($this.termo, $this.dtInicial, $this.dtFim, 0, $this.leiComentada);
                            }
                            
                        } else {
                            console.log("Não foi possível verificar Resultados");
                        }
                    })
                    .catch(function () {
                        console.log("Não foi possível verificar Resultados");
                    });
            },

            getExibeOrientacao(callback) {
                let $this = this;
                axios.get(`${urlApi}/Pesquisa/ExibeOrientacao`)
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data) {
                                $this.flExibeOrientacao = res.data.data;
                            } else {
                                $this.flExibeOrientacao = false;
                            }
                            callback($this.flExibeOrientacao);
                        } else {
                            console.log("Não foi possível verificar ExibeOrientacao");
                            callback(false);
                        }
                    })
                    .catch(function () {
                        console.log("Não foi possível verificar ExibeOrientacao");
                        callback(false);
                    });
            },

            getExibeModelo() {
                let $this = this;
                axios.get(`${urlApi}/Produto/VerificaAcessoProduto`, { params: { cdProduto: 'MD' } })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data != 'Sim') {
                                // nao tem acesso 
                                $this.flExibeModelo = false;
                            } else {
                                $this.flExibeModelo = true;
                            }
                        }
                    })
            },

            getContratoAtivo(callback) {
                let $this = this;
                axios.get(`${urlApi}/Pesquisa/ContratoAtivo`)
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data) {
                                $this.flContratoAtivo = res.data.data;
                            } else {
                                $this.flContratoAtivo = false;
                            }
                            callback($this.flExibeOrientacao);
                        } else {
                            console.log("Não foi possível verificar ContratoAtivo");
                            callback(false);
                        }
                    })
                    .catch(function () {
                        console.log("Não foi possível verificar ContratoAtivo");
                        callback(false);
                    });
            },

            buscaEmAlta(termo, dtInicial, dtFinal, idConteudo, leiComentada = false) {
                let $this = this;
                $this.clearData();
                $this.termo = termo;
                //$this.termoBusca = termo;
                $this.dtInicial = dtInicial;
                $this.dtFinal = dtFinal;
                axios.get(`${urlApi}/Pesquisa/Resultados`, {
                    params: {
                        p_dsTermo: termo,
                        p_dtInicial: dtInicial,
                        p_dtFinal: dtFinal,
                        p_idConteudo: idConteudo,
                        p_tpPesquisa: 'abrangente',
                        p_leiComentada: leiComentada
                    }
                })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data) {
                                $this.flExibeOrientacao = res.data.data.flExibeOrientacao;
                                var lstResultados = res.data.data.lstResultados
                                $this.lstConteudos.push({ idConteudo: idConteudo, cdConteudo: 'TODOS', nmConteudo: 'Todos Resultados', });
                                for (let i = 0; i < res.data.data.lstConteudos.length; i++) {
                                    let item = res.data.data.lstConteudos[i];
                                    var qtdRegistros = lstResultados.filter(x => x.cdConteudo == item.cdConteudo && x.flIndicacao == "Não").length;
                                    if (qtdRegistros > 0) {
                                        $this.lstConteudos.push(item);
                                    }
                                }

                                $this.openResultadosParametrizado(termo, dtFinal, dtFinal, idConteudo, leiComentada);
                            }

                        } else {
                            console.log("Não foi possível verificar Resultados");
                        }
                    })
                    .catch(function () {
                        console.log("Não foi possível verificar Resultados");
                    });
            },



            carregaArquivo(link, cdConteudo, idItemPesquisa) {
                //AbreModalPDF();
                //$('#iframeVisualizarArquivo').attr('src', link);
                //geraLogPesquisaResultado(cdConteudo, idItemPesquisa);
                console.log(link + '' + cdConteudo + '' + idItemPesquisa)
            },


            openPesquisaResultado(cdConteudo, idItemPesquisa, dsReferencia, urlArquivo) {
                if (cdConteudo == 'MATER') {
                    cdConteudo = 'NOTIC'
                }
                if (cdConteudo == 'MODEL' && !this.flExibeModelo) {
                    avisoErro('Você ainda não tem a acesso a ferramenta de Modelos, entre em contato com a nossa equipe e confira os planos!');
                } else {
                    if (cdConteudo == 'ORIJU' && !this.flExibeOrientacao) {
                        avisoErro('Assine o Sollicita Pessoa Jurídica e tenha acesso a informações apresentadas por especialistas.');
                    }
                    else {                        
                        this.modalDetalhes = {
                            urlArquivo: urlArquivo,
                            cdConteudo: cdConteudo,
                            idItemPesquisa: idItemPesquisa,
                            dsReferencia: dsReferencia,
                            idLogPesquisa: this.idLogPesquisa
                        };
                        this.showModalDetalhes = !this.showModalDetalhes;
                    }
                }
            },

            preencheRecomendacoes() {
                let $this = this;
                axios.get(`${urlApi}/Pesquisa/AutoComplete`, {
                    showLoading: false,
                    params: {
                        p_dsTermo: this.termoBusca,
                    }
                })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data) {
                                $this.lstPesquisaRecomendacoes = res.data.data;
                            }

                        } else {
                            console.log("Não foi possível carregar as recomendacoes");
                        }
                    })
                    .catch(function () {
                        console.log("Não foi possível carregar as recomendacoes");
                    });
            },
        },
        mounted: function () {
            if (this.$route.query.termo != undefined) {
                this.termo = this.$route.query.termo;
                this.termoBusca = this.termo;
            }

            if (this.$route.query.leiComentada != undefined) {
                this.leiComentada = this.$route.query.leiComentada;
            }

            this.load();
        },
        watch: {
            $route(to) {
                if (to.query.termo != undefined) {
                    this.termo = to.query.termo;
                    this.termoBusca = this.termo;
                } else {
                    this.termo = null;
                    this.termoBusca = null;
                }

                this.load();
            },

            //termoBusca(value) {
            //    this.lstPesquisaRecomendacoes = [];
            //    if (value.length > 3) {
            //        this.preencheRecomendacoes();
            //    }
            //},

        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    .box-cinza {
        border-radius: 50px 5px 5px 5px;

        .img-pesquisa{
            left: 0;
            top: 0;
        }
    }

    .filtros-pesquisa {
        top: 0;
        position: absolute;

        .divisor-filtro {
            border-top: 1px solid #767676;
        }
    }

    .pesquisa {
        border-color: #FBBC0D;
        border-width: 2px;
        box-shadow: none;
        border-right: none;
        height: 40px;
    }

    .pesquisa:focus {
        border-color: #FBBC0D;
        border-width: 2px;
        box-shadow: none;

        border-right: none;
    }

    .input-pesquisa {
        .input-group-text {
            background: #FFF;
            border-color: #FBBC0D;
            border-width: 2px;
            border-left: none;
            color: #D4D4D4;
        }
    }

    .busca-emAlta .btn-emAlta {
        border-radius: 5px;
        padding: 5px;
        cursor: pointer;
        margin: 5px 0;
    }

    .busca-lateral {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;
        border-radius: 5px 0 0 5px;
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        max-width: 300px;


        .btn-toggle {
            height: 150px;
            display: flex;
            align-items: center;
            font-size: 12px;
            position: absolute;
            left: -28px;
            padding: 10px;
            background: #FFFFFF;
            box-shadow: -9px 4px 14px -8px rgb(0 0 0 / 25%);
            border-radius: 5px 0 0 5px;
            cursor: pointer;
        }

        li {
            list-style-type: none;
            margin: 5px 5px;
        }
    }

    .busca-lateral.inative {
        right: -300px;
    }

    .check-pesquisa {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        accent-color: #163142;
    }

    .pesquisa-formulario__lista-filtros {
        display: flex;
        flex-flow: row wrap;
        list-style: none;
        padding: 0 1rem;

        .pesquisar-fomulario__filtro-item {
            padding: 0;
            margin: 0;
            border-bottom: 3px solid #efecec;

            a {
                color: #767676;
                padding: 1rem 1rem;
                display: block;
                font-weight: 400;
                font-size: 12px;
            }
        }

        .pesquisar-fomulario__filtro-item.active {
            a {
                color: #FBBC0D;
                font-weight: 700;
            }

            border-bottom: 3px solid #FBBC0D;
        }
    }


    .card-resultado, .card-resultado-recomendado {
        background: #FFFFFF;
        border: 1px solid #767676;
        box-sizing: border-box;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
    }

    .pesquisa_recomendacoes {
        z-index: 99;
        margin-top: -15px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border: 1px solid #cccccc;
        border-top: hidden;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
        position: absolute;
        width: 78%;
        top: 55px;
    }

    .pesquisa_recomendacao:hover {
        background-color: rgba(211,211,211,0.6);
    }

    .pesquisa_recomendacao {
        /*padding-left: 12px;*/
        color: #222222;
        cursor: default;
        padding: 10px;
        text-align: initial;
    }

    .box-input-pesquisa {
        height: 500px;
    }
    @media (max-width: 575.98px) {
        .box-input-pesquisa {
            height: 800px;
        }
        .pesquisa-formulario__lista-filtros {
            max-height: 100px;
            overflow: auto;
        }
    }

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) and (max-width: 767.98px) {
        .box-input-pesquisa {
            height: 850px;
        }
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) and (max-width: 991.98px) {
        .box-input-pesquisa {
            height: 850px;
        }
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) and (max-width: 1199.98px) {
        .box-input-pesquisa {
            height: 600px;
        }
    }

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) and (max-width: 1600px) {
        .box-input-pesquisa {
            height: 600px;
        }
    }
</style>

