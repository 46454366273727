<template>
    <div class="grid-app">
        <table v-if="is_table" class="grid-content">
            
        </table>
        <div v-else class="grid-content">
            <slot name="rows" v-bind:rows="rows">
            </slot>
        </div>

        <div class="paginate" v-if="pages > 1">
            <div class="paginate-buttons mt-3 mb-5">
                <button :class="'btn btn-navegacao w-25 float-left ' + (page_on <= 1 ? 'disabled' : '')" @click="prevPage()" :data-tippy-content="(page_on <= 1 ? 'Você está na primeira página' : 'Ir à página anterior')"><i class="fas fa-angle-left"></i> Anterior</button>
                <select class="form-select w-25 float-left" v-model="page_on"><option v-for="(page,index) in pages" :key="index" :selected="page_on == page">{{ page }}</option></select>
                <button :class="'btn btn-navegacao w-25 float-right ' + (page_on == pages ? 'disabled' : '')" @click="nextPage()" :data-tippy-content="(page_on == pages ? 'Você está na última página' : 'Ir à próxima página')">Próxima <i class="fas fa-angle-right"></i></button>
            </div>
        </div>
    </div>
</template>

<script>
    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'Grid',
        data() {
            return {
                rows: [],

                qtd_records: 0,

                pages: 0,

                page_on: 0,

                tippy_instance: null
            }
        },
        props: {
           is_ajax: {
                type: Boolean,
                default: true
            },

            url_ajax: {
                type: String,
                default: ''
            },

            data_ajax: {
                type: Object,
                default: () => { return {}; }
            },

            is_table: {
                type: Boolean,
                default: true
            },

            is_slot: {
                type: Boolean,
                default: false
            },

            nr_pagina_atual: {
                type: Number,
                default: 0
            },

            qtd_registros_por_pagina: {
                type: Number,
                default: 20
            },

            ajax_set_result: {
                type: Function,
                default: (r) => { return r; }
            },

            ajax_set_count: {
                type: Function,
                default: (r) => { return r.data.rows.itensCount; }
            },

            reload: {
                type: Number,
                default: 0
            },
        },
        methods: {
            getData() {
                let $this = this;
                $this.data_ajax['qtdRegistrosPorPagina'] = $this.qtd_registros_por_pagina;
                $this.data_ajax['nrPaginaAtual'] = ($this.page_on);

                axios.get(`${urlApi}${$this.url_ajax}`, { params: $this.data_ajax })
                .then(res => {
                    if (res.data.success) {
                        if (res.data.data) {
                            // eslint-disable-next-line
                            debugger;
                            $this.rows = $this.ajax_set_result(res.data.data);
                            $this.qtd_records = $this.ajax_set_count(res.data);
                            $this.paginate();

                            //$this.$emit('data_loaded', { rows: $this.rows });
                        }
                    } else {
                        avisoErro("Não foi possível carrega os registros");
                    }
                })
                .catch(function () {
                    avisoErro("Não foi possível carrega os registros");
                });

            },

            paginate() {
                this.pages = Math.ceil(this.qtd_records / this.qtd_registros_por_pagina);
            },

            prevPage() {
                if (this.page_on > 1) this.page_on = this.page_on - 1;
            },

            nextPage() {
                if (this.page_on < this.pages) this.page_on = this.page_on + 1;
            },

        },
        watch: {
            rows() {    
                setTimeout(function () {
                    //tippy('.grid-content [data-tippy-content]');
                }, 200); 
            },

            page_on(nValue, oValue) {
                if (oValue && (nValue != oValue) && this.is_ajax) {
                    this.getData();
            
                }           
            },

            reload() {
                this.getData();
            },
        },
        mounted() {
            this.page_on = (this.nr_pagina_atual + 1);
            //if (this.is_ajax) this.getData(); 

        },
    }
</script>
<style lang="scss">
    /* CSS */
    .grid-app {
        .btn-navegacao {
            color: #fff;
            background-color: #6c757d;
            border-color: #6c757d;
        }
    }
</style>