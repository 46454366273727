<template>
    <div class="ModalVideo">
        <b-modal ref="modal-video" id="modalVideo" size="md" :hide-footer="true" :hide-header="true">
            <div class="w-100 text-end txt-size-20 txt-blue-2">
                <span @click="close"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <div class="row">
                <div class="col-12">
                    <template v-if="video != null">
                        <template v-if="video.tpExibicao == 'Local'">
                            <div class="d-flex justify-content-center">
                                <video v-on:loadeddata="montaListener" class="video-player" id="video" width="100%" height="auto" allowfullscreen :controls="controls" :autoplay="autoplay" :muted="mute" data-setup="{}" controlsList="nodownload">
                                    <source :src="video.src" type="video/mp4">
                                    <source :src="video.src" type="video/webm">
                                </video>
                            </div>
                        </template>
                        <template v-else>
                            <iframe width="100%" height="500px" :src="urlVideoExterno" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </template>
                    </template>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'ModalVideo',
        data: function () {
            return {
                video: null,

                controls: true,
                autoplay: false,
                mute: false,
            }
        },
        props: {
            showModal: Boolean,
            dados: Object,
        },
        methods: {

            async load() {
                let $this = this;
                axios.get(`${urlApi}/MediaPlayer/GetVideo/`, { params: { p_tipo: $this.dados.p_tipo, p_idVideo: $this.dados.p_idVideo, p_tpExibicao: $this.dados.p_tpExibicao } })
                    .then(res => {
                        if (res.data.success) {
                            var video = res.data.data;
                            video.tpExibicao = $this.dados.p_tpExibicao;

                            $this.video = video;
                            //$this.$nextTick(function () {
                                
                            //    if ($this.video.tpExibicao == 'Local') {
                            //        $this.montaListener();
                            //    }
                            //});
                            
                        } else {
                            avisoErro("Não foi possível carregar o vídeo");
                            $this.audio = null;
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar o vídeo");
                        $this.audio = null;
                    });
            },

            logVideo(hrTempoVisualizacao) {
                hrTempoVisualizacao = this.segundo_2_hora(hrTempoVisualizacao);
                var quebra_tempo = hrTempoVisualizacao.split(".");
                hrTempoVisualizacao = quebra_tempo[0];

                //let $this = this;
                //axios.post(`${urlApi}/MediaPlayer/LogVideo`, { idVideoLog: $this.idVideoLog, idVideo: $this.dados.p_idVideo, hrTempoVisualizacao: hrTempoVisualizacao, tipo:'Noticia' })
                //    .then(res => {
                //        if (res.data.success) {
                //            $this.idAudioLog = res.data.msg;
                //        }else {
                //            console.log("Erro Log");
                //            $this.video = null;
                //        }
                //    })
                //    .catch(function () {
                //        console.log("Erro Log");
                //        $this.video = null;
                //    });
            },

            segundo_2_hora(s) {
                let hora = (Math.round(s / 3600));
                let minuto = this.duas_casas(Math.floor((s % 3600) / 60));
                let segundo = this.duas_casas((s % 3600) % 60);

                return hora + ":" + minuto + ":" + segundo;
            },
        

            duas_casas(numero) {
                if (numero <= 9) {
                    numero = "0" + numero;
                }
                return numero;
            },

            montaListener() {
                let $this = this;
                var videoElement = document.getElementById('video');
                videoElement.volume = 0.2;

                videoElement.addEventListener("play", function () {
                    $this.logVideo(videoElement.currentTime);
                }, true);

                videoElement.addEventListener("pause", function () {
                    $this.logVideo(videoElement.currentTime);
                }, true);

                videoElement.addEventListener("ended", function () {
                    $this.logVideo(videoElement.currentTime);
                }, true);

                setInterval(function () { $this.logVideo(videoElement.currentTime); }, 30000);

                //Força o log ao sair da página
                window.onunload = function () {
                    $this.logVideo(videoElement.currentTime);
                }

                window.onbeforeunload = function () {
                    $this.logVideo(videoElement.currentTime);
                }

                //Função para pausar e avançar video igual youtube
                //function keyPressed(evt) {
                //    evt = evt || window.event;
                //    var key = evt.keyCode || evt.which;
                //    return String.fromCharCode(key);
                //}

                //document.onkeypress = function (evt) {
                //    var str = keyPressed(evt);
                //    if (str == 'j') {
                //        video.currentTime += skip(-5);
                //        $this.logVideo(videoElement.currentTime);
                //    }
                //    if (str == 'k') {
                //        if (videoElement.paused) {
                //            videoElement.play();
                //        }
                //        else {
                //            videoElement.pause();
                //        }

                //        $this.logVideo(videoElement.currentTime);
                //    }
                //    if (str == 'l') {
                //        video.currentTime += skip(5);
                //        $this.logVideo(videoElement.currentTime);
                //    }
                //    if (true) {
                //        vid.volume = 0.2;
                //    }
                //};

                //function skip(value) {
                //    videoElement.currentTime += value;
                //}

                //function diminuiVolume(value) {
                //    if ((rad.volume - value) >= 0)
                //        rad.volume -= value;
                //}

                //function aumentaVolume(value) {
                //    if ((videoElement.volume + value) <= 1)
                //        videoElement.volume += value;
                //}

                function disableselect() {
                    return false
                }

                function reEnable() {
                    return true
                }

                //if IE4+
                document.onselectstart = new Function("return false")
                document.oncontextmenu = new Function("return false")
                //if NS6
                if (window.sidebar) {
                    document.onmousedown = disableselect
                    document.onclick = reEnable
                }
            },

            close() {
                this.$refs['modal-video'].hide();
            },

            urlVideoExterno() {
                var url = '';
                var video = this.video.src;
                if (video.includes('vimeo.com')) {
                    let src = video.replace("https://vimeo.com/", "");
                    url = 'https://player.vimeo.com/video/' + src
                } else if (video.includes('youtube')) {
                    let src = video.replace("https://www.youtube.com/watch?v=", "");
                    if (src.indexOf("&") > 0) {
                        src = src.substring(0, src.indexOf("&"));
                    }
                    url = 'https://www.youtube.com/embed/' + src
                } else if (video.includes('youtu.be')) {
                    let src = video.replace("https://youtu.be/", "");
                    if (src.indexOf("?") > 0) {
                        src = src.substring(0, src.indexOf("?"));
                    }
                    url = 'https://www.youtube.com/embed/' + src
                } else {
                    url = video;
                }

                return url;
            },

        },
        created: function () {
            
        },
        updated: function () {

        },
        watch: {
            showModal: function () {
                this.$refs['modal-video'].show();
                this.load();
            },
        }

    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    /* ModalVideo*/

    .video-player {
        max-width: 100%;
        height: auto;
        max-height: 600px;
    }
</style>

