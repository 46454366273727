<template>
    <section class="Penalidade">
        <Header></Header>
        <div class="container">
            <div class="row my-3">
                <div class="col-12 text-center">
                    <p class="txt-p txt-size-20 txt-banco_penalidades mb-0">
                        Sollicita <span class="txt-w-7">Banco de Penalidades</span>
                    </p>
                </div>
                <div class="col-12 text-end">
                    <ManualFerramenta :name="'Manual Banco de Penalidades'" :btnClass="'btn-banco_penalidades'" :nameBtn="'Manual'" :urlPdf="'/manuais/FERRAMENTA BANCO DE PENALIDADES PDF.pdf'"></ManualFerramenta>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="box-branco">
                        <div class="row mb-3">
                            <div class="input-group">
                                <span class="input-group-text bg-white border-banco_penalidades" id="dsTermo"><i class="fas fa-search"></i></span>
                                <input type="text" class="form-control border-banco_penalidades" ref="dsTermo" style="border-left: unset;" placeholder="Pequise por Fornecedor" aria-label="dsTermo" aria-describedby="dsTermo" v-model="pesquisa.dsTermo">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-md-8">
                                        <div class="row">
                                            <div class="col-12 col-sm-4">
                                                <div class="h-100 d-flex align-items-center justify-content-center">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input check-cpf-cpnj" v-model="tpPesquisa" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="CNPJ">
                                                        <label class="form-check-label" for="inlineRadio1">CNPJ</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input check-cpf-cpnj" v-model="tpPesquisa" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="CPF">
                                                        <label class="form-check-label" for="inlineRadio2">CPF</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-8">
                                                <div v-show="tpPesquisa == 'CNPJ'">
                                                    <label for="nrCnpjFornecedor" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-3 mb-1 ps-2">CNPJ</label>
                                                    <vue-mask class="form-control"
                                                              id="dados_nrCnpj"
                                                              v-model="pesquisa.nrCnpjFornecedor"
                                                              mask="00.000.000/0000-00"
                                                              maxlength="18"
                                                              :raw="false"
                                                              :options="{placeholder: 'CNPJ'}" required>
                                                    </vue-mask>
                                                </div>
                                                <div v-show="tpPesquisa == 'CPF'">
                                                    <label for="nrCnpjFornecedor" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-3 mb-1 ps-2">CPF</label>
                                                    <vue-mask class="form-control"
                                                              id="dados_nrCpf"
                                                              v-model="pesquisa.nrCpfFornecedor"
                                                              mask="000.000.000-00"
                                                              maxlength="14"
                                                              :raw="false"
                                                              :options="{placeholder: 'CPF'}" required>
                                                    </vue-mask>
                                                </div>
                                                
                                            </div>

                                        </div>
                                        
                                        
                                    </div>
                                    <div class="col-12 col-md-4 mt-2 mt-sm-0">
                                        <label for="uf" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-3 mb-1 ps-2">Selecione a UF:</label>
                                        <select class="form-select" aria-label="nmEstado" v-model="pesquisa.nmEstado">
                                            <option value="">Selecione</option>
                                            <option :value="item" v-for="(item,index) in lstEstados" :key="index">{{item}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row mt-3">
                            <div class="col-12">
                                <button class="btn btn-banco_penalidades w-100" @click="buscar">
                                    Buscar
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <p class="txt-p txt-size-20 txt-grey-6 txt-w-7">
                        Resultados da pesquisa: <span class="txt-italic txt-w-4 txt-grey-3">“{{(dataAjax && dataAjax.dsTermo?dataAjax.dsTermo:'-')}}”</span>
                    </p>
                </div>
                <template v-if="!flResultados">
                    <div class="col-12">
                        <div class="bg-white rounded p-3 text-center">
                            <p class="txt-p txt-size-16 txt-w-7 txt-grey-3 mb-0">
                                Use o menu acima para pesquisar por fornecedores. <span class="txt-w-4">Os resultados aparecerão aqui.</span>
                            </p>
                        </div>
                    </div>
                </template>
                <template>
                    <Grid url_ajax="/BancoPenalidades/Get" :is_table="false" :reload="reload" :ajax_set_result="setBancoPenalidades" :data_ajax="getDataAjax()">
                        <template v-slot:rows="{ rows }">
                            <div v-if="rows.length == 0 && flResultados">
                                <div class="alert alert-danger mt-3" role="alert">
                                    Nenhum Registro Encontrado...
                                </div>
                            </div>
                            <div v-else>
                                <div class="" v-for="(item,index) in rows" :key="index">
                                    <router-link class="item-penalidade mb-3 c-pointer" :to="{name: 'DetalhePenalidade',params: { penalidade: item, pesquisa: pesquisa, idPesquisaPenalidade: idPesquisaPenalidade}}">
                                        <div class="row">
                                            <div class="col-12 mb-2">
                                                <div class="titulo-penalidade">
                                                    <p class="txt-p txt-size-18 txt-w-7 txt-banco_penalidades mb-0">
                                                        Quantidade Total de Penalidades: {{item.numPenalidade}}
                                                    </p>
                                                    <p class="txt-p txt-size-16 txt-w-4 txt-banco_penalidades mb-0">
                                                        {{item.cnpjRazaoSocial}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>

                        </template>
                    </Grid>
                </template>
            </div>
        </div>
        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';
    import Grid from '@/components/Grid';
    import ManualFerramenta from '@/components/ManualFerramenta';

    import { avisoErro } from '@/config/global'
    import vueMask from 'vue-jquery-mask'
    //import moment from 'moment'
    export default {
        name: 'Penalidade',
        components: { Header, Footer, Grid, vueMask, ManualFerramenta },
        computed: {
            user() {
                return this.$store.state.user
            },

        },
        data() {
            return {
                
                pesquisa: {
                    dsTermo: '',
                    nrCnpjFornecedor: '',
                    nrCpfFornecedor: '',
                    nmEstado: '',
                },

                tpPesquisa: 'CNPJ',

                lstEstados: [
                    "AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO",
                ],

                lstResultados: [],
                flResultados: false,

                reload: 0,
                dataAjax: null,

                idPesquisaPenalidade: null,
            }
        },
        methods: {
            load() {
                //let $this = this;
            },

            buscar() {
                let $this = this;
                if ($this.validarBuscar()) {
                    $this.dataAjax = Object.assign({}, $this.pesquisa);
                    $this.reload = $this.reload + 1;
                }
            },

            setBancoPenalidades(data) {
                scroll(100, 100);
                var rows = data.rows;
                let lista = (rows.resultado ? rows.resultado :[]);
                this.lstResultados = lista;
                this.flResultados = true;
                this.idPesquisaPenalidade = data.idPesquisaPenalidade;

                return this.lstResultados;
            },

            getDataAjax() {
                return this.dataAjax;
            },

            validarBuscar() {
                let $this = this;
                if ($this.pesquisa.dsTermo == null || $this.pesquisa.dsTermo == '') {
                    if ($this.tpPesquisa == 'CPF' && ($this.pesquisa.nrCpfFornecedor == null || $this.pesquisa.nrCpfFornecedor == '')) {
                        avisoErro('Pesquise por Fornecedor, CPF ou CNPK');
                        //this.$refs.dsTermo.$el.focus()
                        return false;
                    } else {
                        if ($this.tpPesquisa == 'CNPJ' && ($this.pesquisa.nrCnpjFornecedor == null || $this.pesquisa.nrCnpjFornecedor == '')) {
                            avisoErro('Pesquise por Fornecedor, CPF ou CNPJ');
                            //this.$refs.dsTermo.$el.focus()
                            return false;
                        }
                    }
                }else{
                    if ($this.pesquisa.dsTermo.length < 3) {
                        avisoErro('O campo "Fornecedor" deve conter ao menos 3 caracteres');
                        //this.$refs.dsTermo.$el.focus()
                        return false;
                    }
                }
                                                 
                return true;
            },
                        
        },
        mounted: function () {
            this.load();
        },
        watch: {
            tpPesquisa: function () {
                if (this.pesquisa == 'CNPJ') {
                    this.pesquisa.nrCpfFornecedor = '';
                } else {
                    this.pesquisa.nrCnpjFornecedor = '';
                }
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    .box-branco {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }

    .border-banco_penalidades {
        border-color: #F52628;
    }

    .check-cpf-cpnj {
        margin-top: 5px !important;
    }

    .check-cpf-cpnj:checked {
        background-color: #F52628;
        border-color: #F52628;
    }

    .item-penalidade {
        display: flex;
        background: #FFFFFF;
        border: 1px solid #F52628;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 20px;

        .titulo-penalidade {
            padding-left: 10px;
            border-left: 10px solid #F52628;
        }
    }

    .paginate .paginate-buttons {
        display: flex;
        justify-content: space-between;
    }

    .Penalidade {
        .grid-app {
            .btn-navegacao {
                background-color: #F52628 !important;
                border: 1px solid #ccc;
                border-radius: 3px;
                font-weight: 600;
                padding: 7px 10px;
                color: #FFF !important;
            }
        }
    }
    
    
</style>

