<template>
    <section class="Fornecedor">
        <Header></Header>
        <div class="container">
            <div class="row my-3">
                <div class="col-12 text-center">
                    <p class="txt-p txt-size-20 txt-fornecedores mb-0">
                        Sollicita <span class="txt-w-7">Fornecedores</span>
                    </p>
                </div>
                <div class="col-12 text-end">
                    <ManualFerramenta :name="'Manual Fornecedores'" :btnClass="'btn-fornecedores'" :nameBtn="'Manual'" :urlPdf="'/manuais/FERRAMENTA FORNECEDORES PDF.pdf'"></ManualFerramenta>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="box-branco">
                        <div class="row mb-3">
                            <div class="input-group">
                                <span class="input-group-text bg-white border-fornecedores" id="dsTermo"><i class="fas fa-search"></i></span>
                                <input type="text" class="form-control border-fornecedores" ref="dsTermo" style="border-left: unset;" placeholder="Pequise por produto ou serviço" aria-label="dsTermo" aria-describedby="dsTermo" v-model="pesquisa.dsTermo">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-9 col-lg-11">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4">
                                        <label for="nmFornecedor" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-3 mb-1 ps-2">Fornecedor</label>
                                        <input class="form-control" ref="nmFornecedor" type="text" aria-describedby="nmFornecedor" placeholder="Fornecedor" maxlength="50" v-model="pesquisa.nmFornecedor">
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4">
                                        <label for="nrCnpjFornecedor" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-3 mb-1 ps-2">CNPJ</label>
                                        <vue-mask class="form-control"
                                                  id="dados_nrCnpj"
                                                  v-model="pesquisa.nrCnpjFornecedor"
                                                  mask="00.000.000/0000-00"
                                                  maxlength="18"
                                                  :raw="false"
                                                  :options="options" required>
                                        </vue-mask>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4">
                                        <label for="uf" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-3 mb-1 ps-2">Selecione a UF:</label>
                                        <select class="form-select" aria-label="nmEstado" v-model="pesquisa.nmEstado">
                                            <option value="">Selecione</option>
                                            <option :value="item" v-for="(item,index) in lstEstados" :key="index">{{item}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 col-lg-1 d-flex flex-wrap align-content-end">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="pesquisa.chkProduto">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Produto
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="pesquisa.chkServico">
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Serviço
                                    </label>
                                </div>
                            </div>

                        </div>

                        <div class="row mt-3">
                            <div class="col-12">
                                <button class="btn btn-fornecedores w-100" @click="buscar">
                                    Buscar
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <p class="txt-p txt-size-20 txt-grey-6 txt-w-7">
                        Resultados da pesquisa: <span class="txt-italic txt-w-4 txt-grey-3">“{{(dataAjax && dataAjax.dsTermo?dataAjax.dsTermo:'-')}}”</span>
                    </p>
                </div>
                <template v-if="!flResultados">
                    <div class="col-12">
                        <div class="bg-white rounded p-3 text-center">
                            <p class="txt-p txt-size-16 txt-w-7 txt-grey-3 mb-0">
                                Use o menu acima para pesquisar por produtos, serviços e fornecedores. <span class="txt-w-4">Os resultados aparecerão aqui.</span>
                            </p>
                        </div>
                    </div>
                </template>
                <template>
                    <Grid url_ajax="/Fornecedor/Get" :is_table="false" :reload="reload" :ajax_set_result="setFornecedores" :data_ajax="getDataAjax()">
                        <template v-slot:rows="{ rows }">
                            <div v-if="rows.length == 0 && flResultados">
                                <div class="alert alert-danger mt-3" role="alert">
                                    Nenhum Registro Encontrado...
                                </div>
                            </div>
                            <div v-else>
                                <div class="" v-for="(item,index) in rows" :key="index">
                                    <router-link class="item-fornecedor mb-3 c-pointer" :to="{name: 'DetalheFornecedor',params: { fornecedor: item, pesquisa: pesquisa, idPesquisaFornecedor: idPesquisaFornecedor}}">
                                        <div class="icone-fornecedor d-flex flex-wrap align-content-center justify-content-center">
                                            <template v-if="item.IdCatMat != 0">
                                                <span class="txt-white">
                                                    <i class="fas fa-box fa-3x"></i>
                                                </span>
                                                <p class="txt-p txt-size-16 txt-w-7 txt-white mb-0">
                                                    Produto
                                                </p>
                                            </template>
                                            <template v-else>
                                                <span class="txt-white">
                                                    <i class="fas fa-wrench fa-3x"></i>
                                                </span>
                                                <p class="txt-p txt-size-16 txt-w-7 txt-white mb-0">
                                                    Serviço
                                                </p>
                                            </template>

                                        </div>
                                        <div class="detalhes-fornecedor w-90 d-flex flex-wrap align-content-center">
                                            <div class="w-100 mb-2">
                                                <p class="txt-p txt-size-12 txt-w-4 txt-grey-5 mb-0">
                                                    Produto
                                                </p>
                                                <p class="txt-p txt-size-16 txt-w-7 txt-fornecedores mb-0">
                                                    {{item.descricao}}
                                                </p>
                                            </div>
                                            <div class="w-100">
                                                <p class="txt-p txt-size-12 txt-w-4 txt-grey-5 mb-0">
                                                    Fornecedores
                                                </p>
                                                <p class="txt-p txt-size-16 txt-w-7 txt-fornecedores mb-0">
                                                    {{item.countItens}} fornecedores encontrados
                                                </p>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>

                        </template>
                    </Grid>
                </template>
            </div>
        </div>
        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';
    import Grid from '@/components/Grid';
    import ManualFerramenta from '@/components/ManualFerramenta';

    import { avisoErro, cnpjValidation } from '@/config/global'
    import vueMask from 'vue-jquery-mask'
    //import moment from 'moment'
    export default {
        name: 'Fornecedor',
        components: { Header, Footer, Grid, vueMask, ManualFerramenta },
        computed: {
            user() {
                return this.$store.state.user
            },

        },
        data() {
            return {
                options: {
                    placeholder: 'CNPJ',
                },

                pesquisa: {
                    dsTermo: '',
                    nmFornecedor: '',
                    nrCnpjFornecedor: '',
                    nmEstado: '',
                    chkProduto: true,
                    chkServico: true,
                },
                nmFornecedor: '',

                lstEstados: [
                    "AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO",
                ],

                lstResultados: [],
                flResultados: false,

                reload: 0,
                dataAjax: null,

                idPesquisaFornecedor: null,
            }
        },
        methods: {
            load() {
                //let $this = this;
            },

            buscar() {
                let $this = this;
                if ($this.validarBuscar()) {
                    $this.dataAjax = Object.assign({}, $this.pesquisa);
                    $this.reload = $this.reload + 1;
                }
            },

            setFornecedores(data) {
                scroll(100, 100);
                var rows = data.rows;
                let lista = (rows.resultado ? rows.resultado :[]);
                this.lstResultados = lista;
                this.flResultados = true;
                this.idPesquisaFornecedor = data.idPesquisaFornecedor;

                return this.lstResultados;
            },

            getDataAjax() {
                return this.dataAjax;
            },

            validarBuscar() {
                let $this = this;
                if ($this.pesquisa.dsTermo == null || $this.pesquisa.dsTermo.length < 3) {
                    avisoErro('O campo "Busca" deve conter ao menos 3 caracteres');
                    //this.$refs.dsTermo.$el.focus()
                    return false;
                }

                if ($this.pesquisa.nmFornecedor != null && $this.pesquisa.dsTermo.length < 3) {
                    avisoErro('O campo "Fornecedor" deve conter ao menos 3 caracteres');
                    //this.$refs.nmFornecedor.$el.focus()
                    return false;
                }

                if (($this.pesquisa.nrCnpjFornecedor.length < 18) && ($this.pesquisa.nrCnpjFornecedor.length > 0)) {
                    avisoErro('O campo "CNPJ" deve conter 18 caracteres');
                    //this.$refs.nrCnpjFornecedor.$el.focus()
                    return false;
                }
                else if ((!cnpjValidation($this.pesquisa.nrCnpjFornecedor)) && ($this.pesquisa.nrCnpjFornecedor.length > 0)) {
                    avisoErro('O "CNPJ" é inválido');
                    //this.$refs.nrCnpjFornecedor.$el.focus()
                    return false;
                }

                return true;
            },
                        
        },
        mounted: function () {
            this.load();
        },
        watch: {

        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    .box-branco {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }

    .border-fornecedores {
        border-color: #1ABDAF;
    }

    .item-fornecedor {
        display: flex;
        background: #FFFFFF;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        border-radius: 10px;

        .icone-fornecedor {
            background: #00B6A6;
            /*box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);*/
            border-radius: 10px;
            width: 10%;
            padding: 30px;
        }

        .detalhes-fornecedor {
            padding: 20px;
            /*box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);*/
            background: #FFFFFF;
            border-radius: 0 10px 10px 0;
        }
    }

    .paginate .paginate-buttons {
        display: flex;
        justify-content: space-between;
    }

    .Fornecedor {
        .grid-app {
            .btn-navegacao {
                background-color: #00B6A6 !important;
                border: 1px solid #ccc;
                border-radius: 3px;
                font-weight: 600;
                padding: 7px 10px;
                color: #FFF !important;
            }
        }
    }
    
    

</style>

