<template>
    <div class="viewORIJU">
        <div class="modal-conteudo m-2">
            <div id="divImprimir">
                <div class="row">
                    <div class="col-md-12" v-if="dados.orientacao">
                        <h3 class="bold">{{dados.orientacao.dsAssunto}}</h3>
                        <template v-if="dados.orientacao.tpOrientacao == 'PA'">
                            <p class="bold italic bold-italic mb-4" style="font-weight:bold !important; font-style:italic;">Estudo Técnico</p>
                        </template>
                        <template v-else>
                            <p class="bold italic bold-italic mb-4" style="font-weight:bold !important; font-style:italic;">Resposta Objetiva</p>
                        </template>
                        
                        <span class="regular" v-html="dados.orientacao.dsEmenta.replace('\r\n','<br />')"></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer pb-0 mt-4 d-flex justify-content-end">
            <AdicionarBiblioteca v-if="exibeBiblioteca" :id="dados.orientacao.idOrientacao.toString()" :tipo="tpConteudo" :cdConteudo="cdConteudo"></AdicionarBiblioteca>
            
            <a id="click" class="btn btn-blue txt-size-14" target="_blank" :href="formatLinkPdf(dados.orientacao.urlArquivo)">
                <i class="fas fa-eye "></i> Visualizar
            </a>
        </div>
    </div>
</template>

<script>
    import AdicionarBiblioteca from '../AdicionarBiblioteca'
    import { urlApi, urlLeitorPDF } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'viewORIJU',
        components: { AdicionarBiblioteca },
        data: function () {
            return {
                urlLeitorPDF: urlLeitorPDF,
                exibeBiblioteca: true,
                tpConteudo: 'Pesquisa',
                cdConteudo: 'ORIJU',
            }
        },
        props: {
            dados: Object,
        },
        methods: {
            verificaExisteRepositorio(idConteudo, tpConteudo, cdConteudo) {
                let $this = this;
                axios.get(`${urlApi}/Repositorio`, { params: { idConteudo: idConteudo, tpConteudo: tpConteudo, cdConteudo: cdConteudo } })
                    .then(res => {
                        if (res.data.success) {
                            $this.exibeBiblioteca = res.data.data;
                        } else {
                            console.log('Não foi possível verificar o repositório')
                        }
                    })
                    .catch(function () {
                        console.log('Não foi possível verificar o repositório')
                    });
            },

            imprimir() {
                var conteudo = document.getElementById('divImprimir').innerHTML,
                    tela_impressao = window.open('about:blank');

                tela_impressao.document.write(conteudo);
                tela_impressao.window.print();
                tela_impressao.window.close();
            },

            formatLinkPdf(urlArquivo) {
                return this.urlLeitorPDF +'/'+ encodeURIComponent(urlArquivo) ;
            },
        },
        mounted: function () {
            this.verificaExisteRepositorio(this.dados.orientacao.idOrientacao.toString(), this.tpConteudo, this.cdConteudo);
        },
        watch: {
            dados: {
                handler() {
                    this.verificaExisteRepositorio(this.dados.orientacao.idOrientacao.toString(), this.tpConteudo, this.cdConteudo);
                },
                deep: true
            },
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    
   
</style>

