<template>
    <div class="ManualFerramenta">
        <button :class="'btn btn-manual '+(btnClass)" @click="togleModal" v-if="interna">
            {{nameBtn}}
        </button>
        <p v-else @click="togleModal" class="btn btn-manual-interno txt-h-5 txt-size-14 txt-white txt-w-7 txt-letter-n-2 mb-0 position-absolute" style="top: 3px; right: 3px;">
            <i class="fas fa-book"></i>
        </p>
        <b-modal @backdrop="hideModalFn" ref="modal-manual-ferramenta" id="modal-manual-ferramenta" :size="size" :hide-footer="true" :hide-header="true">
            <div class="w-100 text-end txt-size-20 txt-blue-2">
                <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <form class="px-3 pb-3" id="">

                <div class="row">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-size-25 txt-w-bold mb-2">
                            {{name}}
                        </span>
                        <p class="txt-p txt-size-16 txt-w-5 txt-grey-2">
                            
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <embed :src="urlPdf" type="application/pdf" width="100%" height="650px" />
                    </div>

                </div>

            </form>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: 'Modal',
        props: {            
            titulo: String,
            interna: {
                type: Boolean,
                default: true
            },
            size: {
                type: String,
                default: 'xl'
            },
            name: {
                type: String,
                default: 'Manual'
            },
            btnClass: {
                type: String,
                default: ''
            },
            nameBtn: {
                type: String,
                default: 'Manual'
            },
            urlPdf: {
                type: String,
                default: 'manual-usuario.pdf'
            },
        },
        data: function () {
            return {
                showModal: false,
            }
        },
        methods: {
            hideModalFn: function () {
                this.$refs['modal-manual-ferramenta'].hide();
                this.showModal = false;
            },

            togleModal: function () {
                if (this.showModal) {
                    this.hideModalFn();
                    this.showModal = false;
                    
                }else {
                    this.$refs['modal-manual-ferramenta'].show();
                    this.showModal = true;
                }
                
            },
        },
        mounted() {
            let $this = this;
            $this.$root.$on('bv::modal::hide', function(){
                $this.showModal = false;
            });
        },
        watch: {

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .modal-body {
        padding: 20px;
    }

    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #034471;
        justify-content: space-between;
    }

    .btn-manual{
        font-size: 12px;
        padding: 5px 10px;
    }

    .btn-manual-interno {
        padding: 5px 10px;
        background: rgb(0,0,0,0.5);
        display: none;
    }

    .box-modulo:hover {
        .btn-manual-interno {
            display: block;
        }
    }
</style>

