<template>
    <section class="ContadorPrazos">
        <Header></Header>

        <div class="container">
            <div class="row my-3">
                <div class="col-12 text-center">
                    <p class="txt-p txt-size-20 txt-contador_prazos mb-0">
                        Sollicita <span class="txt-w-7">Contador de Prazos</span>
                    </p>
                </div>
                <div class="col-12 text-end">
                    <ManualFerramenta :name="'Manual Contador de Prazos'" :btnClass="'btn-contador_prazos-outline'" :nameBtn="'Manual'" :urlPdf="'manuais/FERRAMENTA CONTADOR DE PRAZOS PDF.pdf'"></ManualFerramenta>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="box-branco">
                        <div class="row">
                            <div class="col-6 col-md-9">
                                <button class="btn btn-contador_prazos w-100 h-100" @click="novoProcesso">
                                    <i class="fas fa-plus"></i> Adicionar processo
                                </button>
                            </div>
                            <div class="col-6 col-md-3">
                                <router-link class="btn btn-contador_prazos-outline w-100 h-100" :to="{name:'ConfigurarPrazos'}">
                                    <i class="fas fa-cog"></i> Configurações
                                </router-link>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <p class="txt-p txt-grey-6 txt-size-20 txt-w-5">
                                    Busque por processos:
                                </p>
                            </div>
                            <div class="col-6">
                                <select class="form-select" aria-label="Selecione uma modalidade" v-model="idModalidade">
                                    <option class="d-none" value="0" selected disabled>Selecione uma modalidade</option>
                                    <option :value="item.idModalidade" v-for="item in lstModalidades" :key="item.idModalidade">{{item.nmModalidade}}</option>
                                </select>
                            </div>
                            <div class="col-3">
                                <date-picker class="w-100 filtro-data" v-model="dtPublicacao" format="DD/MM/YYYY" value-type="format" placeholder="Data da sessão"></date-picker>
                            </div>
                            <div class="col-3">
                                <input type="text" class="form-control" v-model="nrEdital" id="numero" aria-describedby="Número do edital" placeholder="Número do edital">
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <button class="btn btn-contador_prazos w-100" @click="carregaProcessos">
                                    Buscar
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="container my-4">
            <div class="row">
                <div class="col-12">
                    <p class="txt-p txt-w-7 txt-size-20 txt-grey-6">
                        Processos criados
                    </p>
                </div>
            </div>
            <div class="row my-3" v-for="(item,index) in lstContadorPrazo" :key="index">
                <div class="col-12">
                    <div class="item-processo">
                        <div class="row mb-3">
                            <div class="col-6">
                                <p class="txt-p txt-w-7 txt-size-16 txt-grey-3  mb-0">
                                    {{item.dsEdital}}
                                </p>
                            </div>
                            <div class="col-6 text-end">
                                <p class="txt-p txt-w-7 txt-size-16 txt-grey-3 mb-0">
                                    Status: <span class="txt-grey-6">{{item.dsEstiloPrazo}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="divisor w-100"></div>
                        <div class="row mt-4">
                            <div class="col-12 col-md-9 d-flex flex-column justify-content-center">
                                <p class="txt-p txt-grey-3 txt-size-16 txt-w-5">
                                    Número do edital: <span class="txt-w-4"> {{item.nrEdital}} </span>
                                </p>
                                <p class="txt-p txt-grey-3 txt-size-16 txt-w-5">
                                    Modalidade: <span class="txt-w-4"> {{item.nmModalidade}} </span>
                                </p>
                                <p class="txt-p txt-grey-3 txt-size-16 txt-w-5">
                                    Data sessão: <span class="txt-w-4"> {{item.dtPublicacao}} </span>
                                </p>
                                <p class="txt-p txt-grey-3 txt-size-16 txt-w-5" v-if="user.usuario.dsLogin != item.dsLogin">
                                    Autor: <span class="txt-w-4"> {{item.dsLogin}} </span>
                                </p>
                                <p class="txt-p txt-grey-3 txt-size-16 txt-w-5">
                                    Próximo prazo: <span class="txt-w-4"> {{item.dtPrazo}} </span> - <span :class="'txt-w-4 '+item.corEstiloPrazo">{{item.dsPrazo}}</span>
                                </p>
                            </div>
                            <div class="col-12 col-md-3 d-flex flex-column justify-content-center">
                                <template v-if="user.usuario.dsLogin == item.dsLogin">
                                    <div class="row mb-2">
                                        <div class="col-12">
                                            <router-link class="btn btn-visualizar w-100" :to="{name: 'DetalheProcesso',params: { id: item.idContagemPrazo }}">
                                                <i class="fas fa-eye"></i> Visualizar prazos
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-6">
                                            <button class="btn btn-editar w-100" @click="editar(item.idContagemPrazo)">
                                                <i class="fas fa-pencil-alt"></i> <span class="d-none d-sm-block">Editar</span>
                                            </button>
                                        </div>
                                        <div class="col-6">
                                            <button class="btn btn-remover w-100" @click="excluir(item.idContagemPrazo, item.dsEdital)">
                                                <i class="fas fa-trash-alt"></i> <span class="d-none d-sm-block">Remover</span>
                                            </button>
                                        </div>
                                    </div>
                                </template>

                                <div class="row">
                                    <div class="col-12">
                                        <button class="btn btn-relatorio w-100" @click="relatorio(item.idContagemPrazo)">
                                            <i class="fas fa-clipboard-list"></i> Relatório
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalProcesso :idProcesso="idProcesso" :showModal="showModalNovoProcesso" :atualizar="carregaProcessos" :lstModalidades="lstModalidades"></ModalProcesso>

        <ModalRelatorioProcesso v-if="idProcessoRelatorio" :idProcesso="idProcessoRelatorio"
                                :showModal="showModalRelatorioProcesso"></ModalRelatorioProcesso>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';
    import ModalProcesso from '@/components/ContadorPrazos/ModalProcesso';
    import ModalRelatorioProcesso from '@/components/ContadorPrazos/ModalRelatorioProcesso';
    import ManualFerramenta from '@/components/ManualFerramenta';

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/locale/pt-br';

    import { urlApi, avisoErro, avisoSucesso  } from '@/config/global'
    import axios from 'axios'
    import Swal from 'sweetalert2'
    //import moment from 'moment'
    export default {
        name: 'ContadorPrazos',
        components: { Header, Footer, DatePicker, ModalProcesso, ModalRelatorioProcesso, ManualFerramenta },
        computed: {
            user() {
                return this.$store.state.user
            },
        },
        data() {
            return {
                dtPublicacao: '',
                nrEdital: '',
                idModalidade: 0,

                lstModalidades: [],
                lstContadorPrazo: [],

                idProcesso: null,
                showModalNovoProcesso: false,

                idProcessoRelatorio: null,
                showModalRelatorioProcesso: false,
            }
        },
        methods: {
            load() {
                this.carregaModalidades();
                this.carregaProcessos();
            },

            novoProcesso() {
                this.idProcesso = null;
                this.showModalNovoProcesso = !this.showModalNovoProcesso;
            },

            editar(idContagemPrazo) {
                this.idProcesso = parseInt(idContagemPrazo);
                this.showModalNovoProcesso = !this.showModalNovoProcesso;
            },

            relatorio(idContagemPrazo) {
                this.idProcessoRelatorio = parseInt(idContagemPrazo);
                this.showModalRelatorioProcesso = !this.showModalRelatorioProcesso;
            },

            excluir(idContagemPrazo, dsEdital) {
                let $this = this;
                Swal.fire({
                    title: 'Tem certeza que deseja excluir "' + dsEdital + '"?',
                    text: 'Esta operação não poderá ser desfeita.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    confirmButtonColor: '#83A431',
                }).then((result) => {
                    if (result.isConfirmed) {
                        $this.remove(idContagemPrazo);
                    }
                })
            },

            remove(idContagemPrazo) {
                let $this = this;
                axios.delete(`${urlApi}/ContadorPrazo/Delete`, { params: { idContagemPrazo: idContagemPrazo } })
                    .then(res => {
                        if (res.data.success) {
                            $this.carregaProcessos();
                            avisoSucesso("Processo excluido com sucesso");
                        } else {
                            avisoErro("Não foi possível excluir o processo");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível excluir o processo");
                    });
            },

            carregaModalidades() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/GetModalidade`, { params: {} })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data.rows != null) {
                                $this.lstModalidades = res.data.data.rows;
                            }
                        } else {
                            avisoErro("Não foi possível carregar as modalidades");
                            $this.lstModalidades = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar as modalidades");
                        $this.lstModalidades = [];
                    });
            },

            carregaProcessos() {
                let $this = this;
                $this.nrEdital = ($this.nrEdital == null ? "" : $this.nrEdital);
                axios.get(`${urlApi}/ContadorPrazo/Get`, { params: { idModalidade: $this.idModalidade, dtPublicacao: $this.dtPublicacao, nrEdital: $this.nrEdital} })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data.rows != null) {
                                $this.lstContadorPrazo = res.data.data.rows;
                            }
                        } else {
                            avisoErro("Não foi possível carregar os processos");
                            $this.lstContadorPrazo = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os pr");
                        $this.lstContadorPrazo = [];
                    });
            },

        },
        mounted: function () {
            
            this.load();
        },
        watch: {
            
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .mx-input {
        height: 38px !important;
        border: 1px solid #ced4da;
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    .box-branco {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }

    .item-processo {
        background: #FFFFFF;
        border: 3px solid #A6A6A6;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 20px;

        .divisor {
            border-bottom: 1px solid #DADADA;
        }

        .current {
            color: #e68e03 !important;
            border-color: #e68e03 !important;
        }

        .next {
            color: #9bc339 !important;
            border-color: #9bc339 !important;
        }

        .old {
            color: #bfbfbf !important;
            border-color: #bfbfbf !important;
        }

        .btn-visualizar {
            background: #9BB854;
            padding: 5px;
            border: 1px solid;
            border-color: #9BB854;
            color: #FFFFFF;
            border-radius: 5px;
        }

        .btn-visualizar:hover {
            background: #9BB854;
        }

        .btn-editar {
            background: #7BBDD9;
            padding: 5px;
            border: 1px solid;
            border-color: #7BBDD9;
            color: #FFFFFF;
            border-radius: 5px;
        }

        .btn-remover {
            background: #D78E8E;
            padding: 5px;
            border: 1px solid;
            border-color: #D78E8E;
            color: #FFFFFF;
            border-radius: 5px;
        }

        .btn-relatorio {
            background: transparent;
            padding: 5px;
            border: 1px solid;
            border-color: #9BB854;
            color: #9BB854;
            border-radius: 5px;
        }
    }

</style>

