<template>
    <div class="modal">
        <b-modal :ref="name" :id="name" :size="size" :hide-footer="true" :hide-header="true">
            <div class="modal-body">
                <div :class="'d-flex txt-h-2 txt-blue-2 '+(titulo?'modal-titulo':' justify-content-end')">
                    <span class=" txt-w-20" v-if="titulo">{{titulo}}</span>
                    <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>

                </div>
                <div class="modal-conteudo">
                    <slot name="conteudo"></slot>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

    export default {
        name: 'Modal',
        props: {
            showModal: Boolean,
            hideModal: Boolean,
            titulo: String,
            size: {
                type: String,
                default: 'lg'
            },
            name: {
                type: String,
                default: 'modal-cadastro'
            }
        },
        data: function () {
            return {
               
            }
        },
        methods: {
            hideModalFn: function () {
                this.$refs[this.name].hide();
            },

        },
        watch: {
            showModal: function () {
                this.$refs[this.name].show();
            },

            hideModal: function () {
                this.hideModalFn();
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .modal-body {
        padding: 20px;
    }

    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #034471;
        justify-content: space-between;
    }
</style>

