<template>
    <div class="ModalFeriado">
        <b-modal ref="modal-feriado" id="ModalFeriado" size="lg" :hide-footer="true" :hide-header="true">
            <div class="modal-titulo txt-p txt-w-7 txt-size-20 txt-grey-2 m-2">
                <p class="txt-w-20 mb-0 txt-contador_prazos">{{titulo}}</p>
                <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <div class="modal-conteudo m-2 px-3">
                <form class="mt-2">
                    <div class="mb-3">
                        <label for="nmFeriado" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">Nome</label>
                        <div class="w-100">
                            <input class="form-control" type="text" id="nmFeriado" aria-describedby="nmFeriado" maxlength="50" placeholder="Nome" v-model="feriado.nmFeriado">
                        </div>
                        
                    </div>
                    <div class="mb-3">
                        <label for="dtFeriado" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">Data</label>
                        <div class="w-100">
                            <date-picker class="w-100" v-model="feriado.dtFeriado" format="DD/MM/YYYY" placeholder="Data" value-type="format"></date-picker>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer mt-3">
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-cancelar  me-3" type="button" @click="hideModalFn">
                            Cancelar
                        </button>
                        <button class="btn btn-contador_prazos px-4 py-2" type="button" @click="salvar" :disabled="validaSalvar">
                            Salvar <i class="fa fa-save"></i>
                        </button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template> 

<script>
    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/pt-br';

    export default {
        name: 'ModalFeriado',
        props: {
            idFeriado: Number,
            showModal: Boolean,
            atualizar: Function,
        },
        components: { DatePicker },
        data: function () {
            return {
                titulo: 'Adicionar feriado',

                feriado: {
                    nmFeriado: '',
                    dtFeriado: '',

                },
            }
        },
        computed: {
            validaSalvar() {
                let $this = this;
                
                if ($this.feriado.nmFeriado == null || $this.feriado.nmFeriado == '') {
                    return true;
                }

                if ($this.feriado.dtFeriado == null || $this.feriado.dtFeriado == '') {
                    return true;
                }

                return false;
            },
        },
        methods: {
            load() {
                this.$refs['modal-feriado'].show();

                this.feriado = {
                    nmFeriado: '',
                    dtFeriado: '',
                };

                if (this.idFeriado) {
                    this.titulo = 'Editar feriado';
                    this.carregaFeriado();
                }
            },

            carregaFeriado() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/ModalFeriado`, { params: { idFeriado: $this.idFeriado} })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data != null) {
                                var dados = res.data.data;
                                dados.dtFeriado = moment(dados.dtFeriado).format('DD/MM/YYYY');

                                $this.feriado = dados;
                            }
                        } else {
                            avisoErro("Não foi possível carregar o feriado");
                            $this.lstTipoApartir = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar o feriado");
                        $this.lstTipoApartir = [];
                    });
            },

            hideModalFn() {
                this.$refs['modal-feriado'].hide();
            },

            salvar() {
                let $this = this;
                var feriado = {
                    idFeriado: $this.feriado.idFeriado,
                    nmFeriado: $this.feriado.nmFeriado,
                    dtFeriado: moment($this.feriado.dtFeriado,'DD/MM/YYYY').format('YYYY-MM-DD 00:00:00'),
                };
                axios.post(`${urlApi}/ContadorPrazo/PostFeriado`, feriado, { showLoading: true })
                    .then(res => {
                        if (res.data.success) {
                            $this.atualizar();
                            avisoSucesso("Feriado salvo com sucesso.");
                            $this.hideModalFn();
                        } else {
                            avisoErro("Não foi possível salvar o feriado");
                        }
                    })
                    .catch(res => {
                        avisoErro(res.response.data.errors[0]);
                    });
            },
        },
        mounted: function () {
        },
        watch: {
            showModal: function () {
                this.load();
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #DADADA;
        display: flex;
        justify-content: space-between;
    }

    .modal-conteudo {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 70vh;
    }

</style>

