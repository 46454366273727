<template>
    <section class="homelogado">
        <Header></Header>

        <div class="container mt-4">
            <Ferramentas class="mt-5"></Ferramentas>
        </div>

        <Footer class="mt-5"></Footer>

    </section>
</template>

<script>
    import Header from '@/components/Header'
    import Footer from '@/components/Footer'
    import Ferramentas from '@/components/Ferramentas'

    //import { urlApi, avisoErro } from '@/config/global'
    //import axios from 'axios'

    export default {
        name: 'MinhasFerramentas',
        components: { Header, Footer, Ferramentas },
        data() {
            return {

                
            }
        },
        methods: {
           
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    

</style>

