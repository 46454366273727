<template>
    <div class="ModalConfiguracaoGeral">
        <b-modal ref="modal-configuracaogeral" id="ModalConfiguracaoGeral" size="xl" :hide-footer="true" :hide-header="true">
            <div class="modal-titulo txt-p txt-w-7 txt-size-20 txt-grey-2 m-2">
                <p class="txt-w-20 mb-0">{{titulo}} <span class="txt-w-4 txt-size-14" v-if="configuracao">- {{configuracao.nmModalidade}}</span></p>
                <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>
                
                
            </div>
            <div class="modal-conteudo m-2" style="overflow: auto;">
                <div class="row">
                    <p class="d-block d-sm-none txt-p txt-size-12 txt-grey-2">
                        * devido ao tamanho da tela do dispositivo é gerada a rolagem horizontal da tabela de configuração para melhor visualização.
                    </p>
                </div>
                <table class="table" style="min-width: 900px;">
                    <thead class="d-block">
                        <tr class="d-block txt-p txt-w-7 txt-grey-2">
                            <th class="d-inline-block text-center py-2 w-20">Etapa</th>
                            <th class="d-inline-block text-center py-2 w-10">Qtd</th>
                            <th class="d-inline-block text-center py-2 w-10">Período</th>
                            <th class="d-inline-block text-center py-2 w-20">Contagem</th>
                            <th class="d-inline-block text-center py-2 w-20">A Partir</th>
                            <th class="d-inline-block text-center py-2 w-10">Início</th>
                            <th class="d-inline-block text-center py-2 w-10">Termino</th>
                        </tr>
                    </thead>
                    <tbody class="d-block w-100">
                        <template v-if="configuracao == null || configuracao.lstGridEtapaConfiguracao == undefined">
                            <tr class="d-block w-100 text-center">
                                <td colspan="7" class="d-block w-100">
                                    <span id="semRegistros">Nenhum Registro Encontrado...</span>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr v-for="(item,index) in configuracao.lstGridEtapaConfiguracao" :key="index">
                                <td class="w-20 text-center">
                                    <span v-if="item.flEvento == 'Sim'" class="txt-p txt-w-7 txt-size-16 txt-grey-2">Evento</span>
                                    <span v-else-if="item.flProcedimento == 'Sim'" class="txt-p txt-w-7 txt-size-16 txt-grey-2">Procedimento</span>
                                    <p class="txt-p txt-w-4 txt-size-16 txt-grey-2">{{item.nmEtapa}}</p>

                                    <input type="hidden" :id="'txt_idEtapa-'+item.idEtapa" :name="'txt_idEtapa-'+item.idEtapa" :value="item.idEtapa" />

                                    <input type="hidden" :id="'txt_idEtapaConfiguracao-'+item.idEtapa" :name="'txt_idEtapaConfiguracao-'+item.idEtapa" :value="item.idEtapaConfiguracao" />

                                    <input type="hidden" :id="'txt_nrOrdem-'+item.idEtapa" :name="'txt_nrOrdem-'+item.idEtapa" :value="item.nrOrdem" />
                                </td>

                                <td class="w-10">
                                    <input type="number" :id="'txt_qtdContagem-'+item.idEtapa" :name="'txt_qtdContagem-'+item.idEtapa" class="form-control" v-model="item.qtdContagem" />
                                </td>


                                <td class="w-10">
                                    <select :id="'ddl_TipoPeriodo-'+item.idEtapa" :name="'ddl_TipoPeriodo-'+item.idEtapa" class="form-control" v-model="item.idTipoPeriodo">
                                        <option value="null">Selecione...</option>
                                        <option v-for="(TipoPeriodico,index) in lstTipoPeriodo" :value="TipoPeriodico.idTipoPeriodo" :key="index" :selected="(TipoPeriodico.idTipoPeriodo == item.idTipoPeriodo?'selected':'')">
                                            {{TipoPeriodico.nmTipoPeriodo}}
                                        </option>
                                    </select>
                                </td>


                                <td class="w-20">
                                    <select :id="'ddl_TipoContagem-'+item.idEtapa" :name="'ddl_TipoContagem-'+item.idEtapa" class="form-control" v-model="item.idTipoContagem">
                                        <option value="null">Selecione...</option>
                                        <option v-for="(TipoContagem,index) in lstTipoContagem" :value="TipoContagem.idTipoContagem" :key="index" :selected="(TipoContagem.idTipoContagem == item.idTipoContagem?'selected':'')">
                                            {{TipoContagem.nmTipoContagem}}
                                        </option>
                                    </select>
                                </td>


                                <td class="w-20">
                                    <select :id="'ddl_TipoApartir-'+item.idEtapa" :name="'ddl_TipoApartir-'+item.idEtapa" class="form-control" v-model="item.idTipoApartir">
                                        <option value="null">Selecione...</option>
                                        <option v-for="(TipoApartir,index) in lstTipoApartir" :value="TipoApartir.idTipoApartir" :key="index" :selected="(TipoApartir.idTipoApartir == item.idTipoApartir?'selected':'')">
                                            {{TipoApartir.nmTipoApartir}}
                                        </option>
                                    </select>
                                </td>


                                <td class="w-10">
                                    <select id="ddl_TipoInicioContagem-@item.idEtapa" name="ddl_TipoInicioContagem-@item.idEtapa" class="form-control" v-model="item.idTipoInicioContagem">
                                        <option value="null">Selecione...</option>
                                        <option v-for="(TipoInicioContagem,index) in lstTipoInicioContagem" :value="TipoInicioContagem.idTipoInicioContagem" :key="index" :selected="(TipoInicioContagem.idTipoInicioContagem == item.idTipoInicioContagem?'selected':'')">
                                            {{TipoInicioContagem.nmTipoInicioContagem}}
                                        </option>
                                    </select>
                                </td>


                                <td class="w-10">
                                    <select id="ddl_TipoTerminoContagem-@item.idEtapa" name="ddl_TipoTerminoContagem-@item.idEtapa" class="form-control" v-model="item.idTipoTerminoContagem">
                                        <option value="null">Selecione...</option>
                                        <option v-for="(TipoTerminoContagem,index) in lstTipoTerminoContagem" :value="TipoTerminoContagem.idTipoTerminoContagem" :key="index" :selected="(TipoTerminoContagem.idTipoTerminoContagem == item.idTipoTerminoContagem?'selected':'')">
                                            {{TipoTerminoContagem.nmTipoTerminoContagem}}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer mt-3">
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-cancelar  me-3" type="button" @click="hideModalFn">
                            Cancelar
                        </button>
                        <button class="btn btn-contador_prazos px-4 py-2" type="button" @click="salvar">
                            Salvar <i class="fa fa-save"></i>
                        </button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template> 

<script>
    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'ModalConfiguracaoGeral',
        props: {
            idModalidade: Number,
            showModal: Boolean,
        },
        data: function () {
            return {
                componente: null,
                dataResult: null,

                titulo: 'Configurar',
                configuracao: null,

                lstTipoPeriodo: [],
                lstTipoContagem: [],
                lstTipoApartir: [],
                lstTipoInicioContagem: [],
                lstTipoTerminoContagem: [],
            }
        },
        methods: {
            load() {
                this.carregaTipoPeriodo();
                this.carregaTipoContagem();
                this.carregaTipoApartir();
                this.carregaTipoInicioContagem();
                this.carregaTipoTerminoContagem();
                this.carregaGridEtapaConfiguracao();
            },

            hideModalFn() {
                this.$refs['modal-configuracaogeral'].hide();
            },

            carregaGridEtapaConfiguracao() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/GetConfiguracaoGeral`, { params: { idModalidade: this.idModalidade } })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data != null) {
                                $this.configuracao = res.data.data;
                                this.$refs['modal-configuracaogeral'].show();
                            }
                        } else {
                            avisoErro("Não foi possível carregar a configuração");
                            $this.lstTipoContagem = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar a configuração");
                        $this.lstTipoContagem = [];
                    });
            },

            carregaTipoPeriodo() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/GetTipoPeriodo`, { params: {} })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data.rows != null) {
                                $this.lstTipoPeriodo = res.data.data.rows;
                            }
                        } else {
                            avisoErro("Não foi possível carregar os tipos de período ");
                            $this.lstTipoContagem = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os tipos de período");
                        $this.lstTipoContagem = [];
                    });
            },

            carregaTipoContagem() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/GetTipoContagem`, { params: {} })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data.rows != null) {
                                $this.lstTipoContagem = res.data.data.rows;
                            }
                        } else {
                            avisoErro("Não foi possível carregar os tipos de contagem ");
                            $this.lstTipoPeriodo = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os tipos de contagem");
                        $this.lstTipoPeriodo = [];
                    });
            },

            carregaTipoApartir() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/GetTipoApartir`, { params: {} })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data.rows != null) {
                                $this.lstTipoApartir = res.data.data.rows;
                            }
                        } else {
                            avisoErro("Não foi possível carregar os tipos");
                            $this.lstTipoApartir = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os tipos");
                        $this.lstTipoApartir = [];
                    });
            },

            carregaTipoInicioContagem() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/GetTipoInicioContagem`, { params: {} })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data.rows != null) {
                                $this.lstTipoInicioContagem = res.data.data.rows;
                            }
                        } else {
                            avisoErro("Não foi possível carregar os tipos início contagem");
                            $this.lstTipoInicioContagem = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os tipos início contagem");
                        $this.lstTipoInicioContagem = [];
                    });
            },

            carregaTipoTerminoContagem() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/GetTipoTerminoContagem`, { params: {} })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data.rows != null) {
                                $this.lstTipoTerminoContagem = res.data.data.rows;
                            }
                        } else {
                            avisoErro("Não foi possível carregar os tipos termino contagem");
                            $this.lstTipoTerminoContagem = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os tipos termino contagem");
                        $this.lstTipoTerminoContagem = [];
                    });
            },

            salvar() {
                let $this = this;

                //var lstDados = [];
                //for (let i = 0; i < $this.configuracao.lstGridEtapaConfiguracao.length; i++) {
                //    lstDados.push({
                        
                //    });
                //}

                axios.post(`${urlApi}/ContadorPrazo/PostConfiguracaoGeral`, $this.configuracao.lstGridEtapaConfiguracao, { showLoading: true })
                    .then(res => {
                        if (res.data.success) {
                            avisoSucesso("Configuração salva com sucesso.");
                            $this.hideModalFn();
                        } else {
                            avisoErro("Não foi possível salvar a configuração");
                        }
                    })
                    .catch(res => {
                        avisoErro(res.response.data.errors[0]);
                    });
            },

        },
        mounted: function () {
        },
        watch: {
            showModal: function () {
                this.load();
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #DADADA;
        display: flex;
        justify-content: space-between;
    }

    .modal-conteudo {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 70vh;
    }

    .table {
        tbody {
            border-top: none !important;
        }

        td {
            vertical-align: middle;
        }

        tr:nth-child(odd) {
            /*background-color: #E9E9E9;*/
        }

        tr:nth-child(even) {
            background-color: #F9F9F9;
        }

        th {
            border-bottom: none !important;
        }
    }

</style>

