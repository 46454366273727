<template>
    <div class="ModalAnotacao">
        <b-modal ref="modal-anotacao" id="ModalAnotacao" size="lg" :hide-footer="true" :hide-header="true">
            <div class="modal-titulo txt-p txt-w-7 txt-size-20 txt-grey-2 m-2">
                <p class="txt-w-20 mb-0 txt-contador_prazos">{{titulo}}</p>
                <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <div class="modal-conteudo m-2 px-3">
                <form class="mt-2">
                    <div class="mb-3">
                        <label for="NomeAnotacao" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                            1. Título:
                        </label>
                        <div class="w-100">
                            <input class="form-control" type="text" id="dsTitulo" aria-describedby="dsTitulo" placeholder="Nome do processo" maxlength="50" v-model="anotacao.dsTitulo">
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                            2. Anotação:
                        </label>
                        <div class="row">
                            <div class="col-12">
                                <textarea class="form-control" rows="5" maxlength="500" v-model="anotacao.dsAnotacao" placeholder="Escreva sua anotação"></textarea>
                            </div>
                            
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                            3. Data e Hora:
                        </label>
                        <div class="row">
                            <div class="col-6">
                                <label for="dtEtapa" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-5 mb-1 ps-2">Data</label>
                                <date-picker class="w-100" v-model="anotacao.dtAnotacao" format="DD/MM/YYYY" placeholder="Data"></date-picker>
                            </div>
                            <div class="col-6">
                                <label for="hrEtapa" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-5 mb-1 ps-2">Hora</label>
                                <date-picker class="w-100" v-model="anotacao.hrAnotacao" format="HH:mm" placeholder="Hora" type="time"></date-picker>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer mt-3">
                <button class="btn btn-contador_prazos px-4 py-2 w-100" type="button" @click="salvar" :disabled="validaSalvar">
                    Salvar anotação
                </button>
            </div>
        </b-modal>
    </div>
</template> 

<script>
    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/pt-br';

    export default {
        name: 'ModalAnotacao',
        props: {
            idContagemPrazoAnotacao: Number,
            showModal: Boolean,
            atualizar: Function,
            idContagemPrazo: Number,
        },
        components: { DatePicker },
        data: function () {
            return {
                titulo: 'Adicionar anotação',

                anotacao: {
                    dsTitulo: null,
                    dsAnotacao: null,
                    dtAnotacao: null,
                    hrAnotacao: null,
                },

            }
        },
        computed: {
            validaSalvar() {
                let $this = this;

                if ($this.anotacao.dsTitulo == null || $this.anotacao.dsTitulo == '') {
                    return true;
                }
                if ($this.anotacao.dsAnotacao == null || $this.anotacao.dsAnotacao == '') {
                    return true;
                }
                if ($this.anotacao.dtAnotacao == null || $this.anotacao.dtAnotacao == '') {
                    return true;
                }
                if ($this.anotacao.hrAnotacao == null || $this.anotacao.hrAnotacao == '') {
                    return true;
                }
                                
                return false;
            },
        },
        methods: {
            load() {
                
                this.anotacao = {
                    dsTitulo: null,
                    dsAnotacao: null,
                    dtAnotacao: null,
                    hrAnotacao: null,
                };

                if (this.idContagemPrazoAnotacao) {
                    this.titulo = 'Editar anotação';
                    //this.carregaAnotacao();
                }
                this.$refs['modal-anotacao'].show();
            },

            hideModalFn() {
                this.$refs['modal-anotacao'].hide();
            },

            salvar() {
                let $this = this;
                let anotacao = $this.anotacao;
                anotacao.hrAnotacao = moment(anotacao.hrAnotacao).format('HH:mm:ss');
                anotacao.idContagemPrazo = $this.idContagemPrazo;

                axios.post(`${urlApi}/ContadorPrazo/PostAnotacao`, anotacao, { showLoading: true })
                    .then(res => {
                        if (res.data.success) {
                            $this.atualizar();
                            avisoSucesso("Anotação salva com sucesso.");
                            $this.hideModalFn();
                        } else {
                            avisoErro("Não foi possível salvar a anotação");
                        }
                    })
                    .catch(res => {
                        avisoErro(res.response.data.errors[0]);
                    });
            },

        },
        mounted: function () {
        },
        watch: {
            showModal: function () {
                this.load();
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #DADADA;
        display: flex;
        justify-content: space-between;
    }

    .modal-conteudo {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 70vh;
    }

</style>

