<template>
    <section class="GeraAssinatura">
        <header>
            <div class="header">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-4">

                        </div>
                        <div class="col-6 col-md-4 my-2 my-sm-0 d-flex justify-content-center align-items-center">
                            <router-link :to="{name:'Home'}">
                                <img class="logo-sollicita" :src="logo" />
                            </router-link>

                        </div>
                        <div class="col-6 col-md-4">
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div class="conteudo-gerassinatura">
            <div class="container mt-3">
                <div class="row">
                    <div class="col-12">
                        <div class="alert alert-assinatura bg-blue-2" role="alert">
                            <div class="d-flex justify-content-between align-items-center">
                                <span class="txt-p txt-white">A plataforma completa para quem atua com Licitações e Contratos Administrativos</span>
                                <router-link :to="{name:'Login'}" class="btn btn-yellow txt-p txt-white py-1">
                                    Acessar
                                </router-link>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div class="row" v-if="flGeraAcesso">
                    <div class="col-12 mt-3">
                        <span class="txt-p txt-size-22 txt-blue-2 txt-w-7"><i class="fa fa-user"></i> Cadastro para acesso ao sollicita</span>
                    </div>
                    <div class="col-12">
                        <p class="txt-p txt-size-16 txt-blue-2">Preencha os campos abaixo e será encaminhado para o seu E-mail um Usuário e Senha para acesso.</p>
                    </div>
                    <form class="px-3 pb-3" id="formMeusDados">
                        <div class="row mb-2">
                            <div class="col-12 col-md-6">
                                <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                                    E-mail
                                </span>
                                <div class="form-group">
                                    <input type="email" class="form-control" id="Usuarios_dsEmail" name="usuario.dsEmail" v-model="usuario.dsEmail" maxlength="100" required>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                                    Nome
                                </span>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Usuarios_nmUsuario" name="usuario.nmUsuario" v-model="usuario.nmUsuario" maxlength="100" required>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12 col-md-3">
                                <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                                    CPF
                                </span>
                                <div class="form-group">
                                    <vue-mask class="form-control"
                                              id="nrCpfCnpj"
                                              name="usuario.nrCpfCnpj"
                                              v-model="usuario.nrCpfCnpj"
                                              mask="000.000.000-00"
                                              :raw="false"
                                              :options="optionsCPF" required>
                                    </vue-mask>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                                    Data de Nascimento
                                </span>
                                <div class="form-group">
                                    <vue-mask class="form-control"
                                              id="dtNascimento"
                                              name="usuario.dtNascimento"
                                              v-model="usuario.dtNascimento"
                                              mask="00/00/0000"
                                              :raw="false"
                                              :options="options" required>
                                    </vue-mask>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                                    Telefone
                                </span>
                                <div class="form-group">
                                    <!--<input type="text" class="form-control" id="Usuarios_dsTelefone" name="usuario.dsTelefone" v-model="usuario.dsTelefone" :maxlength="100" required>-->
                                    <vue-mask class="form-control"
                                              id="Usuarios_dsTelefone"
                                              name="usuario.dsTelefone"
                                              v-model="usuario.dsTelefone"
                                              mask="(00) 00000-0000"
                                              :raw="false"
                                              :options="optionTelefone"
                                              required>
                                    </vue-mask>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                                    Celular
                                </span>
                                <div class="form-group">
                                    <!--<input type="text" class="form-control" id="Usuarios_dsCelular" name="usuario.dsCelular" v-model="usuario.dsCelular" :maxlength="100" required>-->
                                    <vue-mask class="form-control"
                                              id="Usuarios_dsCelular"
                                              name="usuario.dsCelular"
                                              v-model="usuario.dsCelular"
                                              mask="(00) 00000-0000"
                                              :raw="false"
                                              :options="optionTelefone"
                                              required>
                                    </vue-mask>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-md-3">
                                <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                                    CEP
                                </span>
                                <div class="form-group">
                                    <div class="input-group mb-3">
                                        <vue-mask class="form-control"
                                                  id="Usuarios_dsCep"
                                                  name="usuario.dsCep"
                                                  v-model="usuario.dsCep"
                                                  mask="00000-000"
                                                  :raw="false"
                                                  :options="optionCep"
                                                  required>
                                        </vue-mask>
                                        <span class="input-group-text" id="basic-addon2"><i id="btnBuscarCep" class="fa fa-search c-pointer" @click="buscarCep" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                                    Endereço
                                </span>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Usuarios_dsEndereco" name="usuario.dsEndereco" v-model="usuario.dsEndereco" maxlength="300" :readonly="bloqueiaEndereco" required>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                                    Número
                                </span>
                                <div class="form-group">
                                    <input type="number" class="form-control" id="Usuarios_nrNumero" name="usuario.nrNumero" v-model="usuario.nrNumero" min="0" max="1000000000" :readonly="bloqueiaComplemento" required>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-md-3">
                                <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                                    Complemento
                                </span>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Usuarios_dsComplemento" name="usuario.dsComplemento" v-model="usuario.dsComplemento" maxlength="500" :readonly="bloqueiaComplemento" >
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                                    Bairro
                                </span>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Usuarios_nmBairro" name="usuario.nmBairro" v-model="usuario.nmBairro" :readonly="bloqueiaEndereco" maxlength="300" required>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                                    Cidade
                                </span>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Usuarios_nmCidade" name="usuario.nmCidade" v-model="usuario.nmCidade" :readonly="bloqueiaEndereco" maxlength="150" required>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                                    Estado
                                </span>
                                <div class="form-group">
                                    <select class="form-control" id="Usuarios_nmUf" name="usuario.nmUf" v-model="usuario.nmUf" :readonly="bloqueiaEndereco" :disabled="bloqueiaEndereco">
                                        <option value="">Selecione</option>
                                        <option value="AC">AC</option>
                                        <option value="AL">AL</option>
                                        <option value="AM">AM</option>
                                        <option value="AP">AP</option>
                                        <option value="BA">BA</option>
                                        <option value="CE">CE</option>
                                        <option value="DF">DF</option>
                                        <option value="ES">ES</option>
                                        <option value="GO">GO</option>
                                        <option value="MA">MA</option>
                                        <option value="MG">MG</option>
                                        <option value="MS">MS</option>
                                        <option value="MT">MT</option>
                                        <option value="PA">PA</option>
                                        <option value="PB">PB</option>
                                        <option value="PE">PE</option>
                                        <option value="PI">PI</option>
                                        <option value="PR">PR</option>
                                        <option value="RJ">RJ</option>
                                        <option value="RN">RN</option>
                                        <option value="RS">RS</option>
                                        <option value="RO">RO</option>
                                        <option value="RR">RR</option>
                                        <option value="SC">SC</option>
                                        <option value="SE">SE</option>
                                        <option value="SP">SP</option>
                                        <option value="TO">TO</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="form-group w-100">
                                <button class="btn btn-blue w-100" type="button" :disabled="!liberaSalvar" @click="enviar"><i class="fas fa-save"></i> Gerar Acesso</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="row" v-else-if="flGeraAcesso != null && flGeraAcesso == false">
                    <div class="col-md-12 bloco">
                        <div class="center pag-erro">
                            <div class="erro-header mb-3">
                                <h1 class="bold">Ops</h1>
                                <h2 class="bold">Desculpe, há algo errado nesta página</h2>
                            </div>
                            <div class="erro-body mt-5">
                                <p class="txt-p txt-blue-2">Há algo errado nesta página, essa Assinatura não existe ou já foi gerado o acesso.</p>
                                <div class="erro-footer">
                                    <router-link :to="{name:'Home'}" class="btn btn-blue back bold mr-0">
                                        Voltar a página Inicial
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import vueMask from 'vue-jquery-mask'
    import logo from '@/assets/img/logo_sollicita.png';
    import { urlApi, avisoSucesso, avisoErro } from '@/config/global'
    import axios from 'axios'
    import Footer from '@/components/Footer'
    import $ from "jquery";
     
    export default {
        name: 'GeraAssinatura',
        components: { vueMask, Footer },
        props: {
            hash: {
                type: String,
                required: true,
            },

        },
        computed: {
            liberaSalvar() {
                if (this.usuario.dsEmail == '' || this.usuario.dsEmail == null) {
                    return false;
                }

                if (this.usuario.nmUsuario == '' || this.usuario.nmUsuario == null) {
                    return false;
                }

                if (this.usuario.nrCpfCnpj == '' || this.usuario.nrCpfCnpj == null) {
                    return false;
                }

                if (this.usuario.dtNascimento == '' || this.usuario.dtNascimento == null) {
                    return false;
                }

                if (this.usuario.dsTelefone == '' || this.usuario.dsTelefone == null) {
                    return false;
                }

                if (this.usuario.dsCelular == '' || this.usuario.dsCelular == null) {
                    return false;
                }                

                if (this.usuario.dsCep == '' || this.usuario.dsCep == null) {
                    return false;
                }

                if (this.usuario.dsEndereco == '' || this.usuario.dsEndereco == null) {
                    return false;
                }

                if (this.usuario.nrNumero == '' || this.usuario.nrNumero == null) {
                    return false;
                }

                if (this.usuario.nmBairro == '' || this.usuario.nmBairro == null) {
                    return false;
                }

                if (this.usuario.nmCidade == '' || this.usuario.nmCidade == null) {
                    return false;
                }

                if (this.usuario.nmUf == '' || this.usuario.nmUf == null) {
                    return false;
                }
                                
                return true;
            },

        },  
        data() {
            return {
                logo,
                dadosCliente: null,
                flGeraAcesso: null,

                usuario: {
                    dsEmail: "",
                    nmUsuario: "",
                    nrCpfCnpj: "",
                    dtNascimento: "",
                    dsTelefone: "",
                    dsCelular: "",
                    dsCep: "",
                    dsEndereco: "",
                    nrNumero: "",
                    dsComplemento: "",
                    nmBairro: "",
                    nmCidade: "",
                    nmUf: "",
                },

                options: {
                    placeholder: '__/__/____',
                },
                optionsCPF: {
                    placeholder: '___.___.___-__',
                },
                optionTelefone: {
                    placeholder: '(__) _____- ____',
                },
                optionCep: {
                    placeholder: '_____-___',
                    onComplete: function () {
                        $("#btnBuscarCep").click();
                    },
                },

                bloqueiaEndereco: true,
                bloqueiaComplemento: true,
            }
        },
        methods: {
            load(){
                this.getDadosAssinatura();
            },

            getDadosAssinatura(){
                let $this = this;
                axios.get(`${urlApi}/Assinatura/GetDadosAssinatura`, { params: { hash: $this.hash } })
                    .then(resp => {
                        $this.dadosCliente = resp.data.data.cliente;
                        $this.usuario.dsEmail = $this.dadosCliente.dsEmail;
                        $this.usuario.nmUsuario = $this.dadosCliente.nmCliente;

                        $this.flGeraAcesso = resp.data.data.isGeraAcesso;
                    })
                    .catch(resp => {
                        $this.flGeraAcesso = false;
                        if (resp.response.data != undefined) {
                            avisoErro(resp.response.data);
                        } else {
                            avisoErro(resp.response.statusText);
                        }
                    });
            },

            buscarCep() {
                let $this = this;
                var cep = $this.usuario.dsCep.replace(/-/g, '');

                if (cep.length == 8) {
                    axios.get(`${urlApi}/Assinatura/CarregarEndereco`, { params: { dsCep: cep } })
                        .then(res => {
                            if (cep == "") {
                                $this.bloqueiaEndereco = true;
                                $this.bloqueiaComplemento = true;
                            } else {
                                if (res.data.data != null && res.data.data.dsCep != null) {
                                    $this.bloqueiaEndereco = true;
                                    $this.usuario.dsEndereco = res.data.data.dsEndereco;
                                    $this.usuario.nmBairro = res.data.data.nmBairro;
                                    $this.usuario.nmCidade = res.data.data.nmCidade;
                                    $this.usuario.nmUf = res.data.data.nmUf;
                                    $this.bloqueiaComplemento = false;

                                } else {
                                    $this.bloqueiaEndereco = false;
                                    $this.bloqueiaComplemento = false;
                                }
                            }
                        })
                        .catch(resp => {
                            $this.flGeraAcesso = false;
                            if (resp.response.data != undefined) {
                                avisoErro(resp.response.data);
                            } else {
                                avisoErro(resp.response.statusText);
                            }
                        });
                } else {
                    $this.bloqueiaEndereco = true;
                    $this.bloqueiaComplemento = true;
                }                
            },

            enviar() {
                if (this.checkForm()) {
                    let $this = this;
                    var dados = $this.usuario;
                    dados.hash = $this.hash;
                    axios.post(`${urlApi}/Assinatura/GeraAssinatura`, dados)
                        .then(res => {
                            if (res.data.token.authenticated) {
                                avisoSucesso("Cadastro realizado com sucesso");
                                res.data.token.usuario = res.data.usuario;
                                $this.$store.commit('setUser', res.data.token)
                                $this.$router.push({ name: 'HomeLogado' });
                            } else {
                                avisoErro("Erro ao realizar login.");
                            }
                        })
                        .catch(res => {
                            if (res.response.data != undefined) {
                                avisoErro(res.response.data.errors[0]);
                            } else {
                                avisoErro(res.response.statusText);
                            }
                        });
                }
                return false;
            },

            checkForm() {
                var form = document.getElementById('formMeusDados')
                if (!form.checkValidity()) {
                    var tmpSubmit = document.createElement('button')
                    form.appendChild(tmpSubmit)
                    tmpSubmit.click()
                    form.removeChild(tmpSubmit)

                    return false;
                }

                return true;
            },

        },
        mounted: function () {
            this.load();

        },
        watch: {
            
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .header {
        background-image: url('../assets/img/bg_sollicita.jpg');
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        padding: 20px 0;
    }

    .logo-sollicita{
        max-height: 91px;
        
    }

    .conteudo-gerassinatura {
        min-height: 43vh;

        .alert-assinatura {
            /*--bs-alert-color: #084298;
            --bs-alert-bg: #cfe2ff;
            --bs-alert-border-color: #b6d4fe;*/
        }
    }



    .pag-erro .erro-header {
        width: 1000px;
        margin: auto;
        left: 0;
        right: 0;
        text-align: center;
    }

    .pag-erro .erro-header h1 {
        display: inline-block;
        width: 350px;
        font-size: 200px;
        line-height: 200px;
        margin: 0 10px;
        color: #2d5289;
        text-align: right;
    }

    .pag-erro .erro-header h2 {
        display: inline-block;
        width: 370px;
        font-size: 45px;
        line-height: 55px;
        margin: 0 10px;
        text-transform: uppercase;
        color: #ecbd0d;
        text-align: left;
    }

    .pag-erro .erro-header hr {
        width: 300px;
        border: none;
        border-bottom: 15px solid #cdcdcd;
    }


    .pag-erro .erro-body, .pag-erro .erro-footer {
        display: block;
        width: 100%;
        margin: 20px auto;
        left: 0;
        right: 0;
    }

    .pag-erro .erro-body p, .pag-erro .erro-body ul li {
        text-align: left;
        font-size: 18px;
        line-height: 22px;
    }

    .pag-erro .erro-body ul {
        margin-left: 20px;
    }

</style>

