import { render, staticRenderFns } from "./viewJURIS.vue?vue&type=template&id=74210cda&scoped=true"
import script from "./viewJURIS.vue?vue&type=script&lang=js"
export * from "./viewJURIS.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74210cda",
  null
  
)

export default component.exports