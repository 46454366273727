<template>
    <section class="detalheprocesso">
        <Header></Header>

        <div class="container mt-3" style="min-height: 450px;">
            <div class="row">
                <div class="col-12">
                    <span class="txt-h-4 txt-grey-6 c-pointer" @click="votar"><i class="fas fa-arrow-left"></i> Voltar</span>
                </div>
            </div>
            <div class="row mt-4 mb-3">
                <div class="col-12">
                    <p class="txt-p txt-w-7 txt-size-20 txt-grey-6">
                        Configurações
                    </p>
                </div>
            </div>

            <div class="d-flex mb-3">
                <div :class="'w-50 aba-geral c-pointer ' +(abaAtiva == 1?'active':'')" @click="abaAtiva = 1">
                    <span class="txt-aba txt-p txt-w-5 txt-size-18">
                        Geral
                    </span>
                </div>
                <div :class="'w-50 aba-feriado c-pointer '+(abaAtiva == 2?'active':'')" @click="abaAtiva = 2">
                    <span class="txt-aba txt-p txt-w-5 txt-size-18">
                        Feriados
                    </span>
                </div>
            </div>
           

            <div v-if="abaAtiva == 1">
                <div class="row">
                    <div class="col-12">
                        <p class="txt-p txt-w-4 txt-size-16 txt-grey-6">
                            Selecione abaixo a modalidade para configurar quais serão seus procedimentos e regras para a contagem de dias.
                        </p>
                    </div>
                </div>
                
                <div class="list-modalidades">
                    <div class="item-modalidades row" v-for="(item,index) in lstModalidade" :key="index">
                        <div class="col-md-10">
                            <p class="pt-2 pb-2 mb-0">
                                {{item.nmModalidade}}
                            </p>
                            <span class="badge rounded-pill bg-warning text-dark" v-if="verificaNovo(item.dtInclusao)">Novo</span>
                        </div>
                        <div class="col-md-2 pe-0">
                            <button class="btn-configure w-100" @click="configurar(item.idModalidade)">
                                <span>
                                    <i class="fas fa-cog"></i> Configure
                                </span>
                            </button>
                        </div>
                    </div>

                </div>
            </div>

            <div v-if="abaAtiva == 2">
                <div class="row">
                    <div class="col-12 col-sm-10">
                        <p class="txt-p txt-w-4 txt-size-16 txt-grey-6">
                            Abaixo estão cadastrados os feriados nacionais, caso tenha necessidade, poderá criar os seus próprios feriados.
                        </p>
                    </div>
                    <div class="col-12 col-sm-2 d-flex justify-content-end" >
                        <button class="btn btn-contador_prazos " @click="addFeriado">
                            <i class="fa fa-plus"></i>  Adicionar Feriado
                        </button>
                    </div>
                </div>
                
                <div class="list-feriados mt-3">
                    <div class="item-modalidades row py-1" v-for="(item,index) in lstFeriado" :key="index">
                        <div class="col-6 col-sm-9">
                            <p class="pt-2 pb-2 mb-0 txt-p txt-w-4 txt-size-16 txt-grey-6">
                                <!-- para sumir icone quando troca de aba -->
                                <span v-if="abaAtiva == 2">
                                    <i class="fas fa-calendar me-3"></i>
                                </span>
                                
                                {{formatDate(item.dtFeriado)}} - {{item.nmFeriado}}
                            </p>
                        </div>
                        <div class="col-6 col-sm-3 d-flex justify-content-end">
                            <button class="btn btn-excluir-feriado me-2" @click="removeFeriado(item.idFeriado, item.nmFeriado)">
                                <i class="fas fa-trash-alt"></i>
                            </button>
                            <button class="btn btn-editar-feriado" @click="editFeriado(item.idFeriado)">
                                <i class="fas fa-pencil-alt"></i> <span class="d-none d-sm-block">Editar</span>
                            </button>
                        </div>
                        <!--<div class="col-1 p-0">
                            <button class="btn btn-excluir-feriado w-100" @click="removeFeriado(item.idFeriado, item.nmFeriado)">
                                <span v-if="abaAtiva == 2">
                                    <i class="fas fa-trash-alt" ></i>
                                </span>
                            </button>
                        </div>
                        <div class="col-2 p-0">
                            <button class="btn btn-editar-feriado w-100" @click="editFeriado(item.idFeriado)">
                                <span>
                                    <i class="fa fa-pencil mr-2"></i> Editar
                                </span>
                            </button>
                            
                        </div>-->
                    </div>

                </div>
            </div>
        </div>

        <ModalConfiguracaoGeral :idModalidade="idModalidade" :showModal="showModalConfiguracoes"></ModalConfiguracaoGeral>
        <ModalFeriado :idFeriado="idFeriado" :showModal="showModalFeriado" :atualizar="carregaFeriados"></ModalFeriado>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';
    import ModalConfiguracaoGeral from '@/components/ContadorPrazos/ModalConfiguracaoGeral';
    import ModalFeriado from '@/components/ContadorPrazos/ModalFeriado';
    import moment from 'moment';

    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import axios from 'axios'
    import Swal from 'sweetalert2'

    export default {
        name: 'ConfigurarPrazos',
        components: { Header, Footer, ModalConfiguracaoGeral, ModalFeriado },
        computed: {
            user() {
                return this.$store.state.user
            },
        },
        data() {
            return {
                abaAtiva: 1,

                lstModalidade: [],

                lstFeriado: [],

                idModalidade: null,
                showModalConfiguracoes: false,
                showModalFeriado: false,
                idFeriado: null,
            }
        },
        methods: {
            votar() {
                this.$router.go(-1);
            },

            verificaNovo(dt) {
                if (moment().diff(moment(dt), 'days') <= 30) {
                    return true;
                }
                return false;
            },

            load() {
                this.carregaModalidades();
                this.carregaFeriados();
            },

            carregaModalidades() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/GetModalidade`, { params: {} })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data.rows != null) {
                                $this.lstModalidade = res.data.data.rows;
                            }
                        } else {
                            avisoErro("Não foi possível carregar as modalidades");
                            $this.lstModalidade = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar as modalidades");
                        $this.lstModalidade = [];
                    });
            },

            carregaFeriados() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/GetFeriado`, { params: {} })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data.rows != null) {
                                $this.lstFeriado = res.data.data.rows;
                            }
                        } else {
                            avisoErro("Não foi possível carregar os feriados");
                            $this.lstFeriado = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os feriados");
                        $this.lstFeriado = [];
                    });
            },

            configurar(idModalidade) {
                this.idModalidade = idModalidade;
                this.showModalConfiguracoes = !this.showModalConfiguracoes;
            },

            addFeriado() {
                this.tpOperacao = "incluir";
                this.idFeriado = null;
                this.showModalFeriado = !this.showModalFeriado;
            },

            editFeriado(idFeriado) {
                this.tpOperacao = "editar";
                this.idFeriado = idFeriado;
                this.showModalFeriado = !this.showModalFeriado;
            },

            removeFeriado(idFeriado, nmFeriado) {
                let $this = this;
                Swal.fire({
                    title: 'Tem certeza que deseja excluir "' + nmFeriado + '"?',
                    text: 'Esta operação não poderá ser desfeita.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    confirmButtonColor: '#83A431',
                }).then((result) => {
                    if (result.isConfirmed) {
                        $this.excluiFeriado(idFeriado);
                    }
                })
            },

            excluiFeriado(idFeriado) {
                let $this = this;
                axios.delete(`${urlApi}/ContadorPrazo/DeleteFeriado`, { params: { idFeriado: idFeriado } })
                    .then(res => {
                        if (res.data.success) {
                            $this.carregaFeriados();
                            avisoSucesso("Feriado excluido com sucesso");
                        } else {
                            avisoErro("Não foi possível excluir o feriado");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível excluir o feriado");
                    });
            },

            formatDate(dt) {
                return moment(dt).format("DD/MM/YYYY");
            }

        },
        mounted: function () {
            
            this.load();
        },
        watch: {
            
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .aba-geral {
        background: #CBCBCB;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
        padding: 13px;
        border-radius: 5px 0px 0px 5px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .aba-geral.active {
        background: #83A431;
        z-index: 2;
        padding: 15px;
        color: #FFF;
    }

    .aba-feriado {
        background: #CBCBCB;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
        padding: 13px;
        border-radius: 0px 5px 5px 0px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .aba-feriado.active {
        background: #83A431;
        z-index: 2;
        padding: 15px;
        color: #FFF;
    }


    .list-modalidades {
        background-color: #FFF;
        padding: 1px;

        .item-modalidades {
            margin: 0;
            border: 1px solid #F2F2F2;
            display: flex;
            align-items: center;
            overflow-wrap: break-word;

            .btn-configure {
                display: flex;
                align-items: center;
                color: #737373 !important;
                background-color: #D7D7D7;
                cursor: pointer;
                font-size: 16px;
                font-weight: 700;
                padding: 15px 10px;
                display: flex;
                justify-content: center;
                border: none;
            }
        }

        .item-modalidades:nth-child(odd) {
            background-color: #E9E9E9;
        }

        .item-modalidades:nth-child(even) {
            background-color: #FFF;
        }
    }


    .list-feriados {
        background-color: #FFF;
        padding: 1px;

        .item-modalidades {
            margin: 0;
            border: 1px solid #F2F2F2;
            display: flex;
            align-items: center;
            overflow-wrap: break-word;

            .btn-excluir-feriado {
                color: #FFF !important;
                background-color: #D78E8E;
                border-radius: unset;
            }

            .btn-editar-feriado {
                color: #FFF !important;
                background-color: #7BBDD9;
                border-radius: unset;
            }
        }

        .item-modalidades:nth-child(odd) {
            background-color: #E9E9E9;
        }

        .item-modalidades:nth-child(even) {
            background-color: #FFF;
        }
    }

</style>

