<template>
    <div class="MudarSenha">
        <b-modal ref="modal-manual-usuario" id="modalManualUsuario" size="xl" :hide-footer="true" :hide-header="true">
            <div class="w-100 text-end txt-size-20 txt-blue-2">
                <span @click="close"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <form class="px-3 pb-3" id="formTrocaSenha">

                <div class="row">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-size-25 txt-w-bold mb-2">
                            Manual do Usuário
                        </span>
                        <p class="txt-p txt-size-16 txt-w-5 txt-grey-2">
                            
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <embed src="manual-usuario.pdf" type="application/pdf" width="100%" height="650px" />
                    </div>

                </div>

            </form>
        </b-modal>
    </div>
</template>

<script>

    export default {
        name: 'ManualUsuario',
        data: function () {
            return {
            }
        },
        props: {
            showModal: Boolean,
        },
        methods: {
            load() {

            },

            close() {
                this.$refs['modal-manual-usuario'].hide();
            }

        },
        created: function () {
            this.load();
        },
        watch: {
            showModal: function () {
                this.$refs['modal-manual-usuario'].show();
            },

        }

    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* Manual Usuario */
    .form-control {
        border: 1px solid #474747;
    }
</style>

