<template>
    <section class="Modelo">
        
        <Header></Header>
        <div class="container">
            <div class="row my-3">
                <div class="col-12 text-center">
                    <p class="txt-p txt-size-20 txt-modelos mb-0">
                        Sollicita <span class="txt-w-7">Modelos</span>
                    </p>
                </div>
                <div class="col-12 text-end">
                    <ManualFerramenta :name="'Manual Modelos'" :btnClass="'btn-modelos'" :nameBtn="'Manual'" :urlPdf="'/manuais/FERRAMENTA MODELOS PDF.pdf'"></ManualFerramenta>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="box-branco">
                        <div class="row">
                            <div class="input-group" style="border-color: #20A769;">
                                <span class="input-group-text bg-white border-modelos" id="dsTermo"><i class="fas fa-search"></i></span>
                                <input type="text" class="form-control border-modelos" ref="dsTermo" style="border-left: unset;" placeholder="Pequise por produto ou serviço" aria-label="dsTermo" aria-describedby="dsTermo" v-model="dsTermo">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <label for="tpModelo" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-6 mb-1 ps-2">Tipo de modelos:</label>
                                <select class="form-select " aria-label="tpModelo" v-model="idModelo">
                                    <option value="0">Selecione</option>
                                    <!--<option :value="item.idModelo" v-for="(item,index) in lstTipoModelo" :key="index">{{item.nmModelo}} <span v-if="item.flNovo" class="badge badge-pill badge-warning">Novo</span></option>-->
                                    <option :value="item.idModelo" v-for="(item,index) in lstTipoModelo" :key="index">{{item.nmModelo}} </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-3" v-if="lstCategoria.length > 0">
                            <div class="col-12">
                                <label for="categoria" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-6 mb-1 ps-2">Categoria:</label>
                                <select class="form-select" aria-label="tpModelo" v-model="idModeloCategoria">
                                    <option value="0">Selecione</option>
                                    <option :value="item.idModeloCategoria" v-for="(item,index) in lstCategoria" :key="index">{{item.nmCategoria}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <button class="btn btn-modelos w-100" @click="pesquisar">
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-3" v-if="lstModeloArquivo.length > 0">
            <div class="row">
                <div class="col-12">
                    <span class="txt-p txt-size-20 txt-w-7 txt-grey-6">
                        Modelos:
                    </span>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <div class="lst-modelos-arquivo">
                        <div class="item-modelo-arquivo mb-2" v-for="(item,index) in lstModeloArquivo" :key="index">
                            <div class="row mb-3">
                                <div class="col-12">
                                    <div class="ps-2" style="border-left: 10px solid #20A769">
                                        <p class="txt-p txt-w-7 txt-size-18 txt-modelos mb-1">
                                            {{item.modeloArquivo.nmArquivo}}
                                        </p>
                                        <p class="txt-p txt-w-4 txt-size-16 txt-modelos mb-0">
                                            {{item.modeloArquivo.nmModelo}} ({{item.modeloArquivo.nmCategoria}})
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <template v-if="item.modeloArquivo.urlArquivo.includes('.pdf')">
                                        <div class="tipo-arquivo icone-pdf w-100 h-100">
                                            <i class="fas fa-file-pdf" data-toggle="tooltip" data-placement="top" title="PDF"></i> PDF
                                        </div>
                                    </template>
                                    <template v-else-if="item.modeloArquivo.urlArquivo.includes('.doc') || item.modeloArquivo.urlArquivo.includes('.docx')">
                                        <div class="tipo-arquivo icone-word w-100 h-100">
                                            <i class="fas fa-file-word" data-toggle="tooltip" data-placement="top" title="Word"></i> Word
                                        </div>
                                    </template>
                                    <template v-else-if="item.modeloArquivo.urlArquivo.includes('.png') || item.modeloArquivo.urlArquivo.includes('.jpg') || item.modeloArquivo.urlArquivo.includes('.jpeg')">
                                        <div class="tipo-arquivo icone-img w-100 h-100">
                                            <i class="fas fa-file-image" data-toggle="tooltip" data-placement="top" title="Imagem"></i> Imagem
                                        </div>
                                    </template>
                                    <template v-else-if="item.modeloArquivo.urlArquivo.includes('.xls') || item.modeloArquivo.urlArquivo.includes('.xlsx')">
                                        <div class="tipo-arquivo icone-excel w-100 h-100">
                                            <i class="fas fa-file-excel" data-toggle="tooltip" data-placement="top" title="Excel"></i> Excel
                                        </div>
                                    </template>
                                    <template v-else-if="item.modeloArquivo.urlArquivo.includes('.rtf') || item.modeloArquivo.urlArquivo.includes('.rtf')">
                                        <div class="tipo-arquivo icone-word w-100 h-100">
                                            <i class="fas fa-file-word" data-toggle="tooltip" data-placement="top" title="Word"></i> Word
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="tipo-arquivo icone-file w-100 h-100">
                                            <i class="fas fa-file"></i> Arquivo
                                        </div>
                                    </template>

                                </div>
                                <div class="col-4">
                                    <template v-if="item.modeloArquivo.urlArquivo.includes('.pdf')">
                                        <router-link :to="{name:'LeitorPdf', params:{urlArquivo:item.modeloArquivo.urlArquivo}}" target="_blank" class="btn btn-modelos-outline w-100">
                                            <i class="fas fa-eye"></i> Visualizar
                                        </router-link>
                                    </template>
                                    <template v-else>
                                        <button class="btn btn-modelos-outline w-100" disabled>
                                            <i class="fas fa-eye"></i> Visualizar
                                        </button>
                                    </template>
                                    
                                </div>
                                <div class="col-4">
                                    <button class="btn btn-modelos w-100"  @click="linkDownload(item.modeloArquivo)">
                                        <i class="fas fa-download"></i> Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';
    import ManualFerramenta from '@/components/ManualFerramenta';

    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'
    export default {
        name: 'Modelo',
        components: { Header, Footer, ManualFerramenta },
        computed: {
            user() {
                return this.$store.state.user
            },
            
        },
        data() {
            return {
                dsTermo: '',
                idModelo: 0,
                idModeloCategoria: 0,
                
                lstModeloArquivo: [],
                lstTipoModelo: [],
                lstCategoria: [],

            }
        },
        methods: {
            load() {
                //let $this = this;
                this.carregaModelosArquivo();
                this.carregaTiposModelo();

            },

            carregaTiposModelo() {
                let $this = this;
                axios.get(`${urlApi}/Modelo/CarregaModelo`, { params: { } })
                    .then(res => {
                        if (res.data.success) {
                            var result = res.data.data;
                            $this.lstTipoModelo = result;
                        } else {
                            avisoErro("Não foi possível carrega os tipos de modelo");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carrega os tipos de modelo");
                    });
            },

            carregaModeloCategoria() {
                let $this = this;
                $this.lstCategoria = [];
                axios.get(`${urlApi}/Modelo/CarregaModeloCategoria`, { params: { idModelo : $this.idModelo } })
                    .then(res => {
                        if (res.data.success) {
                            var result = res.data.data;
                            $this.lstCategoria = result;
                        } else {
                            avisoErro("Não foi possível carrega as categorias");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carrega as categorias");
                    });
            },

            carregaModelosArquivo() {
                let $this = this;
                axios.get(`${urlApi}/Modelo/CarregaModeloArquivo`, {
                    params: {
                        termo: $this.dsTermo,
                        idModelo: $this.idModelo,
                        idModeloCategoria: $this.idModeloCategoria
                    }
                })
                    .then(res => {
                        if (res.data.success) {
                            var result = res.data.data;
                            $this.lstModeloArquivo = result.rows;
                        } else {
                            avisoErro("Não foi possível carrega os arquivos");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carrega os arquivos");
                    });
            },
            linkDownload(modeloArquivo) {
                var nmArquivo = "";
                if (modeloArquivo.urlArquivo.includes('.pdf')) {
                    nmArquivo = modeloArquivo.nmArquivo + ".pdf";
                } else if (modeloArquivo.urlArquivo.includes('.doc') || modeloArquivo.urlArquivo.includes('.docx')) {
                    nmArquivo = modeloArquivo.nmArquivo + ".docx";
                } else if (modeloArquivo.urlArquivo.includes('.png') || modeloArquivo.urlArquivo.includes('.jpg') || modeloArquivo.urlArquivo.includes('.jpeg')) {
                    nmArquivo = modeloArquivo.nmArquivo + ".png";
                } else if (modeloArquivo.urlArquivo.includes('.xls') || modeloArquivo.urlArquivo.includes('.xlsx')) {
                    nmArquivo = modeloArquivo.nmArquivo + ".xlsx";
                } else if (modeloArquivo.urlArquivo.includes('.rtf') || modeloArquivo.urlArquivo.includes('.rtf')) {
                    nmArquivo = modeloArquivo.nmArquivo + ".rtf";
                } else {
                    var nomeArquivo = modeloArquivo.urlArquivo.split('/');
                    if (nomeArquivo.Length >= 6) {
                        nmArquivo = nomeArquivo[6]
                    } else {
                        nmArquivo = "";
                    }
                }

                axios({
                    url: `${urlApi}/Utilidade/BaixarArquivo?urlArquivo=${modeloArquivo.urlArquivo}&nmArquivo${nmArquivo}`, //your url
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', nmArquivo); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },

            pesquisar() {
                this.carregaModelosArquivo();
            }
                        
        },
        mounted: function () {
            this.load();
        },
        watch: {
            idModelo() {
                this.idModeloCategoria = 0;
                this.carregaModeloCategoria();
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    @import "../../../assets/bootstrap-select.min.css";

    .box-branco {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }

    .lst-modelos-arquivo {
        .item-modelo-arquivo {
            background: #FFFFFF;
            border: 1px solid #767676;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            padding: 20px;
        }
    }

    .tipo-arquivo {
        background: #FFFFFF;
        border: 1px solid #007BFF;
        border-radius: 5px;
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: center;

        font-family: Nunito;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        outline: none;
        border-radius: 5px;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .icone-word {
        background-color: transparent;
        padding: 10px;
        border: 2px solid;
        border-color: #007BFF;
        color: #007BFF;
        font-size: 18px;
    }
    .icone-pdf {
        background-color: transparent;
        padding: 10px;
        border: 2px solid;
        border-color: #dc3545;
        color: #dc3545;
        font-size: 18px;
    }
    .icone-excel {
        background-color: transparent;
        padding: 10px;
        border: 2px solid;
        border-color: #28a745;
        color: #28a745;
        font-size: 18px;
    }
    .icone-img {
        background-color: transparent;
        padding: 10px;
        border: 2px solid;
        border-color: #767676;
        color: #767676;
        font-size: 18px;
    }
    .icone-file {
        background-color: transparent;
        padding: 10px;
        border: 2px solid;
        border-color: #767676;
        color: #767676;
        font-size: 18px;
    }
</style>

