<template>
    <div class="ModalNovoFornecedor">
        <b-modal ref="modal-novo-fornecedor" id="ModalNovoFornecedor" size="lg" :hide-footer="true" :hide-header="true">
            <div class="modal-titulo txt-p txt-w-7 txt-size-20 txt-grey-2 m-2">
                <p class="txt-w-20 mb-0 txt-analisador_balancos">{{titulo}}</p>
                <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <div class="modal-conteudo m-2 px-2">
                <form class="mt-2">
                    <div class="mb-3">
                        <label for="nrCnpj" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                            1. CNPJ do fornecedor:
                        </label>
                        <div class="w-100">
                            <vue-mask class="form-control"
                                      placeholder="CNPJ"
                                      id="nrCnpj"
                                      name="fornecedor.nrCnpj"
                                      v-model="fornecedor.nrCnpj"
                                      mask="00.000.000/0000-00"
                                      :raw="false"
                                      required>
                            </vue-mask>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="nmRazaoSocial" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                            2. Razão social do fornecedor:
                        </label>
                        <div class="w-100">
                            <input class="form-control" type="text" id="nmRazaoSocial" aria-describedby="nmRazaoSocial" placeholder="Razão social do fornecedor:" maxlength="500" v-model="fornecedor.nmRazaoSocial">
                        </div>
                    </div>                    
                </form>
            </div>
            <div class="modal-footer mt-3">
                <div class="row ">
                    <div class="col-12 px-0 d-flex justify-content-end">
                        <button class="btn btn-cancelar mr-2" type="button" @click="hideModalFn">
                            Cancelar
                        </button>
                        <button class="btn btn-analisador_balancos" type="button" @click="salvar" :disabled="validaSalvar">
                            Adicionar fornecedor
                        </button>
                        
                    </div>
                    
                </div>
                
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import vueMask from 'vue-jquery-mask'
    import axios from 'axios'

    export default {
        name: 'ModalNovoFornecedor',
        props: {
            idAnalise: Number,
            showModal: Boolean,
            atualizar: Function,
        },
        components: {
            vueMask,
        },
        data: function () {
            return {
                titulo: 'Novo fornecedor',

                fornecedor: {
                    nmRazaoSocial: null,
                    nrCnpj: null,
                },

            }
        },
        computed: {
            validaSalvar() {
                let $this = this;

                if ($this.fornecedor.nmRazaoSocial == null || $this.fornecedor.nmRazaoSocial == '') {
                    return true;
                }
                if ($this.fornecedor.nrCnpj == null || $this.fornecedor.nrCnpj == '') {
                    return true;
                }

                return false;
            },
        },
        methods: {
            load() {

                this.fornecedor = {
                    nmRazaoSocial: null,
                    nrCnpj: null,
                };

                this.$refs['modal-novo-fornecedor'].show();
            },

            hideModalFn() {
                this.$refs['modal-novo-fornecedor'].hide();
            },

            salvar() {
                let $this = this;
                let fornecedor = $this.fornecedor;
                fornecedor.idAnalise = $this.idAnalise;

                axios.post(`${urlApi}/AnalisadorBalancos/PostFornecedor`, fornecedor, { showLoading: true })
                    .then(res => {
                        if (res.data.success) {
                            $this.atualizar();
                            avisoSucesso("Análise salva com sucesso.");
                            $this.hideModalFn();
                        } else {
                            avisoErro("Não foi possível salvar a análise");
                        }
                    })
                    .catch(res => {
                        avisoErro(res.response.data.errors[0]);
                    });
            },

        },
        mounted: function () {
        },
        watch: {
            showModal: function () {
                this.load();
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #DADADA;
        display: flex;
        justify-content: space-between;
    }

    .modal-conteudo {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 70vh;
    }
</style>

