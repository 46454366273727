<template>
    <div class="ModalRelatorioProcesso">
        <b-modal ref="modal-relatorio-processo" id="ModalRelatorioProcesso" size="lg" :hide-footer="true" :hide-header="true">
            <div class="modal-titulo txt-p txt-w-7 txt-size-20 txt-grey-2 m-2">
                <p class="txt-w-20 mb-0 txt-contador_prazos">{{titulo}}</p>
                <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <div class="modal-conteudo m-2" id="imprimirRelatorio">
                <div class="row">
                    <div class="col-12 ">
                        <p style="font-family: 'Roboto'; font-weight: 400; font-size: 16px; color: #767676; margin-bottom: 0;">
                            Processo
                        </p>
                        <p style="font-family: 'Roboto'; font-weight: 700; font-size: 20px; color: #767676;" v-if="contagemPrazo">
                            {{contagemPrazo.dsEdital}}
                        </p>
                    </div>
                    <div class="col-12 ">
                        <span style="font-family: 'Roboto'; font-weight: 700; font-size: 18px; color: #767676;">
                            Ocorrências:
                        </span>
                    </div>
                </div>
                <div class="row item-ocorrencia px-3" v-for="(item,index) in lstGridPrazo" :key="index" style="border-bottom: 2px solid #DADADA; padding: 20px 0;">
                    <template v-if="item.flTipo == 'Data da Sessão'">
                        <!-- DATA DA SESSAO -->
                        <div class="col-12 detalhes-ocorrencia detalhes-vermelho" style="border-left: 10px solid #D88E8F;">
                            <p style="font-family: 'Roboto'; font-weight: 700; font-size: 18px; color: #D88E8F !important; ">
                                {{formatDateTime(contagemPrazo.dtSessao)}}
                            </p>
                            <p style="font-family: 'Roboto'; font-weight: 700; font-size: 14px; color: #767676;">
                                Data da sessão
                            </p>
                        </div>
                    </template>
                    <template v-else-if="item.flTipo == 'Evento'">
                        <div class="col-12 detalhes-ocorrencia detalhes-azul" style="border-left: 10px solid #7BBDD9;">
                            <p style="font-family: 'Roboto'; font-weight: 700; font-size: 18px; color: #7BBDD9 !important; ">
                                {{formatDateTime(item.dtPrazo)}}
                            </p>
                            <p style="font-family: 'Roboto'; font-weight: 700; font-size: 14px; color: #767676;">
                                {{item.dsPrazo}}
                            </p>
                            <p style="font-family: 'Roboto'; font-weight: 700; font-size: 14px; color: #767676;" v-if="item.flConcluido == 'Sim'">
                                Concluído em <span class="txt-w-bold">{{formatDateTime(item.dtConcluido)}}</span> por <span class="txt-w-bold">{{item.dsLoginConcluido}}</span>
                            </p>
                        </div>
                    </template>

                    <template v-else>
                        <div class="col-12 detalhes-ocorrencia detalhes-verde " style="border-left: 10px solid #9BB854;">
                            <p style="font-family: 'Roboto'; font-weight: 700; font-size: 18px; color: #9BB854 !important; ">
                                {{formatDateTime(item.dtPrazo)}}
                            </p>
                            <p style="font-family: 'Roboto'; font-weight: 700; font-size: 14px; color: #767676;">
                                {{item.dsPrazo}}
                            </p>
                            <p style="font-family: 'Roboto'; font-weight: 700; font-size: 14px; color: #767676;" v-if="item.flConcluido == 'Sim'">
                                Concluído em <span class="txt-w-bold">{{formatDateTime(item.dtConcluido)}}</span> por <span class="txt-w-bold">{{item.dsLoginConcluido}}</span>
                            </p>
                        </div>
                    </template>
                </div>
            </div>
            <div class="modal-footer mt-3">
                <button class="btn btn-contador_prazos px-4 py-2 w-100" type="button" @click="imprimir">
                    <span><i class="fas fa-print"></i></span> Imprimir lista de ocorrências
                </button>
            </div>
        </b-modal>
    </div>
</template> 

<script>
    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'

    export default {
        name: 'ModalRelatorioProcesso',
        props: {
            idProcesso: {
                type: Number,
                required: true,
            },
            showModal: Boolean,
            
        },
        data: function () {
            return {
                titulo: 'Relatório de processo',
                contagemPrazo: null,
                lstGridPrazo: [],
            }
        },
        methods: {
            load() {
                this.carregaProcesso();
               
                this.$refs['modal-relatorio-processo'].show();
            },

            hideModalFn() {
                this.$refs['modal-relatorio-processo'].hide();
            },

            formatDateTime(dt) {
                return moment(dt).format("DD/MM/YYYY [às] HH:mm");
            },

            carregaProcesso() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/DetalhesProcesso/${$this.idProcesso}`)
                    .then(res => {
                        if (res.data.success) {
                            $this.contagemPrazo = null;
                            $this.lstGridPrazo = [];
                            if (res.data.data != null) {
                                $this.contagemPrazo = res.data.data.contagemPrazo;
                                let lstGridPrazo = res.data.data.lstGridPrazo;

                                for (let i = 0; i < lstGridPrazo.length; i++) {
                                    var item = lstGridPrazo[i];
                                    item.totalDias = moment(item.dtPrazo, 'DD/MM/YYYY HH:mm:ss').diff(moment(), 'days');
                                    $this.lstGridPrazo.push(item);
                                }
                            }
                        } else {
                            avisoErro("Não foi possível carregar os processos");
                            $this.contagemPrazo = null;
                            $this.lstGridPrazo = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os processos");
                        $this.contagemPrazo = null;
                        $this.lstGridPrazo = [];
                    });
            },

            

            async imprimir() {
                // Pass the element id here
                await this.$htmlToPaper('imprimirRelatorio');
            }
        },
        mounted: function () {
        },
        watch: {
            showModal: function () {
                this.load();
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">

    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #DADADA;
        display: flex;
        justify-content: space-between;
    }

    .modal-conteudo {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 70vh;
    }



    .item-ocorrencia {
        border-bottom: 2px solid #DADADA;
        padding: 20px 0;
    }

        .item-ocorrencia .detalhes-ocorrencia {
            border-left: 10px solid #9BB854;
        }

        .item-ocorrencia .detalhes-vermelho {
            border-left: 10px solid #D88E8F;
        }

            .item-ocorrencia .detalhes-vermelho .data-ocorrencia {
                color: #D88E8F !important;
            }

        .item-ocorrencia .detalhes-azul {
            border-left: 10px solid #7BBDD9;
        }

            .item-ocorrencia .detalhes-azul .data-ocorrencia {
                color: #7BBDD9 !important;
            }

        .item-ocorrencia .detalhes-verde {
            border-left: 10px solid #9BB854;
        }

            .item-ocorrencia .detalhes-verde .data-ocorrencia {
                color: #9BB854 !important;
            }

</style>

