<template>
    <div class="planos">
        <div class="bg-header">
            <div class="header-azul">
                <div class="container py-5">
                    <div class="row">
                        <div class="col-4">
                            <p class="txt-h-5 txt-w-7 txt-white">
                                O Sollicita é mais que um portal de conteúdo.
                            </p>
                            <div class="row">
                                <div class="col-12 col-md-5">
                                    <p class="txt-h-6 txt-size-12 txt-w-7 txt-white">
                                        Conheça os módulos e
                                        ferramentas disponíveis!
                                    </p>
                                </div>
                                <div class="col-12 col-md-7">
                                    <button class="btn btn-yellow txt-w-7 txt-size-12 w-100" style="color: #313131" @click="showModalExperimenteGratis = !showModalExperimenteGratis">
                                        Quero conhecer o Sollicita
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 d-flex justify-content-center">
                            <router-link :to="{name:'Home'}">
                                <img class="logo-sollicita" :src="logo" />
                            </router-link>
                        </div>
                        <div class="col-4 d-flex justify-content-center">
                            <div>
                                <p class="txt-p txt-white mb-2">Já tem o acesso?</p>
                                <router-link class="btn btn-yellow-outline" :to="{name:'Login'}">
                                    Entre na sua conta
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center mt-5">
                        <div class="col-12 col-md-8 text-center">
                            <p class="txt-h-2 txt-size-30 txt-w-7 txt-white">
                                Domine seu trabalho com <span class="txt-cores-1">segurança e eficiência</span>
                            </p>

                        </div>
                        <div class="col-12 col-md-6 text-center">
                            <p class="txt-h-3 txt-size-18 txt-w-7 txt-white px-4">
                                Conheça todas as soluções do <span class="txt-cores-1">Sollicita </span> disponíveis para você e tenha acesso ao melhor suporte em licitações e contratos do Brasil.
                            </p>
                        </div>
                    </div>

                    <div class="row mt-4 px-5">
                        <div class="col-12 col-lg-6 mt-2">
                            <div class="box-plano-pro text-center h-100">
                                <div class="tag-melhor-plano"><div class="txt-h-5 txt-size-15 txt-w-7 txt-blue-6">Melhor plano <br />para melhor escolha</div></div>
                                <div class="content-box-plano">
                                    <img class=" mb-5" :src="logoPlanoPro" />
                                    <div class="w-100" style="padding: 0 25%;">
                                        <p class="txt-h-4 txt-w-8 txt-blue-6 pb-3" style="border-bottom: 5px solid #213947;">
                                            Sollicita PRO
                                        </p>
                                    </div>
                                    <p class="txt-h-5 txt-size-18 txt-w-7 txt-blue-6">
                                        ORIENTAÇÃO TÉCNICA EM LICITAÇÕES E CONTRATOS
                                    </p>
                                    <p class="txt-h-5 txt-w-7 txt-blue-6 px-5">
                                        A melhor fonte de informação para tomada de decisão em licitações e contratos
                                    </p>
                                    <div class="text-left">
                                        <p class="txt-h-5 txt-w-6 txt-blue-6 mb-2 d-flex" v-for="(item,index) in sollicitaPro.lst" :key="index">
                                            <i class="fas fa-check check-green mr-2"></i> {{item}}
                                        </p>
                                    </div>
                                </div>
                                <div class="footer-box-plano">
                                    <div class="w-100 px-5 mt-4">
                                        <button class="btn btn-yellow w-100" @click="showModalExperimenteGratis = !showModalExperimenteGratis">
                                            Conhecer Sollicita PRO
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 mt-2">
                            <div class="box-plano-pf text-center h-100 ">
                                <div class="content-box-plano">
                                    <img class=" mb-5" :src="logoPlanoPf" />
                                    <div class="w-100" style="padding: 0 25%;">
                                        <p class="txt-h-4 txt-w-8 txt-blue-6 pb-3" style="border-bottom: 5px solid #213947;">
                                            Sollicita PESSOA FÍSICA
                                        </p>
                                    </div>
                                    <p class="txt-h-5 txt-size-18 txt-w-7 txt-blue-6">
                                        Plataforma Eletrônica de atualização diária de informações
                                    </p>
                                    <p class="txt-h-5 txt-w-7 txt-blue-6 px-5">
                                        A maior plataforma brasileira de serviços voltados às compras públicas e suas diversas fases de atuação
                                    </p>
                                    <div class="text-left">
                                        <p class="txt-h-5 txt-w-6 txt-blue-6 mb-2 d-flex" v-for="(item,index) in sollicitaPf.lst" :key="index">
                                            <i class="fas fa-check check-green mr-2"></i> {{item}}
                                        </p>
                                    </div>
                                </div>
                                <div class="footer-box-plano">
                                    <div class="w-100 px-5 mt-4">
                                        <a class="btn btn-blue w-100 txt-white" :href="urlHotmart" target="_blank">
                                            Conhecer Sollicita
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="sessao-notebook">
            <div class="sol">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 ">
                            <img class="img-fluid img-sol" :src="imgSol" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <div class="box-sessao-notebook text-center">
                            <p class="txt-h-5 txt-size-18 txt-w-7 txt-white mb-1">
                                Nosso nome é a união das palavras <span class="txt-cores-1">Sol</span> + <span class="txt-cores-1">Licita.</span>
                            </p>
                            <p class="txt-h-5 txt-size-18 txt-w-4 txt-white mb-0">
                                Pois nossa missão desde sempre foi deixar mais claro o mundo das compras públicas.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row mx-5">
                    <div class="col-6 d-flex justify-content-center" style="border-right: 1px solid #FFF">
                        <div class="box-sessao-notebook text-center w-100 my-6">
                            <p class="txt-h-5 txt-size-16 txt-w-7 txt-white mb-0">
                                O <span class="txt-cores-1">SOL</span> que clareia e ilumina as ideias.
                            </p>
                            <div class="divisor-horizontal"></div>
                        </div>

                    </div>
                    <div class="col-6 d-flex justify-content-center">
                        <div class="box-sessao-notebook text-center w-100 my-6">
                            <p class="txt-h-5 txt-size-16 txt-w-7 txt-white mb-0">
                                <span class="txt-cores-1">LICITA</span>, a licitação. Para nós, mais que um procedimento administrativo formal: uma responsabilidade acima da média.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <div class="box-sessao-notebook box-alt text-center">
                            <p class="txt-h-5 txt-size-18 txt-w-7 txt-cores-1 mb-0">
                                A soma desses termos resulta em nosso nome SO(LL)ICITA.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center mt-5">
                    <div class="col-8">
                        <div class="borda-notebook-citacao text-center">
                            <p class="txt-h-4 txt-w-7 txt-white mb-0">
                                Nascemos no seio do maior know how da área das compras públicas,<br />
                                o Grupo Negócios Públicos, como uma forma segura e inovadora de ajudar e orientar.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center mt-5">
                    <div class="col-10">
                        <div class="box-segmentos">
                            <p class="txt-h-3 txt-w-4 txt-white mb-0 recuo mb-1">
                                Missão
                            </p>
                            <p class="txt-h-4 txt-size-18 txt-w-4 txt-white mb-4">
                                Tornar a temática do Direito Administrativo mais clara e acessível, para transformar e
                                possibilitar licitações e contratos mais eficientes e de sucesso.
                            </p>
                            <p class="txt-h-3 txt-w-4 txt-white mb-0 recuo mb-1">
                                Visão
                            </p>
                            <p class="txt-h-4 txt-size-18 txt-w-4 txt-white mb-4">
                                Ser a maior, mais segura, e integral plataforma de gestão, pesquisa e orientação sobre
                                licitações e contratos de todo o território brasileiro.
                            </p>
                            <p class="txt-h-3 txt-w-4 txt-white mb-0 recuo mb-1">
                                Valores
                            </p>
                            <p class="txt-h-4 txt-size-18 txt-w-4 txt-white">
                                Clareza. Eficiência. Excelência. Inovação. Responsabilidade. Respeito. Equilíbrio.
                                Integridade. Trabalho em Equipe.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row py-5">
                    <div class="col-12">
                        <div class="box-tabela-planos">
                            <p class="txt-h-2 txt-w-7 txt-size-35 txt-blue-6 text-center">
                                Conheça o que nossos planos podem te oferecer
                            </p>

                            <div class="row mt-4">
                                <div class="col-8 col-md-6">

                                </div>
                                <div class="col-2 col-md-3">
                                    <p class="txt-h-5 txt-w-7 txt-size-18 txt-blue-6 text-center">
                                        Sollicita PRO
                                    </p>
                                </div>
                                <div class="col-2 col-md-3">
                                    <p class="txt-h-5 txt-w-7 txt-size-18 txt-blue-6 text-center">
                                        Sollicita
                                    </p>
                                </div>
                            </div>
                            <div class="tabela-planos">
                                <div class="row linha-planos " v-for="(item,index) in lstTabelaPlanos" :key="index">
                                    <div class="col-8 col-md-6 detalhe-plano">
                                        <div class="d-flex align-items-center">
                                            <!--<img class="img-ferramenta mr-4" :src="item.img" onerror="this.onerror=null; this.src='/image-not-found.jpg'" />-->
                                            <img :class="'mr-4 '+ (item.flRevista?'img-fluid':'img-ferramenta') " :src="item.img" />
                                            <div>
                                                <p class="txt-h-5 txt-w-7 txt-blue-6 mb-2">
                                                    {{item.titulo}}
                                                </p>
                                                <div v-html="item.desc"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 col-md-3 destaque-pro txt-size-20 d-flex align-items-center justify-content-center">
                                        <i class="fas fa-check check-green " v-if="item.flPro"></i>
                                        <i class="fas fa-minus icon-minus" v-else></i>
                                    </div>
                                    <div class="col-2 col-md-3 txt-size-20 d-flex align-items-center justify-content-center">
                                        <i class="fas fa-check check-green" v-if="item.flPf"></i>
                                        <i class="fas fa-minus icon-minus" v-else></i>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12 col-md-6 ">

                                </div>
                                <div class="col-6 col-md-3">
                                    <button class="btn btn-yellow w-100" @click="showModalExperimenteGratis = !showModalExperimenteGratis">
                                        Teste o PRO por 7 dias grátis
                                    </button>
                                </div>
                                <div class="col-6 col-md-3">
                                    <a class="btn btn-blue-outline w-100" :href="urlHotmart" target="_blank">
                                        Assine já!
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="opinioes">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="divisor-portal d-flex align-items-center py-3">
                        <div class="" style="border-top: 2px solid #163142; width: 40%;"></div>
                        <span class="txt-h-2 txt-size-35 txt-w-7 txt-blue-6 mx-3">
                            Opinião de quem usa
                        </span>
                        <div class="" style="border-top: 2px solid #163142; width: 40%;"></div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="bg-opinioes py-4">
                        <p class="txt-h-3 txt-size-25 txt-w-7 txt-white text-center">
                            São + de 7.000.000 milhões de acessos com 98% de satisfação!
                        </p>

                        <div class="row">
                            <div class="col-1 col-md-4 position-relative">
                                <div class="opiniao-oculta-left">
                                    <p class="txt-h-5 txt-w-4 txt-white txt-italic">
                                        "{{getOpiniaoOculta(0).texto}}"
                                    </p>
                                    <p class="txt-h-5 txt-w-7 txt-white text-right mb-0">
                                        {{getOpiniaoOculta(0).autor}}
                                    </p>
                                    <p class="txt-h-5 txt-w-7 txt-white text-right mb-0">
                                        {{getOpiniaoOculta(0).cargo}}
                                    </p>
                                </div>

                                <p class="txt-right txt-size-70 pr-0 pr-md-5 c-pointer" @click="prevDepoimento">
                                    <i class="fas fa-caret-left txt-white"></i>
                                </p>

                            </div>
                            <div class="col-10 col-md-4 content-opinioes">
                                <p class="txt-h-5 txt-w-4 txt-white txt-italic">
                                    "{{depoimentos_carousel[indexDepoimentos].texto}}"
                                </p>
                                <p class="txt-h-5 txt-w-7 txt-white text-right mb-0">
                                    {{depoimentos_carousel[indexDepoimentos].autor}}
                                </p>
                                <p class="txt-h-5 txt-w-7 txt-white text-right mb-0">
                                    {{depoimentos_carousel[indexDepoimentos].cargo}}
                                </p>

                            </div>
                            <div class="col-1 col-md-4 position-relative">
                                <div class="opiniao-oculta-right">
                                    <p class="txt-h-5 txt-w-4 txt-white txt-italic">
                                        "{{getOpiniaoOculta(1).texto}}"
                                    </p>
                                    <p class="txt-h-5 txt-w-7 txt-white text-right mb-0">
                                        {{getOpiniaoOculta(1).autor}}
                                    </p>
                                    <p class="txt-h-5 txt-w-7 txt-white text-right mb-0">
                                        {{getOpiniaoOculta(1).cargo}}
                                    </p>
                                </div>


                                <p class="txt-left txt-size-70 pl-0 pl-md-5 c-pointer" @click="nextDepoimento">
                                    <i class="fas fa-caret-right txt-white"></i>
                                </p>

                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-3">

                            </div>
                            <div class="col-6 text-center">
                                <i :class="'fas fa-circle mx-1 '+(item == indexDepoimentos+1? 'txt-cores-1':'')" v-for="item in depoimentos_carousel.length" :key="item"></i>
                            </div>
                            <div class="col-3">

                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-1 col-md-3">

                            </div>
                            <div class="col-10 col-md-6 text-center">
                                <button class="btn btn-yellow w-50" @click="showModalExperimenteGratis = !showModalExperimenteGratis">
                                    Quero me juntar ao Sollicita agora!
                                </button>
                            </div>
                            <div class="col-1 col-md-3">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
        <ExperimenteGratis :showModal="showModalExperimenteGratis"></ExperimenteGratis>

        <Footer></Footer>
    </div>
</template>

<script>
    import ExperimenteGratis from '@/components/ExperimenteGratis'

    import logo from '@/assets/img/logo_sollicita.png';
    import logoPlanoPro from '@/assets/img/logo_plano_pro.png'
    import logoPlanoPf from '@/assets/img/logo_plano_pf.png'
    import imgSol from '@/assets/img/sol_planos.png'

    import imgAgenda from '@/assets/img/planos_agenda.png'
    import imgAnalisador from '@/assets/img/planos_analisador.png'
    import imgCapacitacao from '@/assets/img/planos_capacitacao.png'
    import imgContador from '@/assets/img/planos_contador.png'
    import imgModelos from '@/assets/img/planos_modelos.png'
    import imgOrientacoes from '@/assets/img/planos_orientacoes.png'
    import imgPenalidades from '@/assets/img/planos_penalidades.png'
    import imgLeiComentada from '@/assets/img/planos_lei_comentada.png'
    import imgLeiRegulamentada from '@/assets/img/planos_lei_regulamentada.png'
    import imgLivraria from '@/assets/img/planos_livraria.png'
    import imgPesquisa from '@/assets/img/planos_pesquisa.png'
    import imgLicicon from '@/assets/img/planos_revista_licicon.png'
    import imgNegocios from '@/assets/img/planos_revista_negocios.png'
    import imgPregoeiro from '@/assets/img/planos_revista_o_pregoeiro.png'
    import imgGovernanca from '@/assets/img/planos_revista_governanca.png'
    import imgPortal from '@/assets/img/planos_portal.png'
    import imgMural from '@/assets/img/planos_mural.png'
    import imgEditais from '@/assets/img/planos_editais.png'
    import imgFornecedores from '@/assets/img/planos_fornecedores.png'
    import imgBiblioteca from '@/assets/img/planos_bilbioteca.png'

    import Footer from '@/components/Footer'

    import { urlHotmart } from '@/config/global'

    export default {
        name: 'Planos',
        components: { ExperimenteGratis, Footer },
        data: function () {
            return {
                logo,
                logoPlanoPro,
                logoPlanoPf,
                imgSol,
                urlHotmart,

                showModalExperimenteGratis: false,

                sollicitaPro: {
                    lst: [
                        "Único suporte integral e seguro de licitações e contratos do mercado;",
                        "Orientações técnicas com o menor tempo de respostas do mercado;",
                        "Acesso a orientações por escrito, telefone, whatsapp e mentoria;",
                        "Leis comentadas, jurisprudências selecionadas, acórdãos, modelos de documentos e manuais, revistas especializadas, aulas e eventos gravados EXCLUSIVOS.",
                    ],
                },

                sollicitaPf: {
                    lst: [
                        "Atende diretamente as necessidades de atualização e conhecimento de todos os profissionais envolvidos na área das contratações administrativas.",
                        "Acesso ao Portal de Conteúdo(matérias e artigos diários), A revista O Pregoeiro, e quatro ferramentas, sendo elas: biblioteca, banco de editais, fornecedores e penalidades.",
                    ],
                },


                lstTabelaPlanos: [
                    {
                        img: imgOrientacoes, titulo: 'Sollicita Orientações',
                        desc: '<p class="txt-h-5 txt-w-6 txt-size-14 txt-grey-2 mb-0 ps-2">Orientações Técnicas Administrativas</p> <p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Oferecemos Orientações em quatro modalidades: orientações por escrito, telefone, whatsapp e mentoria!</p>',
                        flPro: true,
                        flPf: false,
                        flRevista: false,
                    },
                    {
                        img: imgModelos, titulo: 'Sollicita Modelos',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-14 txt-grey-2 mb-0 ps-2"><span class="txt-w-6">Modelos, manuais e documentos</span> inerentes a contratação, atualizada constantementepelos especialistas do SOLLICITA PRO.</p>',
                        flPro: true,
                        flPf: false,
                        flRevista: false,
                    },
                    {
                        img: imgLeiComentada, titulo: 'Sollicita Lei comentada',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-14 txt-grey-2 mb-0 ps-2"><span class="txt-w-6">As Leis 14.133/21, 13.303/06 e 8.666/93</span> estão comentadas no Sollicita PRO, com doutrinas, acórdãos, links de conteúdo, artigos, vídeos e comentários exclusivos.</p>',
                        flPro: true,
                        flPf: false,
                        flRevista: false,
                    },
                    {
                        img: imgLeiRegulamentada, titulo: 'Sollicita Lei regulamentada',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-14 txt-grey-2 mb-0 ps-2"><span class="txt-w-6">As Leis 14.133/21, 13.303/06 e 8.666/93</span> estão comentadas no Sollicita PRO, com doutrinas, acórdãos, links de conteúdo, artigos, vídeos e comentários exclusivos.</p>',
                        flPro: true,
                        flPf: false,
                        flRevista: false,
                    },
                    {
                        img: imgPesquisa, titulo: 'Sollicita Pesquisa',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-14 txt-grey-2 mb-0 ps-2"><span class="txt-w-6">Banco de dados</span> para pesquisa de conteúdo técnico e especializado com orientações por escrito já respondidas pelo Corpo Técnico</p>',
                        flPro: true,
                        flPf: false,
                        flRevista: false,
                    },
                    {
                        img: imgLicicon, titulo: 'Revista Digital Licicon',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Publicação técnica mensal, com conteúdo completo e exclusivo sobre licitações e contratos.</p>',
                        flPro: true,
                        flPf: false,
                        flRevista: true,
                    },
                    {
                        img: imgNegocios, titulo: 'Revista Digital Negócios Públicos',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Destinada aos setores públicos Federal, Estaduais e Municipais, contém temas de extrema relevância para a prática dos servidores na área de licitações e compras públicas.</p>',
                        flPro: true,
                        flPf: false,
                        flRevista: true,
                    },
                    {
                        img: imgPregoeiro, titulo: 'Revista Digital O Pregoeiro',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Para os pregoeiros e equipe de apoio que buscam uma ferramenta eficaz de atualização e capacitação, a revista O Pregoeiro é um guia útil com foco na modalidade licitatória do tipo pregão.</p>',
                        flPro: true,
                        flPf: true,
                        flRevista: true,
                    },
                    {
                        img: imgGovernanca, titulo: 'Revista Digital Governança Pública',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">A melhor informação, atualização, cases e capacitação, atendendo aos setores públicos Federais, Estaduais e Municipais, nas figuras dos Gestores de órgãos e entidades, das mais diversas escalas hierárquicas.</p>',
                        flPro: true,
                        flPf: false,
                        flRevista: true,
                    },
                    {
                        img: imgPortal, titulo: 'Portal de conteúdo',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Notícias, Artigos e Vídeos diários.</p>',
                        flPro: true,
                        flPf: true,
                        flRevista: false,
                    },
                    {
                        img: imgMural, titulo: 'Acesso à Rede Social',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Faça parte da maior Rede Pública do Brasil. Siga, curta e compartilhe diversos assuntos com professores, especialistas e colegas da administração pública.</p>',
                        flPro: true,
                        flPf: true,
                        flRevista: false,
                    },
                    {
                        img: imgEditais, titulo: 'Sollicita Editais',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Pesquise, encontre, acesse e salve os editais dos últimos 12 meses, incluindo os de SRP.</p>',
                        flPro: true,
                        flPf: true,
                        flRevista: false,
                    },
                    {
                        img: imgFornecedores, titulo: 'Sollicita Fornecedores',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Encontre os fornecedores ideais para objetos específicos, por região e órgãos contratantes.</p>',
                        flPro: true,
                        flPf: true,
                        flRevista: false,
                    },
                    {
                        img: imgPenalidades, titulo: 'Sollicita Penalidades',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Fique sabendo rapidamente se o fornecedor já foi penalizado.</p>',
                        flPro: true,
                        flPf: true,
                        flRevista: false,
                    },
                    {
                        img: imgCapacitacao, titulo: 'Sollicita Capacitação',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Eventos, Seminários, Cursos, Aulas e Lives gravadas com material complementar e Cursos intensivos exclusivos</p>',
                        flPro: true,
                        flPf: false,
                        flRevista: false,
                    },
                    {
                        img: imgBiblioteca, titulo: 'Sollicita Biblioteca',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Monte seu próprio banco de informações a partir de qualquer conteúdo encontrado no portal Sollicita.</p>',
                        flPro: true,
                        flPf: true,
                        flRevista: false,
                    },
                    {
                        img: imgAnalisador, titulo: 'Sollicita Analisador de Balanços',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Insira os dados e calcule o índice contábil quanto a performance financeira do fornecedor.</p>',
                        flPro: true,
                        flPf: false,
                        flRevista: false,
                    },
                    {
                        img: imgContador, titulo: 'Sollicita Contador de Prazos',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Registre os dados do processo e receba uma sequência de prazos e datas, conforme legislação.</p>',
                        flPro: true,
                        flPf: false,
                        flRevista: false,
                    },
                    {
                        img: imgAgenda, titulo: 'Sollicita Agenda',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Gerenciamento dos processos licitatórios e compromissos diários.</p>',
                        flPro: true,
                        flPf: false,
                        flRevista: false,
                    },
                    {
                        img: imgLivraria, titulo: 'Sollicita Livraria',
                        desc: '<p class="txt-h-5 txt-w-4 txt-size-12 txt-grey-2 mb-0 ps-2">Conte com uma livraria particular na área de licitações e contratos.E - Books digitais para baixar, ler e consultar onde e quando você precisar.</p>',
                        flPro: true,
                        flPf: true,
                        flRevista: false,
                    },
                ],


                depoimentos_carousel: [
                    { autor: 'Charles Diniz', cargo: 'Diretor na área de licitações e contratos e Pregoeiro e Presidente das nas Comissões de Licitações do Tribunal de Justiça do Estado de São Paulo', texto: '“Num mundo em que a demanda por respostas ágeis e inovadoras da Administração Pública frente às necessidades da sociedade, a atuação das áreas de compras e contratos públicos é posta à prova diariamente. E, neste cenário, o Portal Sollicita se tornou ferramenta de trabalho indispensável para manter a alta performance das equipes, trazendo com rapidez e precisão informações práticas e úteis ao dia a dia do Pregoeiro, Comissões e demais atores em compras públicas, por meio de entrevista com profissionais altamente qualificados, artigos com visão prática e de efetivo uso, além de vasto material de apoio.”' },
                    { autor: 'Paulo Sérgio de Monteiro Reis', cargo: 'Advogado e Engenheiro Civil', texto: '“O Sollicita é, hoje, o maior e o melhor portal sobre licitações e contratos. Atualizado diariamente, o portal apresenta conteúdo indispensável para todos aqueles que atuam na área, com entrevistas, notícias, análises, comentários, enfim, tudo o que é importante para conhecimento dos servidores. A assinatura para acesso ao Portal é uma obrigação de todos aqueles que querem trabalhar corretamente nessa difícil atividade.”' },
                    { autor: 'Cristiano Heckert', cargo: 'Secretário de Gestão do Ministério da Economia', texto: '“O Portal Sollicita se consolidou nos últimos anos como uma referência para os agentes de contratação pública em todo o Brasil. Nele, encontramos notícias do momento, bem como artigos e conteúdos mais extensos. Merece destaque especial o Mural dos Professores, onde os melhores instrutores nessa temática no País compartilham apresentações, vídeos e textos.”' },
                ],

                indexDepoimentos: 0,
            }
        },
        methods: {
            prevDepoimento() {
                if (this.indexDepoimentos == 0) {
                    this.indexDepoimentos = this.depoimentos_carousel.length - 1;
                } else {
                    this.indexDepoimentos--;
                }
            },

            nextDepoimento() {
                if (this.indexDepoimentos == (this.depoimentos_carousel.length - 1)) {
                    this.indexDepoimentos = 0;
                } else {
                    this.indexDepoimentos++;
                }
            },

            getOpiniaoOculta(tp) {
                if (tp == 0) {
                    if (this.indexDepoimentos == 0) {
                        return this.depoimentos_carousel[this.depoimentos_carousel.length - 1];
                    } else {
                        return this.depoimentos_carousel[this.indexDepoimentos - 1];
                    }
                    
                } else {
                    if (this.indexDepoimentos == (this.depoimentos_carousel.length - 1)) {
                        return this.depoimentos_carousel[0];
                    } else {
                        return this.depoimentos_carousel[this.indexDepoimentos + 1];
                    }
                }

            },

            timerOpinioes: null,
        },
        mounted: function () {
            let $this = this;
            $this.timerOpinioes = setInterval(function () { $this.nextDepoimento(); }, 5000);
        },
        unmounted: function () {
            let $this = this;
            if ($this.timerOpinioes) {
                clearInterval($this.timerOpinioes);
            }
            
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .check-green {
        color: #91C06B;
    }

    .icon-minus {
        color: #B3B3B3;
    }

    .my-6 {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }

    .bg-header {
        background-image: url('../assets/img/bg_header_planos.png');
        background-repeat: no-repeat;

        .header-azul {
            background-image: url('../assets/img/bg_header_planos_overlay.png');
            background-repeat: no-repeat;

            .logo-sollicita {
                width: 125px;
            }

            .box-plano-pro {
                background: #FFFFFF;
                border: 3px solid #FBBC0D;
                box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.25);
                border-radius: 20px;
                padding: 60px 30px 30px 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;

                .tag-melhor-plano {
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: rgb(251,188,13);
                    background: linear-gradient(135deg, rgba(251,188,13,1) 50%, transparent 50%);
                    width: 130px;
                    height: 130px;
                    border-radius: 15px 0 0 0;

                    div {
                        bottom: 8px;
                        left: -12px;
                        position: absolute;
                        transform-origin: 0 0;
                        transform: rotate(-45deg);
                    }
                }
            }

            .box-plano-pf {
                background: #FFFFFF;
                border: 3px solid #E9E9E9;
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
                border-radius: 20px;
                padding: 60px 30px 30px 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }

    .sol {
        position: relative;

        .img-sol {
            position: absolute;
            bottom: 12vh;
            z-index: -1;
        }
    }

    .sessao-notebook {
        background-image: url('../assets/img/bg_notebook_planos.png');
        background-repeat: no-repeat;
        background-position: center top;
        padding-top: 35vh;
        margin-top: 6vw;

        .box-sessao-notebook {
            border: 1px solid #FFFFFF;
            border-radius: 15px;
            padding: 15px 20px;
            min-height: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .divisor-horizontal {
            position: absolute;
            width: 100px;
            height: 1px;
            background-color: #FFF;
            right: -14px;
            width: 27px;
        }

        .box-sessao-notebook.box-alt {
            background: rgba(255, 255, 255, 0.25);
        }

        .borda-notebook-citacao {
            padding: 20px;
            background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), linear-gradient(transparent, transparent);
            background-repeat: no-repeat;
            background-size: 1px 43%, 5% 1px, 1px 43%, 5% 1px, calc(100% - 2px) calc(100% - 2px);
            background-position: 0px 0, 0 0px, 100% 100%, 100% 100%, 2px 2px;
        }

        .box-segmentos {
            background: rgba(77, 77, 77, 0.3);
            border: 1px solid #FFFFFF;
            backdrop-filter: blur(10px);
            padding: 40px;
            border-radius: 15px;
        }

        .box-tabela-planos {
            background: #F9F9F9;
            box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            padding: 40px;

            .tabela-planos {

                .linha-planos {

                    .detalhe-plano {
                        padding: 10px 25px;

                        .img-ferramenta {
                            width: 50px;
                            height: 50px;
                        }

                    }
                }

                .destaque-pro {
                    background: #FFFFFF;
                    /*box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white, 12px 0 15px -4px rgba(0, 0, 0, 0.25), -12px 0 15px -4px rgba(0, 0, 0, 0.25);*/
                    /*border-radius: 20px 20px 0 0;*/
                    border-left: 1px solid #dededed6;
                    border-right: 1px solid #dededed6;
                }

                .linha-planos:first-child .destaque-pro {
                    border-top: 1px solid #dededed6;
                    border-radius: 20px 20px 0 0;
                }

                .linha-planos:last-child .destaque-pro {
                    border-bottom: 1px solid #dededed6;
                    border-radius: 0 0 20px 20px;
                }



                .linha-planos:nth-child(even) {
                    background: #F9F9F9
                }

                .linha-planos:nth-child(odd) {
                    background: #EEEFF0
                }
            }
        }
    }

    .opinioes {

        .bg-opinioes {
            background-image: url('../assets/img/bg_opinioes_planos.png');
            background-repeat: no-repeat;
            background-position: center top;

            .content-opinioes {
                /*height: 240px;*/
            }

            .opiniao-oculta-left {
                position: absolute;
                /*left: -17%;*/
                right: 10vw;
                bottom: 0;
                width: 500px;
                filter: blur(2px);
                top: 5%;
            }

            .opiniao-oculta-right {
                position: absolute;
                /*right: -17%;*/
                left: 10vw;
                bottom: 0;
                width: 500px;
                filter: blur(2px);
                top: 5%;
            }
        }
    }
    
</style>

