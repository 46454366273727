<template>
    <section class="LeitorPdf">
        <component :is="'script'" src="../pdf.js" async></component>
        <div class="pdfreader__menu-acesso"></div>
        <nav class="pdfreader__menu d-none d-md-block">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-4 d-flex justify-content-center justify-content-md-start">
                        <div class="text-light py-2 py-md-3">
                            {{nmArquivo}}
                        </div>
                    </div>
                    <div class="col-12 col-md-4 py-2 justify-content-center align-items-center paginas d-flex">
                        <input type="text" id="paginador" name="paginador" v-model="pagina" class="border rounded p-1 text-center border-secondary mr-1" @keyup="paginador" > / <span id="page_count">{{page_count}}</span>
                    </div>
                    <div class="col-12 col-md-4 justify-content-center justify-content-md-end d-flex" id="dropDownload">
                        <div class="dropdown" v-if="cortesia != 'Sim'">
                            <button class="btn btn-outline-primary border-secondary text-light bg-dark mr-2 px-3 py-2 dropdown-toggle h-100" data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true" id="btnDownload">Download</button>
                            <div class="dropdown-menu text-light bg-dark" aria-labelledby="btnDownload">
                                <div class="dropdown-item dropDownload  text-light bg-dark">
                                    <h2 class="termosAceite__titulo">Termos de aceite.</h2>
                                    <p>Este material é somente para uso próprio.</p>

                                    <div class="checkbox">
                                        <input type="checkbox" id="concordoTermos" name="concordoTermos" v-model="concordoTermos" style="display: none;">
                                        <label for="concordoTermos" class="check labelConcordoTermos">

                                            <svg width="18px" height="18px" viewBox="0 0 18 18">
                                                <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                                <polyline points="1 9 7 14 15 4"></polyline>
                                            </svg>
                                        </label>
                                        <span> Concordo com os termos.</span>
                                    </div>
                                    <button class="btn btn-outline-primary border-secondary rounded text-light bg-dark" @click="verificaDownload">
                                        Baixar <i class="fas fa-file-download"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <button class="btn btn-outline-primary border-secondary text-light bg-dark mr-2 px-3" id="paginaAnterior" @click="paginaAnterior">Anterior</button>
                        <button class="btn btn-outline-primary border-secondary text-light bg-dark px-3" id="proximaPagina" @click="proximaPagina">Próxima</button>
                    </div>
                </div>
            </div>
        </nav>
        <div class="pdfreader">
            <div class="d-flex justify-content-center">
                <div id="pdf-viewer"></div>
            </div>
        </div>

        <!-- Loading -->
        <div id="Loading" class="modalLoading" v-if="loading">
            <i class="fas fa-circle-notch fa-spin fa-3x"></i>
        </div>

        <div class="botoesZoom d-none d-md-block">
            <a href="javascript:void(0)" class="telaCheia rounded-circle" @click="telaCheia"><i class="fas fa-compress"></i></a>
            <a href="javascript:void(0)" class="aumentaZoom rounded-circle" @click="aumentaZoom"><i class="fas fa-search-plus"></i></a>
            <a href="javascript:void(0)" class="diminuirZoom rounded-circle" @click="diminuirZoom"><i class="fas fa-search-minus"></i></a>
        </div>
    </section>
</template>

<script>
    import $ from "jquery";
    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'
    export default {
        name: 'LeitorPdf',
        components: { },
        computed: {
            user() {
                return this.$store.state.user
            },
        },
        props: {
            urlArquivo: {
                type: String,
                required: true,
            },

        },
        data() {
            return {
                cortesia: 'Sim',
                nmArquivo: '',
                concordoTermos: false,
                posProxPagePdf: 0,
                posPageAnteriorPdf: 0,
                pageAtualPdf: 1,
                pdfjsLib: null,
                thePdf: null,
                scale: 2,
                loading: true,

                page_count: 1,
                pagina: null,
            }
        },
        methods: {
            load() {
                let $this = this;
                axios.get(`${urlApi}/Auth/GetDadosUser`, { params: { } })
                    .then(resp => {
                        $this.cortesia = resp.data.flCortesia;
                    })
                    .catch(resp => {
                        if (resp.response.data != undefined) {
                            avisoErro(resp.response.data);
                        } else {
                            avisoErro(resp.response.statusText);
                        }
                    });
            },

            downloadObjectAsJson(exportObj, exportName) {
                var dataStr = "data:application/pdf;base64," + exportObj;
                var downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute("href", dataStr);
                downloadAnchorNode.setAttribute("download", exportName);
                document.body.appendChild(downloadAnchorNode);
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            },

            verificaDownload() {
                if (this.concordoTermos) {
                    this.downloadPdf();
                } else {
                    avisoErro('Para baixar o arquivo é necessário aceitar os termos de utilização do mesmo!');
                }
            },

            downloadPdf() {
                let $this = this;
                var tpArquivo = 'ORIJU';

                if (this.urlArquivo.includes('Perio')) {
                    tpArquivo = 'PERIO';
                } else {
                    if (this.urlArquivo.includes('ModeloArquivo')) {
                        tpArquivo = 'MOD';
                    }
                }

                axios.post(`${urlApi}/Log/SalvarLogAceiteTermo`, { p_urlArquivo: $this.urlArquivo, p_tpArquivo: tpArquivo, p_dsTermo: 'Concordo com os termos' })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.msg == null || res.data.msg == '') {
                                res.data.msg = 'arquivo';
                            }

                            axios.get(`${urlApi}/Utilidade/GetArquivo`, { params: { urlArquivo: $this.urlArquivo } })
                                .then(resp => {
                                    return $this.downloadObjectAsJson(resp.data.data, res.data.msg + '.pdf');
                                })
                                .catch(resp => {
                                    if (resp.response.data != undefined) {
                                        avisoErro(resp.response.data);
                                    } else {
                                        avisoErro(resp.response.statusText);
                                    }
                                });
                        }
                    })
                    .catch(res => {
                        if (res.response.data != undefined) {
                            avisoErro(res.response.data);
                        } else {
                            avisoErro(res.response.statusText);
                        }
                    });

            },

            telaCheia() {
                if ($('.pdfreader').hasClass('fullScreen')) {
                    $('.pdfreader').css('padding', '0 10rem');
                    $('.pdfreader').css('width', '100%');
                }
                else {
                    $('.pdfreader').css('padding', '0 0.5rem');
                    $('.pdfreader').css('width', 'calc(100vw - 17px)');
                }
                $('body').css('overflow-x', 'hidden');
                $('.pdfreader').toggleClass('fullScreen');
            },

            aumentaZoom() {
                var tamanho = $('.pdfreader').width();
                $('.pdfreader').css('padding', '0 0.5rem');
                $('.pdfreader').css('width', `calc(${tamanho}px + 50px)`);
                if ($('.pdfreader').width() > window.innerWidth) {
                    $('body').css('overflow-x', 'scroll');
                }
            },

            diminuirZoom() {
                var tamanho = $('.pdfreader').width();
                $('.pdfreader').css('padding', '0 0.5rem');
                $('.pdfreader').css('width', `calc(${tamanho}px - 50px)`);
                if ($('.pdfreader').width() < window.innerWidth) {
                    $('body').css('overflow-x', 'hidden');
                }
            },

            setPagina(page) {
                this.pageAtualPdf = page;
                this.posProxPagePdf = parseInt($(`#canvas-pdf-page-${this.pageAtualPdf + 1}`).attr('data-local'));
                this.posPageAnteriorPdf = parseInt($(`#canvas-pdf-page-${this.pageAtualPdf - 1}`).attr('data-local'));
                $('#paginador').val(this.pageAtualPdf);
            },

            goToPage(page) {
                this.setPagina(page);
                $(document).scrollTop(parseInt($(`#canvas-pdf-page-${page}`).attr('data-local')) - 65);
            },

            paginaAnterior() {
                if ($('#paginador').val() > 1) {
                    this.goToPage(parseInt($('#paginador').val()) - 1)
                }
            },

            proximaPagina() {
                if ($('#paginador').val() < parseInt($('#page_count').html())) {
                    this.goToPage(parseInt($('#paginador').val()) + 1);
                }
            },

            paginador(e) {
                var keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    return this.goToPage(parseInt($('#paginador').val()));
                }
            },

            getArquivo(url) {
                let $this = this;
                axios.get(`${urlApi}/Utilidade/GetArquivo`, { params: { urlArquivo: url } })
                    .then(resp => {
                        $this.loading = false;
                        this.createPdfViewer('data:application/pdf;base64,' + resp.data.data);

                        $(document).scroll(function () {
                            if (window.pageYOffset >= $this.posProxPagePdf) {
                                $this.setPagina($this.pageAtualPdf + 1);
                            } else if (window.pageYOffset <= $this.posPageAnteriorPdf) {
                                $this.setPagina($this.pageAtualPdf - 1);
                            }
                        });
                    })
                    .catch(resp => {
                        $this.loading = false;
                        if (resp.response.data != undefined) {
                            avisoErro(resp.response.data);
                        } else {
                            avisoErro(resp.response.statusText);
                        }
                    });
            },

            renderPage(pageNumber, canvas) {
                let $this = this;
                $this.thePdf.getPage(pageNumber).then(function (page) {
                    var viewport = page.getViewport($this.scale);
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    page.render({ canvasContext: canvas.getContext('2d'), viewport: viewport });
                    $(canvas).attr('data-local', $(canvas).offset().top);
                    if (pageNumber == 2) {
                        $this.posProxPagePdf = parseInt($('#canvas-pdf-page-2').attr('data-local'));
                    }
                });
            },

            createPdfViewer(urlArquivo) {
                let $this = this;
                $this.pdfjsLib.getDocument(urlArquivo).promise.then(function (pdf) {
                    $this.thePdf = pdf;
                    $this.page_count = pdf._pdfInfo.numPages;
                    $this.pagina = 1;
                    var viewer = document.getElementById('pdf-viewer');

                    for (var page = 1; page <= pdf.numPages; page++) {
                        var canvas = document.createElement("canvas");
                        $(canvas).attr('id', 'canvas-pdf-page-' + page);
                        canvas.className = 'pdf-page-canvas';
                        viewer.appendChild(canvas);
                        $this.renderPage(page, canvas);
                    }
                });
            },

        },
        mounted: function () {
            let $this = this;
            
            $this.load();

            setTimeout(function () {
                $this.pdfjsLib = window['pdfjs-dist/build/pdf'];
                // The workerSrc property shall be specified.
                $this.pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';
                $this.getArquivo($this.urlArquivo);
                $('.pdfreader__menu').css('top', '-3.5rem');

                $('.pdfreader__menu-acesso').mouseenter(function () {
                    $('.pdfreader__menu').stop();
                    $('.pdfreader__menu').animate({ 'top': '0' }, 500);
                });

                $('.pdfreader__menu').mouseleave(function () {
                    $('.pdfreader__menu').stop();
                    $('.pdfreader__menu').animate({ 'top': '-3.5rem' }, 500);
                });

                $(".dropdown-menu").click(function (event) {
                    event.stopPropagation();
                });
            }, 2000)

        },
        watch: {
            
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    .LeitorPdf {
        background-color: #4d4d4d !important;
    }

    .pdf-page-canvas {
        width: 100%;
        border: none;
        padding: 0;
        overflow: scroll;
        z-index: 9999;
        margin-bottom: 1rem;
        box-shadow: 2px 2px 10px black;
    }

    .pdfreader {
        top: 4rem;
        bottom: 5rem;
        width: 100%;
        min-height: 100vh;
        position: relative;
        z-index: 1;
        padding: 0 10rem;
        max-width: 99vw;
        margin: 0 auto;
    }

    .pdfreader__menu {
        background-color: #333131;
        top: 0;
        padding: 0.2rem 1rem;
        z-index: 3;
        width: 100%;
        position: fixed;
    }

    .paginas {
        color: #fff;
    }

    .botoesZoom {
        position: fixed;
        right: 20px;
        bottom: 20px;
        z-index: 3;
    }

    .botoesZoom a {
        display: block;
        clear: both;
        font-size: 1.3rem;
        width: 36px;
        height: 36px;
        text-align: center;
        color: #4d4d4d;
        padding: .2rem;
        background: #FFF;
        margin-top: 10px;
        transition: background, 0.3s;
    }

    .botoesZoom a:hover {
        background: #e6e6e6 radial-gradient(circle, transparent 1%, #e6e6e6 1%) center/15000%;
    }

    .botoesZoom a:active {
        background-color: #e6e6e6;
        background-size: 100%;
        transition: background 0s;
    }

    @media (max-width: 991.98px) {
        .pdfreader__menu {
            position: relative;
        }

        .pdfreader {
            padding: 0;
        }
    }

    #paginador {
        width: 40px;
        display: inline-block;
        background-color: #333131;
        color: #fff;
    }

    #paginador:focus {
        background-color: #4d4d4d;
    }

    .pdfreader__menu-acesso {
        background-color: transparent;
        height: 3.5rem;
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 2;
    }

    .dropDownload {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .termosAceite {
        font-size: 1.1rem;
    }

    .termosAceite * {
        margin-bottom: 0.5rem;
    }

    .labelConcordoTermos {
        font-size: 0.9rem;
        color: #fff;
    }

    .termosAceite__titulo {
        font-size: 1.5rem;
    }

    .check {
        cursor: pointer;
        position: relative;
        margin: auto;
        -webkit-tap-highlight-color: transparent;
        transform: translate3d(0, 0, 0);
        user-select: none;
    }

    .check svg {
        position: relative;
        z-index: 1;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: #c8ccd4;
        stroke-width: 1.5;
        transform: translate3d(0, 0, 0);
        transition: all 0.2s ease;
    }

    .check svg path {
        stroke-dasharray: 60;
        stroke-dashoffset: 0;
    }

    .check svg polyline {
        stroke-dasharray: 22;
        stroke-dashoffset: 66;
    }

    .check:hover svg {
        stroke: #fff;
    }

    #concordoTermos:checked + .check svg {
        stroke: #fff;
    }

    #concordoTermos:checked + .check svg path {
        stroke-dashoffset: 60;
        transition: all 0.3s linear;
    }

    #concordoTermos:checked + .check svg polyline {
        stroke-dashoffset: 42;
        transition: all 0.2s linear;
        transition-delay: 0.15s;
    }

    .checkbox {
        color: #fff;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }

    .modalLoading {
        position: fixed;
        z-index: 9999;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.7);
    }

    #Loading svg {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
    }
</style>

