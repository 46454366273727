<template>
    <div class="viewMODEL">
        

        <div class="modal-conteudo m-2">
            <div id="divImprimir">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="bold">{{dados.nmArquivo}}</h3>
                        <template v-if="dados.modeloCategoria">
                            <p class="bold">Modelo: {{dados.modeloCategoria.modelo.nmModelo}}</p>
                            <p class="bold">Categoria: {{dados.modeloCategoria.nmCategoria}}</p>
                        </template>
                        <span class="regular">{{dados.dsArquivo}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer pb-0 mt-4 d-flex justify-content-end">
            <AdicionarBiblioteca v-if="exibeBiblioteca" :id="dados.idModeloArquivo.toString()" :tipo="tpConteudo" :cdConteudo="cdConteudo"></AdicionarBiblioteca>

            <button class="btn btn-blue txt-size-14" @click="linkDownload(dados)">
                <i class="fas fa-download"></i> Download
            </button>
        </div>
    </div>
</template>

<script>
    import AdicionarBiblioteca from '../AdicionarBiblioteca'
    import { urlApi } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'viewMODEL',
        components: { AdicionarBiblioteca },
        data: function () {
            return {
                linkSollicita: urlApi,
                exibeBiblioteca: true,
                tpConteudo: 'Pesquisa',
                cdConteudo: 'MODEL',
            }
        },
        props: {
            dados: Object,
            busca: Object,
        },
        methods: {
            verificaExisteRepositorio(idConteudo, tpConteudo, cdConteudo) {
                let $this = this;
                axios.get(`${urlApi}/Repositorio`, { params: { idConteudo: idConteudo, tpConteudo: tpConteudo, cdConteudo: cdConteudo } })
                    .then(res => {
                        if (res.data.success) {
                            $this.exibeBiblioteca = res.data.data;
                        } else {
                            console.log('Não foi possível verificar o repositório')
                        }
                    })
                    .catch(function () {
                        console.log('Não foi possível verificar o repositório')
                    });
            },

            linkDownload(modeloArquivo) {
                var nmArquivo = "";
                var url = modeloArquivo.urlArquivo.split('.');
                if (url != []) {
                    nmArquivo = modeloArquivo.nmArquivo + "." + url[url.length - 1];
                } else {
                    nmArquivo = null;
                }

                axios({
                    url: `${urlApi}/Utilidade/BaixarArquivo?urlArquivo=${modeloArquivo.urlArquivo}&nmArquivo${nmArquivo}`, //your url
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', nmArquivo); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },
        },
        mounted: function () {
            this.verificaExisteRepositorio(this.dados.idModeloArquivo.toString(), this.tpConteudo, this.cdConteudo);
        },
        watch: {
            dados: {
                handler() {
                    this.verificaExisteRepositorio(this.dados.idModeloArquivo.toString(), this.tpConteudo, this.cdConteudo);
                },
                deep: true
            },
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    
   
</style>

