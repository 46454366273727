<template>
    <section class="AnalisadorBalancos">
        <Header></Header>

        <div class="container">
            <div class="row my-3">
                <div class="col-12 text-center">
                    <p class="txt-p txt-size-20 txt-analisador_balancos mb-0">
                        Sollicita <span class="txt-w-7">Analisador de Balanços</span>
                    </p>
                </div>
                <div class="col-12 text-end">
                    <ManualFerramenta :name="'Manual Analisador de Balanços'" :btnClass="'btn-analisador_balancos'" :nameBtn="'Manual'" :urlPdf="'/manuais/FERRAMENTA ANALISADOR DE BALANÇOS PDF.pdf'"></ManualFerramenta>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="box-branco">
                        <div class="row mb-3">
                            <div class="input-group">
                                <input v-model="pesquisa.dsTermo" v-on:keyup.enter="buscar" type="text" class="form-control border-analisador-balancos h-100" placeholder="Pesquise por compromissos" aria-label="Pesquise por compromissos" aria-describedby="button-addon2">
                                <button class="btn btn-analisador_balancos w-30" type="button" id="button-addon2" @click="buscar">Buscar</button>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-12">
                                <p class="txt-p txt-size-16 txt-w-5 txt-grey-6 mb-2">
                                    Adicionar novo compromisso:
                                </p>
                                <button class="btn btn-analisador_balancos w-100" @click="novaAnalise">
                                    <i class="fas fa-plus"></i> Adicionar análise
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <p class="txt-p txt-size-20 txt-grey-6 txt-w-7">
                        Análises criadas
                    </p>
                </div>
                <div class="col-12" v-if="lstResultados.length == 0">
                    <div class="box-nova-analise d-flex justify-content-center align-items-center c-pointer" @click="novaAnalise">
                        <div class="me-3">
                            <i class="fas fa-plus fa-2x"></i>
                        </div>
                        <div>
                            <p class="txt-p txt-w-5 txt-size-16 mb-0">
                                Você ainda não possui análises criadas.
                            </p>
                            <p class="txt-p txt-w-5 txt-size-20 mb-0">
                                Clique aqui para adicionar uma análise.
                            </p>                            
                        </div>
                    </div>
                </div>
                <div class="col-12" v-else>
                    <div class="lst-analise">
                        <div class="item-analise mb-2" v-for="(item,index) in lstResultados" :key="index">
                            <div class="row">
                                <div class="col-12">
                                    <p class="txt-p txt-w-7 txt-size-16 txt-analisador_balancos mb-0">
                                        {{item.analise.nmAnalise}}
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <hr />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8 col-sm-9 d-flex">
                                    <div class="icone-analise text-center mr-3" >
                                        <img src="../../../assets/img/icon/iconeAnalise.png" title="Produto" class="img-fluid">
                                        <p class="txt-p txt-size-16 txt-w-7 txt-analisador_balancos mb-0">
                                            Análise
                                        </p>
                                    </div>
                                    <div class="dados-analise d-flex flex-wrap align-content-between py-1">
                                        <div class="w-100">
                                            <p class="txt-p txt-w-5 txt-size-16 txt-grey-3 mb-1">
                                                Código: <span class="txt-w-4">#{{item.codigoFormat}}</span>
                                            </p>
                                            <p class="txt-p txt-w-5 txt-size-16 txt-grey-3 mb-3">
                                                Índice: <span class="txt-w-4">{{formatIndice(item.analise.nrIndiceExigidoLicitacao)}}</span>
                                            </p>
                                        </div>
                                        <p class="txt-p txt-w-5 txt-size-16 txt-grey-3 mb-0">
                                            <template v-if="item.qtdFornecedores == null || item.qtdFornecedores == 0">
                                                Nenhum fornecedor cadastrado
                                            </template>
                                            <template v-else>
                                                <template v-if="item.qtdFornecedores > 1">
                                                    {{item.qtdFornecedores}} fornecedores cadastrados
                                                </template>
                                                <template v-else>
                                                    {{item.qtdFornecedores}} fornecedor cadastrado
                                                </template>
                                            </template>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-3 d-flex flex-wrap align-items-center">
                                    <div class="acoes-analizador-balancos w-100">
                                        <div class="row">
                                            <div class="col-12">
                                                <router-link class="btn btn-analisador_balancos py-1 w-100" :to="{name:'DetalheAnalisadorBalancos', params:{id:item.analise.idAnalise}}">
                                                    <i class="fa fa-eye"></i> Visualizar Prazos
                                                </router-link>
                                            </div>
                                        </div>
                                        <div class="row py-2">
                                            <div class="col-6">
                                                <button type="button" class="btn btn-editar py-1 w-100" @click="editarAnalise(item.analise.idAnalise)">
                                                    <i class="fas fa-pencil-alt"></i> <span class="d-none d-sm-block">Editar</span>
                                                </button>
                                            </div>
                                            <div class="col-6">
                                                <button type="button" class="btn btn-excluir py-1 w-100" @click="removerAnalise(item.analise.idAnalise, item.analise.nmAnalise)">
                                                    <i class="fas fa-trash"></i> <span class="d-none d-sm-block">Remover</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalNovaAnalise :idAnalise="idAnalise" :showModal="showModal" :atualizar="carregaAnalises"></ModalNovaAnalise>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';
    import ModalNovaAnalise from '@/components/AnalisadorBalancos/ModalNovaAnalise';
    import ManualFerramenta from '@/components/ManualFerramenta';

    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import axios from 'axios'
    import Swal from 'sweetalert2'

    export default {
        name: 'AnalisadorBalancos',
        components: { Header, Footer, ModalNovaAnalise, ManualFerramenta },
        computed: {
            user() {
                return this.$store.state.user
            },

        },
        data() {
            return {
                
                pesquisa: {
                    dsTermo: null,                    
                },
                
                lstResultados: [],

                idAnalise: 0,
                showModal: false,
            }
        },
        methods: {
            load() {
                this.carregaAnalises();
            },

            carregaAnalises() {
                let $this = this;
                axios.get(`${urlApi}/AnalisadorBalancos/Get`, { params: { termo: $this.pesquisa.dsTermo } })
                    .then(res => {
                        if (res.data.success) {
                            var result = res.data.data;
                            $this.lstResultados = result;
                        } else {
                            avisoErro("Não foi possível carrega as anáises");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carrega as análises");
                    });
            },

            buscar() {
                let $this = this;
                if ($this.validarBuscar()) {
                    $this.carregaAnalises();
                }
            },
                        
            validarBuscar() {
                let $this = this;
                if ($this.pesquisa.dsTermo == null || $this.pesquisa.dsTermo.length < 3) {
                    avisoErro('O campo "Busca" deve conter ao menos 3 caracteres');
                    //this.$refs.dsTermo.$el.focus()
                    return false;
                }

                return true;
            },

            novaAnalise() {
                this.idAnalise = 0;
                this.showModal = !this.showModal;
            },

            editarAnalise(idAnalise) {
                this.idAnalise = idAnalise;
                this.showModal = !this.showModal;
            },

            removerAnalise(idAnalise, nmAnalise) {
                let $this = this;
                Swal.fire({
                    title: 'Tem certeza que deseja excluir "' + nmAnalise + '"?',
                    text: 'Esta operação não poderá ser desfeita.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    confirmButtonColor: '#83A431',
                }).then((result) => {
                    if (result.isConfirmed) {
                        $this.excluirAnalise(idAnalise);
                    }
                })
            },

            excluirAnalise(idAnalise) {
                let $this = this;
                axios.delete(`${urlApi}/AnalisadorBalancos/Delete`, { params: { idAnalise: idAnalise } })
                    .then(res => {
                        if (res.data.success) {
                            $this.carregaAnalises();
                            avisoSucesso("Análise excluida com sucesso");
                        } else {
                            avisoErro("Não foi possível excluir a análise");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível excluir a análise");
                    });
            },

            formatIndice(nrIndice) {
                if (nrIndice != null) {
                    var novoInice = nrIndice.toFixed(2)
                    return novoInice.replace('.', ',');
                } else {
                    return ""
                }
            },
                        
        },
        mounted: function () {
            this.load();
        },
        watch: {

        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    .box-branco {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }

    .border-analisador-balancos {
        border: 1px solid #00832A;
    }

    .box-nova-analise {
        background-color: #FFFFFF;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23B7B7B7FF' stroke-width='5' stroke-dasharray='10%2c 20' stroke-dashoffset='15' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 20px;
        padding: 30px;
        color: #B7B7B7;
    }

    .lst-analise {
        .item-analise {
            background: #FFFFFF;
            border: 3px solid #A6A6A6;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            padding: 20px;

            .icone-analise {
                padding: 20px;
                background: #F4F4F4;
                border-radius: 5px;
            }

        }
    }
</style>

