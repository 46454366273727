<template>
    <div class="NoticiaVideoModal">
        <div v-if="video != null">

            <div class="box-video mt-4">
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-6">
                        <template v-if="video.tpExibicao == 'Local'">
                            <VideoPlayer :options="getUrlVideo()"></VideoPlayer>
                            <!--<video class="video-publicacao" width="100%" height="auto" allowfullscreen controls="" data-setup="{}" controlsList="nodownload">
                                <source :src="getUrlVideo()" type="video/mp4">
                                <source :src="getUrlVideo()" type="video/webm">
                            </video>-->
                        </template>
                        <template v-else>
                            <iframe class="video-player" width="100%" height="500px" :src="getUrlVideo()" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </template>
                    </div>
                    <div class="col-3"></div>
                </div>
            </div>

            <div class="mt-2">
                <div class="row">
                    <div class="col-12">
                        <p class="txt-h-2 txt-size-30 txt-blue-2 ">
                            {{video.nmTitulo}}
                        </p>
                    </div>
                </div>
                <div class="container mt-3">
                    <div class="row">
                        <div class="col-12">
                            <span class="txt-h-6 txt-size-12 txt-grey-2">{{formatDate(video.dtInclusao)}}</span>

                            <div class="mt-2 txt-p txt-grey-2 txt-size-16 recuo" v-html="video.dsDescricao"></div>

                            <Tags class="mt-3" :titulo="'TAGS'" :lstTags="lstTags" :link="'PesquisaVideo'"></Tags>

                            <Comentarios class="mt-4" :id="video.idVideo.toString()" :tipo="'Video'"></Comentarios>
                                                        

                        </div>
                        
                    </div>
                </div>
            </div>

        </div>
        <div v-else>
            <div class="mt-5">
                <div class="container" style="height: 44vh;">
                    <div class="row">
                        <div class="col-12 col-md-12">
                            <span class="txt-h-5 txt-blue-2">Vídeo não encontrado</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'

    import Tags from '@/components/Biblioteca/Tags'
    import Comentarios from '@/components/Biblioteca/Comentarios'
    import VideoPlayer from '@/components/Biblioteca/VideoPlayer'

    export default {
        name: 'NoticiaVideoModal',
        props: {
            idConteudo: Number,
        },
        components: {
            Tags, Comentarios, VideoPlayer
        },
        data: function () {
            return {
                video: null,
                lstTags: [],
            }
        },
        methods: { 
            load() {
                this.carregaVideo();
            },

            carregaVideo() {
                let $this = this;
                axios.get(`${urlApi}/Video/Get/${$this.idConteudo}`)
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data && res.data.data.idVideo) {
                                $this.video = res.data.data;

                                $this.montaTags($this.video.dsTags)
                            }

                        } else {
                            avisoErro("Não foi possível carregar o vídeo");
                            $this.video = null;
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar o vídeo");
                        $this.video = null;
                    });
            },

            montaTags(tags) {
                let $this = this;
                if (tags != null && tags != undefined) {
                    tags = tags.split(';');
                    if (tags != undefined && tags != null && tags != '') {
                        for (let i = 0; i < tags.length; i++) {
                            let tag = tags[i].trim();
                            $this.lstTags.push(tag);
                        }
                    }
                }
            },

            formatDate(date) {
                return moment(date).format('DD/MM/YYYY [às] HH:mm');
            },

            getUrlVideo() {

                var options = null;

                if (this.video.tpExibicao == 'Local') {
                    options = {
                        autoplay: false,
                        controls: true,
                        sources: [
                            {
                                src: this.video.urlVideo,
                                type: "video/mp4"
                            }
                        ]
                    };
                    return options;
                } else {
                    var url = '';
                    var video = this.video.urlVideo;
                    if (video.includes('vimeo.com')) {
                        let src = video.replace("https://vimeo.com/", "");
                        url = 'https://player.vimeo.com/video/' + src
                    } else if (video.includes('youtube')) {
                        let src = video.replace("https://www.youtube.com/watch?v=", "");
                        if (src.indexOf("&") > 0) {
                            src = src.substring(0, src.indexOf("&"));
                        }
                        url = 'https://www.youtube.com/embed/' + src
                    } else if (video.includes('youtu.be')) {
                        let src = video.replace("https://youtu.be/", "");
                        if (src.indexOf("?") > 0) {
                            src = src.substring(0, src.indexOf("?"));
                        }
                        url = 'https://www.youtube.com/embed/' + src
                    } else {
                        url = video;
                    }

                    options = {
                        autoplay: true,
                        controls: true,
                        sources: [
                            {
                                src: url,
                                type: "video/mp4"
                            }
                        ]
                    };
                    return options;
                }
            },

            formatLink(titulo) {
                if (titulo) {
                    return titulo.toLowerCase().replace(/\s/g, '-');
                } else {
                    return "";
                }
            },
            
        },
        mounted: function () {
            this.load();
        },
        watch: {
            
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">

</style>

