<template>
    <div class="viewNOTIC">
        <div class="modal-conteudo m-2">
            <div id="divImprimir" class="noticia_logado">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <article class="col-md-12" style="border: none; padding: 0;">
                                <h1 class="bold article-titulo" style="font-size:30px; font-weight:bold;">{{dados.noticia.dsTitulo}}</h1>
                                <h2 class="regular article-subtitulo" style="font-size:18px;">{{dados.noticia.dsSubTitulo}}</h2>
                                <div class="article-informacao">
                                    <span class="inline-block">
                                        <span class="inline-block regular article-data" style="font-size:12px;">{{dados.noticia.dtPublicacao}}</span>
                                    </span>
                                </div>
                                <div class="article-conteudo" style="margin-top:5px;">
                                    <div class="article-img">
                                        <img class="img-responsive" :src="dados.noticia.urlImagemInterna">
                                    </div>
                                    <div v-html="dados.noticia.htmNoticia.replace('http://sistemas.negociospublicos.net.br/NP/SollicitaAdmin','')">

                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer pb-0 mt-4 d-flex justify-content-end">
            <input style="display: none;" type="text" id="copyLink" :value="linkSollicita+'Noticia/?p_idNoticia='+dados.noticia.idNoticia" />
            <button class="btn btn-default txt-size-14 me-2" @click="copiarLink">
                Copiar link
            </button>

            <AdicionarBiblioteca v-if="exibeBiblioteca" :id="dados.noticia.idNoticia.toString()" :tipo="tpConteudo" :cdConteudo="cdConteudo"></AdicionarBiblioteca>

            <button class="btn btn-blue txt-size-14" @click="imprimir">
                <i class="fas fa-print"></i> Imprimir
            </button>

        </div>
    </div>
</template>

<script>
    import AdicionarBiblioteca from '../AdicionarBiblioteca'
    import { urlApi, linkSollicita, avisoSucesso } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'viewNOTIC',
        components: { AdicionarBiblioteca },
        data: function () {
            return {
                exibeBiblioteca: true,
                tpConteudo: 'Notícia',
                cdConteudo: '',
                linkSollicita: linkSollicita,
            }
        },
        props: {
            dados: Object,
        },
        methods: {
            copiarLink() {
                var copyText = document.getElementById("copyLink");
                copyText.select();
                copyText.setSelectionRange(0, 99999);
                navigator.clipboard.writeText(copyText.value);

                avisoSucesso("Link copiado");
            },

            verificaExisteRepositorio(idConteudo, tpConteudo, cdConteudo) {
                let $this = this;
                axios.get(`${urlApi}/Repositorio`, { params: { idConteudo: idConteudo, tpConteudo: tpConteudo, cdConteudo: cdConteudo } })
                    .then(res => {
                        if (res.data.success) {
                            $this.exibeBiblioteca = res.data.data;
                        } else {
                            console.log('Não foi possível verificar o repositório')
                        }
                    })
                    .catch(function () {
                        console.log('Não foi possível verificar o repositório')
                    });
            },

            imprimir() {
                var conteudo = document.getElementById('divImprimir').innerHTML,
                    tela_impressao = window.open('about:blank');

                tela_impressao.document.write(conteudo);
                tela_impressao.window.print();
                tela_impressao.window.close();
            },
        },
        mounted: function () {
            this.verificaExisteRepositorio(this.dados.noticia.idNoticia.toString(), this.tpConteudo, this.cdConteudo);
        },
        watch: {
            dados: {
                handler() {
                    this.verificaExisteRepositorio(this.dados.noticia.idNoticia.toString(), this.tpConteudo, this.cdConteudo);
                },
                deep: true
            },
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .printSection label {
        font-weight: normal !important;
        text-align: center;
    }

    .article-titulo {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        padding: 1rem 0;
    }

    .article-subtitulo {
        font-size: 18px;
        text-align: center;
        padding: 1rem;
        color: gray;
    }

    @media print {
        body * {
            visibility: hidden;
        }

        #modalPesquisa .printSection, #modalPesquisa .printSection * {
            visibility: visible;
        }
        /*.printSection .article-informacao {
                visibility: hidden !important;
            }*/
        #modalPesquisa .modal-body {
            height: auto;
            overflow: hidden;
        }

        #modalPesquisa .printSection {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            margin: 0;
            padding: 0 5px;
        }

        #modalPesquisa.modal {
            position: absolute;
            left: 0;
            top: 0;
            margin: 0;
            padding: 0;
            visibility: visible;
            /**Remove scrollbar for printing.**/
            overflow: visible !important;
        }

        #modalPesquisa .modal-dialog {
            visibility: visible !important;
            /**Remove scrollbar for printing.**/
            overflow: visible !important;
        }
    }
   
</style>

