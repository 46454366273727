<template>
    <div class="home position-relative" id="home">

        <span class="voltar-topo" v-show="showVoltarTopo" @click="scrolToDiv('home')">
            <i class="fas fa-angle-double-up"></i>
        </span>

        <div class="container-principal">
            <div class="container">

                <div class="row align-items-center">
                    <div class="col-12 col-md-4">
                    </div>
                    <div class="col-12 col-md-4 d-flex justify-content-center">
                        <router-link :to="{name:'Home'}">
                            <img class="logo-sollicita" :src="logo" />
                        </router-link>
                    </div>
                    <div class="col-12 col-md-4 d-flex justify-content-end">
                        <div>
                            <p class="txt-p txt-white mb-2">Já tem o acesso?</p>
                            <router-link class="btn btn-yellow-outline" :to="{name:'Login'}">
                                Entre na sua conta
                            </router-link>
                        </div>

                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12 text-center">
                        <p class="txt-h-2 txt-size-30 txt-white mb-0"><span class="txt-cores-1">Uma única plataforma. </span> O maior acervo de informações técnicas em licitações e contratos</p>
                        <p class="txt-p txt-size-24 txt-white mt-5 mb-0">Conheça todas as <b>ferramentas</b> que temos disponíveis para você</p>
                        <p class="mt-4 txt-p txt-size-18 txt-white w-30 m-auto">
                            Teste gratuitamente agora.
                        </p>
                        <button class="mt-3 btn btn-yellow px-5" @click="showModalExperimenteGratis = !showModalExperimenteGratis">
                            Experimente grátis!
                        </button>
                    </div>
                    <div class="col-12">

                    </div>
                </div>
                <div class="row justify-content-center mt-5">
                    <div class="w-50">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-4 col-lg-2 d-flex flex-column align-items-center c-pointer" @click="scrolToDiv(item.idDiv)" v-for="item in modulos" :key="item.idModulo">
                                <div class="icone-ferramentas">
                                    <img :src="item.urlIcone" />
                                </div>
                                <span class="txt-h-6 txt-w-5 mt-2 txt-white txt-size-14">
                                    {{item.nmFerramenta}}
                                </span>
                                <i class="fas fa-angle-down fa-2x txt-white"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- PORTAL -->
        <div class="portal mt-5" id="portal">
            <div class="divisor-portal d-flex align-items-center">
                <div class="" style="border-top: 2px solid #163142; width: 22%;"></div>
                <img class="mx-2" width="200px" src="../assets/img/logo_sollicita_portal.png" />
                <div class="w-100" style="border-top: 2px solid #163142;"></div>
            </div>

            <div class="box-principal mt-4">
                <div class="container">
                    <div class="row px-3" v-if="lstNoticias.length > 0">
                        <div class="col-12 col-md-12 col-xl-6 px-0 mb-3" v-if="lstNoticias[0]">
                            <div class="thumb-noticia-capa">
                                <a class="c-pointer" :href="linkPortal+'Noticia/'+lstNoticias[0].idNoticia.toString()+'/'+formatLink(lstNoticias[0].dsTitulo)">
                                    <div class="img-noticia-capa h-100 w-100 zoom" :style="'background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%), url('+lstNoticias[0].urlImagemInterna+');' ">
                                    </div>
                                    <div class="txt-noticia-capa">
                                        <span class="txt-p txt-size-14 txt-w-8 bg-colors-1 txt-white px-2 py-1 rounded">
                                            {{lstNoticias[0].nmSubCategoria}}
                                        </span>
                                        <p class="txt-h-5 txt-size-18 txt-white mt-3 mb-2 reduz-texto-3">
                                            {{lstNoticias[0].dsTitulo}}
                                        </p>
                                        <p class="txt-h-6 txt-size-12 txt-white txt-w-4 mb-2 reduz-texto-2">
                                            {{lstNoticias[0].dsSubTitulo}}
                                        </p>
                                        <p class="txt-h-6 txt-size-12 txt-white mb-0">
                                            {{formatDate(lstNoticias[0].dtPublicacao)}}
                                        </p>
                                    </div>
                                </a>
                            </div>

                        </div>
                        <div class="col-12 col-md-12 col-xl-6 px-0">
                            <div class="d-flex w-100 flex-wrap">
                                <div :class="'col-12 col-md-6 '+(index%2==1?'ps-xs-0 ps-lg-0 ps-xl-3 pe-xs-0 pe-md-0 pe-xl-0':'ps-xs-0 ps-lg-0 ps-xl-3 pe-xs-0 pe-md-3 pe-xl-0')" v-for="(item,index) in lstNoticias.slice(1)" :key="index" style="margin-bottom: 20px;">
                                    <div class="thumb-noticia-p">
                                        <a class="c-pointer" :href="linkPortal+'Noticia/'+item.idNoticia.toString()+'/'+formatLink(item.dsTitulo)">
                                            <div class="img-noticia-p h-100 w-100 zoom" :style="'background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%), url('+item.urlImagemInterna+');'">
                                            </div>
                                            <div class="txt-noticia-capa">
                                                <span class="txt-p txt-size-12 txt-w-8 bg-blue-2 txt-white px-2 py-1 rounded">
                                                    {{item.nmSubCategoria}}
                                                </span>
                                                <p class="txt-h-5 txt-size-14 txt-white my-1 reduz-texto-1">
                                                    {{item.dsTitulo}}
                                                </p>
                                                <p class="txt-h-6 txt-size-12 txt-white txt-w-4 mb-0 reduz-texto-2">
                                                    {{item.dsSubTitulo}}
                                                </p>
                                            </div>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row px-3 mt-3">
                        <div class="col-12 col-md-6 ps-0 pe-sm-0 pe-xl-0 pe-md-3">
                            <div class="d-flex w-100 flex-wrap">
                                <div :class="'col-12 col-lg-6 '+(index%2==1?'ps-xs-0 ps-lg-2':'pe-xs-0 pe-lg-2')" v-for="(item,index) in lstNoticiasSecundarias" :key="index" style="margin-bottom: 20px;">
                                    <div class="thumb-noticia-p">
                                        <a class="c-pointer" :href="linkPortal+'Noticia/'+item.idNoticia.toString()+'/'+formatLink(item.dsTitulo)">
                                            <div class="img-noticia-p h-100 w-100 zoom" :style="'background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%), url('+item.urlImagemInterna+');'">
                                            </div>
                                            <div class="txt-noticia-capa">
                                                <span class="txt-p txt-size-12 txt-w-8 bg-blue-2 txt-white px-2 py-1 rounded">
                                                    {{item.nmSubCategoria}}
                                                </span>
                                                <p class="txt-h-5 txt-size-14 txt-white my-1 reduz-texto-1">
                                                    {{item.dsTitulo}}
                                                </p>
                                                <p class="txt-h-6 txt-size-12 txt-white txt-w-4 mb-0 reduz-texto-2">
                                                    {{item.dsSubTitulo}}
                                                </p>
                                            </div>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 col-md-6 ps-sm-0 ps-md-2 ps-lg-3 pe-0 d-flex flex-column justify-content-center">
                            <div class="divisor mb-4" style="border-top: 2px solid #163142;"></div>

                            <p class="txt-p txt-size-26 txt-blue-2 mb-1 txt-w-7">
                                O Sollicita Portal é o seu portal de notícias atualizadas de licitações e contratos
                            </p>
                            <p class="txt-p txt-size-16 txt-blue-2 txt-w-5 recuo">
                                O Sollicita é o maior portal de conteúdo na matéria de licitações e contratos, são mais de <b>7 milhões de acessos.</b> Todos os dias ele é atualizado pela melhor equipe de especialistas em licitações e contratos do Brasil. Todo dia útil a página é atualizada com as últimas notícias, com um conteúdo exclusivo na capa, com artigos de especialistas, e vídeos novos. Conteúdo de interesse e relevância para as pessoas que pensam em licitações e contratos públicos, que se preocupam em fazer certo e melhor. Afinal, <b>conhecimento é segurança.</b>
                            </p>
                            <a class="btn btn-yellow w-100" :href="linkPortal" target="_blank">
                                Acesse já!
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ORIENTACOES -->
        <div class="orientacoes d-flex mt-5" id="orientacoes">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-5 d-flex flex-column justify-content-center">
                        <img class="img-modulo" src="../assets/img/logo_sollicita_orientacoes.png" />
                        <p class="txt-p txt-size-16 txt-white mt-5 recuo">
                            Ao contratar esse serviço, a <b>Administração</b> conta com um <b>
                                corpo técnico altamente
                                qualificado
                            </b> para auxiliar na resolução dos mais variados e complexos problemas em matéria
                            de contratação.

                        </p>
                        <p class="txt-p txt-size-16 txt-white mt-2 recuo">
                            Além disso, pode ter acesso a um <b>
                                banco de consultas já respondidas e
                                formatadas
                            </b> como material de apoio, disponível na plataforma. O serviço de orientação, que
                            pode ser ofertado dentro do produto Sollicita, é um <b>suporte valioso na tomada das decisões</b>,
                            porque é totalmente personalizado, atende à situação especifica trazida pelo cliente, por
                            meio da construção de uma <b>solução</b>.
                        </p>

                    </div>
                    <div class="col-12 col-lg-7 ps-5">
                        <img class="w-100" src="../assets/img/print_1.png" />
                        <p class="txt-p txt-size-16 txt-w-5 txt-orientacoes mb-4">
                            O Sollicita Orientações é o único suporte integral e seguro de licitações e contratos do mercado! Oferecemos Orientações técnicas com o menor tempo de respostas em quatro modalidades: orientações por escrito, telefone, whatsapp e mentoria!
                        </p>
                        <a class="btn btn-orientacoes w-100" :href="linkOrientacoes" target="_blank">
                            Quero esse suporte na minha instituição!
                        </a>
                    </div>
                </div>

            </div>

        </div>

        <!-- MURAL -->
        <div class="mural mt-5" id="mural">
            <div class="divisor-mural d-flex align-items-center">
                <div class="" style="border-top: 17px solid #00518C; width: 22%;"></div>
                <img class="mx-2" width="200px" src="../assets/img/logo_sollicita_mural.png" />
                <div class="w-100" style="border-top: 17px solid #00518C; "></div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-12 text-center my-5">
                        <p class="txt-p txt-blue-3 txt-size-22 w-70 m-auto">
                            O <span class="txt-colors-1">Mural</span> é a rede social <span class="txt-w-7 txt-size-24">100% gratuita</span> do Sollicita que conecta você e as informações
                            dos nossos usuários de maneira dinâmica e descomplicada!
                        </p>
                    </div>

                    <div class="col-12 box-nossos-usuarios" v-if="lstUsuariosMural.length > 0">
                        <span class="txt-p txt-size-20 txt-blue-3 txt-w-7">
                            Nossos usuários
                        </span>
                        <div class="w-100 mt-2" style="border: 2px solid #034471;"></div>

                        <div class="row">
                            <div :class="'col-12 col-lg-3 my-4 d-flex align-items-center flex-column text-center '+(index == 2?'border-mural':'')" v-for="(item,index) in lstUsuariosMural" :key="item.idPerfil">
                                <img class="rounded-circle" style="height: 182px; width: 182px;" :src="item.urlImagem" v-if="item.urlImagem" />
                                <ImgDefault v-else :nome="item.nmExibicao" :size="4"></ImgDefault>
                                <span class="txt-p txt-w-7 txt-size-18 txt-blue-3 mt-2">
                                    {{item.nmExibicao}}
                                </span>
                                <p class="txt-h-5 txt-size-14 txt-blue-2 w-70 reduz-texto-2 mt-2 mb-1">
                                    {{item.dsCurriculo}}
                                </p>
                                <!--<p class="txt-h-5 txt-size-12 txt-grey-2 w-70 reduz-texto-3">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rutrum a turpis vel suspendisse partur...
                            </p>-->
                                <!--<a :class="'btn '+( index > 1? 'btn-blue-outline':'btn-yellow-outline')+ ' w-70'">
                                IR PARA ARTIGO
                            </a>-->


                            </div>
                        </div>

                        <!--<div class="row mt-3">
                        <div class="col-6 text-center">
                            <a class="btn btn-blue-mural" :href="linkMural">
                                Ver mais usuários do Mural
                            </a>
                        </div>
                        <div class="col-6 text-center">
                            <a class="btn btn-blue-mural" :href="linkMural">
                                Mais artigos no Mural
                            </a>
                        </div>
                    </div>-->
                    </div>

                </div>
                <div class="row mt-5 mb-3">
                    <div class="col-12 col-md-5">
                        <p class="txt-p txt-size-22 txt-blue-3 txt-w-5">
                            Acesse seu <span class="txt-colors-1">Mural</span> e tenha acesso <b>gratuito</b> à publicações, artigos e assuntos importantes dos usuários!
                        </p>
                    </div>
                    <div class="col-12 col-md-7 d-flex align-items-center">
                        <a class="btn btn-yellow w-100 txt-white" :href="linkMural" target="_blank">
                            Acessar meu Mural!
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- PERIODICOS -->
        <div class="periodicos d-flex mt-5" id="periodicos">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-5 d-flex flex-column justify-content-center">
                        <img class="img-modulo" src="../assets/img/logo_sollicita_periodicos.png" />
                        <p class="txt-p txt-size-16 txt-white mt-5 recuo w-90">
                            São <b>quatro periódicos</b> temáticos, de amplo alcance e que tratam mensalmente dos assuntos mais importantes e atuais, em pauta no cenário nacional e regional, em matéria de contratação pública: <b>Licicon, O Pregoeiro, Negócios Públicos e Governança Pública.</b>
                        </p>
                    </div>
                    <div class="col-12 col-lg-7 ps-5">
                        <img class="w-100" src="../assets/img/print_3.png" />
                        <p class="txt-p txt-size-16 txt-periodicos mb-4 txt-w-5">
                            Os periódicos, contam ainda com parceiros experientes e notoriamente reconhecidos como altamente qualificados, em matéria de contratação pública, que atuam como articulistas e colunistas.
                        </p>
                        <a class="btn btn-periodicos w-100" :href="linkPeriodicos" target="_blank">
                            Quero experimentar o Periódicos
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- CAPACITACAO -->
        <div class="capacitacao d-flex mt-5" id="capacitacao">
            <div class="container">
                <div class="row d-none d-lg-flex">
                    <div class="col-12 col-lg-7">
                        <img class="w-100" src="../assets/img/print_2.png" />
                        <p class="txt-p txt-size-16 txt-capacitacao mb-4 txt-w-5">
                            Os melhores e mais conceituados eventos do Grupo Negócios Públicos gravados para
                            assistir quando e onde quiser. Além de cursos intensivos e aulas ao vivo exclusivos do
                            Sollicita PRO.
                        </p>
                        <a class="btn btn-capacitacao w-100" :href="linkCapacitacao" target="_blank">
                            Quero experimentar o Capacitação
                        </a>
                    </div>
                    <div class="col-12 col-lg-5 ps-6 d-flex flex-column justify-content-center align-items-center">
                        <img class="img-modulo" src="../assets/img/logo_sollicita_capacitacao.png" />
                        <p class="txt-p txt-size-16 txt-white mt-5 recuo">
                            Somente aqui você tem acesso a essa <b>ferramenta exclusiva</b> que disponibiliza a
                            oportunidade de se capacitar por meio do acesso ao <b>
                                conteúdo das palestras ministradas
                                nos melhores eventos sobre contratação pública do país
                            </b>, com os mais renomados
                            palestrantes (Como: <b>
                                Ministro Benjamin Zymler, Jorge Ulisses Jacoby Fernandes, Jonas
                                Lima, Victor Amorim, Paulo Sérgio de Monteiro Reis, Ronny Charles, Tatiana Camarão, Larissa Panko,
                            </b> entre inúmeras outras autoridades em matéria de contratação pública, no
                            país).
                        </p>
                    </div>
                </div>
                <div class="row d-flex d-lg-none">
                    <div class="col-12 col-lg-5 ps-5 ">
                        <img src="../assets/img/logo_sollicita_capacitacao.png" style="max-width: 100%;" />
                        <p class="txt-p txt-size-16 txt-white mt-5">
                            Somente aqui você tem acesso a essa ferramenta exclusiva que disponibiliza a
                            oportunidade de se capacitar por meio do acesso ao conteúdo das palestras ministradas
                            nos melhores eventos sobre contratação pública do país, com os mais renomados
                            palestrantes (Como: Ministro Benjamin Zymler, Jorge Ulisses Jacoby Fernandes, Jonas
                            Lima, Victor Amorim, Paulo Sérgio de Monteiro Reis, Ronny Charles, Tatiana Camarão, Larissa Panko, entre inúmeras outras autoridades em matéria de contratação pública, no
                            país).
                        </p>
                    </div>
                    <div class="col-12 col-lg-7">
                        <img class="w-100" src="../assets/img/print_2.png" />
                        <p class="txt-p txt-size-16 txt-capacitacao mb-4 txt-w-5">
                            Os melhores e mais conceituados eventos do Grupo Negócios Públicos gravados para
                            assistir quando e onde quiser. Além de cursos intensivos e aulas ao vivo exclusivos do
                            Sollicita PRO.
                        </p>
                        <a class="btn btn-capacitacao w-100" :href="linkCapacitacao" target="_blank">
                            Quero experimentar o Capacitação
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-blue-2 p-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <p class="txt-h-2 txt-size-24 txt-white txt-letter-n-2 d-flex align-items-center">
                            Conheça as ferramentas e como você tem a ganhar com o <img class="ms-3 logo-sollicita-min" src="../assets/img/logo_sollicita_min.png" />
                        </p>
                    </div>
                    <div class="col-6">
                        <p class="txt-h-4 txt-size-20 txt-white txt-w-6 txt-letter-n-2 mb-0">
                            Cada ferramenta tem uma função que facilita seu dia a dia.<br>
                            Aproveite tudo que o Sollicita tem a oferecer!
                        </p>
                    </div>
                    <div class="col-6 d-flex justify-content-end align-items-center">
                        <p class="txt-h-3 txt-size-24 txt-white txt-w-7 txt-letter-n-2 mb-0">
                            Confira as ferramentas disponíveis!
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="ferramentas my-5" id="ferramentas">
            <div class="container">
                <CarouselFerramentas></CarouselFerramentas>
            </div>
        </div>

        <ExperimenteGratis :showModal="showModalExperimenteGratis"></ExperimenteGratis>

        <Footer></Footer>
    </div>
</template>

<script>
    import logo from '@/assets/img/logo_sollicita.png';
    import Footer from '@/components/Footer'
    import CarouselFerramentas from '@/components/CarouselFerramentas'
    import ImgDefault from '@/components/ImgDefault'
    import ExperimenteGratis from '@/components/ExperimenteGratis'

    import icone1 from '@/assets/img/icon/iconePortal.png';
    import icone2 from '@/assets/img/icon/iconeOrientacoes.png';
    import icone3 from '@/assets/img/icon/iconeMural.png';
    import icone4 from '@/assets/img/icon/iconePeriodicos.png';
    import icone5 from '@/assets/img/icon/iconeCapacitacao.png';
    import icone6 from '@/assets/img/icon/iconeFerramentas.png';

    import { urlApi, avisoErro, linkPortal, linkMural, linkOrientacoes, linkPeriodicos, linkCapacitacao } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'

    export default {
        name: 'Home',
        components: { Footer, CarouselFerramentas, ImgDefault, ExperimenteGratis},
        props: {
            msg: String
        },
        data: function () {
            return {
                logo,
                linkPortal: linkPortal,
                linkMural: linkMural,
                linkOrientacoes: linkOrientacoes,
                linkPeriodicos: linkPeriodicos,
                linkCapacitacao: linkCapacitacao,

                modulos: [
                    { idModulo: 1, idDiv: 'portal', nmFerramenta: 'Portal', urlIcone: icone1},
                    { idModulo: 2, idDiv: 'orientacoes', nmFerramenta: 'Orientações', urlIcone: icone2},
                    { idModulo: 3, idDiv: 'mural', nmFerramenta: 'Mural', urlIcone: icone3},
                    { idModulo: 4, idDiv: 'periodicos', nmFerramenta: 'Periódicos', urlIcone: icone4},
                    { idModulo: 5, idDiv: 'capacitacao', nmFerramenta: 'Capacitação', urlIcone: icone5},
                    { idModulo: 6, idDiv: 'ferramentas', nmFerramenta: 'Ferramentas', urlIcone: icone6},
                ],

                lstNoticias: [],
                lstNoticiasSecundarias: [],

                lstUsuariosMural: [],

                showModalExperimenteGratis: false,

                showVoltarTopo: false,
            }
        },
        methods: {
            formatDate(date) {
                if (date) {
                    return moment(date).format('DD/MM/YYYY [às] HH:mm');
                } else {
                    return "";
                }

            },

            load() {
                this.carregaNoticiaDestaque();
                this.carregaNoticiaSecundarias();
                this.carregaUsuariosMural();
            },

            carregaNoticiaDestaque() {
                let $this = this;
                axios.get(`${urlApi}/Noticia/Get`, { params: { take: 5, index: 0, isNoticiaPrincipal: true } })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data.rows != null) {
                                for (let i = 0; i < res.data.data.rows.length; i++) {
                                    let noticia = res.data.data.rows[i];

                                    noticia.urlImagem = $this.getUrlS3(noticia.urlImagem);
                                    noticia.urlImagemInterna = $this.getUrlS3(noticia.urlImagemInterna);

                                    $this.lstNoticias.push(noticia);
                                   
                                }

                            }
                        } else {
                            avisoErro("Não foi possível carregar as notícias");
                            $this.lstNoticias = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar as notícias");
                        $this.lstNoticias = [];
                    });
            },

            carregaNoticiaSecundarias() {
                let $this = this;
                axios.get(`${urlApi}/Noticia/Get`, { params: { take: 4, index: 0, isNoticiaPrincipal: false } })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data.rows != null) {
                                for (let i = 0; i < res.data.data.rows.length; i++) {
                                    let noticia = res.data.data.rows[i];

                                    noticia.urlImagem = $this.getUrlS3(noticia.urlImagem);
                                    noticia.urlImagemInterna = $this.getUrlS3(noticia.urlImagemInterna);

                                    $this.lstNoticiasSecundarias.push(noticia);
                                }
                            }
                        } else {
                            $this.lstNoticiasSecundarias = [];
                        }
                    })
                    .catch(function () {
                        $this.lstNoticiasSecundarias = [];
                    });
            },

            getUrlS3(url) {
                return `${urlApi}/Utilidade/GetUrlS3?url=${url}`;
            },

            carregaUsuariosMural() {
                let $this = this;
                axios.get(`${urlApi}/Perfil/NossosUsuarios`, { params: { take: 4, index: 0, order:'1' } })
                    .then(res => {
                        if (res.data.success) {
                            $this.lstUsuariosMural = [];
                            if (res.data.data.rows != null) {
                                for (let i = 0; i < res.data.data.rows.length; i++) {
                                    let perfil = res.data.data.rows[i];

                                    $this.lstUsuariosMural.push(perfil);
                                }
                            }

                        } else {
                            avisoErro("Não foi possível carregar os perfis");
                            $this.lstUsuariosMural = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os perfis");
                        $this.lstUsuariosMural = [];
                    });
            },


            scrolToDiv(id) {
                document.getElementById(id).scrollIntoView();
            },

            formatLink(titulo) {
                if (titulo) {
                    return encodeURI(titulo.toLowerCase().replace(/\s/g, '-')).replace('/', '%2F')//;
                } else {
                    return "";
                }

            },
        },
        created: function () {
            let $this = this;
            $this.load();
                        
            var myScrollFunc = function () {
                var y = window.scrollY;
                if (y >= window.screen.availHeight) {
                    $this.showVoltarTopo = true;
                } else {
                    $this.showVoltarTopo = false;
                }
            };

            window.addEventListener("scroll", myScrollFunc);
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .home {
        background: #FFF;
    }

    .voltar-topo {
        position: fixed;
        right: 20px;
        bottom: 20px;
        font-size: 30px;
        background-color: #FFF;
        border: 2px solid #fbbc0d;
        padding: 10px 20px;
        border-radius: 100%;
        cursor: pointer;
    }

    .container-principal {
        padding-top: 150px;
        padding-bottom: 50px;
        background-image: url('../assets/img/bg_sollicita.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .ps-6{
        padding-left: 2rem;
    }

    .icone-ferramentas {
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        /*padding: 20px;*/
        border-radius: 50%;
        width: 62px;
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-height: 29px;
            max-width: 30px;
        }
    }

    .icone-ferramentas:hover {
        background-color: #00000040;
    }
        
    .img-modulo{
        max-width: 350px;
    }


    .mural {
        border-bottom: 2px solid #00518C;


        .box-nossos-usuarios {
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
            padding: 20px;

            .border-mural {
                border-width: 1px;
                border-style: solid;
                /*border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 5%, #8E8E8E 0%, #8E8E8E 85%, rgba(0, 0, 0, 0) 5%) 0 0 0 100%;*/
                border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 5%, #e0e0e0 0%, #e0e0e0 85%, rgba(0, 0, 0, 0) 5%) 0 0 0 100%;
            }

            .btn-blue-mural {
                background: #034471;
                padding: 10px 3em;
                border: 3px solid;
                border-color: #034471;
                color: #F9F9F9;
                font-size: 18px;
            }
        }
    }

    .orientacoes {
        padding: 2em 0;
        background-color: white;
        background-image: linear-gradient( to right, #732A90, #732A90 44%, #FFF 15%, #FFF 85% );
    }

    .periodicos {
        padding: 2em 0;
        background-color: white;
        background-image: linear-gradient( to right, #A94C36, #A94C36 44%, #FFF 15%, #FFF 85% );
    }

    .capacitacao {
        padding: 2em 0;
        background-color: white;
        background-image: linear-gradient( to left, #FF831A, #FF831A 44%, #FFF 15%, #FFF 85% );
    }

    .logo-sollicita-min{
        width: 120px;
        height: auto;
    }

    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
        .orientacoes {
            background-image: linear-gradient( to bottom, #732A90, #732A90 64.5%, #FFF 15%, #FFF 85% );
        }

        .periodicos {
            background-image: linear-gradient( to bottom, #A94C36, #A94C36 64.5%, #FFF 15%, #FFF 85% );
        }

        .capacitacao {
            background-image: linear-gradient( to bottom, #FF831A, #FF831A 64.5%, #FFF 15%, #FFF 85% );
        }
    }

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) and (max-width: 767.98px) {
        .orientacoes {
            background-image: linear-gradient( to bottom, #732A90, #732A90 64.5%, #FFF 15%, #FFF 85% );
        }

        .periodicos {
            background-image: linear-gradient( to bottom, #A94C36, #A94C36 64.5%, #FFF 15%, #FFF 85% );
        }

        .capacitacao {
            background-image: linear-gradient( to bottom, #FF831A, #FF831A 64.5%, #FFF 15%, #FFF 85% );
        }
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) and (max-width: 991.98px) {
        .orientacoes {
            background-image: linear-gradient( to bottom, #732A90, #732A90 64.5%, #FFF 15%, #FFF 85% );
        }

        .periodicos {
            background-image: linear-gradient( to bottom, #A94C36, #A94C36 64.5%, #FFF 15%, #FFF 85% );
        }

        .capacitacao {
            background-image: linear-gradient( to bottom, #FF831A, #FF831A 64.5%, #FFF 15%, #FFF 85% );
        }
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) and (max-width: 1199.98px) {
        .orientacoes {
            background-image: linear-gradient( to right, #732A90, #732A90 42%, #FFF 15%, #FFF 85% );
        }
        .periodicos {
            background-image: linear-gradient( to right, #A94C36, #A94C36 42%, #FFF 15%, #FFF 85% );
        }

        .capacitacao {
            background-image: linear-gradient( to left, #FF831A, #FF831A 42%, #FFF 15%, #FFF 85% );
        }
    }

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) and (max-width: 1600px) {
        .orientacoes {
            background-image: linear-gradient( to right, #732A90, #732A90 42%, #FFF 15%, #FFF 85% );
        }
        .periodicos {
            background-image: linear-gradient( to right, #A94C36, #A94C36 42%, #FFF 15%, #FFF 85% );
        }

        .capacitacao {
            background-image: linear-gradient( to left, #FF831A, #FF831A 42%, #FFF 15%, #FFF 85% );
        }
    }

    .thumb-noticia-capa {
        display: block;
        overflow: hidden;
        position: relative;
        height: 400px;
        box-sizing: border-box;
        padding: 0;
        background-color: transparent;

        .img-noticia-capa {
            background-position: center !important;
            background-size: cover !important;
        }

        .txt-noticia-capa {
            z-index: 100;
            text-align: left;
            position: absolute;
            right: 0;
            left: 0;
            padding: 0 20px;
            bottom: 20px;
            top: auto;
        }
    }

    .thumb-noticia-p {
        display: block;
        overflow: hidden;
        position: relative;
        height: 190px;
        box-sizing: border-box;
        padding: 0;
        background-color: transparent;

        .img-noticia-p {
            background-position: center !important;
            background-size: cover !important;
        }

        .txt-noticia-capa {
            z-index: 100;
            text-align: left;
            position: absolute;
            right: 0;
            left: 0;
            padding: 0 10px;
            bottom: 10px;
            top: auto;
        }
    }
</style>

