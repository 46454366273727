<template>
    <div class="viewVIDEO">
        <div class="modal-conteudo m-2">
            <div v-if="dados">
                <h3 class="bold">{{dados.nmTitulo}}</h3>
                <p class="bold" v-html="dados.dsDescricao"></p>
                <template v-if="dados.tpExibicao == 'Local'">
                    <VideoPlayer :options="getUrlVideo()"></VideoPlayer>                    
                </template>
                <template v-else>
                    <iframe class="video-player" width="100%" height="500px" :src="getUrlVideo()" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </template>
            </div>
        </div>

        <div class="modal-footer pb-0 mt-4 d-flex justify-content-end">
            <input style="display: none;" type="text" id="copyLink" :value="linkSollicita+'NoticiaVideo/?p_idVideo='+dados.idVideo" />
            <button class="btn btn-default txt-size-14 me-2" @click="copiarLink">
                Copiar link
            </button>

            <AdicionarBiblioteca v-if="exibeBiblioteca" :id="dados.idVideo.toString()" :tipo="tpConteudo" :cdConteudo="cdConteudo"></AdicionarBiblioteca>
        </div>
    </div>
</template>

<script>
    import VideoPlayer from "@/components/VideoPlayer.vue";
    import AdicionarBiblioteca from '../AdicionarBiblioteca'
    import { urlApi, linkSollicita, avisoSucesso } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'viewVIDEO',
        components: { VideoPlayer, AdicionarBiblioteca },
        data: function () {
            return {
                videoOptions: {
                    autoplay: false,
                    controls: true,
                    sources: [
                        {
                            src: "",
                            type: "video/mp4"
                        }
                    ]
                },

                exibeBiblioteca: true,
                tpConteudo: 'Notícia Vídeo',
                cdConteudo: '',
                linkSollicita: linkSollicita,
            }
        },
        props: {
            dados: Object,
        },
        methods: {
            load() {
                this.videoOptions.sources[0].src = this.dados.urlVideo;
            },

            copiarLink() {
                var copyText = document.getElementById("copyLink");
                copyText.select();
                copyText.setSelectionRange(0, 99999);
                navigator.clipboard.writeText(copyText.value);

                avisoSucesso("Link copiado");
            },

            verificaExisteRepositorio(idConteudo, tpConteudo, cdConteudo) {
                let $this = this;
                axios.get(`${urlApi}/Repositorio`, { params: { idConteudo: idConteudo, tpConteudo: tpConteudo, cdConteudo: cdConteudo } })
                    .then(res => {
                        if (res.data.success) {
                            $this.exibeBiblioteca = res.data.data;
                        } else {
                            console.log('Não foi possível verificar o repositório')
                        }
                    })
                    .catch(function () {
                        console.log('Não foi possível verificar o repositório')
                    });
            },

            getUrlVideo() {
                var options = null;
                if (this.dados.tpExibicao == 'Local') {
                    options = {
                        autoplay: true,
                        controls: true,
                        sources: [
                            {
                                src: this.dados.urlVideo,
                                type: "video/mp4"
                            }
                        ]
                    };
                    return options;
                } else {
                    var url = '';
                    var video = this.dados.urlVideo;
                    if (video.includes('vimeo.com')) {
                        let src = video.replace("https://vimeo.com/", "");
                        url = 'https://player.vimeo.com/video/' + src
                    } else if (video.includes('youtube')) {
                        let src = video.replace("https://www.youtube.com/watch?v=", "");
                        if (src.indexOf("&") > 0) {
                            src = src.substring(0, src.indexOf("&"));
                        }
                        url = 'https://www.youtube.com/embed/' + src
                    } else if (video.includes('youtu.be')) {
                        let src = video.replace("https://youtu.be/", "");
                        if (src.indexOf("?") > 0) {
                            src = src.substring(0, src.indexOf("?"));
                        }
                        url = 'https://www.youtube.com/embed/' + src
                    } else {
                        url = video;
                    }

                    return url;
                }
            },

        },
        mounted: function () {
            this.verificaExisteRepositorio(this.dados.idVideo.toString(), this.tpConteudo, this.cdConteudo);
            this.load();
        },
        watch: {
            dados: {
                handler() {
                    this.verificaExisteRepositorio(this.dados.idVideo.toString(), this.tpConteudo, this.cdConteudo);
                },
                deep: true
            },
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .video-responsivo{
        max-height: 500px;
    }
   
</style>

