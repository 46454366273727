<template>
    <section class="esqueceu_senha">
        <div class="interno_esqueceu_senha">
            <div class="container">

                <div class="d-flex align-items-center justify-content-center h-100 flex-column">
                    <div class="text-center my-5">
                        <img class="logo_login" src="../assets/img/logo_sollicita.png">

                    </div>
                    <div class="w-40">
                        <div class="formulario">
                            <div class="row text-center">
                                <p class="txt-h-4 txt-white txt-w-7">Redefinir senha</p>
                                <p class="txt-h-4 txt-size-16 txt-white">Insira o endereço de e-mail cadastrado e enviaremos um link para redefinir a sua senha. </p>

                            </div>
                            
                            <form class="">
                                <div class="row">
                                    <input class="form-control" type="email"  nid="email" placeholder="Digite seu e-mail" v-model="email" required/>
                                </div>
                                
                                <div class="row mt-2">
                                    <button class="btn btn-blue btn-enviar" type="button" @click="enviar">Enviar</button>
                                </div>

                            </form>

                            <p class="txt-h-4 txt-size-16 txt-white mt-3">Lembrou da senha? <router-link :to="{name:'Login'}"><span class="txt-white txt-w-7">Faça login aqui! </span></router-link></p>
                        </div>

                        <div class="voltar-sollicita my-5">
                            <span class="txt-h-4 txt-w-7 txt-white c-pointer" @click="voltar"><i class="fas fa-arrow-left"></i> Voltar</span>
                        </div>
                    </div>


                </div>

                
            </div>
        </div>
        
    </section>
</template>

<script>
    import { urlApi, avisoErro, avisoSucesso} from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'EsqueciMinhaSenha',
        data() {
            return {
                

                email: null,
            }
        },
        methods: {
            enviar(event) {
                event.preventDefault();
                if (this.checkForm()) {
                    let $this = this;
                    axios.post(`${urlApi}/Auth/forgotPassword`, { login: $this.email })
                        .then(function () {
                            avisoSucesso("Sua solicitação foi registrada, verifique seu e-mail!");

                        })
                        .catch(res => {
                            if (res.response.data != undefined) {
                                avisoErro(res.response.data.msg);
                            } else {
                                avisoErro(res.response.statusText);
                            }
                        });
                }

                return false;
            },


            checkForm() {
                var form = document.querySelector('form')
                if (!form.checkValidity()) {
                    var tmpSubmit = document.createElement('button')
                    form.appendChild(tmpSubmit)
                    tmpSubmit.click()
                    form.removeChild(tmpSubmit)

                    return false;
                }

                return true;
            },

            voltar() {
                this.$router.go(-1);
            },

        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .esqueceu_senha {
        min-height: 100vh;
    }

    .interno_esqueceu_senha {
        height: 100vh;
        /*background: linear-gradient(107.56deg, #034471 0%, rgba(3, 68, 113, 0.76) 50.01%, #034471 100%);*/
        background: linear-gradient(107.56deg, #163142 0%, #2E4655 50.01%, #173243 100%);
    }

    .container {
        height: 100%;
    }

    .formulario {
        padding: 60px;
        background: rgba(255, 255, 255, 0.1);
        border: 3px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 10px;
    }

    .btn-enviar {
        background: color('blue','3');
        border-color: color('blue','3');
        border-radius: 5px;
    }
   
</style>

