<template>
    <section class="Editais">
        <Header></Header>
        <div class="container">
            <div class="row my-3">
                <div class="col-12 text-center">
                    <p class="txt-p txt-size-20 txt-editais mb-0">
                        Sollicita <span class="txt-w-7">Editais</span>
                    </p>
                </div>
                <div class="col-12 text-end">
                    <ManualFerramenta :name="'Manual Editais'" :btnClass="'btn-editais'" :nameBtn="'Manual'" :urlPdf="'/manuais/FERRAMENTA EDITAIS PDF.pdf'"></ManualFerramenta>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="box-branco">
                        <div class="row mb-3">
                            <div class="input-group">
                                <span class="input-group-text bg-white border-editais" id="dsTermo"><i class="fas fa-search"></i></span>
                                <input type="text" class="form-control border-editais" ref="dsTermo" style="border-left: unset;" placeholder="Pequise por editais" aria-label="dsTermo" aria-describedby="dsTermo" v-model="pesquisa.dsTermo">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-6 col-md-3">
                                        <label for="dtInicial" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-3 mb-1 ps-2">Desde a data:</label>
                                        <date-picker class="w-100" v-model="pesquisa.dtInicial" format="DD/MM/YYYY" placeholder="Data"></date-picker>
                                    </div>
                                    <div class="col-6 col-md-3">
                                        <label for="dtFinal" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-3 mb-1 ps-2">Até a data:</label>
                                        <date-picker class="w-100" v-model="pesquisa.dtFinal" format="DD/MM/YYYY" placeholder="Data"></date-picker>
                                    </div>
                                    <div class="col-6 col-md-3 mt-3 mt-sm-0">
                                        <label for="uf" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-3 mb-1 ps-2">Selecione a UF:</label>
                                        <select class="form-select" aria-label="nmEstado" v-model="pesquisa.nmEstado">
                                            <option value="">Selecione</option>
                                            <option :value="item" v-for="(item,index) in lstEstados" :key="index">{{item}}</option>
                                        </select>
                                    </div>
                                    <div class="col-6 col-md-3 mt-3 mt-sm-0 d-flex flex-wrap align-content-end">
                                        <div class="form-check w-100">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="pesquisa.chkPesquisaSustentavel">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Pesquisa sustentável
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="pesquisa.chkApenasSRP">
                                            <label class="form-check-label" for="flexCheckChecked">
                                                Apenas SRP
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12">
                                <button class="btn btn-editais w-100" @click="buscar">
                                    Buscar
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <p class="txt-p txt-size-20 txt-grey-6 txt-w-7">
                        Resultados da pesquisa: <span class="txt-italic txt-w-4 txt-grey-3">“{{(dataAjax && dataAjax.dsTermo?dataAjax.dsTermo:'-')}}”</span>
                    </p>
                </div>
                <template v-if="!flResultados">
                    <div class="col-12">
                        <div class="bg-white rounded p-3 text-center">
                            <p class="txt-p txt-size-16 txt-w-7 txt-grey-3 mb-0">
                                Use o menu acima para pesquisar por editais. <span class="txt-w-4">Os resultados aparecerão aqui.</span>
                            </p>
                        </div>
                    </div>
                </template>
                <template>
                    <Grid url_ajax="/Edital/Get" :is_table="false" :reload="reload" :ajax_set_result="setEdital" :data_ajax="getDataAjax()">
                        <template v-slot:rows="{ rows }">
                            <div v-if="rows.length == 0 && flResultados">
                                <div class="alert alert-danger mt-3" role="alert">
                                    Nenhum Registro Encontrado...
                                </div>
                            </div>
                            <div v-else>
                                <div class="" v-for="(item,index) in rows" :key="index">
                                    <router-link class="item-edital mb-3 c-pointer" :to="{name: 'DetalheEdital',params: { edital: item, pesquisa: pesquisa, idPesquisaEditais:idPesquisaEditais, index:index}}">
                                        <div class="row">
                                            <div class="col-12 mb-2">
                                                <div class="titulo-edital">
                                                    <p class="txt-p txt-size-18 txt-w-7 txt-editais mb-0">
                                                        Órgão/Entidade: {{item.entidade}} / UF:  {{item.ufEntidade}}
                                                    </p>
                                                    <p class="txt-p txt-size-16 txt-w-4 txt-editais mb-0">
                                                        {{formatDate(item.dataLicitacao)}} - {{item.modalidade}}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-6 mb-0">
                                                    {{item.objeto}}
                                                </p>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>

                        </template>
                    </Grid>
                </template>
            </div>
        </div>
        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';
    import Grid from '@/components/Grid';
    import ManualFerramenta from '@/components/ManualFerramenta';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/pt-br';

    import { avisoErro } from '@/config/global'
    import moment from 'moment'
    export default {
        name: 'Editais',
        components: { Header, Footer, Grid, DatePicker, ManualFerramenta },
        computed: {
            user() {
                return this.$store.state.user
            },

        },
        data() {
            return {
                pesquisa: {
                    dsTermo: null,
                    dtInicial: null,
                    dtFinal: null,
                    nmEstado: '',
                    chkPesquisaSustentavel: false,
                    chkApenasSRP: false,
                },

                lstEstados: [
                    "AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO",
                ],

                lstResultados: [],
                flResultados: false,

                reload: 0,
                dataAjax: null,

                idPesquisaEditais: null,
            }
        },
        methods: {
            load() {
                //let $this = this;
            },

            buscar() {
                let $this = this;
                if ($this.validarBuscar()) {
                    $this.dataAjax = Object.assign({}, $this.pesquisa);
                    if ($this.dataAjax.dtInicial != null && $this.dataAjax.dtInicial != '') {
                        $this.dataAjax.dtInicial = moment($this.dataAjax.dtInicial).format('DD/MM/YYYY');
                    }
                    if ($this.dataAjax.dtFinal != null && $this.dataAjax.dtFinal != '') {
                        $this.dataAjax.dtFinal = moment($this.dataAjax.dtFinal).format('DD/MM/YYYY');
                    }
                    $this.dataAjax.qtdRegistrosPorPagina = 10;
                    $this.dataAjax.nrPaginaAtual = 0;

                    $this.reload = $this.reload + 1;
                }
            },

            setEdital(data) {
                scroll(100, 100);
                var rows = data.rows;
                let lista = (rows.itens ? rows.itens: []);
                this.lstResultados = lista;
                this.flResultados = true;
                this.idPesquisaEditais = data.idPesquisaEditais;

                return this.lstResultados;
            },

            getDataAjax() {
                return this.dataAjax;
            },

            validarBuscar() {
                let $this = this;
                if ($this.pesquisa.dsTermo == null || $this.pesquisa.dsTermo.length < 3) {
                    avisoErro('O campo "Busca" deve conter ao menos 3 caracteres');
                    //this.$refs.dsTermo.$el.focus()
                    return false;
                }

                if ($this.pesquisa.dtInicial != '' && $this.pesquisa.dtInicial != null &&
                    $this.pesquisa.dtFinal != '' && $this.pesquisa.dtFinal != null) {
                    var dtInicial = moment($this.pesquisa.dtInicial);
                    var dtFinal = moment($this.pesquisa.dtFinal);
                    if (dtInicial > dtFinal) {
                        avisoErro('A data inicial deve ser menor que a data final.');
                        //this.$refs.dtInicial.$el.focus()
                        return false;
                    }
                }

                return true;
            },

            formatDate(dt) {
                return moment(dt).format('DD/MM/YYYY HH:mm');
            },
                        
        },
        mounted: function () {
            this.load();
        },
        watch: {

        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    .box-branco {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }

    .border-editais {
        border-color: #DE5E00;
    }

    .item-edital {
        display: flex;
        background: #FFFFFF;
        border: 1px solid #767676;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 20px;

        .titulo-edital {
            padding-left: 10px;
            border-left: 10px solid #DE5E00;
        }
    }
    
    .paginate .paginate-buttons {
        display: flex;
        justify-content: space-between;
    }

    .Editais {
        .grid-app {
            .btn-navegacao {
                background-color: #DE5E00 !important;
                border: 1px solid #DE5E00 !important;
                border-radius: 3px;
                font-weight: 600;
                padding: 7px 10px;
                color: #FFF !important;
            }
        }
    }

</style>

