<template>
    <div class="adicionar-biblioteca">
        <button class="btn btn-biblioteca txt-size-14 me-2" type="button" @click="showModal = !showModal" v-if="showBtn">
            <i class="fas fa-plus"></i> Biblioteca
        </button>

        <Modal :showModal="showModal" :size="'lg'" :titulo="'Salvar na Minha Biblioteca'" :hideModal="hideModal" :name="'modal-conteudo-exclusivo'">
            <template slot="conteudo">
                <form id="formRepositorio">
                    <div class="form-group mt-5">
                        <label class="txt-h-4 txt-blue-1" for="dsNome">Nome:</label>
                        <input class="form-control" id="nmConteudo" placeholder="" aria-describedby="nmConteudo" v-model="repositorio.nmConteudo" :maxlength="200" required>
                    </div>
                    <div class="form-group mt-5">
                        <label class="txt-h-4 txt-blue-1" for="dsAnotacao">Anotação:</label>
                        <textarea class="form-control" cols="20" data-val="true" maxlength="500" id="Repositorio_dsAnotacao" v-model="repositorio.dsAnotacao" rows="5"></textarea>
                    </div>
                    <div class="d-flex justify-content-end mt-5">
                        <button class="btn btn-blue" @click="enviar" type="button">Salvar</button>
                    </div>
                </form>
            </template>
        </Modal>
    </div>
</template>

<script>
    import Modal from '@/components/Modal';

    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import axios from 'axios'
    export default {
        name: 'AdicionarBiblioteca',
        components: { Modal },
        props: {
            id: {
                type: String,
                required: true
            },
            tipo: {
                type: String,
                required: true
            },
            cdConteudo: {
                type: String,
                required: true
            },
        },
        data: function () {
            return {
                showModal: false,
                hideModal: false,

                showBtn: true,

                repositorio: {
                    idConteudo: this.id,
                    tpConteudo: this.tipo,
                    cdConteudo: this.cdConteudo,
                    nmConteudo: null,
                    dsAnotacao: null,
                }
            }
        },
        methods: {
            checkForm() {
                var form = document.getElementById('formRepositorio')
                if (!form.checkValidity()) {
                    var tmpSubmit = document.createElement('button')
                    form.appendChild(tmpSubmit)
                    tmpSubmit.click()
                    form.removeChild(tmpSubmit)

                    return false;
                }

                return true;
            },

            enviar() {
                if (this.checkForm()) {
                    let $this = this;

                    axios.post(`${urlApi}/Repositorio`, $this.repositorio)
                        .then(res => {
                            if (res.data.success) {
                                $this.hideModal = !$this.hideModal;
                                // aviso ok
                                avisoSucesso("Conteúdo adicionado com sucesso!");
                                $this.showBtn = false;
                            } else {
                                avisoErro("Não foi possível adicionar conteúdo.");
                            }
                        })
                        .catch(res => {
                            if (res.response.data != undefined) {
                                avisoErro(res.response.data);
                            } else {
                                avisoErro(res.response.statusText);
                            }
                        });
                }
                return false;
            },
        },

    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>

