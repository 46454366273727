<template>
    <footer class="footer bg-blue-2">
        <div class="container">
            <div class="row">
                <div class="col-6">
                    <img :src="logo" />
                </div>
                <div class="col-6 d-flex flex-column align-items-end">
                    <span class="txt-p txt-white">
                        <b>Dúvidas? </b>Estamos aguardando o seu contato!
                    </span>
                    <p class="txt-p txt-white txt-w-bold my-1">
                        +55 (41) 98862-3426
                    </p>
                    <p>
                        <a class="txt-l txt-white" href="mailto:contato@sollicita.com?">contato@sollicita.com.br</a>
                    </p>
                </div>
            </div>
            <div class="row justify-content-end">
                <p class="txt-p txt-white text-end mb-0">
                    Horário de atendimento: 8:30hs até as 17:30hs de segunda a quinta-feira e das 8:30hs até as 16:30hs na sexta-feira
                </p>
            </div>
            <div class="row borda-topo pt-2">
                <div class="col-6">
                    <span class="txt-p txt-white">
                        Copyright © 2020. Todos os direitos reservados
                    </span>
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <a class="txt-white txt-size-25" :href="item.link" v-for="(item,index) in links" :key="index" target="_blank">
                        <i :class="'fab me-4 '+item.icon"></i>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import logo from '@/assets/img/logo-branca.png';

    export default {
        name: 'Footer',
        components: { },
        data: function () {
            return {
                logo,
                links: [
                    { link: 'https://www.instagram.com/sollicita/', icon: 'fa-instagram ' },
                    { link: 'https://www.linkedin.com/company/sollicita/?originalSubdomain=br', icon: 'fa-linkedin-in ' },
                    { link: 'https://www.facebook.com/sollicita/?locale=pt_BR', icon: 'fa-facebook-f ' },
                    { link: 'https://twitter.com/sollicita', icon: 'fa-twitter ' },
                ],
            }
        },
        methods: {

           

        },
        mounted: function () {
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    footer {
    }

    .footer {
        padding: 30px 0;
    }

    .borda-topo {
        margin-top: 100px;
        border-top: 1px solid #FFFFFF;
    }
   
</style>

