import Vue from 'vue'
import axios from 'axios'
import { urlApi, userKey } from '@/config/global'
import '@/config/loading'

var loading = null;
var cont = 0;
// VALIDA TODO REQUEST
const successRequest = req => {
    // eslint-disable-next-line
    if (req.showLoading == undefined || req.showLoading) {
        if (cont == 0) {
            loading = Vue.$loading.show({});
        }
        cont++;        
    }
    return req;
}

const errorRequest = err => {
    if (cont == 1) {
        cont = 0;
        loading.hide();
        loading = null;        
    } else {
        if (cont > 0) {
            cont--;
        }
    }
    
    return Promise.reject(err)
}

axios.interceptors.request.use(successRequest, errorRequest)

// VALIDA TODO RESPONSE
const successResponse = res => {
    if (cont == 1) {
        cont = 0;
        loading.hide();
        loading = null;
    } else {
        if (cont > 0) {
            cont--;
        }
    }
    return res
}

const errorResponse = err => {
    if (cont == 1) {
        cont = 0;
        loading.hide();
        loading = null;
    } else {
        if (cont > 0) {
            cont--;
        }
    }

    // request Unauthorized
    if (401 === err.response.status) {
        if (err.request.responseURL.indexOf("Auth/signin") == -1) {
            // se request n�o foi para signin
            localStorage.removeItem(userKey)
            window.location = '/Home'
        }
        
        return Promise.reject(err);
    } else {
        return Promise.reject(err)
    }
}
axios.interceptors.response.use(successResponse, errorResponse)


Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios.create({
            baseURL: urlApi,
            //headers: {
            //    "Authorization":""
            //}
        })
    }
})
