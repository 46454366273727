import Vue from 'vue'
import Router from 'vue-router'
import store from './store.js'
import axios from 'axios'
import { userKey, urlApi, linkPortal, linkPeriodicos } from '@/config/global'

import Home from '../views/Home'
import Login from '../views/Login'
import EsqueciMinhaSenha from '../views/EsqueciMinhaSenha'
import HomeLogado from '../views/HomeLogado'
import MeusModulos from '../views/MeusModulos'
import MinhasFerramentas from '../views/MinhasFerramentas'
import Pesquisa from '../views/Pesquisa'
import AutenticacaoAutomatica from '../views/AutenticacaoAutomatica'
import Planos from '../views/Planos'
import LeitorPdf from '../views/LeitorPdf'
import GerenciarUsuarios from '../views/GerenciarUsuarios'
import GeraAssinatura from '../views/GeraAssinatura'
import CancelamentoHotmart from '../views/CancelamentoHotmart'

// Ferramentas 
import ContadorPrazos from '../views/ferramentas/ContadorPrazos/ContadorPrazos'
import DetalheProcesso from '../views/ferramentas/ContadorPrazos/DetalheProcesso'
import ConfigurarPrazos from '../views/ferramentas/ContadorPrazos/ConfigurarPrazos'
import Agenda from '../views/ferramentas/Agenda/Agenda'
import Fornecedor from '../views/ferramentas/Fornecedor/Fornecedor'
import DetalheFornecedor from '../views/ferramentas/Fornecedor/DetalheFornecedor'
import Modelo from '../views/ferramentas/Modelo/Modelo'
import Editais from '../views/ferramentas/Editais/Editais'
import DetalheEdital from '../views/ferramentas/Editais/DetalheEdital'
import AnalisadorBalancos from '../views/ferramentas/AnalisadorBalancos/AnalisadorBalancos'
import DetalheAnalisadorBalancos from '../views/ferramentas/AnalisadorBalancos/DetalheAnalisadorBalancos'
import Biblioteca from '../views/ferramentas/Biblioteca/Biblioteca'
import BancoPenalidades from '../views/ferramentas/BancoPenalidades/BancoPenalidades'
import DetalhePenalidade from '../views/ferramentas/BancoPenalidades/DetalhePenalidade'


Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home,
            name: 'Home',
            meta: { authenticated: false },
        },

        {
            path: 'home/geraAssinatura',
            component: GeraAssinatura,
            name: 'GeraAssinatura',
            props: true,
            meta: { authenticated: false },
        },

        {
            path: '/Planos',
            component: Planos,
            name: 'Planos',
            meta: { authenticated: false },
        },
        {
            path: '/assine',
            component: Planos,
            meta: { authenticated: false },
        },

        {
            path: '/Login',
            component: Login,
            name: 'Login',
            meta: { authenticated: false },
        },

        {
            path: '/EsqueciMinhaSenha',
            component: EsqueciMinhaSenha,
            name: 'EsqueciMinhaSenha',
            meta: { authenticated: false },
        },

        {
            path: '/HomeLogado',
            component: HomeLogado,
            name: 'HomeLogado',
            meta: { authenticated: true },
        },

        {
            path: '/MeusModulos',
            component: MeusModulos,
            name: 'MeusModulos',
            meta: { authenticated: true },
        },

        {
            path: '/MinhasFerramentas',
            component: MinhasFerramentas,
            name: 'MinhasFerramentas',
            meta: { authenticated: true },
        },

        {
            path: '/Pesquisa',
            component: Pesquisa,
            name: 'Pesquisa',
            meta: { authenticated: true , flProduto: false, cdProduto: 'SP'},
        },

        {
            path: '/AutenticacaoAutomatica',
            component: AutenticacaoAutomatica,
            name: 'AutenticacaoAutomatica',
        },

        // CONTADOR PRAZO
        {
            path: '/ContadorPrazos',
            component: ContadorPrazos,
            name: 'ContadorPrazos',
            meta: { authenticated: true, flProduto: true, cdProduto: 'CP' },
        },

        {
            path: '/ConfigurarPrazos',
            component: ConfigurarPrazos,
            name: 'ConfigurarPrazos',
            meta: { authenticated: true },
        },

        {
            path: '/DetalheProcesso/:id',
            component: DetalheProcesso,
            name: 'DetalheProcesso',
            meta: { authenticated: true },
            props: true,
        },
        // FIM CONTADOR PRAZO

        // AGENDA
        {
            path: '/Agenda',
            component: Agenda,
            name: 'Agenda',
            meta: { authenticated: true, flProduto: true, cdProduto: 'SA' },
        },

        // FORNECEDORES
        {
            path: '/Fornecedor',
            component: Fornecedor,
            name: 'Fornecedor',
            meta: { authenticated: true, keepAlive: true, flProduto: true, cdProduto: 'SF' },
        },

        {
            path: '/DetalheFornecedor',
            component: DetalheFornecedor,
            name: 'DetalheFornecedor',
            meta: { authenticated: true, keepAlive: true },
            props: true,
        },

        // MODELOS
        {
            path: '/Modelo',
            component: Modelo,
            name: 'Modelo',
            meta: { authenticated: true, flProduto: true, cdProduto: 'MD' },
        },

        // EDITAIS
        {
            path: '/Editais',
            component: Editais,
            name: 'Editais',
            meta: { authenticated: true, keepAlive: true, flProduto: true, cdProduto: 'SE' },
        },
        {
            path: '/DetalheEdital',
            component: DetalheEdital,
            name: 'DetalheEdital',
            meta: { authenticated: true, keepAlive: true },
            props: true,
        },

        // ANALISADOR DE BALANCOS
        {
            path: '/AnalisadorBalancos',
            component: AnalisadorBalancos,
            name: 'AnalisadorBalancos',
            meta: { authenticated: true, flProduto: true, cdProduto: 'AB' },
            props: true,
        },
        {
            path: '/DetalheAnalisadorBalancos/:id',
            component: DetalheAnalisadorBalancos,
            name: 'DetalheAnalisadorBalancos',
            meta: { authenticated: true },
            props: true,
        },

        // BIBLIOTECA
        {
            path: '/Biblioteca',
            component: Biblioteca,
            name: 'Biblioteca',
            meta: { authenticated: true, flProduto: false, cdProduto: 'RP' },
        },

        // BANCO DE PENALIDADES
        {
            path: '/BancoPenalidades',
            component: BancoPenalidades,
            name: 'BancoPenalidades',
            meta: { authenticated: true, keepAlive: true, flProduto: true, cdProduto: 'BP' },
        },
        {
            path: '/DetalhePenalidade',
            component: DetalhePenalidade,
            name: 'DetalhePenalidade',
            meta: { authenticated: true, keepAlive: true },
            props: true,
        },

        {
            path: '/LeitorPdf/:urlArquivo',
            component: LeitorPdf,
            name: 'LeitorPdf',
            props: true,
            meta: { authenticated: true },
        },

        {
            path: '/GerenciarUsuarios',
            component: GerenciarUsuarios,
            name: 'GerenciarUsuarios',
            nickName: 'GerenciarUsuarios',
            meta: { authenticated: true },
        },

        {
            path: '/GeraAssinatura/:hash',
            component: GeraAssinatura,
            name: 'GeraAssinatura',
            props: true,
            meta: { authenticated: false },
        },

        {
            path: '/CancelamentoHotmart',
            component: CancelamentoHotmart,
            name: 'CancelamentoHotmart',
            nickName: 'CancelamentoHotmart',
            meta: { authenticated: true },
        },
        // redirect
        {
            path: '/redirect',
            redirect: '/'
        },
        // 404
        {
            path: '*',
            name: '404',
            redirect: '/'
        },
    ]
})

router.beforeEach((to, from, next) => {
    // redirect url antiga
    if (to.redirectedFrom) {
        var urlParams = "";
        var isNoticia = to.redirectedFrom.indexOf("Noticia");
        if (isNoticia != -1) {
            urlParams = new URLSearchParams(to.redirectedFrom.replace('/Noticia/?',''));
            var idNoticia = urlParams.get('p_idNoticia');
            if (idNoticia) {
                window.location = linkPortal + 'Noticia/' + idNoticia;
            } else {
                window.location = linkPortal;
            } 
        }

        var isPesquisaNoticia = to.redirectedFrom.indexOf("ResultadoPesquisaNaoLogado");
        if (isPesquisaNoticia != -1) {
            urlParams = new URLSearchParams(to.redirectedFrom.replace('/ResultadoPesquisaNaoLogado/?', ''));
            var params = "";
            if (urlParams.get("p_Categoria")) { params += "idCategoria=" + urlParams.get("p_Categoria"); }
            if (urlParams.get("p_flagTag")) { params += "&flagTag=" + (urlParams.get("p_flagTag") == 'Sim'?true:false); }
            if (urlParams.get("p_Termo")) { params += "&termo=" + urlParams.get("p_Termo"); }

            window.location = linkPortal + 'PesquisaNoticia?' + params;
        }

        var isLivro = to.redirectedFrom.indexOf("VendaLivroNaoLogado");
        if (isLivro != -1) {
            var url = to.redirectedFrom.split("?");
            if (url.length > 0) {
                urlParams = new URLSearchParams(url[1]);
                var idLivro = urlParams.get('p_idLivro');
                if (idLivro) {
                    window.location = linkPeriodicos + 'Ebook/' + idLivro;
                } else {
                    window.location = linkPeriodicos + 'Ebook/';
                }
            } else {
                window.location = linkPeriodicos + 'Ebook/';
            }
        }
    }

    if (to.matched.some(record => record.meta.authenticated)) {
        var flProduto = to.matched.some(record => record.meta.flProduto);
        // rota logado
        let user = store.getters.getUser

        if (!user) {
            // deslogado
            const json = localStorage.getItem(userKey)
            const userData = JSON.parse(json)

            const req = async () => {
                try {
                    const res = await axios.post(`${urlApi}/Auth/refresh`, userData)
                    if (res.data) {
                        if (res.data.token.authenticated) {
                            res.data.token.usuario = res.data.usuario;
                            res.data.token.usuario.idContrato = res.data.idContrato;
                            res.data.token.usuario.tpPessoa = res.data.tpPessoa;
                            res.data.token.usuario.flCortesia = res.data.flCortesia;
                            store.commit('setUser', res.data.token);

                            // verifica produto
                            if (flProduto) {
                                const verificaProduto = async () => {
                                    try {
                                        const res = await axios.get(`${urlApi}/Produto/VerificaAcessoProduto`, { params: { cdProduto: to.meta.cdProduto } })
                                        if (res.data) {
                                            if (res.data.data == 'Sim') {
                                                // LOG ACESSOPRODUTO
                                                axios.post(`${urlApi}/Log/AcessoProduto`, { 'cdProduto': to.meta.cdProduto });
                                                next();
                                            } else {
                                                next({ name: 'HomeLogado' });
                                            }
                                        } else {
                                            next({ name: 'HomeLogado' });
                                        }
                                    } catch (err) {
                                        next({ name: 'HomeLogado' });
                                    }
                                };

                                verificaProduto();
                            } else {
                                if (to.matched.some(record => record.meta.cdProduto)) {
                                    // LOG ACESSOPRODUTO
                                    axios.post(`${urlApi}/Log/AcessoProduto`, { 'cdProduto': to.meta.cdProduto });
                                }
                                next()
                            }
                            
                        } else {
                            store.commit('setUser', null);
                            next({ name: 'Home' });
                        }
                    } else {
                        next({ name: 'Home' });
                    }
                } catch (err) {
                    next({ name: 'Home' });
                }
            };

            req();

        } else {
            // logado
            // verifica produto
            if (flProduto) {
                const verificaProduto = async () => {
                    try {
                        const res = await axios.get(`${urlApi}/Produto/VerificaAcessoProduto`, { params: { cdProduto: to.meta.cdProduto } })
                        if (res.data) {
                            if (res.data.data == 'Sim') {
                                // LOG ACESSOPRODUTO
                                axios.post(`${urlApi}/Log/AcessoProduto`, { 'cdProduto': to.meta.cdProduto });
                                next();
                            } else {
                                next({ name: 'HomeLogado' });
                            }
                        } else {
                            next({ name: 'HomeLogado' });
                        }
                    } catch (err) {
                        next({ name: 'HomeLogado' });
                    }
                };

                verificaProduto();
            } else {
                if (user.authenticated) {
                    if (to.matched.some(record => record.meta.cdProduto)) {
                        // LOG ACESSOPRODUTO
                        axios.post(`${urlApi}/Log/AcessoProduto`, { 'cdProduto': to.meta.cdProduto });
                    }

                    next()
                } else {
                    next(false)
                }
            }
        }

    } else {
        // rota deslogado
        next()
    }
})

export default router