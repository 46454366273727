import { render, staticRenderFns } from "./viewSUSTF.vue?vue&type=template&id=32969faa&scoped=true"
import script from "./viewSUSTF.vue?vue&type=script&lang=js"
export * from "./viewSUSTF.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32969faa",
  null
  
)

export default component.exports