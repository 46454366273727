<template>
    <div class="viewBOLET">
        <div class="modal-conteudo m-2">
            <div id="divImprimir">
                <!--<h3 class="bold txtApp" v-html="dados.nmTitulo"></h3>-->
                <div class="row mb-2">
                    <div class="col-md-8">
                        <h4 class="txtApp">Acórdão:</h4>
                        <template v-if="dados.dsTextoAcordao == null || dados.dsTextoAcordao == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dsTextoAcordao"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Indexação:</h4>
                        <template v-if="dados.dsIndexacao == null || dados.dsIndexacao == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dsIndexacao"></span>
                        </template>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <h4 class="txtApp">Enunciado:</h4>
                        <template v-if="dados.dsEnunciado == null || dados.dsEnunciado == ''">
                            <span class="regular">...</span>
                        </template>
                        <template v-else>
                            <span class="regular" v-html="dados.dsEnunciado"></span>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer pb-0 mt-4 d-flex justify-content-end">
            <AdicionarBiblioteca v-if="exibeBiblioteca" :id="dados.idBoletim.toString()" :tipo="tpConteudo" :cdConteudo="cdConteudo"></AdicionarBiblioteca>

            <a :href="dados.urlDocumentoPdf" class="btn btn-default txt-size-14" >
                <i class="fas fa-file-pdf"></i> Baixar PDF
            </a>
            <a :href="dados.urlDocumento" class="btn btn-default txt-size-14" >
                <i class="fas fa-file-word"></i> Baixar Word
            </a>

            <button class="btn btn-blue txt-size-14" @click="imprimir">
                <i class="fas fa-print"></i> Imprimir
            </button>
        </div>
    </div>
</template>

<script>
    import AdicionarBiblioteca from '../AdicionarBiblioteca'
    import { urlApi } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'viewBOLET',
        components: { AdicionarBiblioteca },
        data: function () {
            return {
                exibeBiblioteca: true,
                tpConteudo: 'Pesquisa',
                cdConteudo: 'BOLET',
            }
        },
        props: {
            dados: Object,
        },
        methods: {
            verificaExisteRepositorio(idConteudo, tpConteudo, cdConteudo) {
                let $this = this;
                axios.get(`${urlApi}/Repositorio`, { params: { idConteudo: idConteudo, tpConteudo: tpConteudo, cdConteudo: cdConteudo } })
                    .then(res => {
                        if (res.data.success) {
                            $this.exibeBiblioteca = res.data.data;
                        } else {
                            console.log('Não foi possível verificar o repositório')
                        }
                    })
                    .catch(function () {
                        console.log('Não foi possível verificar o repositório')
                    });
            },

            imprimir() {
                var conteudo = document.getElementById('divImprimir').innerHTML,
                    tela_impressao = window.open('about:blank');

                tela_impressao.document.write(conteudo);
                tela_impressao.window.print();
                tela_impressao.window.close();
            },
        },
        mounted: function () {
            this.verificaExisteRepositorio(this.dados.idBoletim.toString(), this.tpConteudo, this.cdConteudo);
        },
        watch: {
            dados: {
                handler() {
                    this.verificaExisteRepositorio(this.dados.idBoletim.toString(), this.tpConteudo, this.cdConteudo);
                },
                deep: true
            },
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    
   
</style>

