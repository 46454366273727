<template>
    <section class="Agenda">
        <Header></Header>
        
        <div class="container">
            <div class="row my-3">
                <div class="col-12 text-center">
                    <p class="txt-p txt-size-20 txt-agenda mb-0">
                        Sollicita <span class="txt-w-7">Agenda</span>
                    </p>
                </div>
                <div class="col-12 text-end">
                    <ManualFerramenta :name="'Manual Agenda'" :btnClass="'btn-agenda'" :nameBtn="'Manual'" :urlPdf="'/manuais/FERRAMENTA AGENDA.pdf'"></ManualFerramenta>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="box-branco">
                        <div class="row">
                            <div class="input-group mb-3">
                                <input v-model="termo" v-on:keyup.enter="pesquisar" type="text" class="form-control h-100" placeholder="Pesquise por compromissos" aria-label="Pesquise por compromissos" aria-describedby="button-addon2" style="border: 1px solid #53BFEF; ">
                                <button class="btn btn-agenda w-30" type="button" id="button-addon2" @click="pesquisar">Buscar</button>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <p class="txt-p txt-grey-6 txt-size-16 txt-w-7 mb-0" @click="addCompromisso">
                                    Adicionar novo compromisso na agenda:
                                </p>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-12">
                                <button class="btn btn-agenda w-100" @click="addCompromisso">
                                    <span><i class="fas fa-plus"></i></span>  Adicionar compromisso
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row mt-5">
                <div class="col-12 col-md-5">
                    <template v-if="flPesquisa">
                        <p class="txt-p txt-grey-6 txt-size-16 txt-w-7 mb-2">
                            Resultados para o termo "{{termo}}":
                        </p>
                        <div class="lst-pesquisa" v-if="lstPesquisa">
                            <div class="item-pesquisa mb-2" v-for="(item,index) in lstPesquisa.lstTarefas" :key="index">
                                <div class="dt-compromisso py-2 d-flex w-100 align-items-center justify-content-between" v-if="exibeDataPesquisa(item.dtTarefa,index)">
                                    <span class="txt-p txt-size-14 txt-w-7 txt-grey-3">
                                        {{formataDataCompromisso(item.dtTarefa)}}
                                    </span>
                                </div>
                                <div class="evento-compromisso mt-2 txt-white d-flex w-100 align-items-center justify-content-between c-pointer" @click="editCompromisso(item.idTarefa)">
                                    <span class="txt-p txt-w-7 txt-size-14">
                                        {{item.nmTarefa}}
                                    </span>
                                    <span class="txt-p txt-w-7 txt-size-14" v-if="item.hrTarefa">
                                        {{formatHoraCompromisso(item.hrTarefa)}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <p class="txt-p txt-grey-6 txt-size-16 txt-w-7 mb-2">
                            Compromissos agendados:
                        </p>
                        <div class="lst-compromissos">
                            <div class="item-compromisso">
                                <div class="dt-compromisso py-2 d-flex w-100 align-items-center justify-content-between">
                                    <span class="txt-p txt-size-14 txt-w-7 txt-grey-3">
                                        {{formatDateCompromisso}}
                                    </span>
                                    <span class="badge-hoje" v-if="verificaHoje()">
                                        HOJE
                                    </span>
                                </div>
                                <div class="evento-compromisso mt-2 txt-white d-flex w-100 align-items-center justify-content-between c-pointer" @click="editCompromisso(item.idTarefa)"
                                     v-for="(item,index) in lstCompromissos" :key="index">
                                    <span class="txt-p txt-w-7 txt-size-14">
                                        {{item.nmTarefa}}
                                    </span>
                                    <span class="txt-p txt-w-7 txt-size-14" v-if="item.hrTarefa">
                                        {{formatHoraCompromisso(item.hrTarefa)}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </template>
                    
                </div>
                <div class="col-12 col-md-7">
                    <div class="box-branco-2">
                        <div id='calendar'></div>
                    </div>
                </div>
            </div>
        </div>

        <ModalCompromisso :atualizar="load" :showModal="showModalCompromisso" :idCompromisso="idCompromisso" ></ModalCompromisso>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';
    import ModalCompromisso from '@/components/Agenda/ModalCompromisso';
    import ManualFerramenta from '@/components/ManualFerramenta';

    import { urlApi, avisoErro  } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'
    export default {
        name: 'Agenda',
        components: { Header, Footer, ModalCompromisso, ManualFerramenta },
        computed: {
            user() {
                return this.$store.state.user
            },

            formatDateCompromisso() {
                if (this.ano && this.mes && this.dia) {
                    var dt = this.ano + '-' + this.mes + '-' + this.dia;
                    return moment(dt,'YYYY-MM-DD').format("DD [de] MMMM [de] YYYY");
                } else {
                    return "";
                }
            },
        },
        data() {
            return {
                lstCompromissos: [],

                showModalCompromisso: false,
                idCompromisso: null,

                dia: null,
                mes: null,
                ano: null,

                calendar : null,
                termo : null,
                flPesquisa : false,
                lstPesquisa: [],
                dtReferenciaPesquisa: moment(),
            }
        },
        methods: {
            load() {
                let $this = this;
                $this.lstCompromissos = [];

                var hoje = moment();
                $this.dia = hoje.format('DD');
                $this.mes = hoje.format('MM');
                $this.ano = hoje.format('YYYY');
                $this.carregaCalendario($this.dia, $this.mes, $this.ano);
                $this.listaCompromissoDia($this.dia, $this.mes, $this.ano);
            },

            carregaCalendario(dia, mes, ano) {
                let $this = this;
                
                var calendarEl = document.getElementById('calendar');
                var calendar = new window.FullCalendar.Calendar(calendarEl, {
                    initialDate: ano + '-' + mes + '-01',
                    selectable: true,
                    showNonCurrentDates: false,
                    initialView: 'dayGridMonth',
                    locale: 'pt-br',
                    themeSystem: 'bootstrap',
                    bootstrapFontAwesome: {
                        myCustomButton: 'fa-plus'
                    },
                    customButtons: {

                        myCustomButton: {
                            text: 'Adicionar compromisso',
                            click: function () {
                                $this.addCompromisso();
                            }
                        },
                        today: {
                            text: 'Hoje',
                            click: function () {
                                location.reload();
                            }
                        },
                        prev: {
                            click: function () {
                                $this.recarregaCalendario(-1);
                            }
                        },
                        next: {
                            click: function () {
                                $this.recarregaCalendario(1);
                            }
                        }
                    },
                    headerToolbar: {
                        start: 'prev,next,today',
                        center: 'title',
                        end: 'myCustomButton'
                    }, // buttons for switching between views
                    dateClick: function (info) {
                        $this.recarregaCompromissoDia(info.dateStr.split('-')[2]);
                    },
                    eventClick: function (info) {
                        $this.recarregaCompromissoDia(parseInt(info.event.id));
                    },

                });

                calendar.render();

                $this.calendar = calendar;

                axios.get(`${urlApi}/Agenda/CarregaCalendario`, { params: { dia: dia, mes: mes, ano: ano } })
                    .then(res => {
                        if (res.data.success) {
                            var result = res.data.data;
                            for (let i = 0; i <= 5; i++) {
                                for (let j = 0; j <= 6; j++) {
                                    if (result[i][j] != null) {
                                        let lstTarefas = result[i][j].lstTarefas;
                                        for (let k = 0; k <= lstTarefas.length; k++) {
                                            if (lstTarefas[k] != null) {
                                                $this.calendar.addEvent({
                                                    title: lstTarefas[k].nmTarefa,
                                                    start: moment(lstTarefas[k].dtTarefa).format('YYYY-MM-DD'),
                                                    id: result[i][j].dia,
                                                });
                                            } 
                                        }
                                    }
                                }
                            }
                        } else {
                            avisoErro("Não foi possível carrega o calendário");
                        }
                    })
                    .catch(function(){
                        avisoErro("Não foi possível carrega o calendário");
                    });
            },

            recarregaCalendario(addMonth) {
                let $this = this;
                $this.dia = moment().format("DD");

                if (parseInt($this.mes) == 1 && addMonth == -1) {
                    $this.mes = 12;
                    $this.ano = parseInt($this.ano) -1;
                }
                else if (parseInt($this.mes) == 12 && addMonth == 1) {
                    $this.mes = 1;
                    $this.ano = parseInt($this.ano) + 1;
                }
                else {
                    $this.mes = parseInt($this.mes) + parseInt(addMonth);
                }
                $this.mes = ($this.mes < 10 ? "0" + $this.mes : $this.mes);

                $this.calendar.destroy();
                $this.calendar = null;

                $this.carregaCalendario($this.dia, $this.mes, $this.ano);
                $this.listaCompromissoDia($this.dia, $this.mes, $this.ano);
            },

            recarregaCompromissoDia(dia) {
                this.dia = dia;
                this.listaCompromissoDia(this.dia, this.mes, this.ano);
            },
            
            listaCompromissoDia(dia, mes, ano) {
                let $this = this;
                axios.get(`${urlApi}/Agenda/CarregaCompromissoDia`, { params: { dia: dia, mes: mes, ano: ano } })
                    .then(res => {
                        if (res.data.success) {
                            $this.flPesquisa = false;
                            let result = res.data.data;
                            if (result.lstTarefas) {
                                $this.lstCompromissos = result.lstTarefas;
                            }
                            
                        } else {
                            avisoErro("Não foi possível carrega os compromissos do dia");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carrega os compromissos do dia");
                    });
            },

            addCompromisso() {
                this.idCompromisso = null;
                this.showModalCompromisso = !this.showModalCompromisso;
            },

            editCompromisso(idCompromisso) {
                this.idCompromisso = idCompromisso;
                this.showModalCompromisso = !this.showModalCompromisso;
            },

            formatHoraCompromisso(dtInicio) {
                return moment(dtInicio,'HH:mm:ss').format("HH[h]mm");
            },

            formataDataCompromisso(dtInicio) {
                return moment(dtInicio).format("DD [de] MM [de] YYYY");
            },

            verificaHoje() {
                if (this.ano && this.mes && this.dia) {
                    let hoje = moment().format('YYYMMDD');
                    let dt = this.ano + '-' + this.mes + '-' + this.dia;
                    let dtCompara = moment(dt).format('YYYMMDD');
                    return hoje == dtCompara;
                } else {
                    return false;
                }
               
            },

            pesquisar() {
                let $this = this;
                axios.get(`${urlApi}/Agenda/PesquisaCompromisso`, { params: { termo: $this.termo} })
                    .then(res => {
                        if (res.data.success) {
                            $this.flPesquisa = true;
                            $this.lstPesquisa = res.data.data;
                        } else {
                            avisoErro("Não foi possível carrega a pesquisa");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carrega a pesquisa");
                    });
            },


            exibeDataPesquisa(dt, index) {
                var dtAtual = moment(dt).format('YYYY-MM-DD');
                if (index == 0) {
                    return true;
                } else {
                    var dtReferencia = moment(this.lstPesquisa.lstTarefas[index - 1].dtTarefa).format('YYYY-MM-DD')
                    if (dtReferencia != dtAtual) {
                        return true;
                    } else {
                        return false;
                    }
                }
                
            }
        },
        mounted: function () {
            this.load();
        },
        watch: {

        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    .box-branco {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        background-color: #FFF;
        border-radius: 20px;
        padding: 20px;
    }
    .box-branco-2 {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        background-color: #FFF;
        border-radius: 10px;
        padding: 20px;
    }
    

    .lst-compromissos {

        .item-compromisso {
            background: #FFFFFF;
            border: 1px solid #009FE6;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            padding: 10px 20px;

            .dt-compromisso {
                border-bottom: 1px solid #DADADA;

                .badge-hoje {
                    background: #FFFFFF;
                    border: 1px solid #009FE6;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                }
            }

            .evento-compromisso {
                padding: 10px 20px;
                background: #009FE6;
                border-radius: 5px;
            }
        }
    }

    .lst-pesquisa {

        .item-pesquisa {
            background: #FFFFFF;
            padding: 10px 20px;

            .dt-compromisso {
                border-bottom: 1px solid #DADADA;

                .badge-hoje {
                    background: #FFFFFF;
                    border: 1px solid #009FE6;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                }
            }

            .evento-compromisso {
                padding: 10px 20px;
                background: #009FE6;
                border-radius: 5px;
            }
        }
    }

    .fc .fc-toolbar {
        flex-wrap: wrap;
    }

    .fc .fc-view-harness{
        min-height: 330px !important;
    }

</style>

