<template>
    <div class="img-default">
        <div :class="'circulo-usuario '+(borda?'borda':'')+' size-'+size">
            {{inicialNome}}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ImgDefault',
        props: {
            nome: {
                type: String,
                required: true,
            },

            size: {
                type: Number,
            },

            borda: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            inicialNome() {
                if (this.nome) {
                    return this.nome.trim()[0].toUpperCase();
                } else {
                    return "";
                }
            },
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    /* ImgDefault */

    .circulo-usuario {
        background: #F4F4F4;
        box-sizing: border-box;
        border-radius: 50%;
        width: 130px;
        height: 130px;
        font-size: 30px;
        font-weight: 500;
        color: #474747;
        display: flex;
        justify-content: center;
        align-items: center;

        
    }

    .borda{
        border: 1px solid #FBBC0D;
    }

    .size-0 {
        width: 30px !important;
        height: 30px !important;
        font-size: 15px !important;
    }

    .size-1 {
        width: 45px !important;
        height: 45px !important;
        font-size: 20px !important;
    }

    .size-2 {
        width: 75px !important;
        height: 75px !important;
        font-size: 25px !important;
    }

    .size-3 {
        width: 130px !important;
        height: 130px !important;
        font-size: 50px !important;
    }

    .size-4 {
        width: 182px !important;
        height: 182px !important;
        font-size: 55px !important;
    }

</style>

