<template>
    <div class="ModalOcorrencia">
        <b-modal ref="modal-ocorrencia" id="ModalOcorrencia" size="lg" :hide-footer="true" :hide-header="true">
            <div class="modal-titulo txt-p txt-w-7 txt-size-20 txt-grey-2 m-2">
                <p class="txt-w-20 mb-0 txt-contador_prazos">{{titulo}}</p>
                <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <div class="modal-conteudo m-2 px-3">
                <form class="mt-2">
                    <div class="mb-3">
                        <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                            1. Etapa:
                        </label>
                        <div class="w-100" v-if="!idContagemPrazoData">
                            <select class="form-select" aria-label="Selecione uma etapa" v-model="ocorrencia.idEtapa">
                                <option class="d-none" value="null" selected disabled>Selecione uma etapa</option>
                                <option :value="item.idEtapa" v-for="(item,index) in lstEtapas" :key="index">{{item.nmEtapa}}</option>
                            </select>
                        </div>
                        <div class="w-100" v-else>
                            <span class="txt-p txt-size-16 txt-w-4 txt-grey-5" v-if="nmEtapa">{{nmEtapa}}</span>
                            <span class="txt-p txt-size-16 txt-w-4 txt-grey-5" v-else> Este tipo de evento não permite edição da etapa.</span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                            2. Prazos:
                        </label>
                        <div class="row">
                            <div class="col-6">
                                <label for="dtEtapa" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-5 mb-1 ps-2">Data</label>
                                <date-picker class="w-100" v-model="ocorrencia.dtEtapa" format="DD/MM/YYYY" placeholder="Data" ></date-picker>
                            </div>
                            <div class="col-6">
                                <label for="hrEtapa" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-5 mb-1 ps-2">Hora</label>
                                <date-picker class="w-100" v-model="ocorrencia.hrEtapa" format="HH:mm" placeholder="Hora" type="time"></date-picker>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer mt-3">
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-contador_prazos px-4 py-2 w-100" type="button" @click="salvar" :disabled="validaSalvar">
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template> 

<script>
    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/pt-br';

    export default {
        name: 'ModalOcorrencia',
        props: {
            idContagemPrazoData: Number,
            showModal: Boolean,
            atualizar: Function,
            idModalidade: Number,
            idContagemPrazo: Number,
        },
        components: { DatePicker },
        data: function () {
            return {
                titulo: 'Adicionar ocorrência',
                lstEtapas: [],
                ocorrencia: {
                    idEtapa: null,
                    dtEtapa: null,
                    hrEtapa: null,
                    flConcluido: 'Não',
                    dtConcluido: null,
                    dsLoginConcluido: null,
                },

                nmEtapa: null,
            }
        },
        computed: {
            validaSalvar() {
                let $this = this;

                if ($this.ocorrencia.idEtapa == null || $this.ocorrencia.idEtapa == '') {
                    return true;
                }

                if ($this.ocorrencia.dtEtapa == null || $this.ocorrencia.dtEtapa == '') {
                    return true;
                }
                if ($this.ocorrencia.hrEtapa == null || $this.ocorrencia.hrEtapa == '') {
                    return true;
                }
                                
                return false;
            },
        },
        methods: {
            load() {
                this.carregaEtapas();
                
                this.ocorrencia = {
                    idEtapa: null,
                    dtEtapa: null,
                    hrEtapa: null,
                    flConcluido: 'Não',
                    dtConcluido: null,
                    dsLoginConcluido: null,
                };

                if (this.idContagemPrazoData) {
                    this.titulo = 'Editar ocorrência';
                    this.carregaOcorrencia();
                }
                this.$refs['modal-ocorrencia'].show();
            },

            hideModalFn() {
                this.$refs['modal-ocorrencia'].hide();
            },

            salvar() {
                let $this = this;
                var ocorrencia = $this.ocorrencia;
                ocorrencia.idContagemPrazoData = ($this.idContagemPrazoData ? $this.idContagemPrazoData : 0);
                ocorrencia.idContagemPrazo = $this.idContagemPrazo;
                ocorrencia.idModalidade = $this.idModalidade;
                ocorrencia.dtPrazo = moment($this.ocorrencia.dtEtapa).format('YYYY-MM-DD') + " " + moment(ocorrencia.hrEtapa).format('HH:mm:ss');

                axios.post(`${urlApi}/ContadorPrazo/PostOcorrencia`, ocorrencia, { showLoading: true })
                    .then(res => {
                        if (res.data.success) {
                            $this.atualizar();
                            avisoSucesso("Ocorrência salva com sucesso.");
                            $this.hideModalFn();
                        } else {
                            avisoErro("Não foi possível salvar a ocorrência");
                        }
                    })
                    .catch(res => {
                        avisoErro(res.response.data.errors[0]);
                    });
            },

            carregaEtapas() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/GetEtapas`, { params: { idModalidade : $this.idModalidade}})
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data != null) {
                               $this.lstEtapas = res.data.data.rows;
                            }
                        } else {
                            avisoErro("Não foi possível carregar as etapas");
                            $this.lstEtapas = [];
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar as etapas");
                        $this.lstEtapas = [];
                    });
            },

            carregaOcorrencia() {
                let $this = this;
                axios.get(`${urlApi}/ContadorPrazo/GetOcorrencia/${$this.idContagemPrazoData}`)
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data != null) {
                                var ocorrencia = res.data.data.contagemPrazoData;
                                $this.ocorrencia = {
                                    idEtapa: ocorrencia.idEtapa,
                                    dtEtapa: new Date(moment(ocorrencia.dtPrazo).toISOString()),
                                    hrEtapa: new Date(ocorrencia.dtPrazo),
                                    flConcluido: ocorrencia.flConcluido,
                                    dtConcluido: ocorrencia.dtConcluido,
                                    dsLoginConcluido: ocorrencia.dsLoginConcluido,
                                };
                                var etapa = res.data.data.etapa;

                                if (etapa.flProcedimento == "Sim") {
                                    $this.titulo = 'Editar procedimento';
                                } else {
                                    $this.titulo = 'Editar ocorrência';
                                }

                                $this.nmEtapa = etapa.nmEtapa;
                            }
                        } else {
                            avisoErro("Não foi possível carregar a ocorrência");
                            $this.ocorrencia = null;
                            $this.hideModalFn();
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar a ocorrência");
                        $this.ocorrencia = null;
                        $this.hideModalFn();
                    });
            },
        },
        mounted: function () {
        },
        watch: {
            showModal: function () {
                this.load();
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #DADADA;
        display: flex;
        justify-content: space-between;
    }

    .modal-conteudo {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 70vh;
    }

</style>

