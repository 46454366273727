<template>
    <div class="viewLICIT">
        <div class="modal-conteudo m-2">
            <div class="row">
                <div class="col-6">
                    <fieldset>
                        <legend class="regular">Dados do Edital</legend>
                        <p id="txt-orgao">{{dados.objLicitacao.dsObjeto}}</p>
                    </fieldset>

                    <fieldset>
                        <legend class="regular">Download do Edital</legend>

                        <!--<div id="divCaptcha" class="form-group"></div>-->
                        <a :href="dados.objLicitacao.urlEdital.replace('http://www.comprasnet', 'https://www.comprasnet')" id="linkEdital" class="btn btn-default btnApp" target="_blank">Link do Edital</a>
                    </fieldset>
                </div>
                <div class="col-6">
                    <fieldset>
                        <legend class="regular bold">Itens do Edital</legend>
                        <ul class="list-group">
                            <template v-if="dados.lstEdital.length == 0">
                                <li class="list-group-item">
                                    <span id="semRegistros">Nenhum Registro Encontrado...</span>
                                </li>
                            </template>
                            <template v-else>
                                <li class="list-group-item regular itemLista" v-for="(item,index) in dados.lstEdital" :key="index">
                                    <p class="text-left">{{item.descricao}}</p>
                                </li>
                            </template>

                        </ul>
                    </fieldset>
                </div>
            </div>
        </div>

        <div class="modal-footer pb-0 mt-4 d-flex justify-content-end">
            
        </div>
        
    </div>
</template>

<script>

    export default {
        name: 'viewLICIT',
        components: { },
        data: function () {
            return {
                
            }
        },
        props: {
            dados: Object,
        },
        methods: {

        },
        mounted: function () {
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    
   
</style>

