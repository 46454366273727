<template>
    <div class="MudarSenha" >
        <b-modal ref="modal-mudar-senha" id="modalMudarSenha" size="xl" :hide-footer="true" :hide-header="true">
            <div class="w-100 text-end txt-size-20 txt-blue-2">
                <span @click="close"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <form class="px-3 pb-3" id="formTrocaSenha">

                <div class="row">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-size-25 txt-w-bold mb-2">
                            Mudar senha
                        </span>
                        <p class="txt-p txt-size-16 txt-w-5 txt-grey-2">
                            Preencha os campos abaixo, e realize a alteração da sua senha.
                        </p>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-25">
                            Senha Atual
                        </span>
                        <div class="form-group">
                            <input type="password" class="form-control" id="senhaAtual" name="usuario.senhaAtual" v-model="usuario.senhaAtual" :maxlength="limiteCaracteres" required>
                        </div>
                    </div>
                   
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-25">
                            Senha
                        </span>
                        <div class="form-group">
                            <input type="password" class="form-control" id="dsSenha" name="usuario.dsSenha" v-model="usuario.dsSenha" :maxlength="limiteCaracteres" required>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-25">
                            Confirma Senha
                        </span>
                        <div class="form-group">
                            <input type="password" class="form-control" id="dsSenhaConfirma" name="usuario.dsSenhaConfirma" v-model="usuario.dsSenhaConfirma" :maxlength="limiteCaracteres" required>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group w-100">
                        <button class="btn btn-blue w-100" type="button" :disabled="!liberaSalvar" @click="enviar">Alterar senha</button>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
    import { urlApi, avisoSucesso, avisoErro } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'MudarSenha',
        data: function () {
            return {

                limiteCaracteres: 500,

                usuario: {
                    senhaAtual: null,
                    dsSenha: null,
                    dsSenhaConfirma: null,
                },

            }
        },
        props: {
            showModal: Boolean,
        },
        computed: {
            liberaSalvar() {
                if (this.usuario.senhaAtual == '' || this.usuario.senhaAtual == null) {
                    return false;
                }

                if (this.usuario.dsSenha == '' || this.usuario.dsSenha == null) {
                    return false;
                }

                if (this.usuario.dsSenhaConfirma == '' || this.usuario.dsSenhaConfirma == null) {
                    return false;
                }


                return true;
            }
        },
        methods: {
            enviar() {
                if (this.checkForm()) {
                    let $this = this;
                    axios.post(`${urlApi}/Auth/AlteraSenha`, $this.usuario)
                        .then(res => {
                            if (res.data.success) {
                                // aviso ok
                                avisoSucesso("Senha alterada com sucesso!");
                                $this.$refs['modal-mudar-senha'].hide();
                            } else {
                                avisoErro("Não foi possível alterar sua senha.");
                            }
                        })
                        .catch(res => {
                            if (res.response.data != undefined) {
                                avisoErro(res.response.data);
                            } else {
                                avisoErro(res.response.statusText);
                            }
                        });
                }
                return false;
            },

            checkForm() {
                var form = document.getElementById('formTrocaSenha')
                if (!form.checkValidity()) {
                    var tmpSubmit = document.createElement('button')
                    form.appendChild(tmpSubmit)
                    tmpSubmit.click()
                    form.removeChild(tmpSubmit)

                    return false;
                }

                if (this.limiteCaracteres > 0) {
                    if (this.usuario.senhaAtual.length > this.limiteCaracteres) {
                        avisoErro("O limite de caracteres da \"Senha\" foi ultrapassado")
                        return false;
                    }

                    if (this.usuario.dsSenha.length > this.limiteCaracteres) {
                        avisoErro("O limite de caracteres da \"Nova Senha\" foi ultrapassado")
                        return false;
                    }

                    if (this.usuario.dsSenhaConfirma.length > this.limiteCaracteres) {
                        avisoErro("O limite de caracteres da \"Confirma Senha\" foi ultrapassado")
                        return false;
                    }
                }

                if (this.usuario.dsSenha != this.usuario.dsSenhaConfirma) {
                    avisoErro("As senhas não coincidem")
                    return false;
                }
                

                return true;
            },

            load() {
                
            },

            close() {
                this.$refs['modal-mudar-senha'].hide();
            }

        },
        created: function () {
            this.load();
        },
        watch: {
            showModal: function () {
                this.$refs['modal-mudar-senha'].show();
                this.usuario = {
                    senhaAtual: null,
                    dsSenha: null,
                    dsSenhaConfirma: null,
                };
            },
            
        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* Mudar Senha */
   .form-control {
        border: 1px solid #474747;
    }
</style>

