<template>
    <section class="DetalheAnalisadorBalancos">
        <Header></Header>

        <div class="container my-3">
            <div class="row ">
                <div class="col-2">
                    <span class="txt-h-4 txt-grey-6 c-pointer" @click="votar"><i class="fas fa-arrow-left"></i> Voltar</span>
                </div>
                <div class="col-8 text-center">
                    <p class="txt-p txt-size-20 txt-analisador_balancos mb-0">
                        Sollicita <span class="txt-w-7">Analisador de Balanços</span>
                    </p>
                </div>
                <div class="col-2">

                </div>
            </div>
        </div>

        <div class="container " v-if="analise">
            <div class="row">
                <div class="col-12">
                    <div class="box-detalhes">
                        <div class="row">
                            <div class="col-12 d-flex">
                                <div>
                                    <p class="txt-p txt-w-5 txt-size-16 txt-grey-3 mb-0">
                                        Código: <span class="txt-w-4">{{analise.cdAnalise}}</span>
                                    </p>
                                </div>
                                <div class="divisor-titulo ms-2 ps-2">
                                    <p class="txt-p txt-w-7 txt-size-16 txt-analisador_balancos mb-0">
                                        {{analise.nmAnalise}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <hr />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="box-indice">
                                    <div class="d-flex justify-content-center align-items-center w-100" style=" ">
                                        <img class="mr-2" src="../../../assets/img/icon/iconeAnaliseIndice.png" />
                                        <span class="txt-p txt-w-4 txt-size-16 txt-white">
                                            Índice exigido na licitação:
                                        </span>
                                    </div>
                                    <div class="w-100 text-center">
                                        <p class="txt-p txt-w-7 txt-size-20 txt-white mb-0">
                                            {{formatIndice(analise.nrIndiceExigidoLicitacao)}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-3">
                                <div class="row">
                                    <div class="col-12 col-md-5">
                                        <p class="txt-p txt-size-14 txt-w-4 txt-grey-2 mb-0">
                                            Significado do Índices:
                                        </p>
                                        <ul class="ps-5 mt-2 txt-grey-2">
                                            <li><span class="txt-p txt-size-14 txt-w-4 txt-grey-2">Deficitário = menor que 1</span></li>
                                            <li><span class="txt-p txt-size-14 txt-w-4 txt-grey-2">Equilibrado = entre 1 e 1,35</span></li>
                                            <li><span class="txt-p txt-size-14 txt-w-4 txt-grey-2">Satisfatório = maior que 1,35</span></li>
                                        </ul>
                                    </div>
                                    <div class="col-12 col-md-7">
                                        <select class="form-select" aria-label="idFornecedor" v-model="idFornecedor">
                                            <option value="0" >Todos os Fornecedores</option>
                                            <option :value="item.analiseFornecedor.idAnaliseFornecedor" v-for="(item,index) in lstFornecedores" :key="index">{{item.analiseFornecedor.nmRazaoSocial}}</option>
                                        </select>
                                        <div class="row mt-2 d-flex align-items-center">
                                            <div class="col-8">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="visaoResumida">
                                                    <label class="form-check-label" for="flexCheckDefault">
                                                        Visão Resumida
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" v-model="apenasIndice">
                                                    <label class="form-check-label" for="flexCheckChecked">
                                                        Apenas com Índice atingido
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <button class="btn btn-analisador_balancos w-100" @click="showModalImprimir = !showModalImprimir">
                                                    <i class="fas fa-print"></i> Imprimir análise
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <hr />
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-6">
                                <button class="btn btn-analisador_balancos w-100" @click="showModal = !showModal">
                                    <i class="fas fa-plus"></i> Adicionar fornecedor
                                </button>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-12">
                                <div class="lst-fornecedores">
                                    <div class="form-fornecedor mt-3" v-for="(item,index) in getListFornecedores()" :key="index">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-10">
                                                        <p class="txt-p txt-size-16 txt-w-7 txt-analisador_balancos mb-1">
                                                            {{item.analiseFornecedor.nmRazaoSocial}}
                                                        </p>
                                                        <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0">
                                                            CNPJ: {{item.analiseFornecedor.nrCnpj}}
                                                        </p>
                                                        <p class="txt-p txt-size-15 txt-w-4 txt-grey-3" v-if="apenasIndice && item.analiseFornecedor.dtInicio != null && item.analiseFornecedor.dtFim != null">
                                                            Período: {{item.analiseFornecedor.dtInicio}} a {{item.analiseFornecedor.dtFim}}
                                                        </p>
                                                    </div>
                                                    <div class="col-2 d-flex justify-content-end">
                                                        <button class="btn btn-excluir txt-size-12" @click="removerFornecedor(item.analiseFornecedor.idAnaliseFornecedor,item.analiseFornecedor.nmRazaoSocial)">
                                                            <i class="fas fa-trash"></i> Remover fornecedor
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row" v-if="item.dadosGnp">
                                                    <div class="col-12">
                                                        <hr />
                                                    </div>
                                                    <template v-if="item.dadosGnp.idCnpj">
                                                        <div class="accordion" id="accordionExample">
                                                            <div class="accordion-item">
                                                                <h2 class="accordion-header" id="headingOne">
                                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                        <p class="txt-p txt-size-16 txt-w-7 txt-analisador_balancos mb-1">
                                                                            Dados do fornecedor
                                                                        </p>
                                                                    </button>
                                                                </h2>
                                                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                    <div class="accordion-body">
                                                                        <div class="row">
                                                                            <div class="col-12 col-md-6">
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.nmRazaoSocial">
                                                                                    Razão Social: {{item.dadosGnp.nmRazaoSocial}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.nmFantasia">
                                                                                    Nome Fantasia: {{item.dadosGnp.nmFantasia}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.dtAbertura">
                                                                                    Data de abertura: {{formataData(item.dadosGnp.dtAbertura)}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.dsEmail">
                                                                                    Email: {{item.dadosGnp.dsEmail}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.nrTelefone">
                                                                                    Telefone: {{item.dadosGnp.nrTelefone}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.vlCapitalSocial">
                                                                                    Capital social: R$ {{formatIndice(item.dadosGnp.vlCapitalSocial)}}
                                                                                </p>

                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.dsSituacao">
                                                                                    Situação: {{item.dadosGnp.dsSituacao}} - {{formataData(item.dadosGnp.dtSituacao)}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.dsMotivoSituacao">
                                                                                    Motivo Situação: {{item.dadosGnp.dsMotivoSituacao}} - {{item.dadosGnp.dsMotivoSituacaoEspecial}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.dsNaturezaJuridica">
                                                                                    Natureza Jurídica: {{item.dadosGnp.dsNaturezaJuridica}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.dsStatus">
                                                                                    Status: {{item.dadosGnp.dsStatus}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.dsTipo">
                                                                                    Tipo: {{item.dadosGnp.dsTipo}}
                                                                                </p>
                                                                            </div>
                                                                            <div class="col-12 col-md-6">
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.nrCep">
                                                                                    CEP: {{item.dadosGnp.nrCep}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.dsEndereco">
                                                                                    Endereço: {{item.dadosGnp.dsEndereco}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.nrNumero">
                                                                                    Número: {{item.dadosGnp.nrNumero}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.dsBairro">
                                                                                    Bairro: {{item.dadosGnp.dsBairro}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.dsComplemento">
                                                                                    Complemento: {{item.dadosGnp.dsComplemento}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.nmCidade">
                                                                                    Cidade: {{item.dadosGnp.nmCidade}}
                                                                                </p>
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.dsUf">
                                                                                    UF: {{item.dadosGnp.dsUf}}
                                                                                </p>
                                                                            </div>
                                                                            <div class="col-12">
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.lstSocio && item.dadosGnp.lstSocio.length > 0">
                                                                                    Sócio(s): <span v-for="(item2,index2) in item.dadosGnp.lstSocio" :key="index2">
                                                                                                  <template v-if="item2.socio">
                                                                                                      {{item2.socio.nmSocio}}
                                                                                                  </template></span>
                                                                                </p>
                                                                            </div>
                                                                            <div class="col-12">
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.lstAtividadePrincipal">
                                                                                    Atividade Principal: <span v-for="(item2,index2) in item.dadosGnp.lstAtividadePrincipal" :key="index2">{{item2}}</span>
                                                                                </p>
                                                                            </div>
                                                                            <div class="col-12">
                                                                                <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0" v-if="item.dadosGnp.lstAtividadeSecundaria">
                                                                                    Atividade Secundária: <span v-for="(item2,index2) in item.dadosGnp.lstAtividadeSecundaria" :key="index2">{{item2}}</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="col-12">
                                                            <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-0">
                                                                Não foram encontrados dados do cnpj informado.
                                                            </p>
                                                        </div>
                                                    </template>
                                                </div>
                                                <div class="row" v-if="!apenasIndice">
                                                    <div class="col-12">
                                                        <hr />
                                                    </div>
                                                    <div class="col-12 col-md-8" v-if="!visaoResumida">
                                                        <form class="mt-2">
                                                            <div class="mb-3">
                                                                <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                                                    1. Datas
                                                                </label>
                                                                <div class="row">
                                                                    <div class="col-12 col-md-6">
                                                                        <label for="dtInicio" class="form-label txt-p txt-w-5 txt-size-14 txt-grey-2 mb-1 ps-2">
                                                                            Data INÍCIO
                                                                        </label>
                                                                        <date-picker class="w-100" v-model="item.analiseFornecedor.dtInicio" format="DD/MM/YYYY" placeholder="Data" value-type="format"></date-picker>
                                                                    </div>
                                                                    <div class="col-12 col-md-6">
                                                                        <label for="dtFim" class="form-label txt-p txt-w-5 txt-size-14 txt-grey-2 mb-1 ps-2">
                                                                            Data FIM
                                                                        </label>
                                                                        <date-picker class="w-100" v-model="item.analiseFornecedor.dtFim" format="DD/MM/YYYY" placeholder="Data" value-type="format"></date-picker>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                                                    2. Ativos
                                                                </label>
                                                                <div class="row">
                                                                    <div class="col-12 col-md-4">
                                                                        <label for="circulante" class="form-label txt-p txt-w-5 txt-size-14 txt-grey-2 mb-1 ps-2">
                                                                            Circulante
                                                                        </label>
                                                                        <vue-mask class="form-control"
                                                                                  placeholder="Circulante"
                                                                                  v-model="item.analiseFornecedor.vlAtivoCirculante"
                                                                                  mask="000.000.000.000.000,00"
                                                                                  :raw="false"
                                                                                  :options="{reverse:true}"
                                                                                  required>
                                                                        </vue-mask>
                                                                    </div>
                                                                    <div class="col-12 col-md-4">
                                                                        <label for="circulante" class="form-label txt-p txt-w-5 txt-size-14 txt-grey-2 mb-1 ps-2">
                                                                            Realizável Longo Prazo
                                                                        </label>
                                                                        <vue-mask class="form-control"
                                                                                  placeholder="Realizável Longo Prazo"
                                                                                  v-model="item.analiseFornecedor.vlAtivoRealizavelLongoPrazo"
                                                                                  mask="000.000.000.000.000,00"
                                                                                  :raw="false"
                                                                                  :options="{reverse:true}"
                                                                                  required>
                                                                        </vue-mask>
                                                                    </div>
                                                                    <div class="col-12 col-md-4">
                                                                        <label for="circulante" class="form-label txt-p txt-w-5 txt-size-14 txt-grey-2 mb-1 ps-2">
                                                                            Total
                                                                        </label>
                                                                        <vue-mask class="form-control"
                                                                                  placeholder="Total"
                                                                                  v-model="item.analiseFornecedor.vlAtivoTotal"
                                                                                  mask="000.000.000.000.000,00"
                                                                                  :raw="false"
                                                                                  :options="{reverse:true}"
                                                                                  required>
                                                                        </vue-mask>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                                                                    3. Passivos
                                                                </label>
                                                                <div class="row">
                                                                    <div class="col-12 col-md-4">
                                                                        <label for="circulante" class="form-label txt-p txt-w-5 txt-size-14 txt-grey-2 mb-1 ps-2">
                                                                            Circulante
                                                                        </label>
                                                                        <vue-mask class="form-control"
                                                                                  placeholder="Circulante"
                                                                                  v-model="item.analiseFornecedor.vlPassivoCirculante"
                                                                                  mask="000.000.000.000.000,00"
                                                                                  :raw="false"
                                                                                  :options="{reverse:true}"
                                                                                  required>
                                                                        </vue-mask>
                                                                    </div>
                                                                    <div class="col-12 col-md-4">
                                                                        <label for="circulante" class="form-label txt-p txt-w-5 txt-size-14 txt-grey-2 mb-1 ps-2">
                                                                            Não Circulante
                                                                        </label>
                                                                        <vue-mask class="form-control"
                                                                                  placeholder="Não Circulante Longo Prazo"
                                                                                  v-model="item.analiseFornecedor.vlPassivoNaoCirculante"
                                                                                  mask="000.000.000.000.000,00"
                                                                                  :raw="false"
                                                                                  :options="{reverse:true}"
                                                                                  required>
                                                                        </vue-mask>
                                                                    </div>
                                                                    <div class="col-12 col-md-4">
                                                                        <label for="circulante" class="form-label txt-p txt-w-5 txt-size-14 txt-grey-2 mb-1 ps-2">
                                                                            Exigível Longo Prazo
                                                                        </label>
                                                                        <vue-mask class="form-control"
                                                                                  placeholder="Exigível Longo Prazo"
                                                                                  v-model="item.analiseFornecedor.vlPassivoExigivelLongoPrazo"
                                                                                  mask="000.000.000.000.000,00"
                                                                                  :raw="false"
                                                                                  :options="{reverse:true}"
                                                                                  required>
                                                                        </vue-mask>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        <button class="btn btn-analisador_balancos w-100" @click="calcular(item.analiseFornecedor)">
                                                            Calcular
                                                        </button>
                                                    </div>
                                                    <div class="col-12 col-md-4 mt-2 mt-sm-0">
                                                        <div class="box-resultados h-100 pt-3">
                                                            <div class="dados-resultados px-3">
                                                                <div class="row">
                                                                    <div class="col-12 d-flex justify-content-center">
                                                                        <p class="txt-p txt-size-16 txt-w-7 txt-grey-6 mb-0">
                                                                            Resultados:
                                                                        </p>
                                                                    </div>
                                                                    <div class="col-12" v-for="(item2,index2) in item.retornoIndices" :key="index2">
                                                                        <p class="txt-p txt-size-14 txt-w-4 txt-grey-3 mb-1">
                                                                            <span :class="'badge txt-white indicador-'+(item2.status)">{{formatIndice(item2.vlIndice)}}</span>
                                                                            <span class="bold mx-1">{{item2.nmIndice}}</span>
                                                                            <span :class="'txt-w-bold txt-'+(item2.status)">{{item2.resIndice}}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <template v-if="item.txtIndice.includes('Índice não atingido')">
                                                                <div class="indicador-resultado indicador-error">
                                                                    <p class="txt-p txt-size-20 txt-w-7 txt-white mb-0">
                                                                        {{item.txtIndice}}
                                                                    </p>
                                                                </div>
                                                            </template>
                                                            <template v-else-if="item.txtIndice.includes('Índice atingido')">
                                                                <div class="indicador-resultado indicador-success">
                                                                    <p class="txt-p txt-size-20 txt-w-7 txt-white mb-0">
                                                                        {{item.txtIndice}}
                                                                    </p>
                                                                </div>
                                                            </template>
                                                            <template v-else-if="item.txtIndice.includes('Aguardando Dados')">
                                                                <div class="indicador-resultado indicador-warning">
                                                                    <p class="txt-p txt-size-20 txt-w-7 txt-white mb-0">
                                                                        {{item.txtIndice}}
                                                                    </p>
                                                                </div>
                                                            </template>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalNovoFornecedor :idAnalise="id" :showModal="showModal" :atualizar="carregaDetalhes"></ModalNovoFornecedor>
        <ModalImprimirAnalise :idAnalise="id" :idAnaliseFornecedor="idFornecedor" :showModal="showModalImprimir" :visaoResumida="visaoResumida" :apenasIndice="apenasIndice" ></ModalImprimirAnalise>

        <Footer class="mt-5"></Footer>
    </section>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';
    import ModalNovoFornecedor from '@/components/AnalisadorBalancos/ModalNovoFornecedor';
    import ModalImprimirAnalise from '@/components/AnalisadorBalancos/ModalImprimirAnalise';

    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import axios from 'axios'

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/pt-br';
    import vueMask from 'vue-jquery-mask'
    import moment from 'moment'
    import Swal from 'sweetalert2'

    export default {
        name: 'DetalheAnalisadorBalancos',
        components: { Header, Footer, DatePicker, ModalNovoFornecedor, ModalImprimirAnalise, vueMask },
        props: {
            id: Number,
        },
        computed: {
            user() {
                return this.$store.state.user
            },
        },
        data() {
            return {
                analise: null,
                lstFornecedores: null,
                idFornecedor: 0,

                visaoResumida: false,
                apenasIndice: false,
                showModal: false,
                showModalImprimir: false,
            }
        },
        methods: {
            votar() {
                this.$router.go(-1);
            },

            load() {
                //let $this = this;
                this.carregaDetalhes();
            },

            carregaDetalhes() {
                let $this = this;
                axios.get(`${urlApi}/AnalisadorBalancos/GetDetalhes/${$this.id}`)
                    .then(res => {
                        if (res.data.success) {
                            var result = res.data.data;
                            $this.analise = result.analise;
                            $this.lstFornecedores = [];
                            for (let i = 0; i < result.lstFornecedores.length; i++) {
                                var item = result.lstFornecedores[i];

                                item.analiseFornecedor.dtInicio = item.analiseFornecedor.dtInicio != null ? moment(item.analiseFornecedor.dtInicio).format('DD/MM/YYYY'): null;
                                item.analiseFornecedor.dtFim = item.analiseFornecedor.dtFim != null ? moment(item.analiseFornecedor.dtFim).format('DD/MM/YYYY') : null;

                                $this.lstFornecedores.push(item);
                            }
                            
                        } else {
                            avisoErro("Não foi possível carrega as anáises");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carrega as análises");
                    });
            },

            formataData(dt) {
                return dt != null ? moment(dt).format('DD/MM/YYYY') : null;
            },

            formatIndice(nrIndice) {
                if (nrIndice != null) {
                    var novoInice = nrIndice.toFixed(2)
                    return novoInice.replace('.', ',');
                } else {
                    return null;
                }
                
            },

            getListFornecedores() {
                let $this = this;
                if ($this.idFornecedor != 0) {
                    return $this.lstFornecedores.filter(x => x.analiseFornecedor.idAnaliseFornecedor == $this.idFornecedor);
                } else {
                    return $this.lstFornecedores;
                }
            },

            calcular(obj) {
                if (this.validaCalcular(obj)) {
                    let $this = this;
                    let fornecedor = Object.assign({}, obj);
                    fornecedor.idAnalise = $this.id;
                    if (fornecedor.dtInicio != "" && fornecedor.dtInicio != null) {
                        fornecedor.dtInicio = moment(fornecedor.dtInicio, 'DD/MM/YYYY').format('YYYY-MM-DD');
                    } else {
                        fornecedor.dtInicio = null;
                    }

                    if (fornecedor.dtFim != "" && fornecedor.dtFim != null) {
                        fornecedor.dtFim = moment(fornecedor.dtFim, 'DD/MM/YYYY').format('YYYY-MM-DD');
                    } else {
                        fornecedor.dtFim = null;
                    }
                    fornecedor.vlAtivoCirculante = this.formataValor(fornecedor.vlAtivoCirculante);
                    fornecedor.vlAtivoRealizavelLongoPrazo = this.formataValor(fornecedor.vlAtivoRealizavelLongoPrazo);
                    fornecedor.vlAtivoTotal = this.formataValor(fornecedor.vlAtivoTotal);
                    fornecedor.vlPassivoCirculante = this.formataValor(fornecedor.vlPassivoCirculante);
                    fornecedor.vlPassivoNaoCirculante = this.formataValor(fornecedor.vlPassivoNaoCirculante);
                    fornecedor.vlPassivoExigivelLongoPrazo = this.formataValor(fornecedor.vlPassivoExigivelLongoPrazo);

                    axios.post(`${urlApi}/AnalisadorBalancos/PostFornecedor`, fornecedor, { showLoading: true })
                        .then(res => {
                            if (res.data.success) {
                                $this.carregaDetalhes();
                                avisoSucesso("Índice calculado com sucesso.");
                            } else {
                                avisoErro("Não foi possível calcular");
                            }
                        })
                        .catch(res => {
                            avisoErro(res.response.data.errors[0]);
                        });
                }
            },

            formataValor(valor) {
                if (typeof (valor) == 'string') {
                    valor = valor.replace(".", "").replace(',', '.');
                }
                
                return valor;
            },

            validaCalcular(obj) {
                if (obj.vlAtivoCirculante === null || obj.vlAtivoCirculante === '') {
                    avisoErro("O campo 'Circulante' é obrigatório");
                    return false;
                }
                if (obj.vlAtivoRealizavelLongoPrazo === null || obj.vlAtivoRealizavelLongoPrazo === '') {
                    avisoErro("O campo 'Realizável Longo Prazo' é obrigatório");
                    return false;
                }
                if (obj.vlAtivoTotal === null || obj.vlAtivoTotal === '') {
                    avisoErro("O campo 'Total' é obrigatório");
                    return false;
                }
                if (obj.vlPassivoCirculante === null || obj.vlPassivoCirculante === '') {
                    avisoErro("O campo 'Circulante' é obrigatório");
                    return false;
                }
                if (obj.vlPassivoNaoCirculante === null || obj.vlPassivoNaoCirculante === '') {
                    avisoErro("O campo 'Não Circulante' é obrigatório");
                    return false;
                }
                if (obj.vlPassivoExigivelLongoPrazo === null || obj.vlPassivoExigivelLongoPrazo === '') {
                    avisoErro("O campo 'Exigível Longo Prazo' é obrigatório");
                    return false;
                }

                return true;
            },

            removerFornecedor(idAnaliseFornecedor, nmFornecedor) {
                let $this = this;
                Swal.fire({
                    title: 'Tem certeza que deseja excluir "' + nmFornecedor + '"?',
                    text: 'Esta operação não poderá ser desfeita.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    confirmButtonColor: '#83A431',
                }).then((result) => {
                    if (result.isConfirmed) {
                        $this.excluirFornecedor(idAnaliseFornecedor);
                    }
                })
            },

            excluirFornecedor(idAnaliseFornecedor) {
                let $this = this;
                axios.delete(`${urlApi}/AnalisadorBalancos/DeleteFornecedor`, { params: { idAnaliseFornecedor: idAnaliseFornecedor } })
                    .then(res => {
                        if (res.data.success) {
                            $this.carregaDetalhes();
                            avisoSucesso("Fornecedor excluido com sucesso");
                        } else {
                            avisoErro("Não foi possível excluir o fornecedor");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível excluir o fornecedor");
                    });
            },

        },
        mounted: function () {
            this.load();
        },
        watch: {

        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .box-detalhes {
        background: #FFFFFF;
        border: 3px solid #A6A6A6;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 20px;
    }

    .divisor-titulo {
        border-left: 1px solid #DADADA;
    }

    .box-indice {
        background: #4E9A67;
        border-radius: 5px;
        padding: 10px;
    }

    .lst-fornecedores {
        .form-fornecedor {
            background: #F8F8F8;
            border: 1px solid #B9B9B9;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            padding: 20px;
        }

        .box-resultados {
            display: flex;
            flex-wrap: wrap;
            background: #FFFFFF;
            border: 1px solid #B9B9B9;
            border-radius: 10px;

            .dados-resultados {
                display: flex;
                flex-wrap: wrap;
            }

            .indicador-resultado {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                padding: 20px;
                border-radius: 8px;
                width: 100%;
            }

            .indicador-success {
                background-color: #5CCA41;
            }

            .indicador-warning {
                background-color: #D8BC59;
            }

            .indicador-error {
                background-color: #B03D3D;
            }

            .txt-success {
                color: #5CCA41;
            }

            .txt-warning {
                color: #D8BC59;
            }

            .txt-error {
                color: #B03D3D;
            }
        }
    }
</style>

