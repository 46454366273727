<template>
    <div>
        <header>
            <div class="header">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <template v-if="!user">
                                <p class="txt-h-6 txt-white txt-size-16">
                                    O Sollicita é mais que um portal de conteúdo.
                                </p>
                                <div class="row">
                                    <div class="col-12 col-md-5 pe-0">
                                        <p class="txt-h-6 txt-size-12 txt-white mb-2">
                                            Conheça os módulos e
                                            ferramentas disponíveis!
                                        </p>
                                    </div>
                                    <div class="col-12 col-md-7 ps-0">
                                        <button class="btn btn-yellow">
                                            Quero conhecer o Sollicita
                                        </button>
                                    </div>
                                </div>
                            </template>
                            
                        </div>
                        <div class="col-6 col-md-4 my-2 my-sm-0 d-flex justify-content-center align-items-center">
                            <router-link :to="{name:'HomeLogado'}">
                                <img class="logo-sollicita" :src="logo" />
                            </router-link>
                            
                        </div>
                        <div class="col-6 col-md-4 d-flex flex-column align-items-center">
                            <template v-if="user != null && user != undefined">
                                <div class="d-flex" style=" position: relative; width: 100%; height: 100%; display: flex; justify-content: end; align-items: center;">
                                    <div :class="'box-dados-usuario ' + (flDadosUsuario ?'active':'' )">
                                        <div class="box-dados-usuario-titulo" @click="flDadosUsuario = !flDadosUsuario">
                                            <template v-if="user.usuario">
                                                <!--<img :src="user.usuario.urlImgFoto" v-if="user.usuario.urlImgFoto != null" />
                                                    <ImgDefault :nome="user.usuario.nmUsuario" :size="1" :borda="true" v-else></ImgDefault>-->
                                                <ImgDefault :nome="user.usuario.nmUsuario" :size="1" :borda="true"></ImgDefault>
                                            </template>


                                            <span class="ms-3 txt-p txt-size-20" v-if="user.usuario != null">Olá, <b>{{primeiroNome}}</b></span>
                                            <span class="ms-3 txt-p c-pointer txt-size-20" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-caret-down"></i></span>
                                        </div>


                                        <div class="box-dados-usuario-conteudo">
                                            <p class="txt-p txt-blue-1 txt-w-5 c-pointer mb-1" @click="showModalDados = !showModalDados"><i class="fas fa-user me-2"></i> Meus dados</p>
                                            <p class="txt-p txt-blue-1 txt-w-5 c-pointer mb-1" @click="showModalSenha = !showModalSenha"><i class="fas fa-key me-2"></i> Mudar senha</p>
                                            <p class="txt-p txt-blue-1 txt-w-5 c-pointer mb-1" @click="showModalMetodo = !showModalMetodo"><i class="fas fa-book me-2"></i> Método de trabalho</p>
                                            <p class="txt-p txt-blue-1 txt-w-5 c-pointer mb-1" @click="showModalManual = !showModalManual"><i class="fas fa-book me-2"></i> Manual do Usuário</p>
                                            <p class="txt-p txt-blue-1 txt-w-5 c-pointer mb-1" @click="gerenciarUsuarios" v-if="isAdmin"><i class="fas fa-users me-2"></i> Gerenciar Usuários</p>
                                            <router-link class="txt-p txt-blue-1 txt-w-5 c-pointer mb-1" :to="{name:'CancelamentoHotmart'}" v-if="tpPessoa != 'J'">
                                                <i class="fa fa-exclamation-triangle me-2"></i>Cancelamento Hotmart
                                            </router-link>
                                            <!-- SUPORTE -->
                                            <template v-if="user.usuario.tpPessoa == 'J' && user.usuario.flCortesia != 'Sim'">
                                                <a class="txt-p txt-blue-1 txt-w-5 c-pointer mb-1" v-if="user.usuario.idContrato != null && user.usuario.idPessoa != null"
                                                   :href="urlSuporte+'p_idContrato='+user.usuario.idContrato+'&amp;p_idPessoa='+user.usuario.idPessoa" target="_blank">
                                                    <i class="fas fa-headset me-2"></i>
                                                    <span>Falar com o suporte</span>
                                                </a>
                                            </template>
                                            <p class="txt-p txt-blue-1 txt-w-5 c-pointer my-2" @click="sair"><i class="fas fa-sign-out-alt me-2"></i> Sair</p>
                                        </div>

                                    </div>
                                </div>
                            </template>                            
                        </div>
                    </div>
                </div>
            </div>

            <div class="navegacao">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-12 d-flex align-items-center justify-content-center">
                            <div class="d-flex flex-wrap">
                                <template v-for="(item) in navegacao">
                                    <router-link :key="item.id"
                                                 :to="{name:item.route}" :class="(subIsActive(item) ? 'txt-h-6 me-4 navegacao-active py-3 c-pointer':'txt-h-6 txt-white me-4 py-3 c-pointer')">
                                        {{item.nome}}
                                    </router-link>
                                </template>
                                <router-link v-if="flPesquisa" :to="{name:'Pesquisa'}" :class="(subIsActive({ id: 4, nome: 'SOLLICITA PESQUISA', route: 'Pesquisa' }) ? 'txt-h-6 me-4 navegacao-active py-3 c-pointer':'txt-h-6 txt-white me-4 py-3 c-pointer')">
                                    SOLLICITA PESQUISA
                                </router-link>
                                <div class="divisor-menu me-4"></div>
                                <p class="txt-h-6 txt-white me-4 py-3 c-pointer mb-0" @click="mural">MURAL<i class="fas fa-external-link-alt ms-1"></i></p>
                                <p class="txt-h-6 txt-white me-4 py-3 c-pointer mb-0" @click="portal">PORTAL SOLLICITA<i class="fas fa-external-link-alt ms-1"></i></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="aviso-manual-usuario" v-if="showAvisoManualUsuario">
                <div class="row">
                    <div class="col-11">
                        <div class="row">
                            <div class="col-8">
                                <p class="txt-h-6 txt-blue-1 txt-w-7 mb-1">
                                    Precisa de ajuda com o uso do sistema?
                                </p>
                                <p class="txt-h-6 txt-blue-1 txt-w-4 txt-size-12">
                                    Acesse o manual do usuário!
                                </p>
                            </div>
                            <div class="col-4 d-flex align-items-center">
                                <div class="icon-quest txt-white d-flex align-items-center justify-content-center">
                                    <i class="fas fa-question"></i>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button class="btn btn-ver-manual-usuario txt-white mb-3 w-100" @click="showModalManual = !showModalManual">
                                    <i class="fas fa-book"></i> Ver manual do usuário
                                </button>
                                <button class="btn btn-excluir-manual-usuario w-100 txt-size-12 mb-1" @click="hideAvisoManualUsuario">
                                    Não preciso de ajuda
                                </button>
                                <div class="mb-3 form-check">
                                    <input type="checkbox" class="form-check-input" id="exampleCheck1" @change="setAvisoManualUsuario" v-model="avisoModalUsuario">
                                    <label class="form-check-label txt-p txt-grey-2 txt-size-12" for="exampleCheck1"> Não pergunte novamente</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 px-0">
                        <span @click="hideAvisoManualUsuario"><i class="fas fa-times c-pointer txt-grey-2"></i></span>
                    </div>
                </div>
            </div>
        </header>

        <MudarSenha :showModal="showModalSenha"></MudarSenha>
        <MeusDados :showModal="showModalDados"></MeusDados>
        <ManualUsuario :showModal="showModalManual"></ManualUsuario>
        <MetodoTrabalho :showModal="showModalMetodo"></MetodoTrabalho>

    </div>
</template>

<script>
    import logo from '@/assets/img/logo_sollicita.png';
    import ImgDefault from '@/components/ImgDefault'
    import MudarSenha from '@/components/MudarSenha'
    import MeusDados from '@/components/MeusDados'
    import ManualUsuario from '@/components/ManualUsuario'
    import MetodoTrabalho from '@/components/MetodoTrabalho'
        
    import { urlApi, avisoErro, linkMural, linkPortal, avisoManualUsuarioKey, urlSuporte } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'Header',
        components: { ImgDefault, MudarSenha, MeusDados, ManualUsuario, MetodoTrabalho },
        computed: {
            user() {
                return this.$store.state.user
            },

            isAdmin() {
                return this.$store.state.isAdmContrato
            },

            tpPessoa() {
                return this.$store.state.tpPessoa
            },

            flPesquisa() {
                return this.$store.state.flPesquisa
            },

            primeiroNome() {
                if (this.user && this.user.usuario) {
                    if (this.user.usuario.nmUsuario) {
                        return this.user.usuario.nmUsuario.split(" ")[0]
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
                
            },
        },
        data: function () {
            return {
                logo,

                userLogin: {
                    login: "",
                    password: "",
                    Opcoes: {
                        dadosMural: false,
                    }
                },

                navegacao: [
                    { id: 1, nome: "MEU SOLLICITA", route: "HomeLogado"},
                    { id: 2, nome: "MEUS MÓDULOS", idCategoria: 87, route: "MeusModulos"},
                    { id: 3, nome: "MINHAS FERRAMENTAS", idCategoria: 80, route: "MinhasFerramentas"},                    
                ],

                flDadosUsuario: false,
                showModalDados: false,
                showModalSenha: false,
                showModalManual: false,
                showModalMetodo: false,

                showAvisoManualUsuario: true,
                avisoModalUsuario: false,

                urlSuporte: urlSuporte,
            }
        },
        methods: {
            mural() {
                var url = linkMural + 'AutenticacaoAutomatica?token=' + this.user.accessToken + '&refreshTokenWeb=' + this.user.refreshTokenWeb;
                window.location.href = encodeURI(url);
            },

            portal() {
                var url = linkPortal + 'AutenticacaoAutomatica?token=' + this.user.accessToken + '&refreshTokenWeb=' + this.user.refreshTokenWeb;
                window.location.href = encodeURI(url);
            },

            subIsActive(input) {
                let name = this.$route.name.split('-')[0];
                
                if (name == 'PesquisaNoticia') {
                    if (input.idCategoria == undefined) {
                        return false;
                    }
                    let idCategoria = parseInt(this.$route.params.id);
                    return idCategoria == input.idCategoria;
                } else {
                    return name.toLowerCase() == input.route.toLowerCase();
                }
                
            },

            checkForm() {
                var form = document.querySelector('form')
                if (!form.checkValidity()) {
                    var tmpSubmit = document.createElement('button')
                    form.appendChild(tmpSubmit)
                    tmpSubmit.click()
                    form.removeChild(tmpSubmit)

                    return false;
                }

                return true;
            },

            autoatendimento() {
                document.getElementById('blip-chat-icon').click();
            },

            sair() {
                let $this = this;
                axios.get(`${urlApi}/Auth/revoke`)
                    .then(function () {
                        $this.$store.commit('setUser', null)
                        location.reload();
                    })
                    .catch(res => {
                        if (res.response.data != undefined) {
                            avisoErro(res.response.data);
                        } else {
                            avisoErro(res.response.statusText);
                        }
                    });
            },

            hideAvisoManualUsuario() {
                this.showAvisoManualUsuario = false;
            },

            setAvisoManualUsuario() {
                localStorage.setItem(avisoManualUsuarioKey, JSON.stringify(this.avisoModalUsuario))
            },

            gerenciarUsuarios() {
                this.$router.push({ name: 'GerenciarUsuarios' });
            },
        },
        mounted: function () {
            this.avisoModalUsuario = JSON.parse(localStorage.getItem(avisoManualUsuarioKey));
            if (this.avisoModalUsuario) {
                this.showAvisoManualUsuario = false;
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    header {
    }

    .header {
        background-image: url('../assets/img/bg_sollicita.jpg');
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        padding: 20px 0;
    }

    .logo-sollicita{
        max-height: 91px;
        
    }

    .navegacao {
        background: #071B28;
    }

    .navegacao-active {
        color: #FBBC0D;
        border-bottom: 5px solid #FBBC0D;
    }

    .divisor-menu {
        border-left: 2px solid #173243;
        margin: 10px 0;
    }


    .box-dados-usuario {
        padding: 10px;
        color: #FFF;
        z-index: 999;

        .box-dados-usuario-conteudo {
            display: none;
        }

        .box-dados-usuario-titulo {
            cursor: pointer;
            display: flex;
            align-items: center;

            img {
                width: 45px;
                height: 45px;
                border-radius: 50%;
            }
        }
    }

    .box-dados-usuario.active {
        position: absolute;
        top: 15%;
        background-color: #FFF;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        color: #071B28;

        .box-dados-usuario-conteudo {
            display: block;
            margin-top: 15px;

            p:hover, a:hover {
                border-radius: 5px;
                background-color: #F1F1F1;
            }
        }
    }

    .aviso-manual-usuario {
        position: absolute;
        /*min-width: 50vw;*/
        background: #FFFFFF;
        box-shadow: 10px 10px 12px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 10px;
        top: 25%;
        z-index: 3;

        .icon-quest {
            background: #163142;
            border-radius: 50%;
            width: 33px;
            height: 33px;
        }

        .btn-ver-manual-usuario {
            background-color: #163142;
            background: #163142;
            padding: 10px;
            border: 2px solid;
            border-color: #FBBC0D;
            color: #FFF;
            font-size: 12px;
            font-weight: 400;
        }

        .btn-ver-manual-usuario:hover {
            border-color: #163142;
        }

        .btn-excluir-manual-usuario {
            background-color: transparent;
            background: transparent;
            padding: 10px;
            border: 2px solid;
            border-color: #D78E8E;
            color: #D78E8E;
            font-size: 12px;
            font-weight: 400;
            border-radius: 8px;
        }

        .btn-excluir-manual-usuario:hover {
            background-color: #D78E8E;
            color: #FFF;
        }
    }


</style>

