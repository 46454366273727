<template>
    <div class="tags w-100">
        <div class="titulo-tags">
            <span class="txt-h-4 txt-size-18 txt-w-7 txt-colors-1">
                {{titulo}}
            </span>
        </div>
        <div class="lst-tags">
            <a :href="linkPortal+''+link+'?termo='+item+'&flagTag=true'" target="_blank" v-for="(item,index) in lstTags" :key="index" class="tag txt-h-4 txt-blue-2 txt-size-18 txt-w-7 px-2">
                <span >{{item}}</span>
            </a>
            
        </div>
    </div>
</template>

<script>
    
    import { linkPortal } from '@/config/global'

    export default {
        name: 'Tags',
        props: {
            titulo: {
                type: String,
                required: true
            },
            lstTags: {
                type: Array,
                required: true
            },
            link: {
                type: String,
                default: 'PesquisaNoticia'
            },
        },
        data: function () {
            return {
                linkPortal,
            }
        },
        methods: {
            
        },
        created: function () {
           
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .tags {
        background: #F1F1F1;
        border: 4px solid #FBBC0D;
        padding: 10px;
        display: flex;
        align-items: center;

        .titulo-tags {
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 5px solid #FBBC0D;
            padding: 15px 15px;
        }

        .lst-tags {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 80%;
            max-width: 100%;
            padding: 0 15px;
            /*justify-content: space-between;*/

            .tag {
            }

            .tag:hover {
                color: #071B28 !important;
                font-size: 20px !important;
            }

            .tag::before {
                content: "#";
            }
        }
    }
</style>

