<template>
    <div class="viewAUDIO">
        <div class="modal-conteudo m-2">
            <div v-if="dados">
                <h5 class="bold" v-html="dados.dsDescricao"></h5>
                <div class="video-responsivo audio">
                   <audio ref="videoPlayer" class="video-js"></audio>
                </div>
            </div>
            
        </div>

        <div class="modal-footer pb-0 mt-4 d-flex justify-content-end">
            <AdicionarBiblioteca v-if="exibeBiblioteca" :id="dados.idAudio.toString()" :tipo="tpConteudo" :cdConteudo="cdConteudo"></AdicionarBiblioteca>

        </div>
    </div>
</template>

<script>
    import AdicionarBiblioteca from '../AdicionarBiblioteca'

    import { urlApi, linkSollicita, } from '@/config/global'
    import axios from 'axios'

    import videojs from 'video.js';
    import 'video.js/dist/video-js.css'

    export default {
        name: 'viewAUDIO',
        components: { AdicionarBiblioteca },
        data: function () {
            return {
                linkSollicita: linkSollicita,
                player: null,

                options: {
                    autoplay: false,
                    controls: true,
                    sources: [
                        {
                            src: "",
                            type: "video/mp4"
                        }
                    ]
                },
                

                exibeBiblioteca: true,
                tpConteudo: 'Notícia Áudio',
                cdConteudo: '',
            }
        },
        props: {
            dados: Object,
        },
        methods: {
            verificaExisteRepositorio(idConteudo, tpConteudo, cdConteudo) {
                let $this = this;
                axios.get(`${urlApi}/Repositorio`, { params: { idConteudo: idConteudo, tpConteudo: tpConteudo, cdConteudo: cdConteudo } })
                    .then(res => {
                        if (res.data.success) {
                            $this.exibeBiblioteca = res.data.data;
                        } else {
                            console.log('Não foi possível verificar o repositório')
                        }
                    })
                    .catch(function () {
                        console.log('Não foi possível verificar o repositório')
                    });
            },
        },
        mounted: function () {
            this.options.sources[0].src = this.dados.urlAudio;

            this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
                console.log('onPlayerReady', this);
            })

            this.verificaExisteRepositorio(this.dados.idAudio.toString(), this.tpConteudo, this.cdConteudo);
        },
        watch: {
            dados: {
                handler() {
                    this.verificaExisteRepositorio(this.dados.idAudio.toString(), this.tpConteudo, this.cdConteudo);
                },
                deep: true
            },
        },
        beforeDestroy() {
            if (this.player) {
                this.player.dispose()
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    
   
</style>

