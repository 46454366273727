<template>
    <div class="ModalImprimirAnalise">
        <b-modal ref="modal-imprimir-analise" id="ModalImprimirAnalise" size="lg" :hide-footer="true" :hide-header="true">
            <div class="modal-titulo txt-p txt-w-7 txt-size-20 txt-grey-2 m-2">
                <p class="txt-w-20 mb-0 txt-analisador_balancos">{{titulo}}</p>
                <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <div class="modal-conteudo m-2 px-2 printSection" id="imprimirRelatorio">
                <div class="row" v-for="(item,index) in lstFornecedores" :key="index">
                    <template v-if="idAnaliseFornecedor == 0 || idAnaliseFornecedor == item.analiseFornecedor.idAnaliseFornecedor">
                        <div class="col-12" style="" v-if="!visaoResumida">
                            <div class="panel panel-default" style="margin-bottom: 20px; background-color: rgb(255, 255, 255); border: 1px solid #dee2e6; border-radius: 4px; box-shadow: rgb(0 0 0 / 5%) 0px 1px 1px;">
                                <div class="panel-heading" style="color: rgb(51, 51, 51); background-color: #f5f5f5; border-color: rgb(221, 221, 221); padding: 10px 15px; border-radius: 3px;">
                                    <h3 class="regular">{{item.analiseFornecedor.nmRazaoSocial}}</h3>
                                </div>
                                <div class="panel-body" style="padding: 10px">
                                    <p class="regular text-center">CNPJ: {{item.analiseFornecedor.nrCnpj}}</p>

                                    <div class="row">
                                        <div class="col-12">
                                            <label class="regular mr-2">Data Início:</label>
                                            <label class="text-center">{{item.analiseFornecedor.dtInicio}}</label>
                                        </div>
                                        <div class="col-12">
                                            <label class="regular mr-2">Data Fim:</label>
                                            <label class="text-center">{{item.analiseFornecedor.dtFim}}</label>
                                        </div>
                                    </div>

                                    <fieldset>
                                        <legend>Ativos</legend>
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="regular">Circulante:</label>
                                                <p class="text-center">{{item.analiseFornecedor.vlAtivoCirculante}}</p>
                                            </div>
                                            <div class="col-4">
                                                <label class="regular">Realizável Longo Prazo:</label>
                                                <p class="text-center">{{item.analiseFornecedor.vlAtivoRealizavelLongoPrazo}}</p>
                                            </div>
                                            <div class="col-4">
                                                <label class="regular">Total:</label>
                                                <p class="text-center">{{item.analiseFornecedor.vlAtivoTotal}}</p>
                                            </div>
                                        </div>

                                        <legend>Passivos</legend>
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="regular">Circulante:</label>
                                                <p class="text-center">{{item.analiseFornecedor.vlPassivoCirculante}}</p>
                                            </div>
                                            <div class="col-4">
                                                <label class="regular">Não Circulante:</label>
                                                <p class="text-center">{{item.analiseFornecedor.vlPassivoNaoCirculante}}</p>
                                            </div>
                                            <div class="col-4">
                                                <label class="regular">Exigível Longo Prazo:</label>
                                                <p class="text-center">{{item.analiseFornecedor.vlPassivoExigivelLongoPrazo}}</p>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" style="">
                            <div class="panel panel-default" style="margin-bottom: 20px; background-color: rgb(255, 255, 255); border: 1px solid #dee2e6; border-radius: 4px; box-shadow: rgb(0 0 0 / 5%) 0px 1px 1px;">
                                <div class="panel-heading" style="color: rgb(51, 51, 51); background-color: #f5f5f5; border-color: rgb(221, 221, 221); padding: 10px 15px; border-radius: 3px;">
                                    <h3 class="regular">Resultado</h3>
                                </div>
                                <div class="panel-body panel-body-height text-center" style="padding: 10px">
                                    <h4 class="regular text-panel-resultado">{{item.analiseFornecedor.nmRazaoSocial}}</h4>
                                    <p class="regular"><span class="bold">CNPJ:</span> {{item.analiseFornecedor.nrCnpj}}</p>
                                    <p class="regular"><span class="bold">Período:</span> {{item.analiseFornecedor.dtInicio}} a {{item.analiseFornecedor.dtFim}}</p>
                                </div>

                                <table class="table">
                                    <tr v-for="(item2,index2) in item.retornoIndices" :key="index2">
                                        <td>
                                            <span class="bold" style="margin-right: 5px;">{{formatIndice(item2.vlIndice)}}</span>
                                            <span class="bold">{{item2.nmIndice}} - {{item2.resIndice}}</span>
                                        </td>
                                    </tr>

                                </table>
                                <div class="panel-footer text-center bold mb-2">
                                    {{item.txtIndice}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12"><hr /></div>
                    </template>
                    
                </div>
            </div>
            <div class="modal-footer mt-3">
                <div class="row ">
                    <div class="col-12 px-0 d-flex justify-content-end">
                        <button class="btn btn-cancelar mr-2" type="button" @click="hideModalFn">
                            Cancelar
                        </button>
                        <button class="btn btn-analisador_balancos" type="button" @click="imprimir">
                            Imprimir
                        </button>                        
                    </div>
                    
                </div>
                
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'

    export default {
        name: 'ModalImprimirAnalise',
        props: {
            idAnalise: Number,
            idAnaliseFornecedor: Number,
            showModal: Boolean,
            visaoResumida: Boolean,
            apenasIndice: Boolean,
        },
        components: {
            
        },
        data: function () {
            return {
                titulo: 'Imprimir Análise',
                analise: null,
                lstFornecedores: null,
            }
        },
        methods: {
            load() {
                this.carregaDetalhes();
                this.$refs['modal-imprimir-analise'].show();
            },

            hideModalFn() {
                this.$refs['modal-imprimir-analise'].hide();
            },

            carregaDetalhes() {
                let $this = this;
                axios.get(`${urlApi}/AnalisadorBalancos/GetDetalhes/${$this.idAnalise}`)
                    .then(res => {
                        if (res.data.success) {
                            var result = res.data.data;
                            $this.analise = result.analise;

                            $this.lstFornecedores = [];
                            for (let i = 0; i < result.lstFornecedores.length; i++) {
                                var item = result.lstFornecedores[i];

                                item.analiseFornecedor.dtInicio = item.analiseFornecedor.dtInicio != null ? moment(item.analiseFornecedor.dtInicio).format('DD/MM/YYYY') : null;
                                item.analiseFornecedor.dtFim = item.analiseFornecedor.dtFim != null ? moment(item.analiseFornecedor.dtFim).format('DD/MM/YYYY') : null;

                                $this.lstFornecedores.push(item);
                            }

                        } else {
                            avisoErro("Não foi possível carrega as anáises");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carrega as análises");
                    });
            },

            async imprimir() {
                var options = {
                    name: '_blank',
                    specs: [
                        'fullscreen=yes',
                        'titlebar=yes',
                        'scrollbars=yes'
                    ],
                    styles: [
                        'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css',
                    ],
                    timeout: 1000, // default timeout before the print window appears
                    autoClose: true, // if false, the window will not close after printing
                    windowTitle: window.document.title, // override the window title
                };
                await this.$htmlToPaper('imprimirRelatorio',options);
            },

            formatIndice(nrIndice) {
                if (nrIndice != null) {
                    var novoInice = nrIndice.toFixed(2)
                    return novoInice.replace('.', ',');
                } else {
                    return null;
                }

            },

        },
        mounted: function () {
        },
        watch: {
            showModal: function () {
                this.load();
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">

    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #DADADA;
        display: flex;
        justify-content: space-between;
    }

    .modal-conteudo {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 70vh;
    }

    .panel {
        margin-bottom: 20px;
        background-color: rgb(255, 255, 255);
        border: 1px solid #dee2e6;
        border-radius: 4px;
        box-shadow: rgb(0 0 0 / 5%) 0px 1px 1px;
    }
    .panel-heading {
        color: rgb(51, 51, 51);
        background-color: rgb(245, 245, 245);
        border-color: rgb(221, 221, 221);
        padding: 10px 15px;
        border-radius: 3px;
    }

    .panel-body {
        padding: 10px
    }
</style>

