<template>
    <div class="ModalDetalhes">
        <b-modal ref="modal-detalhes" id="modalDetalhes" size="xl" :hide-footer="true" :hide-header="true">
            <div class="modal-titulo txt-h-2 txt-blue-2 m-2">
                <span class=" txt-w-20">{{titulo}}</span>
                <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>

            </div>
            <template v-if="dados.cdConteudo == 'Arquivo'">
                <div class="modal-conteudo m-2">
                    <iframe class="" :src="dsReferencia"></iframe>
                </div>
            </template>
            <template v-else>
                <template v-if="componente">
                    <component v-if="componente" :is="componente" :dados="dataResult" :busca="dados" ></component>
                </template>
            </template>
        </b-modal>
    </div>
</template> 

<script>
    import viewACORD from '@/components/Pesquisa/viewACORD'
    import viewAUDIO from '@/components/Pesquisa/viewAUDIO'
    import viewBOLET from '@/components/Pesquisa/viewBOLET'
    import viewJURIS from '@/components/Pesquisa/viewJURIS'
    import viewLFSIS from '@/components/Pesquisa/viewLFSIS'
    import viewLGEST from '@/components/Pesquisa/viewLGEST'
    import viewLGFED from '@/components/Pesquisa/viewLGFED'
    import viewLGMUN from '@/components/Pesquisa/viewLGMUN'
    //import viewLICCO from '@/components/pesquisa/viewLICCO'
    import viewLICIT from '@/components/Pesquisa/viewLICIT'
    import viewMODEL from '@/components/Pesquisa/viewMODEL'
    import viewNOTIC from '@/components/Pesquisa/viewNOTIC'
    import viewORIJU from '@/components/Pesquisa/viewORIJU'
    import viewPERIO from '@/components/Pesquisa/viewPERIO'
    //import viewPESJU from '@/components/pesquisa/viewPESJU'
    import viewPUBLI from '@/components/Pesquisa/viewPUBLI'
    import viewSUMUL from '@/components/Pesquisa/viewSUMUL'
    import viewVIDEO from '@/components/Pesquisa/viewVIDEO'
    import viewLEICO from '@/components/Pesquisa/viewLEICO'

    import viewACSTF from '@/components/Pesquisa/viewACSTF'
    import viewJUSTF from '@/components/Pesquisa/viewJUSTF'
    import viewBOSTF from '@/components/Pesquisa/viewBOSTF'
    import viewSUSTF from '@/components/Pesquisa/viewSUSTF'

    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'

    export default {
        name: 'ModalDetalhes',
        components: {
            viewACORD,
            viewAUDIO,
            viewBOLET,
            viewJURIS,
            viewLFSIS,
            viewLGEST,
            viewLGFED,
            viewLGMUN,
            //viewLICCO,
            viewLICIT,
            viewMODEL,
            viewNOTIC,
            viewORIJU,
            viewPERIO,
            //viewPESJU,
            viewPUBLI,
            viewSUMUL,
            viewVIDEO,
            viewLEICO,
            viewACSTF,
            viewJUSTF,
            viewBOSTF,
            viewSUSTF
        },
        props: {
            dados: Object,
            showModal: Boolean,
            titulo: {
                type: String,
                default: 'Sollicita Pesquisa'
            },
        },
        data: function () {
            return {
                componente: null,
                dataResult: null,
            }
        },
        methods: {
            load() {
                let $this = this;
                axios.get(`${urlApi}/Pesquisa/view${$this.dados.cdConteudo}`, {
                    params: {
                        idItemPesquisa: $this.dados.idItemPesquisa,
                        dsReferencia: $this.dados.dsReferencia,
                        idLogPesquisa: $this.dados.idLogPesquisa,
                    }
                })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data) {
                                $this.dataResult = res.data.data;
                                $this.componente = 'view' + $this.dados.cdConteudo;

                                $this.$refs['modal-detalhes'].show();
                            } else {
                                $this.dataResult = 'NotFound';
                            }
                        } else {
                            avisoErro("Não foi possível carregar view");
                            //console.log("Não foi possível carregar view");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar view");
                        //console.log("Não foi possível carregar view");
                    });
            },

            loadArquivo() {
                this.$refs['modal-detalhes'].show();
            },

            hideModalFn() {
                this.$refs['modal-detalhes'].hide();
            }

        },
        mounted: function () {
        },
        watch: {
            showModal: function () {
                if (this.dados.cdConteudo != 'Arquivo') {
                    this.load();
                } else {
                    this.loadArquivo();
                }
                
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
    
    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #034471;
        display: flex;
        justify-content: space-between;
    }

    .modal-conteudo {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 70vh;
    }

    .btn-default {
        background: #767676;
        padding: 10px;
        border: 3px solid;
        border-color: #767676;
        color: #FFF;
        border-radius: 5px;
    }

    .btn-default:hover {
        background: #777777;
        border-color: #777777;
        color: #FFF;
    }

</style>

