<template>
    <div class="comentarios">
        <div class="btn-comentarios w-100 txt-h-4 txt-size-18 txt-blue-2" @click="showComentarios = !showComentarios">
            VER COMENTÁRIOS <span v-if="qtdComentarios > 0">({{qtdComentarios}})</span>
        </div>
        <div class="conteudo-comentarios" v-show="showComentarios">
            <template v-if="user">
                <div v-if="!showComentar">
                    <span class="txt-h-6 txt-size-12 txt-blue-2">
                        Entre na discução!
                    </span>
                    <button class="btn btn-blue txt-size-12 w-100 py-1" @click="showComentar = !showComentar">
                        Adicionar um comentário
                    </button>
                </div>
                <div v-else>
                    <form id="novoComentario">
                        <div class="form-group">
                            <label class="txt-h-6 txt-size-12 txt-blue-2">
                                Digite seu comentário abaixo:
                            </label>
                            <textarea for="dsComentario" class="form-label w-100" v-model="novoComentario.dsComentario" rows="5" maxlength="500" required></textarea>
                        </div>
                        <div class="text-end">
                            <button class="btn btn-blue txt-size-12 px-3 py-1" type="button" @click="enviar">Enviar comentário</button>
                        </div>
                    </form>
                </div>
            </template>

            <div class="lst-comentarios mt-5" id="lst-comentarios" >
                <!--<div class="order-comentarios">
                    <span>Classificar comentários por:</span> 
                    <select class="ms-2">
                        <option selected>Mais recentes</option>
                    </select>
                </div>-->

                <template v-if="lstComentarios != null && lstComentarios.length > 0">
                    <div class="item-comentario mt-4" v-for="(item,index) in lstComentarios" :key="item.idComentario">
                        <div class="d-flex justify-content-between">
                            <span class="txt-p txt-w-5 txt-size-18 txt-blue-2">
                                {{item.usuario.nmUsuario}}
                            </span>
                            <span class="txt-p txt-size-12 txt-grey-5">
                                {{getTempoComentario(item.dtComentario)}}
                            </span>
                        </div>

                        <p class="txt-p txt-size-18 txt-grey-2 mt-2 mb-4">
                            {{item.dsComentario}}
                        </p>

                        <div class="mb-4 divisor-comentario" v-if="index < lstComentarios.length-1"></div>
                    </div>
                </template>
                <template v-else>
                    <div class="d-flex justify-content-center">
                        <span class="txt-h-5">Nenhum comentário até o momento</span>
                    </div>
                </template>
                
            </div>
           
        </div>
    </div>
</template>

<script>
    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import axios from 'axios'
    import * as moment from 'moment';

    export default {
        name: 'Comentarios',
        props: {
            id: {
                type: String,
                required: true
            },
            tipo: {
                type: String,
                required: true
            },
        },
        computed: {
            user() {
                return this.$store.state.user
            },
        },
        data: function () {
            return {
                totalRecords: false,
                qtdComentarios: 0,
                take: 5,
                skip: 0,

                showComentarios: false,
                showComentar: false,

                lstComentarios: null,

                novoComentario: {
                    dsComentario: null,
                }
            }
        },
        methods: {
            load() {
                this.novoComentario.idNoticia = this.id;
                this.lstComentarios = [];
                this.carregaComentarios();
                
            },

            recarregaComentarios() {
                this.totalRecords = false;
                this.skip = 0;
                this.lstComentarios = [];
                this.carregaComentarios();
            },

            carregaComentarios() {
                let $this = this;
                axios.get(`${urlApi}/NoticiaComentario/Get`, { params: { idNoticia: $this.id, take: $this.take, index: $this.skip } })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data.rows != null) {
                                $this.qtdComentarios = res.data.data.qtdRecords;
                                if (res.data.data.qtdRecords <= ($this.take * ($this.skip == 0 ? 1 : $this.skip))) {
                                    $this.totalRecords = true;
                                }

                                $this.skip = $this.skip++;
                                for (let i = 0; i < res.data.data.rows.length; i++) {
                                    $this.lstComentarios.push(res.data.data.rows[i]);
                                }

                            }
                        } else {
                            avisoErro("Não foi possível carregar os comentários");
                            $this.lstComentarios = null;
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os comentários");
                        $this.lstComentarios = null;
                    });
            },

            checkForm() {
                var form = document.getElementById('novoComentario')
                if (!form.checkValidity()) {
                    var tmpSubmit = document.createElement('button')
                    form.appendChild(tmpSubmit)
                    tmpSubmit.click()
                    form.removeChild(tmpSubmit)

                    return false;
                }

                if (this.novoComentario.dsComentario == "") {
                    avisoErro("O comentário não pode ser vazio")
                    return false;
                }

                if (this.novoComentario.dsComentario.length > 500) {
                    avisoErro("O comentário deve conter até 500 caracteres")
                    return false;
                }


                return true;
            },

            enviar() {
                if (this.checkForm()) {
                    let $this = this;

                    $this.novoComentario.idNoticia = $this.id;

                    axios.post(`${urlApi}/NoticiaComentario`, $this.novoComentario)
                        .then(res => {
                            if (res.data.success) {
                                // aviso ok
                                avisoSucesso("Seu comentário foi registrado com sucesso!");

                                $this.recarregaComentarios();
                            } else {
                                avisoErro("Não foi possível registrar seu comentário.");
                            }
                        })
                        .catch(res => {
                            if (res.response.data != undefined) {
                                avisoErro(res.response.data);
                            } else {
                                avisoErro(res.response.statusText);
                            }
                        });
                }
                return false;
            },

            getTempoComentario(dt) {
                var dif = moment().diff(dt, 'seconds');
                if (dif > 60) {
                    if (dif > (60 * 60)) {
                        if (dif > (60 * (60 * 24))) {
                            if (dif > (60 * (60 * (24 * 30)))) {
                                if (dif > (60 * (60 * (24 * 360)))) {
                                    return parseInt(dif / (60 * (60 * (24 * 360)))) + ' ano' + (dif == 1 ? '' : 's') + ' atrás';
                                } else {
                                    return parseInt(dif / (60 * (60 * (24 * 30)))) + ' ' + (dif == 1 ? 'mês' : 'meses') + ' atrás';
                                }
                            } else {
                                return parseInt(dif / (60 * (60 * 24))) + ' dia' + (dif == 1 ? '' : 's') + ' atrás';
                            }
                        } else {
                            return parseInt(dif / (60 * 60)) + ' hora' + (dif == 1 ? '' : 's') + ' atrás';
                        }
                    } else {
                        return parseInt(dif/60) + ' minuto' + (dif == 1?'':'s')+' atrás';
                    }
                } else {
                    return dif + ' segundo' + (dif == 1 ? '' : 's') +' atrás';
                }
            },

            //formatDate(date) {
            //    return moment(date).format('DD/MM/YYYY [às] HH:mm');
            //}
        },
        created: function () {
            if (this.id != undefined) {
                this.load();
            }
            
            
            
        },

        mounted: function () {
            let $this = this;            
            document.getElementById('lst-comentarios').onscroll = () => {
                var comentarios = document.getElementById('lst-comentarios');
                let bottomOfWindow = comentarios.scrollTop + comentarios.offsetHeight >= comentarios.scrollHeight

                if (bottomOfWindow && !$this.totalRecords) {
                    $this.carregaComentarios();
                }
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .comentarios {
        border: 2px solid #163142;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 16px 40px;

        .divisor-comentario {
            border-top: 2px solid #C3C3C3;
        }

        .btn-comentarios {
            text-align: center;
            cursor: pointer;
        }

        .lst-comentarios {
            max-height: 300px;
            overflow: auto;
        }
    }

</style>

