<template>
    <div class="row justify-content-center" v-if="ferramentas != []">
        <div class="col-12 d-flex justify-content-between">
            <span class="prev d-flex align-items-center c-pointer txt-size-70 txt-blue-2 me-3" @click="prev"><i class="fas fa-chevron-left"></i></span>

            <div class="item-ferramentas d-flex flex-column txt-blue-2 w-80" v-if="ferramentaAtiva">
                <div class="row">
                    <div class="col-4 col-lg-6 text-center d-flex align-items-center">
                        <img class="img-fluid" style="width: 450px; height: 450;" :src="ferramentaAtiva.urlImagem" />
                    </div>
                    <div class="col-8 col-lg-6">
                        <span :class="'txt-h-4 txt-size-25 txt-letter-n-2 txt-w-4 '+( 'txt-'+ferramentaAtiva.classe )">
                            Sollicita <span class="txt-w-8">{{ferramentaAtiva.nmFerramenta}}</span>
                        </span>

                        <div class="box-branco txt-h-5 txt-size-16 txt-blue-2 txt-w-6 mt-3" >
                            <div class="" v-html="ferramentaAtiva.dsFerramenta">

                            </div>
                            <div class="bg-grey-4 rounded px-3 py-2 mt-3" v-if="ferramentaAtiva.descricao || ferramentaAtiva.quemUsa">
                                <div v-if="ferramentaAtiva.descricao">
                                    <span class="txt-h-6 txt-blue-2 txt-w-7">
                                        Descrição:
                                    </span>
                                    <p class="txt-h-6 txt-blue-2 txt-size-12 txt-w-7" v-html="ferramentaAtiva.descricao">
                                    </p>
                                </div>
                                <div v-if="ferramentaAtiva.quemUsa">
                                    <span class="txt-h-6 txt-blue-2 txt-w-7">
                                        Quem usa:
                                    </span>
                                    <p class="txt-h-6 txt-blue-2 txt-size-12 txt-w-7">
                                        {{ferramentaAtiva.quemUsa}}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="w-100 text-center">
                            <button :class="'btn w-80 mt-5 btn-' +ferramentaAtiva.classe" @click="showModalExperimenteGratis = !showModalExperimenteGratis">
                                Experimente grátis!
                            </button>
                        </div>

                    </div>

                </div>
            </div>

            <span class="next d-flex align-items-center c-pointer txt-size-70 txt-blue-2 ms-3" @click="next"><i class="fas fa-chevron-right"></i></span>
        </div>
        <div class="col-12 mt-5 d-none justify-content-center">
            <span :class="'c-pointer ' + (item.idFerramenta == ferramentaAtiva.idFerramenta ? 'txt-'+ferramentaAtiva.classe:'txt-grey-5')" v-for="item in ferramentas" :key="item.idFerramenta" @click="ferramentaAtiva = item">
                <i class="fas fa-circle txt-size-20 mx-2"></i>
            </span>
        </div>

        <div class="col-12 mt-5 d-flex justify-content-between flex-wrap">
            <div :class="'icone-ferramentas '+(item.idFerramenta == ferramentaAtiva.idFerramenta ? 'active':'')" v-for="item in ferramentas" :key="item.idFerramenta">
                <img class="noselect c-pointer icone-ferramentas-img" :src="item.urlIconeActive" v-if="item.idFerramenta == ferramentaAtiva.idFerramenta" @click="ferramentaAtiva = item" />
                <img class="noselect c-pointer icone-ferramentas-img" :src="item.urlIcone" v-else @click="ferramentaAtiva = item" />
            </div>
        </div>

        <ExperimenteGratis :showModal="showModalExperimenteGratis"></ExperimenteGratis>
    </div>
</template>

<script>
    import ExperimenteGratis from '@/components/ExperimenteGratis'

    import contador_gg from '@/assets/img/ferramentas/contador_gg.png';
    import agenda_gg from '@/assets/img/ferramentas/agenda_gg.png';
    import biblioteca_gg from '@/assets/img/ferramentas/biblioteca_gg.png';
    import fornecedores_gg from '@/assets/img/ferramentas/fornecedores_gg.png';
    import banco_gg from '@/assets/img/ferramentas/banco_gg.png';
    import balanco_gg from '@/assets/img/ferramentas/balanco_gg.png';
    import editais_gg from '@/assets/img/ferramentas/editais_gg.png';
    import modelos_gg from '@/assets/img/ferramentas/modelos_gg.png';
    import lei_gg from '@/assets/img/ferramentas/lei_comentada_gg.png';
    import ebook_gg from '@/assets/img/ferramentas/ebook_gg.png';
    import pesquisa_gg from '@/assets/img/ferramentas/pesquisa_gg.png';

    import contador from '@/assets/img/ferramentas/contador.png';
    import agenda from '@/assets/img/ferramentas/agenda.png';
    import biblioteca from '@/assets/img/ferramentas/biblioteca.png';
    import fornecedores from '@/assets/img/ferramentas/fornecedores.png';
    import banco from '@/assets/img/ferramentas/banco.png';
    import balanco from '@/assets/img/ferramentas/balanco.png';
    import editais from '@/assets/img/ferramentas/editais.png';
    import modelos from '@/assets/img/ferramentas/modelos.png';
    import lei from '@/assets/img/ferramentas/lei_comentada.png';
    import ebook from '@/assets/img/ferramentas/ebook.png';
    import pesquisa from '@/assets/img/ferramentas/pesquisa.png';

    import contador_active from '@/assets/img/ferramentas/contador_active.png';
    import agenda_active from '@/assets/img/ferramentas/agenda_active.png';
    import biblioteca_active from '@/assets/img/ferramentas/biblioteca_active.png';
    import fornecedores_active from '@/assets/img/ferramentas/fornecedores_active.png';
    import banco_active from '@/assets/img/ferramentas/banco_active.png';
    import balanco_active from '@/assets/img/ferramentas/balanco_active.png';
    import editais_active from '@/assets/img/ferramentas/editais_active.png';
    import modelos_active from '@/assets/img/ferramentas/modelos_active.png';
    import lei_active from '@/assets/img/ferramentas/lei_comentada_active.png';
    import ebook_active from '@/assets/img/ferramentas/ebook_active.png';
    import pesquisa_active from '@/assets/img/ferramentas/pesquisa_active.png';

    export default {
        name: 'CarouselFerramentas',
        components: { ExperimenteGratis },
        data: function () {
            return {
                
                index: 0,
                
                ferramentas: [],

                ferramentaAtiva: null,

                showModalExperimenteGratis: false,

            }
        },
        methods: {
            prev() {
                if (this.index == 0) {
                    this.index = this.ferramentas.length -1 ;
                } else {
                    this.index = this.index - 1;
                }

                this.ferramentaAtiva = this.ferramentas[this.index];
            },

            next() {
                if (this.index == this.ferramentas.length - 1) {
                    this.index = 0;
                } else {
                    this.index = this.index + 1;
                }

                this.ferramentaAtiva = this.ferramentas[this.index];
            },

            load() {
                this.ferramentas = [
                    {
                        idFerramenta: 1, nmFerramenta: 'Contador de Prazos', urlImagem: contador_gg, urlIcone: contador, urlIconeActive: contador_active, classe: 'contador_prazos',
                        dsFerramenta: `<p class="recuo">A forma de contagem dos prazos previstos na legislação licitatória e o seu respectivo
                                        vencimento é, muitas vezes, objeto de dúvidas. Qualquer equívoco pode repercutir na
                                        esfera de direitos do licitante, que não deve ser prejudicado por atos da Administração.</p>

                                        <p class="recuo">O <b class="txt-contador_prazos">Contador de Prazos</b> é um recurso que possibilita o <b>controle seguro e preciso</b> dos prazos
                                        para impugnações, decisões administrativas, recursos, contrarrazões, entre outros.</p>
                                        `,
                        descricao: 'Registro das datas relevantes, com a geração automática do fluxo de vencimentos dos prazos, visão geral do processo e manutenção do histórico.',
                        quemUsa: 'Pregoeiros e comissões de licitação ',
                    },
                    {
                        idFerramenta: 2, nmFerramenta: 'Agenda', urlImagem: agenda_gg, urlIcone: agenda, urlIconeActive: agenda_active, classe: 'agenda',
                        dsFerramenta: `<p class="recuo">A rotina dos agentes pode ser planejada e facilitada com a utilização do <b class="txt-agenda">Sollicita Agenda</b>, que auxilia a lembrar dos compromissos diários, interligada com os prazos relevantes dos processos licitatórios que estiverem sob sua responsabilidade.</p>`,
                        descricao: 'Registro de atividades e compromissos e visualização de programações recentes.',
                        quemUsa: 'Qualquer interessado.',
                    },
                    {
                        idFerramenta: 3, nmFerramenta: 'Biblioteca', urlImagem: biblioteca_gg, urlIcone: biblioteca, urlIconeActive: biblioteca_active, classe: 'biblioteca',
                        dsFerramenta: `<p class="recuo">Monte seu próprio banco de informações a partir de qualquer conteúdo encontrado no portal Sollicita. Salve suas consultas a qualquer hora e lugar, e mantenha sua própria biblioteca digital na área de Licitações e Contratos Administrativos.</p>`,
                        descricao: 'Ambiente exclusivo e individual de acesso rápido a conteúdos salvos.',
                        quemUsa: 'Qualquer interessado.',
                    },
                    {
                        idFerramenta: 4, nmFerramenta: 'Fornecedores', urlImagem: fornecedores_gg, urlIcone: fornecedores, urlIconeActive: fornecedores_active, classe: 'fornecedores',
                        dsFerramenta: `<p class="recuo">Saber quais são os potenciais fornecedores para objetos específicos em uma determinada região é deter informação estratégica para o planejamento e condução das compras públicas.</p> <p class="recuo">O <b class="txt-fornecedores">Sollicita Fornecedores</b> é um recurso que possibilita a busca de <b>fornecedores no mercado</b>, para as mais diversas finalidades, entre eles a realização de <b>pesquisa periódica</b> para a verificação dos preços praticados e a regularidade de preços constantes dos <b>sistemas de registro de preços.</b></p>`,
                        descricao: 'Banco de dados com fornecedores que participaram de licitações registradas nos principais portais de compras públicas; buscas por produto, serviço e fornecedor.',
                        quemUsa: 'Pregoeiros, equipes de apoio, comissões de licitação, gestores de contrato e fiscais de contrato.',
                    },
                    {
                        idFerramenta: 5, nmFerramenta: 'Banco de Penalidades', urlImagem: banco_gg, urlIcone: banco, urlIconeActive: banco_active, classe: 'banco_penalidades',
                        dsFerramenta: `<p class="recuo">Para a aceitação de licitantes e para a renovação de contratos, é necessário realizar <b>pesquisa prévia</b> com o fim de verificar a ausência de sanções aplicadas, que tenham efeitos impeditivos à participação em licitações ou contratação com a Administração contratante.</p> <p class="recuo">O <b class="txt-banco_penalidades">Sollicita Penalidades</b> possibilita a busca nas diversas fontes de pesquisa a um só tempo, <b>conferindo agilidade e garantindo a amplitude da verificação</b>, com efeitos diretos no resultado da ação administrativa. Ainda, amplia a pesquisa para o âmbito da pessoa jurídica, abrangendo a matriz e todas as suas filiais.`,
                        descricao: 'Pesquisa nas informações do Portal Transparência, do Cadastro de Empresas Inidôneas e Suspensas e em publicações em Diários Oficiais.',
                        quemUsa: 'Pregoeiros, equipes de apoio, comissões de licitação, gestores de contrato, fiscais de contrato, membros do controle interno.',

                    },
                    {
                        idFerramenta: 6, nmFerramenta: 'Analisador de Balanços', urlImagem: balanco_gg, urlIcone: balanco, urlIconeActive: balanco_active, classe: 'analisador_balancos',
                        dsFerramenta: `<p class="recuo">A <b>análise dos balanços contábeis</b> para o fim de decidir sobre a habilitação do licitante demanda tempo significativo, o qual poderá ser reduzido com a utilização do <b class="txt-analisador_balancos">Analisador de Balanços</b>, um recurso que calcula os índices financeiros de forma rápida e segura, de acordo com as informações fornecidas pelo usuário.</p>`,
                        descricao: 'Cálculo automático de índices, mediante a utilização das fórmulas indicadas no edital.',
                        quemUsa: 'Pregoeiros, equipes de apoio, comissões de licitação, financeiro.',
                    },
                    {
                        idFerramenta: 7, nmFerramenta: 'Editais', urlImagem: editais_gg, urlIcone: editais, urlIconeActive: editais_active, classe: 'editais',
                        dsFerramenta: `<p class="recuo">A elaboração do principal documento da licitação nem sempre é tarefa singela. Embora a Administração possa servir-se de modelos pré-aprovados pela assessoria jurídica ou órgãos de controle interno, cada licitação contém peculiaridades a serem consideradas.</p> <p class="recuo">O <b class="txt-editais">Sollicita Editais</b> disponibiliza ampla <b>base de editais</b>, incluindo sistema de <b>registro de preços</b>, que possibilitarão à Administração tomar como parâmetro documentos já elaborados por outros órgãos e entidades, de todas as esferas, da Administração Pública direta e indireta, <b>facilitando a análise do conteúdo</b> dos seus próprios editais conforme cada situação concreta pesquisada. Vários editais em uma única ferramenta, acessíveis com uma pesquisa rápida e simples.</p> `,
                        descricao: 'Acesso a editais, inclusive Sistema de Registro de Preços, de diversos órgãos e entidades, das três esferas de governo, divulgados nos principais portais de compras públicas.',
                        quemUsa: 'Setor de compras, setores requisitantes, pregoeiros, equipes de apoio, comissões de licitação, pareceristas e assessoria jurídica.',
                    },
                    {
                        idFerramenta: 8, nmFerramenta: 'Modelos', urlImagem: modelos_gg, urlIcone: modelos, urlIconeActive: modelos_active, classe: 'modelos',
                        dsFerramenta: `
                            <p class="recuo"><b class="txt-modelos">Modelos</b> é uma ferramenta de pesquisa de documentos inerentes a contratação, atualizada constantemente pelos especialistas do <b>SOLLICITA PRO</b>.</p>
                            <p class="recuo">Nesta ferramenta você encontra modelos e manuais segundo a <b>Lei 8.666/93, a Lei 10.520/02, a Lei 13.303/06</b> e a nova <b>Lei 14.133/21.</b></p>
                            <ul>
                              <li>Estudos Técnicos Preliminares;</li>
                              <li>Termos de Referência;</li>
                              <li>Registro de Preços;</li>
                              <li>Tomada de Preços;</li>
                              <li>Projetos Básicos;</li>
                              <li>E milhares de outros modelos, também para contratos e assessoria jurídica.</li>
                            </ul>
                        `,
                        descricao: `<p>Acesse a Modelos e Manuais da AGU e PGFN – Procuradoria Geral da Fazenda Nacional, manuais oficiais, checklists, planilhas, e modelos exclusivos NP elaborados especialmente para você.</p> <p>Somente no Sollicita PRO você poderá realizar em qualquer lugar e momento download de documentos seguros e verificados!</p>`,
                        quemUsa: 'Setor de compras, setores requisitantes, pregoeiros, equipes de apoio, comissões de licitação, pareceristas e assessoria jurídica.',
                    },
                    {
                        idFerramenta: 9, nmFerramenta: 'Lei Comentada', urlImagem: lei_gg, urlIcone: lei, urlIconeActive: lei_active, classe: 'lei_comentada',
                        dsFerramenta: `
                            <p class="recuo">As <b>Leis 14.133/21, 13.303/06 e 8.666/93</b> estão comentadas no <b>Sollicita PRO</b>, com doutrinas, acórdãos, links de conteúdo, artigos, vídeos e comentários exclusivos.</p>
                            <p class="recuo">Mais tempo, mais facilidade de busca, informações mais completas, em cada dispositivo legal. Você poderá <b>localizar</b>, sobre o mesmo assunto, vários posicionamentos de uma só vez, <b>comparar e tomar sua decisão</b> com mais <b>segurança e assertividade.</b></p>

                            <p>Com as <b class="txt-lei_comentada">Leis comentadas</b> você conta com:</p>

                            <ul>
                              <li>Agilidade na pesquisa. Encontre o que precisa em menos tempo.</li>
                              <li>Anotações atualizadas constantemente.</li>
                              <li>Acórdãos, doutrinas, legislação, conteúdo Sollicita PRO, em cada dispositivo legal (artigo, caput, parágrafo, inciso e alínea).</li>
                              <li>Acesso a orientações ligadas aos dispositivos das Leis.</li>
                              <li>Mais segurança na tomada de decisões em todas as etapas das contratações públicas.</li>
                            </ul>
                            `,
                        descricao: null,
                        quemUsa: null,
                    },
                    {
                        idFerramenta: 10, nmFerramenta: 'Livraria', urlImagem: ebook_gg, urlIcone: ebook, urlIconeActive: ebook_active, classe: 'ebook',
                        dsFerramenta: `
                            <p class="recuo">Conte com uma livraria particular na área de <b>licitações e contratos.</b></p>
                           <p class="recuo"><b class="txt-ebook">E-Books</b> digitais para <b>baixar, ler e consultar</b> onde e quando você precisar. Organização, conteúdo e praticidade. Além de quadros comparativos exclusivos. O melhor conteúdo de compras públicas reunidos nesta ferramenta.</p>`,
                        descricao: null,
                        quemUsa: null,
                    },
                    {
                        idFerramenta: 11, nmFerramenta: 'Pesquisa', urlImagem: pesquisa_gg, urlIcone: pesquisa, urlIconeActive: pesquisa_active, classe: 'pesquisa',
                        dsFerramenta: `
                            <p class="recuo">Pesquise por termos, temas ou perguntas e nós te mostramos o que temos em nosso acervo de módulos e ferramentas!</p>
                           `,
                        descricao: null,
                        quemUsa: null,
                    },
                ];

                this.ferramentaAtiva = this.ferramentas[0];
            },
        },
        mounted() {
            this.load();
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .box-branco {
        padding: 15px;
        background: #FFFFFF;
        /*box-shadow: -18px 7px 25px rgba(0, 0, 0, 0.25);*/
        box-shadow: -20px 15px 25px rgb(0 0 0 / 25%);
    }

    .prev:hover {
        transform: scale(1.05);
        filter: brightness(100%);
        transition: .6s transform,.3s filter !important;
    }

    .next:hover {
        transform: scale(1.05);
        filter: brightness(100%);
        transition: .6s transform,.3s filter !important;
    }

    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }

    .box-branco{
        position: relative;
    }

    .box-branco::after {
        content: "\00a0";
        position: absolute;
        top: 0;
        left: -40px;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 40px solid #FFF;

    }


    .icone-ferramentas-img{
        width: 100px;
        height: auto;
    }

    @media (min-width: 575.98px) and (max-width: 992px) {
        .box-branco::after {
            display: none;
        }
    }

</style>

