<template>
    <div class="CadatroUsuarioCliente" >
        <b-modal ref="modal-cadastro-usuario-cliente" id="modalCadatroUsuarioCliente" size="xl" :hide-footer="true" :hide-header="true">
            <div class="w-100 text-end txt-size-20 txt-blue-2">
                <span @click="close"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <form class="px-3 pb-3" id="formMeusDados" v-if="dados">

                <div class="row">
                    <span class="txt-p txt-roxo-1 txt-size-25 txt-w-bold mb-2">
                        Gerenciar Cliente
                    </span>
                </div>
                <div class="row mb-2">
                    <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                        E-mail
                    </span>
                    <div class="form-group">
                        <input type="email" class="form-control" id="Usuarios_dsEmail" name="usuario.dsEmail" v-model="usuario.dsEmail" maxlength="100" :readonly="dados.dsLogin != null" required>
                    </div>
                </div>
                <div class="row mb-2">
                    <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                        Nome
                    </span>
                    <div class="form-group">
                        <input type="text" class="form-control" id="Usuarios_nmUsuario" name="usuario.nmUsuario" v-model="usuario.nmUsuario" maxlength="100" required>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-6">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                            Departamento
                        </span>
                        <div class="form-group">
                            <select class="form-control" v-model="usuario.idDepartamento" required>
                                <option value="" selected>Selecione</option>
                                <option v-for="(item,index) in lstDepartamentos" :key="index" :value="item.idDepartamento">{{item.nmDepartamento}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                            Função
                        </span>
                        <div class="form-group">
                            <select class="form-control" v-model="usuario.idFuncao" required>
                                <option value="" selected>Selecione</option>
                                <option v-for="(item,index) in lstFuncoes" :key="index" :value="item.idFuncao">{{item.nmFuncao}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-6">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                            Cargo
                        </span>
                        <div class="form-group">
                            <input type="text" class="form-control" id="Usuarios_dsCargo" name="usuario.dsCargo" v-model="usuario.dsCargo" maxlength="100" required>
                        </div>
                    </div>
                    <div class="col-6">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                            Data de Nascimento
                        </span>
                        <div class="form-group">
                            <vue-mask class="form-control"
                                      id="dtNascimento"
                                      name="usuario.dtNascimento"
                                      v-model="usuario.dtNascimento"
                                      mask="00/00/0000"
                                      :raw="false"
                                      :options="options" required>
                            </vue-mask>
                        </div>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-6">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                            Telefone
                        </span>
                        <div class="form-group">
                            <!--<input type="text" class="form-control" id="Usuarios_dsTelefone" name="usuario.dsTelefone" v-model="usuario.dsTelefone" :maxlength="100" required>-->
                            <vue-mask class="form-control"
                                      id="Usuarios_dsTelefone"
                                      name="usuario.dsTelefone"
                                      v-model="usuario.dsTelefone"
                                      mask="(00) 00000-0000"
                                      :raw="false"
                                      :options="optionTelefone"
                                      required>
                            </vue-mask>
                        </div>
                    </div>
                    <div class="col-6">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                            Celular
                        </span>
                        <div class="form-group">
                            <!--<input type="text" class="form-control" id="Usuarios_dsCelular" name="usuario.dsCelular" v-model="usuario.dsCelular" :maxlength="100" required>-->
                            <vue-mask class="form-control"
                                      id="Usuarios_dsCelular"
                                      name="usuario.dsCelular"
                                      v-model="usuario.dsCelular"
                                      mask="(00) 00000-0000"
                                      :raw="false"
                                      :options="optionTelefone"
                                      required>
                            </vue-mask>
                        </div>
                    </div>
                </div>

                <div class="row mb-2" v-if="resultConsulta && resultConsulta.clienteUsuario && resultConsulta.clienteUsuario.flSupervisor != 'Sim'">
                    <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                        <input type="checkbox" v-model="usuario.ckb_admin" /> Administrador
                    </span>
                    <div class="form-group">
                        
                    </div>
                </div>

                <div class="row mb-2" v-if="this.dados.dsLogin == null">
                    <div class="col-6">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                            Senha
                        </span>
                        <div class="form-group">
                            <input type="password" class="form-control" id="Usuarios_dsSenha" name="usuario.dsSenha" v-model="usuario.dsSenha" :maxlength="100" required>
                        </div>
                    </div>
                    <div class="col-6">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                            Confirma Senha
                        </span>
                        <div class="form-group">
                            <input type="password" class="form-control" id="Usuarios_dsSenhaConfirma" name="usuario.dsSenhaConfirma" v-model="usuario.dsSenhaConfirma" :maxlength="100" required>
                        </div>
                    </div>
                </div>

                <div class="row mb-2" v-else>
                    <div class="col-6">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                            Refaz Cadastro
                        </span>
                        <div class="form-group">
                            <select class="form-control" id="Usuarios_flRefazCadastro" name="usuario.flRefazCadastro" v-model="usuario.flRefazCadastro" required>
                                <option value="Sim">Sim</option>
                                <option value="Não">Não</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <span class="txt-p txt-blue-2 txt-w-5 txt-size-18">
                            Refaz Senha
                        </span>
                        <div class="form-group">
                            <select class="form-control" id="Usuarios_flRefazSenha" name="usuario.flRefazSenha" v-model="usuario.flRefazSenha" required>
                                <option value="Sim">Sim</option>
                                <option value="Não">Não</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group w-100">
                        <button class="btn btn-blue w-100" type="button" :disabled="!liberaSalvar" @click="enviar">Salvar</button>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
    import vueMask from 'vue-jquery-mask'
    import { urlApi, avisoSucesso, avisoErro } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'

    export default {
        name: 'CadatroUsuarioCliente',
        components: {
            vueMask,
        },
        data: function () {
            return {

                limiteCaracteres: 500,
                resultConsulta: null,
                usuario: {
                    nmUsuario: null,
                    dsEmail: null,
                    dsTelefone: null,
                    dsCelular: null,
                    dtNascimento: null,
                },

                lstDepartamentos: [],
                lstFuncoes: [],

                options: {
                    placeholder: '__/__/____',
                },
                optionTelefone: {
                    placeholder: '(__) _____- ____',
                },
            }
        },
        props: {
            showModal: Boolean,
            dados: Object,
        },
        computed: {
            liberaSalvar() {
                if (this.usuario.dsEmail == '' || this.usuario.dsEmail == null) {
                    return false;
                }

                if (this.usuario.nmUsuario == '' || this.usuario.nmUsuario == null) {
                    return false;
                }

                if (this.usuario.idDepartamento == '' || this.usuario.idDepartamento == null) {
                    return false;
                }

                if (this.usuario.idFuncao == '' || this.usuario.idFuncao == null) {
                    return false;
                }

                if (this.usuario.dsCargo == '' || this.usuario.dsCargo == null) {
                    return false;
                }

                if (this.usuario.dtNascimento == '' || this.usuario.dtNascimento == null) {
                    return false;
                }

                if (this.usuario.dsTelefone == '' || this.usuario.dsTelefone == null) {
                    return false;
                }

                if (this.usuario.dsCelular == '' || this.usuario.dsCelular == null) {
                    return false;
                }

                if (this.dados.dsLogin == null) {
                    if (this.usuario.dsSenha == '' || this.usuario.dsSenha == null) {
                        return false;
                    }
                    if (this.usuario.dsSenhaConfirma == '' || this.usuario.dsSenhaConfirma == null) {
                        return false;
                    }
                } else {
                    if (this.usuario.flRefazCadastro == '' || this.usuario.flRefazCadastro == null) {
                        return false;
                    }
                    if (this.usuario.flRefazSenha == '' || this.usuario.flRefazSenha == null) {
                        return false;
                    }
                }

                return true;
            },

        },        
        methods: {
            enviar() {
                if (this.checkForm()) {
                    let $this = this;
                    axios.post(`${urlApi}/ClienteContratoProduto/AddUsuarioCliente`, $this.usuario)
                        .then(res => {
                            if (res.data.success) {
                                // aviso ok
                                avisoSucesso("Dados salvos com sucesso!");
                                $this.$refs['modal-cadastro-usuario-cliente'].hide();
                                $this.$emit('reload');
                            } else {
                                avisoErro("Não foi possível salvar os dados.");
                            }
                        })
                        .catch(res => {
                            if (res.response.data != undefined) {
                                var msg = res.response.data.msg +' '+ (res.response.data.errors.length > 0 ? res.response.data.errors[0] : '');
                                avisoErro(msg);
                            } else {
                                avisoErro(res.response.statusText);
                            }
                        });
                }
                return false;
            },

            checkForm() {
                var form = document.getElementById('formMeusDados')
                if (!form.checkValidity()) {
                    var tmpSubmit = document.createElement('button')
                    form.appendChild(tmpSubmit)
                    tmpSubmit.click()
                    form.removeChild(tmpSubmit)

                    return false;
                }

                if (this.limiteCaracteres > 0) {
                    if (this.usuario.nmUsuario.length > 100) {
                        avisoErro("O limite de caracteres do \"Nome\" foi ultrapassado")
                        return false;
                    }

                    if (this.usuario.dsEmail.length > 100) {
                        avisoErro("O limite de caracteres \"E-mail\" foi ultrapassado")
                        return false;
                    }

                    if (this.usuario.dsCargo.length > 100) {
                        avisoErro("O limite de caracteres \"Cargo\" foi ultrapassado")
                        return false;
                    }

                    if (this.usuario.dsTelefone.length > 100) {
                        avisoErro("O limite de caracteres \"Telefone\" foi ultrapassado")
                        return false;
                    }

                    if (this.usuario.dsCelular.length > 100) {
                        avisoErro("O limite de caracteres \"Celular\" foi ultrapassado")
                        return false;
                    }
                }

                if (this.usuario.dsSenha != this.usuario.dsSenhaConfirma) {
                    avisoErro("As senhas não são iguais.")
                    return false;
                }
                return true;
            },

            load() {
                let $this = this;
                axios.get(`${urlApi}/ClienteContratoProduto/GetCadastroUsuarioCliente`, { params: this.dados })
                    .then(res => {
                        if (res.data.success) {
                            var result = res.data.data;
                            $this.resultConsulta = result;
                            $this.lstDepartamentos = result.lstDepartamento;
                            $this.lstFuncoes = result.lstFuncao;
                            $this.usuario = {
                                tpOperacao: ($this.dados.dsLogin == null ? "Inserir":"Editar"),
                                dsEmail: result.usuarioCRUD.dsEmail,
                                nmUsuario: result.usuarioCRUD.nmUsuario,
                                idDepartamento: result.idDepartamento,
                                idFuncao: result.idFuncao,
                                dsCargo: result.dsCargo,
                                dtNascimento: moment(result.usuarioCRUD.dtNascimento).format('DD/MM/YYYY'),
                                dsTelefone: result.usuarioCRUD.dsTelefone,
                                dsCelular: result.usuarioCRUD.dsCelular,
                                ckb_admin: (result.clienteUsuario.flAdministrador == "Sim"?true:false),
                                dsSenha : "",
                                dsSenhaConfirma : "",
                                flRefazCadastro: result.usuarioCRUD.flRefazCadastro,
                                flRefazSenha: result.usuarioSenhaCRUD.flRefazSenha,
                                idContrato: result.idContrato,
                                idCliente: result.idCliente,
                                flSupervisor: result.clienteUsuario.flSupervisor,
                            }
                            
                        } else {
                            avisoErro("Não foi possível carregar os dados.");
                        }
                    })
                    .catch(res => {
                        if (res.response.data != undefined) {
                            avisoErro(res.response.data);
                        } else {
                            avisoErro(res.response.statusText);
                        }
                    });
            },

            close() {
                this.$refs['modal-cadastro-usuario-cliente'].hide();
            }

        },
        created: function () {
            //this.load();
        },
        watch: {
            showModal: function () {
                this.$refs['modal-cadastro-usuario-cliente'].show();
                this.load();
            },
            
        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* MODAL */
   .form-control {
        border: 1px solid #474747;
    }
</style>

