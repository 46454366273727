<template>
    <div class="ModalRepositorio">
        <template v-if="repositorio && repositorio.tpConteudo">
            
            <ModalPesquisa :dados="dadosModalPesquisa" :showModal="showModalPesquisa" :titulo="titulo" v-if="repositorio.tpConteudo == 'Pesquisa'"></ModalPesquisa>
            
            <b-modal ref="modal-repositorio" id="ModalRepositorio" size="xl" :hide-footer="true" :hide-header="true" v-else>
                <div class="modal-titulo txt-p txt-w-7 txt-size-20 txt-grey-2 m-2">
                    <p class="txt-w-20 mb-0 txt-biblioteca">{{titulo}}</p>
                    <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>
                </div>
                <div class="modal-conteudo m-2 px-2">
                    
                    <template v-if="repositorio.tpConteudo == 'Notícia' || repositorio.tpConteudo == 'Noticia'">
                        <NoticiaModal :idConteudo="repositorio.idConteudo"></NoticiaModal>
                    </template>
                    <template v-else-if="repositorio.tpConteudo == 'Notícia Vídeo'">
                        <NoticiaVideoModal :idConteudo="repositorio.idConteudo"></NoticiaVideoModal>
                    </template>
                    <template v-else-if="repositorio.tpConteudo == 'Notícia Áudio'">
                        <NoticiaAudioModal :idConteudo="repositorio.idConteudo"></NoticiaAudioModal>
                    </template>
                    <!--<template v-else-if="repositorio.tpConteudo == 'Áudio'">
                        <AudioModal :idConteudo="repositorio.idConteudo"></AudioModal>
                    </template>
                    <template v-else-if="repositorio.tpConteudo == 'Capacitação'">
                        <CapacitacaoModal :idConteudo="repositorio.idConteudo"></CapacitacaoModal>
                    </template>
                    <template v-else-if="repositorio.tpConteudo == 'Coluna'">
                        <ColunaModal :idConteudo="repositorio.idConteudo"></ColunaModal>
                    </template>-->
                    <template v-else-if="repositorio.tpConteudo == 'Penalidade'">
                        <PenalidadeModal :idConteudo="repositorio.idConteudo"></PenalidadeModal>
                    </template>

                </div>
                <div class="modal-footer mt-3">
                    <div class="row ">
                        <div class="col-12 px-0 d-flex justify-content-end">

                        </div>
                    </div>
                </div>
            </b-modal>
        </template>
        
    </div>
</template>

<script>
    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'

    import NoticiaModal from '@/components/Biblioteca/NoticiaModal'
    import NoticiaVideoModal from '@/components/Biblioteca/NoticiaVideoModal'
    import NoticiaAudioModal from '@/components/Biblioteca/NoticiaAudioModal'
    //import AudioModal from '@/components/Biblioteca/AudioModal'
    //import CapacitacaoModal from '@/components/Biblioteca/CapacitacaoModal'
    //import ColunaModal from '@/components/Biblioteca/ColunaModal'
    import PenalidadeModal from '@/components/Biblioteca/PenalidadeModal'

    import ModalPesquisa from '@/components/ModalDetalhes'

    export default {
        name: 'ModalRepositorio',
        props: {
            idRepositorio: Number,
            showModal: Boolean,
        },
        components: {
            ModalPesquisa, NoticiaModal, NoticiaVideoModal, NoticiaAudioModal, /* AudioModal, CapacitacaoModal, ColunaModal,*/ PenalidadeModal
        },
        data: function () {
            return {
                titulo: 'Conteúdo da Minha Biblioteca',
                repositorio: null,

                showModalPesquisa: false,
                dadosModalPesquisa: null,

            }
        },
        methods: {
            load() {
                this.carregaRepositorio();
            },

            hideModalFn() {
                this.$refs['modal-repositorio'].hide();
            },

            carregaRepositorio() {
                let $this = this;
                axios.get(`${urlApi}/Repositorio/Get/${$this.idRepositorio}`)
                    .then(res => {
                        if (res.data.success) {
                            // eslint-disable-next-line
                            debugger;
                            var result = res.data.data;
                            $this.repositorio = result;
                            $this.$nextTick(function () {
                                if ($this.repositorio.tpConteudo == 'Pesquisa') {
                                    $this.dadosModalPesquisa = {
                                        cdConteudo: $this.repositorio.cdConteudo,
                                        idItemPesquisa: $this.repositorio.idConteudo,
                                        dsReferencia: ' ',
                                    };
                                    $this.showModalPesquisa = !$this.showModalPesquisa;

                                } else {
                                    $this.$refs['modal-repositorio'].show();
                                }
                            });
                            
                        } else {
                            avisoErro("Não foi possível carrega o conteúdo");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carrega o conteúdo");
                    });
            },
                      

        },
        mounted: function () {
        },
        watch: {
            showModal: function () {
                this.load();
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">

    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #DADADA;
        display: flex;
        justify-content: space-between;
    }

    .modal-conteudo {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 70vh;
    }

    
</style>

