<template>
    <div class="ModalImprimirCompromisso">
        <b-modal ref="modal-imprimir-compromisso" id="ModalImprimirCompromisso" size="lg" :hide-footer="true" :hide-header="true">
            <div class="modal-titulo txt-p txt-w-7 txt-size-20 txt-grey-2 m-2">
                <p class="txt-w-20 mb-0 txt-agenda">{{titulo}}</p>
                <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <div class="modal-conteudo m-2 px-3" id="imprimirRelatorio">
                <div class="row">
                    <div class="col-12" v-if="carregou">
                        <h1 class="bold">{{compromisso.nmTitulo}}</h1>

                        <p class="regular">
                            <span class="bold">Data:</span> {{getData(compromisso.dtCompromisso)}}
                        </p>

                        <p class="regular">
                            <span class="bold">Hora:</span> {{getHora(compromisso.hrCompromisso)}}
                        </p>
                                                
                        <p class="regular" v-if="compromisso.dsLocal"><span class="bold">Local:</span> {{compromisso.dsLocal}}</p>
                        
                        <p class="regular">
                            <span class="bold">Descrição:</span> {{compromisso.dsCompromisso}}
                        </p>

                        <fieldset>
                            <legend class="bold">Assuntos tratados</legend>
                            <ol>
                                <li v-for="(item,index) in lstPautas" :key="index">
                                    <p class="regular">
                                        {{item.dsPauta}}. 
                                    </p>
                                    <p>
                                        Conclui-se que:
                                    </p>
                                    <p class="regular">
                                        {{item.dsResultado}}
                                    </p>
                                </li>
                                
                            </ol>
                        </fieldset>

                        <fieldset v-if="lstParticipantes.length > 0">
                            <legend class="bold">Participantes</legend>
                            <p class="bold">
                                <span>{{nome}}</span><template v-for="(item,index) in lstParticipantes"><span v-if="item.agendaCompromissoParticipante.flPresente == 'Sim'" :key="index">, {{item.agendaPessoa.nmPessoa}}</span></template>
                            </p>
                        </fieldset>

                        <fieldset>
                            <p class="regular" style="text-align: center;">Nada mais havendo a tratar, foi lavrada por mim, {{nome}}, a presente ata, assinada por todos os presentes acima nominados e referenciados.</p>

                            <div class="assinatura" style="margin-top: 50px;">
                                <hr />
                                <p class="bold text-center">{{nome}}</p>
                            </div>

                            <div class="assinatura" style="margin-top: 50px;" v-for="(item,index) in lstParticipantes" :key="index">
                                <template v-if="item.agendaCompromissoParticipante.flPresente == 'Sim'">
                                    <hr />
                                    <p class="bold text-center"> {{item.agendaPessoa.nmPessoa}}</p>
                                </template>

                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
            <div class="modal-footer mt-3">
                <button class="btn btn-agenda px-4 py-2 w-100" type="button" @click="imprimir">
                    Imprimir
                </button>
            </div>
        </b-modal>
    </div>
</template> 

<script>
    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'
    
    export default {
        name: 'ModalImprimirCompromisso',
        props: {
            idCompromisso: Number,
            showModal: Boolean,
        },
        components: {  },
        data: function () {
            return {
                titulo: 'Imprimir',

                compromisso: null,
                lstPautas: [],
                lstParticipantes: [],
                nome: '',

                carregou: false,

            }
        },
        methods: {
            load() {
                this.titulo = 'Imprimir';
                this.carregaDados();
                this.$refs['modal-imprimir-compromisso'].show();
            },

            carregaDados() {
                let $this = this;
                axios.get(`${urlApi}/Agenda/GetImprimirCompromisso/${$this.idCompromisso}`)
                    .then(res => {
                        $this.limpaDados();
                        if (res.data.success) {
                            if (res.data.data != null) {
                                var result = res.data.data;
                                $this.compromisso = result.compromisso;
                                $this.lstPautas = result.lstPautas;
                                $this.lstParticipantes = result.lstParticipantes;
                                $this.nome = result.nome;
                                $this.carregou = true;
                            }
                        } else {
                            avisoErro("Não foi possível carregar os dados");
                            $this.limpaDados();
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar os dados");
                        $this.limpaDados();
                    });
            },

            limpaDados() {
                this.compromisso = null;
                this.lstPautas = [];
                this.lstParticipantes = [];
                this.nome = '';
                this.carregou = false;
            },

            hideModalFn() {
                this.$refs['modal-imprimir-compromisso'].hide();
            },

            async imprimir() {
                // Pass the element id here
                await this.$htmlToPaper('imprimirRelatorio');
            },

            getData(dt) {
                if (dt != null) {
                    return moment(dt).format('DD/MM/YYYY');
                } else {
                    return "";
                }

            },

            getHora(dt) {
                if (dt != null) {
                    return moment(dt,'HH:mm:ss').format('HH:mm');
                } else {
                    return "";
                }
            },

        },
        mounted: function () {
        },
        watch: {
            showModal: function () {
                this.load();
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #DADADA;
        display: flex;
        justify-content: space-between;
    }

    .modal-conteudo {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 70vh;
    }

</style>

