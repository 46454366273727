import Vue from 'vue'
import Vuex from 'vuex'
import { userKey, urlApi, avisoErro } from '@/config/global'
import axios from 'axios'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        // variaveis globais
        user: null,
        isAdmContrato: false,
        tpPessoa: null,
        flVerificaAcesso: false,
        flPesquisa: false,
    },
    getters: {
        // get das variaveis
        
        getUser(state) {
            return state.user;
        },
                
        getIdContrato(state) {
            return state.autenticacao.idContrato;
        },
        getIsAdmContrato(state) {
            return state.isAdmContrato;
        },
        getFlPesquisa(state) {
            return state.flPesquisa;
        }

    },
    mutations: {
        // troca valores das variaveis
        setUser(state, user) {
            state.user = user
            if (user) {
                localStorage.setItem(userKey, JSON.stringify(user))
                axios.defaults.headers.common['Authorization'] = `Bearer ${user.accessToken}`

                axios.get(`${urlApi}/Auth/GetDadosUser?dadosOrientacao=true`)
                    .then(res => {
                        if (res.data) {
                            state.flVerificaAcesso = true;
                            state.isAdmContrato = res.data.isAdmContrato;
                            state.tpPessoa = res.data.tpPessoa;
                        } else {
                            avisoErro("Erro ao carregar dados de usu�rio.");
                        }
                    })
                    .catch(function () {
                        avisoErro("Erro ao carregar dados de usu�rio.");
                    });

                axios.get(`${urlApi}/Produto/VerificaAcessoProduto`, { params: { cdProduto: 'SP' } })
                    .then(res => {
                        if (res.data.success) {
                            if (res.data.data != 'Sim') {
                                // nao tem acesso 
                                state.flPesquisa = false;
                            } else {
                                state.flPesquisa = true;
                            }
                        } else {
                            avisoErro("N�o foi poss�vel verificar a ferramenta de pesquisa");
                        }
                    })
                    .catch(function () {
                        avisoErro("N�o foi poss�vel verificar a ferramenta de pesquisa");
                    });
            } else {
                localStorage.removeItem(userKey)
                delete axios.defaults.headers.common['Authorization']
            }
        },

        setIsAdmContrato(state, isAdmContrato) {
            state.isAdmContrato = isAdmContrato;
        },

        setFlPesquisa(state, flPesquisa) {
            state.flPesquisa = flPesquisa;
        },
     
    },
    actions: {
        // logicas de trocar valores
        
    }
});

export default store;