<template>
    <div class="PenalidadeModal">
        <div class="container" v-if="penalidade">
            <div class="row" style="margin-bottom: 15px;">
                <div class="col-md-12">
                    <h3 class="inline-block bold" v-if="penalidade.flPenalidadeAtiva">
                        <span class="label label-danger"><i class="fas fa-times text-danger"></i> Penalidade Ativa</span>
                    </h3>
                    <h3 class="inline-block bold" v-else>
                        <span class="label label-success"><i class="fas fa-check text-success"></i>  Penalidade Inativa</span>
                    </h3>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <ul class="list-group regular">
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Processo:</span>
                            {{penalidade.nrProcesso}}
                        </li>
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Fundamentação Legal:</span>
                            {{penalidade.dsFundamentacaoLegal}}
                        </li>
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Período:</span>
                            
                            <span v-if="penalidade.dtInicioFormat != null">
                                {{penalidade.dtInicioFormat}} até {{penalidade.dtFimFormat}}
                            </span>
                            
                            <span v-else>Período não encontrado</span>
                            
                        </li>
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Penalidade:</span>
                            {{penalidade.tpPenalidade}}
                        </li>
                    </ul>
                </div>

                <div class="col-md-6">
                    <ul class="list-group regular">
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Número Fundamentação Legal:</span>
                            <span v-if="penalidade.nmFundamentacaoLegal && penalidade.nmFundamentacaoLegal != ''">
                                {{penalidade.nmFundamentacaoLegal}}
                            </span>
                            <span v-else>**</span>
                            

                        </li>
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Data Publicação:</span>
                            <span v-if="penalidade.dtPublicacaoFormat != null">
                                {{penalidade.dtPublicacaoFormat}}
                            </span>
                            <span v-else>**</span>

                        </li>
                        <li class="list-group-item"> 
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Descrição Publicação:</span>
                            <template v-if="penalidade.dsPublicacao && penalidade.dsPublicacao != ''">
                                <template v-if="penalidade.lnkPublicacao && penalidade.lnkPublicacao != ''">
                                    <a :href="penalidade.lnkPublicacao" target="_blank">
                                        {{penalidade.dsPublicacao}}
                                    </a>
                                </template>
                                <template v-else>
                                    {{penalidade.dsPublicacao}}
                                </template>
                            </template>
                            <template v-else>
                                <span>**</span>
                            </template>
                            
                        </li>
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Detalhes Meio Publicação:</span>
                            <span v-if="penalidade.dsDetalhesMeioPublicacao && penalidade.dsDetalhesMeioPublicacao != ''">
                                {{penalidade.dsDetalhesMeioPublicacao}}
                            </span>
                            <span v-else>**</span>
                        </li>
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Data Trânsito:</span>
                            <span v-if="penalidade.dtTransitoFormat != null">
                                {{penalidade.dtTransitoFormat}}
                            </span>
                            <span v-else>**</span>
                            
                        </li>
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Abrangência Decisão Judicial:</span>
                            <span v-if="penalidade.dsAbrangenciaDecisaoJudicial && penalidade.dsAbrangenciaDecisaoJudicial != ''">
                                {{penalidade.dsAbrangenciaDecisaoJudicial}}
                            </span>
                            <span v-else>**</span>

                        </li>
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Orgão Sancionador:</span>
                            
                            <span v-if="penalidade.dsOrgaoSancionador && penalidade.dsOrgaoSancionador != ''">
                                {{penalidade.dsOrgaoSancionador}}
                            </span>
                            <span v-else>**</span>

                        </li>
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Complemento Orgão Sancionador:</span>
                            
                            <span v-if="penalidade.dsComplementoOrgaoSancionador && penalidade.dsComplementoOrgaoSancionador != ''">
                                {{penalidade.dsComplementoOrgaoSancionador}}
                            </span>
                            <span v-else>**</span>

                        </li>
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">UF Orgão Sancionador:</span>
                            <span v-if="penalidade.uf_orgao_sancionador && penalidade.uf_orgao_sancionador != ''">
                                {{penalidade.uf_orgao_sancionador}}
                            </span>
                            <span v-else>**</span>

                        </li>
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Origem Informação:</span>
                            <span v-if="penalidade.dsOrigemInformacao && penalidade.dsOrigemInformacao != ''">
                                {{penalidade.dsOrigemInformacao}}
                            </span>
                            <span v-else>**</span>
                            
                        </li>
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Endereço:</span>
                            <span v-if="penalidade.dsEdereco && penalidade.dsEdereco != ''">
                                {{penalidade.dsEdereco}}
                            </span>
                            <span v-else>**</span>
                            

                        </li>
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Contato Origem Informação:</span>
                            <span v-if="penalidade.dsContatoOrigemInformacao && penalidade.dsContatoOrigemInformacao != ''">
                                {{penalidade.dsContatoOrigemInformacao}}
                            </span>
                            <span v-else>**</span>
                            
                        </li>
                        <li class="list-group-item">
                            <span class="txt-p txt-size-16 txt-w-7 mb-0">Data Informação:</span>
                            <span v-if="penalidade.dtInformacaoFormat != null">
                                {{penalidade.dtInformacaoFormat}}
                            </span>
                            <span v-else>**</span>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { urlApi, avisoErro } from '@/config/global'
    import axios from 'axios'
    import moment from 'moment'

    export default {
        name: 'PenalidadeModal',
        props: {
            idConteudo: Number,
        },
        components: {

        },
        data: function () {
            return {
                penalidade: null,
            }
        },
        methods: {
            load() {
                this.carregaPenalidade();
            },

            carregaPenalidade() {
                let $this = this;
                axios.get(`${urlApi}/BancoPenalidades/Get/${$this.idConteudo}`)
                    .then(res => {
                        if (res.data.success) {
                            $this.penalidade = res.data.data;

                            $this.penalidade.dtInicioFormat = ($this.penalidade.dtInicio && $this.penalidade.dtInicio != "" ? moment($this.penalidade.dtInicio).format('DD/MM/YYYY') : null);
                            $this.penalidade.dtFimFormat = ($this.penalidade.dtFim && $this.penalidade.dtFim != "" ? moment($this.penalidade.dtFim).format('DD/MM/YYYY') : null);
                            $this.penalidade.dtPublicacaoFormat = ($this.penalidade.dtPublicacao && $this.penalidade.dtPublicacao != "" ? moment($this.penalidade.dtPublicacao).format('DD/MM/YYYY') : null);
                            $this.penalidade.dtTransitoFormat = ($this.penalidade.dtTransito && $this.penalidade.dtTransito != "" ? moment($this.penalidade.dtTransito).format('DD/MM/YYYY') : null);
                            $this.penalidade.dtInformacaoFormat = ($this.penalidade.dtInformacao && $this.penalidade.dtInformacao != "" ? moment($this.penalidade.dtInformacao).format('DD/MM/YYYY') : null);

                            if ($this.penalidade.urlPublicacao && $this.penalidade.urlPublicacao != '') {
                                var lnkPublicacao = $this.penalidade.urlPublicacao.replace("<a href=\"", " ");
                                lnkPublicacao = lnkPublicacao.substr(1, lnkPublicacao.indexOf(">") - 2);

                                $this.penalidade.lnkPublicacao = lnkPublicacao;
                            } else {
                                $this.penalidade.lnkPublicacao = null;
                            }

                            var hoje = moment();

                            if ($this.penalidade.flAtiva == "Sim" && (moment($this.penalidade.dtFim) >= hoje || $this.penalidade.dtFim == null || $this.penalidade.dtFim == "")) {
                                $this.penalidade.flPenalidadeAtiva = true;
                            } else {
                                $this.penalidade.flPenalidadeAtiva = false;
                            }

                        } else {
                            avisoErro("Não foi possível carregar a penalidade");
                            $this.penalidade = null;
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carregar a penalidade");
                        $this.penalidade = null;
                    });
            },
        },
        mounted: function () {
            this.load();
        },
        watch: {
            
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">

</style>

