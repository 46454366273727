<template>
    <div class="ModalNovaAnalise">
        <b-modal ref="modal-nova-analise" id="ModalNovaAnalise" size="lg" :hide-footer="true" :hide-header="true">
            <div class="modal-titulo txt-p txt-w-7 txt-size-20 txt-grey-2 m-2">
                <p class="txt-w-20 mb-0 txt-analisador_balancos">{{titulo}}</p>
                <span @click="hideModalFn"><i class="fas fa-times c-pointer"></i></span>
            </div>
            <div class="modal-conteudo m-2 px-2">
                <form class="mt-2">
                    <div class="mb-3">
                        <label for="nmAnalise" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                            1. Nome da análise:
                        </label>
                        <div class="w-100">
                            <input class="form-control" type="text" id="nmAnalise" aria-describedby="nmAnalise" placeholder="Escreva um nome para sua análise" maxlength="100" v-model="analise.nmAnalise">
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="NomeProcesso" class="form-label txt-p txt-w-7 txt-size-16 txt-grey-2 mb-1">
                            2. Índice Exigido (apenas números):
                        </label>
                        <div class="w-100">
                            <vue-mask class="form-control"
                                      placeholder="Índice"
                                      id="nrIndiceExigidoLicitacao"
                                      name="analise.nrIndiceExigidoLicitacao"
                                      v-model="analise.nrIndiceExigidoLicitacao"
                                      mask="000,00"
                                      :raw="false"
                                      :options="{reverse:true}"
                                      required>
                            </vue-mask>
                        </div>
                    </div>                    
                </form>
            </div>
            <div class="modal-footer mt-3">
                <div class="row ">
                    <div class="col-12 px-0 d-flex justify-content-end">
                        <button class="btn btn-cancelar mr-2" type="button" @click="hideModalFn">
                            Cancelar
                        </button>
                        <template v-if="idAnalise != null && idAnalise != 0">
                            <button class="btn btn-analisador_balancos" type="button" @click="salvar" :disabled="validaSalvar">
                                Editar análise
                            </button>
                        </template>
                        <template v-else>
                            <button class="btn btn-analisador_balancos" type="button" @click="salvar" :disabled="validaSalvar">
                                Adicionar análise
                            </button>
                        </template>
                        
                    </div>
                    
                </div>
                
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { urlApi, avisoErro, avisoSucesso } from '@/config/global'
    import vueMask from 'vue-jquery-mask'
    import axios from 'axios'

    export default {
        name: 'ModalAnotacao',
        props: {
            idAnalise: Number,
            showModal: Boolean,
            atualizar: Function,
        },
        components: {
            vueMask,
        },
        data: function () {
            return {
                titulo: 'Nova análise de balanços',

                analise: {
                    nmAnalise: null,
                    nrIndiceExigidoLicitacao: null,
                },

            }
        },
        computed: {
            validaSalvar() {
                let $this = this;

                if ($this.analise.nmAnalise == null || $this.analise.nmAnalise == '') {
                    return true;
                }
                if ($this.analise.nrIndiceExigidoLicitacao == null || $this.analise.nrIndiceExigidoLicitacao == '') {
                    return true;
                }

                return false;
            },
        },
        methods: {
            load() {

                this.analise = {
                    nmAnalise: null,
                    nrIndiceExigidoLicitacao: null,
                };

                if (this.idAnalise) {
                    this.titulo = 'Editar análise de balanços';
                    this.carregaAnalise();
                }
                this.$refs['modal-nova-analise'].show();
            },

            hideModalFn() {
                this.$refs['modal-nova-analise'].hide();
            },

            carregaAnalise() {
                let $this = this;
                axios.get(`${urlApi}/AnalisadorBalancos/Get/${this.idAnalise}`)
                    .then(res => {
                        if (res.data.success) {
                            var result = res.data.data;
                            $this.analise = result.analise;
                        } else {
                            avisoErro("Não foi possível carrega a anáise");
                        }
                    })
                    .catch(function () {
                        avisoErro("Não foi possível carrega a análise");
                    });
            },

            salvar() {
                let $this = this;
                let analise = $this.analise;
                analise.idAnalise = $this.idAnalise;
                analise.nrIndiceExigidoLicitacao = analise.nrIndiceExigidoLicitacao.replace(',', '.');

                axios.post(`${urlApi}/AnalisadorBalancos/Post`, analise, { showLoading: true })
                    .then(res => {
                        if (res.data.success) {
                            $this.atualizar();
                            avisoSucesso("Análise salva com sucesso.");
                            $this.hideModalFn();
                        } else {
                            avisoErro("Não foi possível salvar a análise");
                        }
                    })
                    .catch(res => {
                        avisoErro(res.response.data.errors[0]);
                    });
            },

        },
        mounted: function () {
        },
        watch: {
            showModal: function () {
                this.load();
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

    .modal-titulo {
        padding-bottom: 20px;
        border-bottom: 2px solid #DADADA;
        display: flex;
        justify-content: space-between;
    }

    .modal-conteudo {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 70vh;
    }
</style>

