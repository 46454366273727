import Swal from 'sweetalert2'

export const userKey = '_Sollicita.Home'
export const rememberKey = '_Sollicita.Home_remember'
export const avisoManualUsuarioKey = '_Sollicita.Home_avisoManualUsuario'

export var urlApi = ''
export var urlApiSollicita = ''
export var urlLeitorPDF = ''
export var urlHotmart = 'https://pay.hotmart.com/X6562841I?off=g0qncg1g&checkoutMode=4&bid=1602852032215'

var urlBase = 'sollicita.com.br';

export var linkPlanos = 'https://sollicita.com.br/Planos'
export var linkPortal = 'https://portal.' + urlBase + '/'
export var linkMural = 'https://mural.' + urlBase + '/'
export var linkOrientacoes = 'https://orientacao.' + urlBase + '/'
export var linkPeriodicos = 'https://periodicos.' + urlBase + '/'
export var linkCapacitacao = 'https://capacitacao.' + urlBase + '/'
export var linkFerramentas = 'https://sollicita.com.br/'
export var linkSollicita = 'https://sollicita.com.br/'
export var urlSuporte = 'https://gnp.negociospublicos.net.br/np/suporte/login?';

var dev = false;
if (dev) {
    urlApi = 'https://localhost:44324/api/v1'
    urlApiSollicita = 'http://localhost:82/NP/SollicitaAdmin/api'
    urlLeitorPDF = 'http://localhost:8080/LeitorPdf';
    linkOrientacoes = 'http://localhost:8081/';
    linkSollicita = 'http://localhost:8080/';
    linkFerramentas = 'http://localhost:8081/'
    urlSuporte = 'http://localhost:82/np/suporte/login?'
    linkPortal = 'http://localhost:8081/';
} else {
    urlApi = 'https://api.' + urlBase + '/api/v1';
    urlApiSollicita = 'https://gnp.negociospublicos.net.br/NP/SollicitaAdmin/api'
    urlLeitorPDF = window.location.origin +'/LeitorPdf';
}

export function avisoErro(e) {
    if (e && e.response && e.response.data) {
        //alert(e.response.data);
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: e.response.data,
        })
    } else if (typeof e === 'string') {
        //alert(e);
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: e,
        })
    } else {
        //alert('Erro');
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: e,
        })
    }
}

export function avisoSucesso(msg) {
    Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: msg,
    })
}

export function avisoToast(msg, icon = 'success') {

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    Toast.fire({
        icon: icon,
        title: msg
    })
}

export function cnpjValidation(value) {
    if (!value) return false

    // Aceita receber o valor como string, número ou array com todos os dígitos
    const isString = typeof value === 'string'
    const validTypes = isString || Number.isInteger(value) || Array.isArray(value)

    // Elimina valor em formato inválido
    if (!validTypes) return false

    // Filtro inicial para entradas do tipo string
    if (isString) {
        // Limita ao máximo de 18 caracteres, para CNPJ formatado
        if (value.length > 18) return false

        // Teste Regex para veificar se é uma string apenas dígitos válida
        const digitsOnly = /^\d{14}$/.test(value)
        // Teste Regex para verificar se é uma string formatada válida
        const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value)

        // Se o formato é válido, usa um truque para seguir o fluxo da validação
        if (digitsOnly || validFormat) true
        // Se não, retorna inválido
        else return false
    }

    // Guarda um array com todos os dígitos do valor
    const match = value.toString().match(/\d/g)
    const numbers = Array.isArray(match) ? match.map(Number) : []

    // Valida a quantidade de dígitos
    if (numbers.length !== 14) return false

    // Elimina inválidos com todos os dígitos iguais
    const items = [...new Set(numbers)]
    if (items.length === 1) return false

    // Cálculo validador
    const calc = (x) => {
        const slice = numbers.slice(0, x)
        let factor = x - 7
        let sum = 0

        for (let i = x; i >= 1; i--) {
            const n = slice[x - i]
            sum += n * factor--
            if (factor < 2) factor = 9
        }

        const result = 11 - (sum % 11)

        return result > 9 ? 0 : result
    }

    // Separa os 2 últimos dígitos de verificadores
    const digits = numbers.slice(12)

    // Valida 1o. dígito verificador
    const digit0 = calc(12)
    if (digit0 !== digits[0]) return false

    // Valida 2o. dígito verificador
    const digit1 = calc(13)
    return digit1 === digits[1]
}

export default {
    userKey,
    rememberKey,
    urlApi,
    avisoErro,
    avisoSucesso,
    urlApiSollicita,
    urlLeitorPDF,
    linkPortal,
    linkMural,
    linkOrientacoes,
    linkPeriodicos,
    linkCapacitacao,
    linkPlanos,
    linkFerramentas,
    linkSollicita,
    cnpjValidation,
    avisoManualUsuarioKey,
    urlHotmart,
    urlSuporte
}